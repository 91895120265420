import React, { Fragment, Suspense, useMemo } from 'react';

import css from '@styled-system/css';
import { useRecoilValue } from 'recoil';

import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    applicationListState,
    getProductSelectionState,
    selectedApplication,
} from 'store/applications';
import { formatPercentage } from 'utils';

import type { I18n } from '@lingui/core';

const getAnswerValueMap = (i18n: I18n) => ({
    DIFFICULT_SITUATION: i18n._('productSelection.answer.DIFFICULT_SITUATION'),
    MINIMUM: i18n._('productSelection.answer.MINIMUM'),
    ALL_GOOD: i18n._('productSelection.answer.ALL_GOOD'),
    '0_PERCENT': formatPercentage(0),
    '25_PERCENT': formatPercentage(25),
    '50_PERCENT': formatPercentage(50),
    '75_PERCENT': formatPercentage(75),
    '100_PERCENT': formatPercentage(100),
    YES: i18n._('yes'),
    NO: i18n._('no'),
});

const SelectedProductStateView = () => {
    const { i18n } = useI18n();
    // Application dep required
    useRecoilValue(applicationListState);

    const application = useRecoilValue(selectedApplication);

    const productSelectionState = useRecoilValue(
        getProductSelectionState(application.id)
    );

    const answerValueMap = useMemo(() => getAnswerValueMap(i18n), [i18n]);

    return (
        <Grid
            gridColumnGap={10}
            gridTemplateColumns={'repeat(2, auto)'}
            gridAutoRows="auto"
        >
            <Divider css={css({ gridColumnStart: 'span 2', marginY: 2 })} />
            {productSelectionState.selectionQuestions.length ? (
                productSelectionState.selectionQuestions.map(
                    ({ question, answer }) => (
                        <Fragment key={`selection.${question}`}>
                            <SectionAnswer
                                label={`productSelection.question.${question}`}
                                value={answerValueMap[answer]}
                            />
                            <Divider
                                css={css({
                                    gridColumnStart: 'span 2',
                                    marginY: 2,
                                })}
                            />
                        </Fragment>
                    )
                )
            ) : (
                <Text
                    css={css({
                        fontSize: 1,
                        padding: '20px 98px 0 30px',
                    })}
                    tx={`dashboardHeader.customerNeeds.noInfo`}
                />
            )}
        </Grid>
    );
};

export const SelectedProductState = () => {
    return (
        <Suspense fallback={null}>
            <SelectedProductStateView />
        </Suspense>
    );
};

const SectionAnswer = ({ label, value }) => (
    <>
        <Text
            css={css({
                fontSize: 1,
                fontWeight: 600,
                color: 'brand',
                paddingLeft: 10,
            })}
            tx={label}
        />
        <Text css={css({ fontSize: 1 })}>{value}</Text>
    </>
);
