import React, { useMemo, useEffect } from 'react';

import { PDFPreview } from 'components/pdf/pdf-preview';
import { client as apiClient } from 'libs/api';
import { useAsyncMemo } from 'utils/async-hooks';

export type CreditReportPDFModalProps = {
    applicationId: number;
    applicantId: number;
};

export const CreditReportPDFModal = ({
    applicationId,
    applicantId,
}: CreditReportPDFModalProps) => {
    const [documentPreviewUrl, { pending }] = useAsyncMemo(
        () =>
            apiClient
                .getCreditReportsPDF(applicationId, applicantId)
                .then(({ data }) => data),
        [applicationId, applicantId]
    );

    const filePreviewUrl = useMemo(
        () =>
            URL.createObjectURL(
                new Blob([documentPreviewUrl], { type: 'application/pdf' })
            ),
        [documentPreviewUrl]
    );

    // Release memory for file preview URL.
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
    useEffect(
        () => () => URL.revokeObjectURL(filePreviewUrl),
        [filePreviewUrl]
    );

    return <PDFPreview url={filePreviewUrl} loading={pending} />;
};
