import React from 'react';

import { Trans } from '@lingui/react';
import { Button } from '@nestoca/ui';
import { BsArrowRight } from 'react-icons/bs';

import styles from './next-step-card.module.scss';
import { Step } from './next-step-info';

type Props = {
    step: Step;
};

export const NextStepAction = ({ step }: Props) => {
    return step.completed ? (
        <Button
            className={styles.action}
            size="small"
            variant="link"
            rightIcon={<BsArrowRight title={null} />}
            onClick={() => {
                // Add routing
            }}
        >
            <Trans id="viewOverview" />
        </Button>
    ) : (
        <Button
            className={styles.action}
            size="small"
            rightIcon={<BsArrowRight title={null} />}
            onClick={() => {
                // Add routing
            }}
        >
            <Trans id="start" />
        </Button>
    );
};
