import { Trans } from '@lingui/react';
import { useTenantSetting } from '@nestoca/multi-tenant';
import { Banner, Box, Flex, Grid, Heading } from '@nestoca/ui';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SidebarDashboard } from 'components/dashboard/sidebar-dashboard';
import { PARTNER, SEGMENT_LABEL } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getMainApplicant } from 'store/applications';
import { clientSegmentHasChanged } from 'store/atom';
import { Segment } from 'types/application';
import { useEditing } from 'utils/use-editing';
import { EditingProvider } from 'utils/use-editing-context';

import { DashboardModalWrapper } from '../dashboard-modal-wrapper';
import { DocumentsCardContainer } from '../documents-card/documents-card.container';

import { ApplicantCardContainer } from './applicant-card/applicant-container';
import styles from './broker-dashboard.module.scss';
import { CanadaLifeCard } from './canada-life-card';
import { ConsultantContact } from './consultant-contact';
import { CustomerDataCardContainer } from './customer-data-card/customer-data-card-container';
import { Header } from './header';
import { MainApplicant } from './main-applicant';
import { OtherMortgages } from './other-mortgages';
import { PropertyCardContainer } from './property-card/property-card.container';
import { ReferrerContact } from './referrer-contact';

type BrokerDashboardProps = {
    applicationId: number;
    segment: Segment;
};

type ReferralSection = {
    id: string;
    tenantSetting: boolean;
    Component: React.FC<{ applicationId: number; className?: string }>;
};

export const BrokerDashboard = ({
    applicationId,
    segment,
}: BrokerDashboardProps) => {
    const { i18n } = useI18n();

    const editing = useEditing();

    const mainApplicant = useRecoilValue(getMainApplicant(applicationId));

    const {
        settings: {
            sections: {
                showApplicantDetails,
                showConsultantContact,
                showOtherMortgages,
                showReferrerContact,
            },
        },
    } = useTenantSetting('brokerDashboard');

    const REFERRAL_SECTIONS: ReferralSection[] = [
        {
            id: 'main-applicant',
            tenantSetting: showApplicantDetails,
            Component: MainApplicant,
        },
        {
            id: 'consultant-contact',
            tenantSetting: showConsultantContact,
            Component: ConsultantContact,
        },
        {
            id: 'referrer-contact',
            tenantSetting: showReferrerContact,
            Component: ReferrerContact,
        },
        {
            id: 'other-mortgages',
            tenantSetting: showOtherMortgages,
            Component: OtherMortgages,
        },
    ];

    const showReferralSection = REFERRAL_SECTIONS.some(
        ({ tenantSetting }) => tenantSetting
    );

    const [hasClientSegmentChanged, setHasClientSegmentChanged] =
        useRecoilState(clientSegmentHasChanged);

    /* Set the clientSegmentHasChanged state to false 
       when the user acknowledges the change */
    const handleAcknowledgeClientSegmentChange = () => {
        setHasClientSegmentChanged(false);
    };

    return (
        <EditingProvider {...editing}>
            <DashboardModalWrapper>
                <Box className={styles.container}>
                    <Header />
                    <Banner
                        hasCloseButton
                        visible={hasClientSegmentChanged}
                        onClose={handleAcknowledgeClientSegmentChange}
                    >
                        {i18n._({
                            id: 'segment.hasChanged',
                            values: {
                                segment: SEGMENT_LABEL(i18n)[segment],
                            },
                        })}
                    </Banner>
                    <Flex wrap="wrap" gap={2}>
                        <ApplicantCardContainer />
                        <PropertyCardContainer />
                        {mainApplicant?.partner === PARTNER.CANADA_LIFE && (
                            <CanadaLifeCard segment={segment} />
                        )}
                        <DocumentsCardContainer />
                        <CustomerDataCardContainer />
                    </Flex>
                    {showReferralSection && (
                        <>
                            <Heading as="h2" size={5} weight={4}>
                                <Trans id="referral.sectionTitle" />
                            </Heading>
                            <Grid className={styles['referral-grid']} gap={2}>
                                {REFERRAL_SECTIONS.map(
                                    ({ id, tenantSetting, Component }) =>
                                        tenantSetting && (
                                            <Component
                                                key={id}
                                                applicationId={applicationId}
                                                className={styles[id]}
                                            />
                                        )
                                )}
                            </Grid>
                        </>
                    )}
                </Box>
                <SidebarDashboard applicationId={applicationId} />
            </DashboardModalWrapper>
        </EditingProvider>
    );
};
