import { Trans } from '@lingui/react';
import { Box, Flex } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { Badge } from 'components/badge/badge';
import { GdsTds } from 'components/scenarios/components/scenario-gds-tds';
import { Text } from 'components/text/text';
import { BADGE_TYPE } from 'constants/appConstants';
import {
    getCurrentAppMainApplicant,
    selectedApplication,
} from 'store/applications';

import styles from './broker-dashboard.module.scss';

import type { Variant } from 'components/badge/badge';

export const Header = () => {
    const { id: applicationId } = useRecoilValue(selectedApplication);
    const mainApplicant = useRecoilValue(getCurrentAppMainApplicant);
    const application = useRecoilValue(selectedApplication);

    return (
        <Flex className={styles.headerContainer}>
            <Box className={styles.applicantHeader}>
                <Box>
                    <Text as="h3" tx="mainApplicant" />
                    <Text as="h2">
                        {`${mainApplicant?.firstName} ${mainApplicant?.lastName}`}
                    </Text>
                </Box>
                <Badge
                    variant={
                        BADGE_TYPE[application.applicationState] as Variant
                    }
                >
                    <Trans id={application.applicationState} />
                </Badge>
            </Box>
            <GdsTds applicationId={applicationId} gridArea={'gds'} />
        </Flex>
    );
};
