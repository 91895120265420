import React from 'react';

import { Flex } from 'reflexbox/styled-components';
import styled, { css } from 'styled-components';

import type { BoxProps } from 'reflexbox/styled-components';

export type Props = {
    id?: string;
    children?: React.ReactNode | React.ReactNode[];
    preset?: 'primary' | 'secondary';
    selected?: boolean;
    className?: string;
} & Omit<BoxProps, 'color' | 'baseline' | 'alignContent' | 'alignSelf'>;

const SelectedProductStyle = ({ selected, theme }) =>
    selected &&
    css`
        border: 2px solid ${theme.global.colors.portGore};
    `;

const StyledBox = styled(Flex)<BoxProps & { selected: boolean }>`
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
    border-color: transparent;
    border-radius: 10px;
    ${SelectedProductStyle}
`;

export const Frame = ({ preset = 'primary', selected, ...rest }: Props) => (
    <StyledBox
        flexDirection="column"
        backgroundColor={preset === 'primary' ? 'white' : 'brand'}
        padding={3}
        justifyContent="space-between"
        border
        selected={selected}
        {...rest}
    />
);
