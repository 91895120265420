import React from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { useRecoilValue } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';
import { Titles } from 'components/titles/titles';
import {
    INCOME_TYPE_LABEL,
    TENURE_LABEL,
    EMPLOYMENT_TYPE_LABEL,
    OTHER_INCOME_LABELS,
    OtherIncomeEnum,
} from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    selectedApplication,
    getCurrentEmployers,
    getOtherIncomeList,
} from 'store/applications';
import { formatMoney } from 'utils';

const Label = ({ label, ...rest }: { label: string }) => (
    <Text
        css={{
            fontSize: 15,
            fontWeight: 600,
            color: '#212243',
        }}
        {...rest}
    >
        {label}
    </Text>
);

export const DashboardEmploymentModal = () => {
    const application = useRecoilValue(selectedApplication);
    const currentEmployers = useRecoilValue(
        getCurrentEmployers(application.id)
    );
    const otherIncomesList = useRecoilValue(getOtherIncomeList(application.id));

    const { i18n } = useI18n();

    return (
        <Box
            css={css({
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
            })}
        >
            <Grid
                gridGap={0}
                gridTemplateColumns="1fr"
                gridAutoRows="auto"
                gridTemplateAreas={`
                    "income-sectionTitle"
                    ${(currentEmployers || [])
                        .map((employer) => `"employerSection-${employer.id}"`)
                        .join('')}
                    `}
            >
                <Grid gridArea={`income-sectionTitle`}>
                    <Titles>
                        <Grid
                            gridTemplateColumns={`1.5fr repeat(3, 1fr) 1.5fr 0.5fr 0.7fr 1fr`}
                            gridAutoRows="auto"
                            gridTemplateAreas={`
                                "applicant incomeType jobTitle tenure employmentType employmentYears employmentMonths totalIncome"
                                `}
                        >
                            <Grid gridArea={`applicant`}>
                                <Trans id="applicant" />
                            </Grid>
                            <Grid gridArea={`incomeType`}>
                                <Trans id="Income Type" />
                            </Grid>
                            <Grid gridArea={`jobTitle`}>
                                <Trans id="jobTitle" />
                            </Grid>
                            <Grid gridArea={`tenure`}>
                                <Trans id="tenure" />
                            </Grid>
                            <Grid gridArea={`employmentType`}>
                                <Trans id="employmentType" />
                            </Grid>
                            <Grid gridArea={`employmentYears`}>
                                <Trans id="Years" />
                            </Grid>
                            <Grid gridArea={`employmentMonths`}>
                                <Trans id="Months" />
                            </Grid>
                            <Grid gridArea={`totalIncome`}>
                                <Trans id="income" />
                            </Grid>
                        </Grid>
                    </Titles>
                </Grid>

                {(currentEmployers || []).map((employer) => (
                    <Grid
                        key={`employerSection-${employer.id}`}
                        gridArea={`employerSection-${employer.id}`}
                        gridTemplateColumns={`1.5fr repeat(3, 1fr) 1.5fr 0.5fr 0.7fr 1fr`}
                        gridAutoRows="auto"
                        gridTemplateAreas={`
                                "applicant incomeType jobTitle tenure employmentType employmentYears employmentMonths totalIncome"
                                "divider divider divider divider divider divider divider divider"
                                `}
                        css={{ padding: '4px 15px' }}
                    >
                        <Grid gridArea={`applicant`}>
                            <Label
                                label={`${employer.applicant.firstName} ${employer.applicant.lastName}`}
                            />
                        </Grid>
                        <Grid gridArea={`incomeType`}>
                            <Label
                                label={
                                    INCOME_TYPE_LABEL(i18n)[employer.incomeType]
                                }
                            />
                        </Grid>
                        <Grid gridArea={`jobTitle`}>
                            <Label
                                label={
                                    employer?.jobTitle ? employer.jobTitle : '-'
                                }
                            />
                        </Grid>

                        <Grid gridArea={`tenure`}>
                            <Label
                                label={
                                    TENURE_LABEL(i18n)[employer.tenure] || '-'
                                }
                            />
                        </Grid>
                        <Grid gridArea={`employmentType`}>
                            <Label
                                label={
                                    EMPLOYMENT_TYPE_LABEL(i18n)[
                                        employer.employmentType
                                    ] || '-'
                                }
                            />
                        </Grid>
                        <Grid gridArea={`employmentYears`}>
                            <Label label={employer.employedYears || '-'} />
                        </Grid>
                        <Grid gridArea={`employmentMonths`}>
                            <Label label={employer.employedMonths || '-'} />
                        </Grid>
                        <Grid gridArea={`totalIncome`}>
                            <Label label={formatMoney(employer.totalIncome)} />
                        </Grid>
                        <Grid gridArea={`divider`}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            {!!otherIncomesList.length && (
                <Grid
                    css={{ marginTop: 30 }}
                    gridGap={0}
                    gridTemplateColumns="1fr"
                    gridAutoRows="auto"
                    gridTemplateAreas={`
                    "incomeOther-sectionTitle"
                    ${(otherIncomesList || [])
                        .map(
                            (otherIncome) =>
                                `"otherIncomeSection-${otherIncome.id}"`
                        )
                        .join('')}
                    `}
                >
                    <Grid gridArea={`incomeOther-sectionTitle`}>
                        <Titles>
                            <Grid
                                gridTemplateColumns={`1.5fr 1fr 3fr 1fr `}
                                gridAutoRows="auto"
                                gridTemplateAreas={`
                                "applicant type description total"
                                `}
                            >
                                <Grid gridArea={`applicant`}>
                                    <Trans id="applicant" />
                                </Grid>
                                <Grid gridArea={`type`}>
                                    <Trans id="dashboardHeader.otherIncome" />
                                </Grid>
                                <Grid gridArea={`description`}>
                                    <Trans id="expandedRatePage.notes.description" />
                                </Grid>
                                <Grid gridArea={`total`}>
                                    <Trans id="totalOtherIncome" />
                                </Grid>
                            </Grid>
                        </Titles>
                    </Grid>

                    {(otherIncomesList || []).map((otherIncome) => (
                        <Grid
                            key={`otherIncomeSection-${otherIncome.id}`}
                            gridArea={`otherIncomeSection-${otherIncome.id}`}
                            gridTemplateColumns={`1.5fr 1fr 3fr 1fr `}
                            gridAutoRows="auto"
                            gridTemplateAreas={`
                                "applicant type description  total"
                                "divider divider divider  divider"
                            `}
                            css={{ padding: '4px 15px' }}
                        >
                            <Grid gridArea={`applicant`}>
                                <Label
                                    label={`${otherIncome.applicant.firstName} ${otherIncome.applicant.lastName}`}
                                />
                            </Grid>
                            <Grid gridArea={`type`}>
                                <Label
                                    label={
                                        OTHER_INCOME_LABELS(i18n)[
                                            OtherIncomeEnum[otherIncome.type]
                                        ] || '-'
                                    }
                                />
                            </Grid>
                            <Grid gridArea={`description`}>
                                <Label label={otherIncome.description || '-'} />
                            </Grid>
                            <Grid gridArea={`total`}>
                                <Label
                                    label={formatMoney(
                                        otherIncome.totalOtherIncome || 0
                                    )}
                                />
                            </Grid>
                            <Grid gridArea={`divider`}>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};
