import {
    useRefreshApplicationMortgage,
    useRefreshApplications,
} from './applications';
import { useRefreshApplicationDocumentsCounts } from './documents';
import { useRefreshRatesFilters } from './filters.selector';
import { useRefreshQualification } from './qualification';

export const useRefreshApplicationContext = (applicationId: number) => {
    const refreshApplications = useRefreshApplications();
    const { refresh: refreshQualification } = useRefreshQualification();
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const refreshApplicationMortgage =
        useRefreshApplicationMortgage(applicationId);
    const refreshRatesFilters = useRefreshRatesFilters();

    return async () => {
        await refreshApplications();
        await refreshQualification(applicationId);
        await refreshApplicationDocumentsCounts();
        await refreshApplicationMortgage();
        refreshRatesFilters();

        return;
    };
};
