import React, { useState, useEffect } from 'react';

import cn from 'classnames';
import { nanoid } from 'nanoid';
import styled, { css } from 'styled-components';

import { Divider } from 'components/divider/divider';
import { CheckboxComponent, Label as LabelStyled } from 'components/forms';

export const CheckList = styled.ul`
    list-style: none;
    padding-left: 0;
`;

const ListItem = styled.li`
    > input[type='checkbox'] {
        margin-right: 10px;
    }
`;

const checkedStyles = css`
    text-decoration: line-through;
    color: #949599;
`;

const LabelContent = styled.span<{ checked?: boolean }>`
    ${({ checked }) => checked && checkedStyles}
`;

const Label = styled(LabelStyled)`
    font-size: 14px;
`;

type CheckListItemProps = {
    id?: string;
    children: React.ReactNode;
    defaultChecked?: boolean;
    onClick?: () => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    hideCheckbox?: boolean;
    checked?: boolean;
};

export const CheckListItem = ({
    id,
    children,
    defaultChecked = false,
    onClick,
    onChange,
    disabled = false,
    hideCheckbox = false,
    ...rest
}: CheckListItemProps) => {
    const [itemId] = React.useState(id || nanoid);

    const [checked, setChecked] = useState(rest?.checked || defaultChecked);

    useEffect(() => {
        if ([true, false].includes(rest?.checked)) {
            setChecked(rest?.checked);
        }
    }, [rest?.checked]);

    return (
        <ListItem>
            <Label
                className={cn('label', {
                    'label--line-through': rest.checked,
                })}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 'var(--spacing-3)',
                }}
                htmlFor={itemId}
                onClick={onClick && onClick}
            >
                <CheckboxComponent
                    id={itemId}
                    data-testid={itemId}
                    checked={checked}
                    onChange={(event) => {
                        onChange && onChange(event);
                    }}
                    disabled={disabled || hideCheckbox}
                    hidden={hideCheckbox}
                />

                <LabelContent checked={checked} style={{ width: '100%' }}>
                    {children}
                </LabelContent>
            </Label>
            <Divider />
        </ListItem>
    );
};
