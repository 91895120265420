import { useState } from 'react';

import { i18n } from '@lingui/core';
import { atomFamily, selectorFamily, atom } from 'recoil';

import { INVESTOR_ID_TO_LABEL } from 'constants/appConstants';
import { client as apiClient } from 'libs/api';
import { defaultRateFiltersSelector } from 'store/filters.selector';

import type { MiddleOfficeSyncForm } from 'types/middle-office-sync';
import type { ProductTerm, ProductType } from 'types/product';

export const moSyncState = atomFamily<MiddleOfficeSyncForm | null, number>({
    key: 'MOSyncState',
    default: null,
});

export const isSyncFormEditState = atom<boolean>({
    key: 'IsSyncFormEditState',
    default: false,
});

export const getApplication = selectorFamily({
    key: 'MOSyncGetApplication',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const syncData = get(moSyncState(applicationId));

            if (!syncData) {
                return null;
            }

            return {
                current: syncData.fields.currentApplication,
                updated: syncData.fields.updatedApplication,
            };
        },
});

export const getApplicant = selectorFamily({
    key: 'MOSyncGetApplicant',
    get:
        (params: { applicationId: number; applicantId: number }) =>
        ({ get }) => {
            const { applicantId, applicationId } = params;
            const application = get(getApplication(applicationId));

            if (!application) {
                return null;
            }

            const { current, updated } = application;

            return {
                current: current.applicants?.[applicantId],
                updated: updated.applicants?.[applicantId],
            };
        },
});

export const getMainApplicant = selectorFamily({
    key: 'MOSyncGetMainApplicant',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            if (!application) {
                return null;
            }

            return get(
                getApplicant({
                    applicationId,
                    applicantId: application.current.mainApplicantId,
                })
            );
        },
});

export const getCoBorrowers = selectorFamily({
    key: 'MOSyncGetCoBorrowersIDs',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const { current, updated } = get(getApplication(applicationId));

            const currentApplicants = { ...current.applicants };
            delete currentApplicants[current.mainApplicantId];

            return Object.values(currentApplicants)
                .map((applicant) => ({
                    current: applicant,
                    updated: updated.applicants?.[applicant.applicantId],
                }))
                .filter((applicants) => applicants.updated);
        },
});

export const getApplicantFullName = selectorFamily({
    key: 'MOSyncGetApplicantFullName',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const mainApplicant = get(getMainApplicant(applicationId));

            if (!mainApplicant) {
                return null;
            }

            return `${mainApplicant.current.firstName} ${mainApplicant.current.lastName}`;
        },
});

export function useSyncItems<T extends { id?: number }>(_items: T[] = []) {
    const [items, setItems] = useState(_items);

    const removeItem = (index: number) => {
        setItems((prev) => prev.filter((_, idx) => idx !== index));
    };

    const saveItem = (index: number, newItem: T) => {
        setItems((prev) =>
            prev.map((item, idx) => {
                if (idx === index) {
                    return { ...item, ...newItem };
                }

                return item;
            })
        );
    };

    return {
        items,
        removeItem,
        saveItem,
    };
}

export const getProductOptions = selectorFamily({
    key: 'getProductOptions',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const filters = get(defaultRateFiltersSelector(applicationId));

            try {
                const { data: products } = await apiClient.getAllProducts({
                    regionCode: filters.regionCode,
                    type: 'FIXED,VARIABLE',
                });

                return products;
            } catch {
                return [];
            }
        },
});

export const getLenderProductsOptions = selectorFamily({
    key: 'MOSyncGetLenderProductOptions',
    get:
        ({
            lenderId,
            applicationId,
            rateType,
            term,
        }: {
            lenderId: number;
            applicationId: number;
            rateType: ProductType;
            term: ProductTerm;
        }) =>
        async ({ get }) => {
            const products = get(getProductOptions({ applicationId }));
            const lenderProducts = products.filter(
                (product) =>
                    product.lenderId === lenderId &&
                    product.type === rateType &&
                    product.term === term
            );

            return lenderProducts.map(
                ({ id, name, type, term, investorId }) => ({
                    value: id,
                    label: `${name} - ${i18n._(type)} - ${i18n._(
                        term
                    )} - ${i18n._(INVESTOR_ID_TO_LABEL[investorId])}`,
                })
            );
        },
});

export const getSelectedProductName = selectorFamily({
    key: 'MOSyncGetSelectedProductOption',
    get:
        ({
            lenderId,
            applicationId,
            productId,
            rateType,
            term,
        }: {
            lenderId: number;
            applicationId: number;
            productId: number;
            rateType: ProductType;
            term: ProductTerm;
        }) =>
        ({ get }) => {
            if (productId) {
                const productOptions = get(
                    getLenderProductsOptions({
                        lenderId,
                        applicationId,
                        rateType,
                        term,
                    })
                );

                return productOptions.find(
                    (product) => product.value === productId
                )?.label;
            }
        },
});
