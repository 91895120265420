import React from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { useRecoilValue } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import { CheckList, CheckListItem } from 'components/check-list/check-list';
import { Emoji } from 'components/emoji';
import { Text } from 'components/text/text';
import { useToasts } from 'components/toast';
import { FLAGS_LABELS, HIDDEN_FLAGS } from 'constants/appConstants';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicant } from 'store/applications';
import { getFlags, useRefreshFlags } from 'store/flags';

import type { FlagType } from 'types/application';

type Props = { applicationId: number };

export const Flags = ({ applicationId }: Props) => {
    const flags = useRecoilValue(getFlags({ applicationId }));

    const { i18n } = useI18n();
    const { addToast } = useToasts();
    const refreshFlags = useRefreshFlags(applicationId);

    const onClickAction = async (flag: FlagType) => {
        try {
            await apiClient.updateFlags(applicationId, {
                ...flag,
                checked: !flag.checked,
            });

            refreshFlags();
        } catch (error) {
            addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                appearance: 'error',
            });
        }
    };

    return (
        <Box
            css={css({
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 5,
            })}
        >
            <Text
                css={css({ color: 'portGore', fontSize: 4, fontWeight: 700 })}
            >
                <Emoji
                    symbol="🚩"
                    label="property"
                    css={css({ marginRight: 2 })}
                />
                <Trans id="redFlags" />
            </Text>

            <CheckList>
                {flags?.flags.length > 0 ? (
                    (flags?.flags || []).map(
                        (flag) =>
                            !HIDDEN_FLAGS.includes(flag.code) && (
                                <Flag
                                    key={`flag_${flag.code}-${flag.created}`}
                                    flag={flag}
                                    applicationId={applicationId}
                                    onClickAction={onClickAction}
                                />
                            )
                    )
                ) : (
                    <Text tx="noFlags" />
                )}
            </CheckList>
        </Box>
    );
};

const Flag = ({
    flag,
    applicationId,
    onClickAction,
}: {
    flag: FlagType;
    applicationId: number;
    onClickAction: (flag: FlagType) => void;
}) => {
    const { i18n } = useI18n();

    const applicant = useRecoilValue(
        getApplicant({ applicationId, applicantId: flag.applicantId })
    );

    const applicantName =
        applicant && `${applicant?.firstName} ${applicant?.lastName}`;

    return (
        <CheckListItem
            onChange={() => onClickAction(flag)}
            checked={flag.checked}
        >
            {FLAGS_LABELS(i18n, applicantName)[flag.code]}
        </CheckListItem>
    );
};
