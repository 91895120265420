import { useTenantSetting } from '@nestoca/multi-tenant';
import { Banner, Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import {
    FREQUENCY,
    LIABILITY_PAYMENT_MULTIPLIER_OPTIONS,
    LIABILITY_PAYOFF_MAP,
} from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { Liability } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    liability: Liability;
};

export const Payment = ({ isCreating, isEditing, liability }: Props) => {
    const { i18n } = useI18n();
    const { settings: liabilityPayOffOptions } = useTenantSetting(
        'liabilityPayOffOptions'
    );

    const { showCreditMultiplier } = useFlags();

    const { watch } = useFormContext() || {};

    const { creditType, fromCreditBureau, payment, paymentMultiplier, payoff } =
        liability;

    let creditTypeWatch: Liability['creditType'] = creditType;

    if (watch) {
        creditTypeWatch = watch('creditType');
    }

    const LIABILITY_PAY_OFF_OPTIONS = liabilityPayOffOptions.map(
        (liabilityOption) => {
            const { id, labelKey } = LIABILITY_PAYOFF_MAP[liabilityOption];

            return {
                value: id,
                label: i18n._(`${labelKey}`),
            };
        }
    );

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('payments')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="payment.amount"
                    label="amount"
                    fieldType="money"
                    value={payment?.amount}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="payment.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={payment?.frequency}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />

                {showCreditMultiplier && (
                    <EditableCell
                        isEditing={isEditing}
                        name="paymentMultiplier"
                        label="paymentMultiplier"
                        fieldType="select"
                        options={LIABILITY_PAYMENT_MULTIPLIER_OPTIONS(i18n)}
                        value={paymentMultiplier}
                        isClearable
                        isFieldInvalidCompareFn={compareForId(liability)}
                    />
                )}

                <EditableCell
                    isEditing={isEditing}
                    name="payoff"
                    label="payoff"
                    fieldType="select"
                    options={LIABILITY_PAY_OFF_OPTIONS}
                    value={payoff}
                    isClearable
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
                <EditableCell
                    isEditing={isEditing}
                    name="fromCreditBureau"
                    label="fromCreditBureau"
                    fieldType="readonly"
                    value={fromCreditBureau}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
            </Grid>
            <Banner visible={creditTypeWatch === 'REVOLVING'}>
                {i18n._('liabilities.revolvingMessage')}
            </Banner>
        </Flex>
    );
};
