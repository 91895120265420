import React, { forwardRef } from 'react';

import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { shadow, variant } from 'styled-system';

import type { BoxProps } from 'reflexbox/styled-components';
import type { ShadowProps, ResponsiveValue } from 'styled-system';
import type { Assign } from 'types/components';

interface TitlesOwnProps extends BoxProps, ShadowProps {
    // As prop is defined into BoxProps
    // as?: React.ElementType;
    variant?: ResponsiveValue<Variant>;
}

export type TitlesProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    TitlesOwnProps
>;

type Variant = 'sectionTitle';

export const TitlesStyled = styled(Flex)`
    display: inline-block;
    vertical-align: baseline;
    font-weight: bold;
    white-space: nowrap;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
    color: white;
    min-width: 100%;

    ${variant({
        scale: 'titles',
        prop: 'variant',
    })}
    ${shadow}
`;

export type TitlesType = BoxProps & TitlesProps;

export const Titles: React.FC<TitlesType> = forwardRef(
    (
        { variant = 'sectionTitle', ...rest }: TitlesType,
        ref: React.Ref<HTMLDivElement>
    ) => <TitlesStyled ref={ref} boxShadow="none" variant={variant} {...rest} />
);

Titles.displayName = 'Titles';
