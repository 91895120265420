import { Grid } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { PROPERTY_VALUE_MAP } from 'constants/property';
import { useI18n } from 'providers/i18n/use-i18n';
import { getPropertySelectors } from 'store/applications';

import { CellData } from '../../applicants/components/cell-data';

export const Renewal = () => {
    const { i18n } = useI18n();
    const {
        application: { property },
        sumAssets,
        mortgageAmount,
        closingCost,
    } = useRecoilValue(getPropertySelectors);

    if (!property) {
        return null;
    }

    return (
        <Grid css={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
            <CellData
                tx={'dashboardHeader.propertyType'}
                value={i18n._(
                    PROPERTY_VALUE_MAP[property.propertyType].labelKey
                )}
            />
            <CellData
                fieldType="money"
                tx="Property Value"
                value={property.purchasePrice}
            />
            <CellData
                fieldType="money"
                tx={'mortgageBalance'}
                value={mortgageAmount.amount}
            />
            <CellData
                fieldType="money"
                tx={'closingCost'}
                value={closingCost}
            />
            <CellData
                fieldType="money"
                tx={'additionalAmount'}
                value={property.additionalFundAmount}
            />
            <CellData
                fieldType="money"
                tx={'totalAssets'}
                value={sumAssets.value}
            />
        </Grid>
    );
};
