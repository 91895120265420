import { useState } from 'react';

import { Trans } from '@lingui/react';
import { Button, ButtonIcon, Card, Divider, Flex } from '@nestoca/ui';
import { BsPinMap, BsHouseDoor } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';

import { Text } from 'components/text/text';
import { useModal } from 'providers/modals/use-modal';
import { getSubjectProperty } from 'store/applications';

import styles from './property-card.module.scss';
import { PropertyInfo } from './property-info';
import { PropertyMap } from './property-map';

type Props = {
    applicationId: number;
    showViewMore?: boolean;
};

export const PropertyCard = ({ applicationId, showViewMore }: Props) => {
    const [showMapView, setShowMapView] = useState(false);

    const property = useRecoilValue(getSubjectProperty(applicationId));

    const handleToggleView = () => {
        setShowMapView((currentState) => !currentState);
    };

    const { isVisible: propertyModalVisible, open: openPropertyModal } =
        useModal('dashBoardPropertyModal');

    return (
        <Card className={styles.property_card}>
            <Flex
                className={styles.property_header}
                align="center"
                justify="between"
                wrap="wrap"
                gap={4}
            >
                <Flex align="center" gap={2}>
                    <BsHouseDoor title={null} size={24} />
                    <Text
                        tx={'asset.property'}
                        fontSize="var(--font-size-1)"
                        fontWeight="var(--font-weight-7)"
                    />
                </Flex>
                <Flex align="center" gap={2}>
                    {showViewMore && (
                        <Button
                            size="medium"
                            variant="secondary"
                            isActive={propertyModalVisible}
                            onClick={() => openPropertyModal()}
                        >
                            <Trans id="viewMore" />
                        </Button>
                    )}
                    <ButtonIcon
                        aria-label="map-btn"
                        isRound
                        size="medium"
                        variant="ghost"
                        isActive={showMapView}
                        onClick={handleToggleView}
                        icon={<BsPinMap key="pin-map-icon" />}
                    />
                </Flex>
            </Flex>
            <Divider />
            {showMapView ? (
                <PropertyMap
                    applicationId={applicationId}
                    property={property}
                />
            ) : (
                <PropertyInfo
                    applicationId={applicationId}
                    property={property}
                />
            )}
        </Card>
    );
};
