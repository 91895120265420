import React, { Fragment } from 'react';

import { Trans } from '@lingui/react';
import intlFormat from 'date-fns/intlFormat';
import { useRecoilValue } from 'recoil';
import { Box, Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';

import { Badge } from 'components/badge/badge';
import { Card } from 'components/card/new-card';
import { ApplicantInfo } from 'components/dashboard-header/components/applicant-info';
import { ApplicationInfo } from 'components/dashboard-header/components/application-info';
import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';
import { BADGE_TYPE } from 'constants/appConstants';
import { useAuth } from 'providers/auth';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getMainApplicant,
    getCoApplicantsList,
    selectedApplication,
} from 'store/applications';
import { getApplicationMainType } from 'utils/application-type';

import type { I18n } from '@lingui/core';
import type { Variant } from 'components/badge/badge';

const ApplicationTypeWrapper = styled(Flex)`
    position: relative;
    background: ${({ theme }) => theme.global.colors['portGore']};
    margin: 0 -16px;
    color: ${({ theme }) => theme.colors.white};
    padding: 16px 16px 24px 16px;
    border-radius: 10px 10px 0 0;
`;

export const MainApplicationTypeLabel = (i18n: I18n) => ({
    NEW: i18n._('type.NEW'),
    RENEWAL: i18n._('type.RENEWAL'),
    REFINANCE: i18n._('type.REFINANCE'),
});

const ApplicationType = styled(Box)`
    font-weight: 600;
    font-size: 20;
    padding-right: 16px;
`;

const ApplicantInfoWrapper = styled(Grid)`
    position: relative;
    top: -10px;
    margin: 0 -16px;
    padding: 16px;
`;

const PartnerInfo = ({ partner }: { partner: string }) => (
    <Box ml={2} fontWeight={700} fontSize={3}>
        <Trans id="partner" />
        <Text css={{ paddingLeft: 4 }} fontSize={3}>
            {partner}
        </Text>
    </Box>
);

export const DashboardHeader = () => {
    const application = useRecoilValue(selectedApplication);
    const mainApplicant = useRecoilValue(getMainApplicant(application.id));
    const coApplicantsList = useRecoilValue(
        getCoApplicantsList(application.id)
    );

    const { client } = useAuth();

    const { i18n } = useI18n();

    const { mainType } = getApplicationMainType(application.type);

    return (
        <Card
            variant="summary"
            css={{
                marginBottom: 40,
                marginTop: 10,
                paddingTop: 0,
            }}
        >
            <ApplicationTypeWrapper css={{ justifyContent: 'space-between' }}>
                <Flex>
                    <ApplicationType>
                        {MainApplicationTypeLabel(i18n)[mainType]}
                    </ApplicationType>
                    <PartnerInfo partner={client?.partner} />
                </Flex>
                <Text tx="mainApplicant">
                    <b>
                        : {mainApplicant?.firstName} {mainApplicant?.lastName}
                    </b>
                </Text>
            </ApplicationTypeWrapper>
            <ApplicantInfoWrapper>
                <Flex mb={3} justifyContent="space-between">
                    <Badge
                        variant={
                            BADGE_TYPE[application.applicationState] as Variant
                        }
                    >
                        <Trans id={application.applicationState} />
                    </Badge>
                    <Text>
                        {i18n._('Created')}:{'  '}
                        {intlFormat(
                            new Date(application.created),
                            {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                            },
                            { locale: i18n.locale }
                        )}
                    </Text>
                </Flex>
                <ApplicantInfo
                    applicant={mainApplicant}
                    currentApplicationId={application.id}
                />
                {(coApplicantsList || []).map((applicant) => (
                    <Fragment
                        key={`dashboard-header-applicant-${applicant.applicantId}`}
                    >
                        <Divider variant="dashboard" />

                        <ApplicantInfo
                            applicant={applicant}
                            currentApplicationId={application.id}
                        />
                    </Fragment>
                ))}
            </ApplicantInfoWrapper>
            <Grid>
                <ApplicationInfo
                    currentApplicationId={application.id}
                    application={application}
                />
            </Grid>
        </Card>
    );
};
