export type Referral = {
    id: number;
    mainApplicantId: number;
    applicants: ReferralApplicant[];
    closingAuthority: string;
    contacts: ReferralContact[];
    externalData: ReferralExternalData;
    productsDiscussed: string[];
    rateDiscountType: string;
    tenantReferralId: string;
    time: string;
    updated: string;
};

export type ReferralApplicant = {
    applicantId: number;
    externalData: ReferralExternalData;
};

export type ReferralContact = {
    contactType: 'referrer' | 'consultant' | 'other';
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    externalData: ReferralExternalData;
};

export type OtherMortgage = {
    appraisalValue: number;
    currentMortgageAmount: number;
    currentMortgageBalance: number;
    currentMortgageBalanceDate: string;
    currentPropertyAddress: ReferralAddress;
    downPayment: number;
    existingLoanNumber: string;
    fundingDate: string;
    interestRate: number;
    interestRateType: ReferralInterestRateType;
    maturityDate: string;
    mlsNumber: string;
    paymentAmount: number;
    paymentFrequency: ReferralPaymentFrequency;
    propertyType: ReferralPropertyType;
    purchasePrice: number;
    remainingTermDays: number;
};

export type ReferralAddress = {
    city: string;
    number: string;
    postalCode: string;
    province: string;
    street: string;
};

export type ReferralPaymentFrequency =
    | 'Annual'
    | 'Bi-Annually'
    | 'Bi-Monthly'
    | 'Bi-Weekly'
    | 'Daily'
    | 'Monthly'
    | 'Other'
    | 'Quarterly'
    | 'Semi-Annual'
    | 'Semi-Monthly'
    | 'Weekly';

// The keys used in this map are the actual value sent from the BE for FR / EN
export const REFERRAL_PAYMENT_FREQUENCY_TRANSLATION_MAP: Record<
    ReferralPaymentFrequency,
    string
> = {
    Annual: 'referral.paymentFrequencyAnnual',
    'Bi-Annually': 'referral.paymentFrequencyBiAnnually',
    'Bi-Monthly': 'referral.paymentFrequencyBiMonthly',
    'Bi-Weekly': 'referral.paymentFrequencyBiWeekly',
    Daily: 'referral.paymentFrequencyDaily',
    Monthly: 'referral.paymentFrequencyMonthly',
    Other: 'referral.paymentFrequencyOther',
    Quarterly: 'referral.paymentFrequencyQuarterly',
    'Semi-Annual': 'referral.paymentFrequencySemiAnnual',
    'Semi-Monthly': 'referral.paymentFrequencySemiMonthly',
    Weekly: 'referral.paymentFrequencyWeekly',
} as const;

export type ReferralInterestRateType =
    | 'Adjustable Rate; Adjustable Payment'
    | 'Adjustable Rate; Adjustable Payment; Closed'
    | 'Adjustable Rate; Adjustable Payment; Open'
    | 'Closed'
    | 'Fixed Closed'
    | 'Fixed Open'
    | 'Fixed Rate Mortgage'
    | 'Fixed Rate Mortgage; Closed'
    | 'Fixed Rate Mortgage; Open'
    | 'Lock & Roll Mortgage'
    | 'Lock & Roll Mortgage; Closed'
    | 'Lock & Roll Mortgage; Open'
    | 'Open'
    | 'Variable Closed'
    | 'Variable Open'
    | 'Variable Rate Mortgage'
    | 'Variable Rate Mortgage; Closed';

// The keys used in this map are the actual value sent from the BE for FR / EN
export const REFERRAL_INTEREST_RATE_TYPE_TRANSLATION_MAP: Record<
    ReferralInterestRateType,
    string
> = {
    'Adjustable Rate; Adjustable Payment':
        'referral.interestRateType.adjustableRateAdjustablePayment',
    'Adjustable Rate; Adjustable Payment; Closed':
        'referral.interestRateType.adjustableRateAdjustablePaymentClosed',
    'Adjustable Rate; Adjustable Payment; Open':
        'referral.interestRateType.adjustableRateAdjustablePaymentOpen',
    Closed: 'referral.interestRateType.closed',
    'Fixed Closed': 'referral.interestRateType.fixedClosed',
    'Fixed Open': 'referral.interestRateType.fixedOpen',
    'Fixed Rate Mortgage': 'referral.interestRateType.fixedRateMortgage',
    'Fixed Rate Mortgage; Closed':
        'referral.interestRateType.fixedRateMortgageClosed',
    'Fixed Rate Mortgage; Open':
        'referral.interestRateType.fixedRateMortgageOpen',
    'Lock & Roll Mortgage': 'referral.interestRateType.lockRollMortgage',
    'Lock & Roll Mortgage; Closed':
        'referral.interestRateType.lockRollMortgageClosed',
    'Lock & Roll Mortgage; Open':
        'referral.interestRateType.lockRollMortgageOpen',
    Open: 'referral.interestRateType.open',
    'Variable Closed': 'referral.interestRateType.variableClosed',
    'Variable Open': 'referral.interestRateType.variableOpen',
    'Variable Rate Mortgage': 'referral.interestRateType.variableRate',
    'Variable Rate Mortgage; Closed': 'referral.interestRateType.variableRate',
} as const;

export type ReferralPropertyType =
    | 'SingleFamily'
    | 'Condominium'
    | 'MultiFamily'
    | 'TownhouseCondo'
    | 'LeasedLand'
    | 'Strata'
    | 'FreeHoldCondo'
    | 'Recreation'
    | 'LeaseHoldCondo'
    | 'Other';

// The keys used in this map are the actual value sent from the BE for FR / EN
export const REFERRAL_PROPERTY_TYPE_TRANSLATION_MAP: Record<
    ReferralPropertyType,
    string
> = {
    SingleFamily: 'referral.propertyType.singleFamily',
    Condominium: 'referral.propertyType.condominium',
    MultiFamily: 'referral.propertyType.multiFamily',
    TownhouseCondo: 'referral.propertyType.townhouseCondo',
    LeasedLand: 'referral.propertyType.leasedLand',
    Strata: 'referral.propertyType.strata',
    FreeHoldCondo: 'referral.propertyType.freeHoldCondo',
    Recreation: 'referral.propertyType.recreation',
    LeaseHoldCondo: 'referral.propertyType.leaseHoldCondo',
    Other: 'referral.propertyType.other',
} as const;

export type ReferralExternalData = Record<string, string | any[]> & {
    other_mortgages: OtherMortgage[];
};
