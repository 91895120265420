import React, { useState } from 'react';

import { Trans } from '@lingui/react';
import { Flex } from '@nestoca/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import {
    useRecoilCallback,
    useRecoilValue,
    useSetRecoilState,
    useRecoilRefresher_UNSTABLE,
} from 'recoil';

import { Button } from 'components/button-v2';
import { Spinner } from 'components/spinner';
import { useToasts } from 'components/toast';
import { LenderId } from 'constants/lenderConstants';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { ratesTabsState } from 'store/atom';
import { useRefreshHelocApplication } from 'store/heloc';
import {
    currentElegibility,
    getProductEligibility,
} from 'store/product-selection';
import {
    getQualificationState,
    sectionSelectedState,
    useRefreshQualification,
} from 'store/qualification';
import { useRefreshApplicationContext } from 'store/refresh';
import {
    useEditingRights,
    useEditProductAfterNotesSubmittedRights,
} from 'store/rights';
import { InvestorId, SectionKeys } from 'types/application';
import { getInvestorCodeByID } from 'utils/product';

import type { AdvisorReminderModalProps } from 'components/advisor-reminder-modal';

type Props = {
    applicationId: number;
    investorId: number;
    lenderId: number;
    productId: number;
    regionCode: string;
    productVersion?: number;
    productName?: string;
    isHelocProduct?: boolean;
    insurable?: boolean;
};

const investorReminders: InvestorId[] = [InvestorId.TD];

export const ProductSelectButton = ({
    applicationId,
    investorId,
    productId,
    regionCode,
    productVersion = 0,
    productName,
    lenderId,
    isHelocProduct = false,
    insurable,
}: Props) => {
    const { push } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const { showProductEligibility } = useFlags();
    const { addToast } = useToasts();
    const { i18n } = useI18n();

    const setProductElegibility = useSetRecoilState(currentElegibility);

    const hasEditingRights = useEditingRights();
    const hasEditProductAfterNotesSubmittedRights =
        useEditProductAfterNotesSubmittedRights();

    const refreshContext = useRefreshApplicationContext(applicationId);
    const refreshHeloc = useRefreshHelocApplication(applicationId);
    const qualification = useRecoilValue(getQualificationState(applicationId));
    const { refresh: refreshQualification } = useRefreshQualification();

    const setSectionSelected = useSetRecoilState(
        sectionSelectedState({ applicationId })
    );
    const ratesTab = useRecoilValue(ratesTabsState);

    const { amortizationMonths, amortizationYears } = qualification;

    const { open: openAdvisorReminderModal } =
        useModal<AdvisorReminderModalProps>('advisorReminderModal');

    const { open: openMissingProductRightsModal } = useModal(
        'missingProductRightsModal'
    );

    const getIsElegible = useRecoilCallback(
        ({ snapshot }) =>
            async ({ applicationId, productId, productVersion }) => {
                return await snapshot.getPromise(
                    getProductEligibility({
                        applicationId,
                        productId,
                        productVersion,
                    })
                );
            },
        []
    );

    /*  We need to check if the product is elegible every time we select the product
        if we don't use this, it will cache the result and get inaccurate data if we change the data */
    const forceCheckElegibility = useRecoilRefresher_UNSTABLE(
        getProductEligibility({
            applicationId,
            productId,
            productVersion,
        })
    );

    const handleSelectThisProduct = async () => {
        setIsLoading(true);

        await apiClient.updateApplicationProduct(applicationId, {
            productId,
        });

        await refreshQualification(applicationId);

        const { data: mortgageDetails } =
            await apiClient.getApplicationMortgage(applicationId);

        // Update mortgage details application servicing `investor`
        // Cannot do `Promise.all` because we need to wait for the API call to finish
        // It will create a backend race condition and wont update the investor
        await apiClient.updateApplicationMortgage(applicationId, {
            ...mortgageDetails,
            amortizationMonths,
            amortizationYears,
            investor: getInvestorCodeByID(investorId),
            rateOverride: null,
        });

        await refreshContext();

        // set application review section to mortgage details
        setSectionSelected(SectionKeys.mortgageDetails);

        setIsLoading(false);

        push(`/applications/${applicationId}`);
    };

    const handleOpenAdvisorReminderModal = () => {
        openAdvisorReminderModal({
            applicationId,
            investorId,
            productId,
            regionCode,
            productVersion,
            productName,
            onConfirm: handleSelectThisProduct,
            insurable,
        });
    };

    const onClick = async () => {
        if (!hasEditProductAfterNotesSubmittedRights) {
            return openMissingProductRightsModal();
        }

        if (showProductEligibility) {
            try {
                forceCheckElegibility();
                const isProductElegible = await getIsElegible({
                    applicationId,
                    productId,
                    productVersion,
                });

                setProductElegibility(isProductElegible);

                if (!isProductElegible.decision) {
                    handleOpenAdvisorReminderModal();
                    return;
                }
            } catch (error) {
                addToast(
                    `Warning: ${i18n._({ id: 'warningProductEligibility' })}`,
                    {
                        appearance: 'error',
                    }
                );
            }
        }

        lenderId === LenderId.NESTO && investorReminders.includes(investorId)
            ? handleOpenAdvisorReminderModal()
            : handleSelectThisProduct();
    };

    const handleSelectHelocProduct = async () => {
        try {
            setIsLoading(true);
            await apiClient.updateApplicationHelocProduct(applicationId, {
                productId,
            });

            await Promise.all([
                refreshQualification(applicationId),
                refreshContext(),
                refreshHeloc(),
            ]);

            // set application review section to heloc details
            setSectionSelected(SectionKeys.helocDetails);

            setIsLoading(false);

            push(`/applications/${applicationId}`);
        } catch (error) {
            setIsLoading(false);
            console.error('updateApplicationHelocProduct error', error);
            throw error;
        }
    };

    return (
        <Flex style={{ width: '100%' }}>
            {ratesTab !== 'SIMULATION' && (
                <Button
                    data-testid="select-this-product"
                    variant="ghost"
                    onClick={
                        isHelocProduct ? handleSelectHelocProduct : onClick
                    }
                    iconRight={isLoading ? <Spinner size={16} /> : null}
                    width="inherit"
                    disabled={!hasEditingRights}
                >
                    <Trans id="selectThisProduct" />
                </Button>
            )}
        </Flex>
    );
};
