import { I18n } from '@lingui/core';
import * as yup from 'yup';

import { hasAmount } from 'utils/general';

import { minMoneyOf, minMoneyRequiredOf } from './number';
import { stringRequired } from './string';

export const getAmountFrequencySchema = (i18n: I18n) =>
    yup
        .object()
        .nullable(true)
        .shape(
            {
                amount: yup
                    .number()
                    .zeroAsUndefined()
                    .when('frequency', {
                        is: (frequency: string) => frequency,
                        then: minMoneyRequiredOf(i18n),
                        otherwise: minMoneyOf(i18n),
                    }),
                frequency: yup.string().when('amount', {
                    is: (amount: number) => amount,
                    then: stringRequired(i18n).nullable().emptyAsUndefined(),
                    otherwise: yup.string().optional().nullable().default(''),
                }),
            },
            // @ts-ignore
            ['amount', 'frequency']
        );

export const getAmountFrequencyRequiredSchema = (i18n: I18n) =>
    yup.object().shape({
        amount: minMoneyRequiredOf(i18n),
        frequency: stringRequired(i18n).emptyAsUndefined().nullable(),
    });

export const getAmountFrequencyOptionalSchema = (i18n: I18n) =>
    yup.object().shape({
        amount: minMoneyOf(i18n),
        frequency: yup.string().when('amount', {
            is: (amount: number) => hasAmount(amount),
            then: (schema) =>
                schema.required(i18n._('error.fieldRequired')).nullable(),
            otherwise: (schema) => schema.nullable(),
        }),
    });
