import { atomFamily, selectorFamily, useSetRecoilState } from 'recoil';

import { client as apiClient } from 'libs/api';

export const getApplicationFlags = selectorFamily({
    key: 'getApplicationFlags',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async () => {
            try {
                const { data } = await apiClient.getApplicationSummary(
                    applicationId
                );
                return data.flagSummary;
            } catch (error) {
                console.error('Flags error', error);
            }
        },
});

export const getFlags = atomFamily({
    key: 'getFlags',
    default: getApplicationFlags,
});

export const useRefreshFlags = (applicationId) => {
    const setNotes = useSetRecoilState(getFlags({ applicationId }));

    return async () => {
        const { data } = await apiClient.getApplicationSummary(applicationId);
        setNotes(data.flagSummary);

        return data.flagSummary;
    };
};
