import * as yup from 'yup';

import { getAddressSchema } from './address';
import { getAmountFrequencyRequiredSchema } from './amount-frequency';
import { numberRequired } from './number';
import { stringRequired } from './string';

import type { I18n } from '@lingui/core';

export const getRegisterAddressSchema = (i18n: I18n) =>
    yup.object().shape({
        address: getAddressSchema(i18n),
        isCurrentAddress: stringRequired(i18n),
        occupiedYears: numberRequired(i18n).emptyAsUndefined().min(0),
        occupiedMonths: yup.number().when('occupiedYears', {
            is: (occupiedYears: number) => occupiedYears,
            then: numberRequired(i18n).emptyAsUndefined().min(0),
            otherwise: numberRequired(i18n)
                .emptyAsUndefined()
                .min(1, i18n._({ id: 'error.minMonthsAddress' })),
        }),
        situation: stringRequired(i18n),
        rent: yup.object().when('situation', {
            is: (situation) => situation === 'RENTING',
            then: getAmountFrequencyRequiredSchema(i18n),
            otherwise: (schema) => schema.nullable(true),
        }),
    });
