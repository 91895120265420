import React from 'react';

import { Box } from 'reflexbox/styled-components';

import type { BoxProps } from 'reflexbox/styled-components';

type EmojiProps = Omit<
    {
        label?: string;
        symbol: string;
    } & BoxProps &
        JSX.IntrinsicElements['span'],
    'color' | 'ref'
>;

export const Emoji = ({ label, symbol, ...rest }: EmojiProps) => (
    <Box
        as="span"
        aria-hidden={label ? undefined : true}
        aria-label={label ? label : undefined}
        role="img"
        css={{
            fontFamily: 'segoe ui emoji',
        }}
        {...rest}
    >
        {symbol}
    </Box>
);
