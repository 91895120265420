import React from 'react';

import { Trans } from '@lingui/react';
import { useFormState } from 'react-hook-form';
import { Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';
import { Spinner } from 'components/spinner';

export const FormActions = ({
    onSkip,
    onSkipAll,
    isSubmitDisabled = false,
    label = 'Submit',
}: {
    onSkip?: () => void;
    onSkipAll?: () => void;
    isSubmitDisabled?: boolean;
    label?: string;
}) => {
    const { isSubmitting } = useFormState();

    return (
        <Flex
            css={{
                paddingTop: 32,
                justifyContent: 'center',
            }}
        >
            {onSkipAll && (
                <Button
                    variant="ghost"
                    type="button"
                    css={{
                        paddingLeft: '4em',
                        paddingRight: '4em',
                        marginRight: '4em',
                    }}
                    onClick={onSkipAll}
                >
                    <Trans id="skipAll" />
                </Button>
            )}
            {onSkip && (
                <Button
                    variant="ghost"
                    type="button"
                    css={{
                        paddingLeft: '4em',
                        paddingRight: '4em',
                        marginRight: '4em',
                    }}
                    onClick={onSkip}
                >
                    <Trans id="skip" />
                </Button>
            )}
            <Button
                type="submit"
                css={{
                    paddingLeft: '4em',
                    paddingRight: '4em',
                }}
                iconRight={isSubmitting ? <Spinner size={16} /> : null}
                disabled={isSubmitDisabled}
            >
                <Trans id={label} />
            </Button>
        </Flex>
    );
};
