import * as yup from 'yup';

import { minMoneyOf, minMoneyRequiredOf } from './number';
import { stringRequired } from './string';

import type { I18n } from '@lingui/core';
import type { AssetTypes } from 'types/asset';

export const getCreateAssetPartialSchema = (i18n: I18n) =>
    yup
        .object()
        .shape({
            applicantId: stringRequired(i18n),
        })
        .concat(getAssetPartialSchema(i18n));

export const getAssetPartialSchema = (i18n: I18n) =>
    yup.object().shape({
        type: stringRequired(i18n),
        totalValue: minMoneyOf(i18n).optional().emptyAsUndefined(),
        amountUsedForDownPayment: yup
            .object()
            .when(['totalValue', 'type'], (totalValue, type) => {
                if (type === 'VEHICLE') {
                    return yup
                        .number()
                        .optional()
                        .nullable()
                        .emptyAsUndefined();
                } else if (totalValue) {
                    return minMoneyRequiredOf(i18n)
                        .max(
                            totalValue,
                            i18n._({ id: 'amountUsedForDownPayment' })
                        )
                        .emptyAsUndefined();
                }

                return minMoneyRequiredOf(i18n).emptyAsUndefined();
            }),
        /**
         * This should be improved to check for the current asset type
         *
         * @see https://nestoca.atlassian.net/browse/OG-6847
         */
        // type -> GIFT
        donorFirstName: yup.string().optional(),
        donorLastName: yup.string().optional(),
        familyRelationship: yup.string().optional(),

        /**
         * This should be improved to check for the current asset type
         *
         * @see https://nestoca.atlassian.net/browse/OG-6847
         */
        // type -> NON_REGISTERED_INVESTMENTS/RRSP/TFSA/SAVINGS
        institution: yup.string().optional().nullable(),
        institutionOther: yup.string().optional().nullable(),

        /**
         * This should be improved to check for the current asset type
         *
         * @see https://nestoca.atlassian.net/browse/OG-6847
         */
        // type -> OTHER
        name: yup.string().optional().nullable(),

        /**
         * This should be improved to check for the current asset type
         *
         * @see https://nestoca.atlassian.net/browse/OG-6847
         */
        // type -> OTHER/VEHICLE
        description: yup.string().optional().nullable(),

        /**
         * This should be improved to check for the current asset type
         *
         * @see https://nestoca.atlassian.net/browse/OG-6847
         */
        // type -> PROPERTY
        existingPropertyId: yup.number().when('type', {
            is: (type: AssetTypes) => type === 'PROPERTY',
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional(),
        }),

        /**
         * This should be improved to check for the current asset type
         *
         * @see https://nestoca.atlassian.net/browse/OG-6847
         */
        // type -> VEHICLE
        make: yup.string().optional().nullable(),
        model: yup.string().optional().nullable(),
        year: yup.number().optional().isValueNaN().nullable(),
    });
