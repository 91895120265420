import { useCallback, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { useI18n } from 'providers/i18n/use-i18n';
import { isEmpty } from 'utils';

export const useProblems = (
    problems: any = [],
    applicantId?: number,
    entity: any = undefined
) => {
    const { i18n } = useI18n();
    const methods = useFormContext();

    const problemList = Array.isArray(problems)
        ? problems
        : problems?.[applicantId] || [];

    const setProblemErrors = useCallback(
        (setError) => {
            problemList.forEach((problem) => {
                let shouldSetError = false;
                const hasFieldName = !!problem.field.name?.length;
                const hasEntityId = !!problem.entityId;

                if (
                    hasFieldName &&
                    hasEntityId &&
                    problem?.entityId === entity?.id
                ) {
                    shouldSetError = true;
                } else if (hasFieldName && entity === undefined) {
                    shouldSetError = true;
                }

                if (shouldSetError) {
                    setError(problem.field.name, {
                        type: 'warning',
                        message: !isEmpty(problem.detail?.[i18n.locale])
                            ? problem.detail?.[i18n.locale]
                            : i18n._('errors.fieldInvalid'),
                    });
                }
            });
        },
        [problems, applicantId]
    );

    useEffect(() => {
        if (methods) {
            const {
                setError,
                formState: { isSubmitting },
            } = methods;

            if (!isSubmitting && setError) {
                setProblemErrors(setError);
            }
        }
    }, [problems, applicantId]);
};
