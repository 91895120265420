import { Chip, Flex, Typography } from '@nestoca/ui';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { ProgressCircle } from 'components/progress-circle/progress-circle';
import { useI18n } from 'providers/i18n/use-i18n';

import styles from './icon-name-number.module.scss';

type IconNameNumberProps = {
    title: string;
    total?: number;
    approved?: number;
    rejected?: number;
    text?: string;
    size?: number;
    onClick?: () => void;
};

const IconNameNumber = ({
    title,
    total,
    approved,
    rejected,
    text,
    size = 30,
    onClick,
}: IconNameNumberProps) => {
    const value = (approved / total) * 100;
    const { i18n } = useI18n();

    return (
        <Flex
            justify="between"
            align="center"
            className={styles.container}
            onClick={onClick}
        >
            <Flex align="center">
                {value === 100 && !rejected ? (
                    <IoIosCheckmarkCircle
                        size={size}
                        fill="var(--color-green-600)"
                    />
                ) : (
                    <ProgressCircle
                        size={size}
                        text={text}
                        value={value}
                        borderColor="var(--color-blue-500)"
                        primaryStrokeWidth={3}
                    />
                )}
                <Typography className={styles.title}>
                    {i18n._(title)}
                </Typography>
            </Flex>
            <Flex>
                {rejected > 0 && <Chip label={`${rejected}`} variant="error" />}
                <Flex className={styles.totalDocuments}>
                    <Chip label={`${approved} / ${total}`} />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default IconNameNumber;
