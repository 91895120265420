import React, { useMemo, useEffect } from 'react';

import { PDFPreview } from 'components/pdf/pdf-preview';
import { client as apiClient } from 'libs/api';
import { useAsyncMemo } from 'utils/async-hooks';

export type ApplicationReviewSummaryPDFModalProps = {
    applicationId: number;
};

export const ApplicationReviewSummaryPDFModal = ({
    applicationId,
}: ApplicationReviewSummaryPDFModalProps) => {
    const [documentPreviewUrl, { pending, error }] = useAsyncMemo(
        () =>
            apiClient
                .getApplicationReviewSummaryPDF(applicationId)
                .then(({ data }) => data),
        [applicationId]
    );

    const filePreviewUrl = useMemo(
        () =>
            URL.createObjectURL(
                new Blob([documentPreviewUrl], { type: 'application/pdf' })
            ),
        [documentPreviewUrl]
    );

    // Release memory for file preview URL.
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
    useEffect(
        () => () => URL.revokeObjectURL(filePreviewUrl),
        [filePreviewUrl]
    );

    return <PDFPreview url={filePreviewUrl} loading={pending} error={error} />;
};
