import React from 'react';

import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { CheckListItem } from 'components/check-list/check-list';
import { FLAGS_LABELS } from 'constants/appConstants';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicant } from 'store/applications';
import { useRefreshFlags } from 'store/flags';
import { FlagType } from 'types/application';

type Props = {
    applicationId: number;
    flag: FlagType;
};

export const Flag = ({ applicationId, flag }: Props) => {
    const { i18n } = useI18n();
    const refreshFlags = useRefreshFlags(applicationId);

    const applicant = useRecoilValue(
        getApplicant({ applicationId, applicantId: flag.applicantId })
    );

    const applicantName = `${applicant?.firstName} ${applicant?.lastName}`;

    const onClickAction = async (flag: FlagType) => {
        try {
            await apiClient.updateFlags(applicationId, {
                ...flag,
                checked: !flag.checked,
            });

            refreshFlags();
        } catch (error) {
            toast.error(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                closeOnClick: true,
            });
        }
    };

    return (
        <CheckListItem
            onChange={() => onClickAction(flag)}
            checked={flag.checked}
        >
            {FLAGS_LABELS(i18n, applicantName)[flag.code]}
        </CheckListItem>
    );
};
