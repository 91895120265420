import { IncomeEmployment } from 'types/applicant';
import { formatAddress } from 'utils';

/**
 * Update applicant employments with valid addresses
 * @param applicantEmployments List of applicant employments
 * @returns List of applicant employments with valid addresses
 */
export const updateApplicantEmployments = (
    applicantEmployments: IncomeEmployment[]
) => {
    const employments: IncomeEmployment[] = [];

    applicantEmployments.forEach((employment) => {
        /* Don't include employment if address is in this format:
           {unit: '', streetNumber: '', street: '', city: '', stateCode: '', …} */
        const formattedAddress = formatAddress(employment.employer.address);

        if (formattedAddress) {
            employments.push(employment);
        }
    });

    return employments;
};

/**
 * Get applicant employment address selected
 * @param employmentId Employment ID
 * @param applicantEmployments List of applicant employments
 * @returns Employment address
 */
export const getApplicantEmploymentAddress = (
    employmentId: number | null,
    applicantEmployments: IncomeEmployment[]
) =>
    applicantEmployments.find((employment) => employment.id === employmentId)
        ?.employer?.address;
