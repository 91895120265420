import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This hook is used to determine if an element is in the view
 * @param intersectionObserverOptions Options that let you control the circumstances under which the observer's callback is invoked
 * @returns The inView state and a ref to the observable element
 */
export const useInView = (
    intersectionObserverOptions?: IntersectionObserverInit
) => {
    const [inView, setInView] = useState(false);

    const observableElementRef = useRef<HTMLElement | null>(null);

    const intersectionObserverCallback: IntersectionObserverCallback =
        useCallback(([entry]: IntersectionObserverEntry[]) => {
            setInView(entry.isIntersecting);
        }, []);

    useEffect(() => {
        if (!observableElementRef?.current) return;

        const observer = new IntersectionObserver(
            intersectionObserverCallback,
            intersectionObserverOptions
        );

        observer.observe(observableElementRef.current);

        return () => {
            observer.disconnect();
        };
    }, [intersectionObserverCallback, intersectionObserverOptions]);

    return { inView, observableElementRef };
};
