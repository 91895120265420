import { ProblemType } from 'constants/problem';
import { SectionKeys } from 'types/application';

export const SECTION_KEYS_TO_PROBLEM_TYPE = {
    [SectionKeys.applicants]: ProblemType.applicants,
    [SectionKeys.employments]: ProblemType.employments,
    [SectionKeys.otherIncome]: ProblemType.incomeOthers,
    [SectionKeys.ownedProperties]: ProblemType.properties,
    [SectionKeys.assetsAndDownPayment]: ProblemType.downPayment,
    [SectionKeys.subjectProperty]: ProblemType.subjectProperty,
    [SectionKeys.liabilities]: ProblemType.liabilities,
    [SectionKeys.mortgageDetails]: ProblemType.mortgageDetails,
    [SectionKeys.helocDetails]: ProblemType.helocDetails,
};

export const PROBLEM_TYPE_TO_SECTION_KEYS = {
    [ProblemType.applicants]: SectionKeys.applicants,
    [ProblemType.registeredAddresses]: SectionKeys.applicants,
    [ProblemType.employments]: SectionKeys.employments,
    [ProblemType.incomeOthers]: SectionKeys.otherIncome,
    [ProblemType.properties]: SectionKeys.ownedProperties,
    [ProblemType.assets]: SectionKeys.assetsAndDownPayment,
    [ProblemType.downPayment]: SectionKeys.assetsAndDownPayment,
    [ProblemType.subjectProperty]: SectionKeys.subjectProperty,
    [ProblemType.liabilities]: SectionKeys.liabilities,
    [ProblemType.mortgageDetails]: SectionKeys.mortgageDetails,
    [ProblemType.helocDetails]: SectionKeys.helocDetails,
};
