import React from 'react';

import css from '@styled-system/css';

import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';

/**
 * @deprecated use <Typography> from '@nestoca/ui' instead
 */
export const ApplicationLabel = ({
    gridArea,
    tx,
    sx,
}: {
    gridArea: string;
    tx: string;
    sx?: any;
}) => (
    <Grid
        gridArea={gridArea}
        css={css({
            alignItems: 'center',
            borderTop: '1px solid',
            borderColor: 'gray-4',
            ...sx,
        })}
    >
        <Text
            css={css({ fontSize: 16, color: 'dark-1', fontWeight: 400 })}
            tx={tx}
        />
    </Grid>
);
