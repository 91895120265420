import React, { useState } from 'react';

import css from '@styled-system/css';

import { Box } from 'components/box';
import { Popper } from 'components/popper/popper';
import { Text } from 'components/text/text';
import { formatMoney, formatPercentage } from 'utils';

type LabelWithPopperProps = {
    label: string;
    ratioValue: string | number;
    frequency?: string;
    onClick?: () => void;
    isTotal?: boolean;
    clickable?: boolean;
    originalValue: number | string;
    ratio: number;
};

export const ScenarioLabelWithPopper = ({
    label,
    ratioValue,
    frequency,
    isTotal = false,
    onClick,
    clickable = true,
    originalValue,
    ratio,
}: LabelWithPopperProps) => {
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    return (
        <>
            <Text
                onClick={onClick}
                tx={label}
                css={css({
                    fontSize: 1,
                    fontWeight: '600',
                    cursor: clickable ? 'pointer' : 'default',
                    textDecoration: clickable ? 'underline' : '',
                })}
            />
            <Box
                ref={setReferenceElement}
                onMouseOver={() => {
                    setIsPopperOpen(true);
                }}
                onMouseLeave={() => {
                    setIsPopperOpen(false);
                }}
            >
                <Text
                    data-testid={`${label}-value`}
                    css={css({
                        fontSize: 1,
                        fontWeight: isTotal ? 600 : 400,
                    })}
                >
                    {ratioValue} {frequency && '/'} {frequency}
                </Text>
                <Popper
                    isOpen={isPopperOpen}
                    referenceElement={referenceElement}
                    placement="top"
                >
                    {formatMoney(originalValue)} x{' '}
                    {formatPercentage(ratio * 100)}
                </Popper>
            </Box>
        </>
    );
};
