import React from 'react';

import { Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import { useI18n } from 'providers/i18n/use-i18n';
import { SelectOptions } from 'types';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    applicantId: number;
    applicantOptions: SelectOptions<number>;
};

export const Applicant = ({
    isCreating,
    isEditing,
    applicantId,
    applicantOptions,
}: Props) => {
    const { i18n } = useI18n();

    return (
        <Flex
            className={cn(styles.section, styles['section--applicant'], {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('applicant')}
            </Typography>
            <EditableCell
                required
                isEditing={isEditing}
                name="applicantId"
                label="applicant"
                fieldType="select"
                options={applicantOptions}
                value={applicantId}
            />
        </Flex>
    );
};
