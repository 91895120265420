import React from 'react';

import css from '@styled-system/css';

import { EditableCell } from 'components/editable';
import { Grid } from 'components/grid/grid';

import type { EditableCellProps } from 'components/editable';

type InputContainerProps = {
    isEditing?: boolean;
    gridArea: string;
    value: number | string;
    placeholder?: string;
    name: string;
    input?: 'number' | 'money' | 'text' | 'select' | 'percentage';
    sx?: any;
    onChange?: () => void;
    onChangeSelect?: () => void;
    disabled?: boolean;
    groupedOptions?: boolean;
};

export const InputContainer = ({
    gridArea,
    value,
    name,
    input = 'money',
    placeholder,
    sx,
    onChange,
    disabled = false,
    groupedOptions = false,
    ...rest
}: InputContainerProps & EditableCellProps) => (
    <Grid
        gridArea={gridArea}
        css={css({
            alignItems: 'center',
            fontWeight: 400,
            borderTop: '1px solid',
            borderColor: 'gray-4',
            padding: '6px 0',
            ...sx,
        })}
    >
        <EditableCell
            isEditing
            fieldType={input}
            name={name}
            label={placeholder}
            value={value}
            variant="lightGray"
            onChange={onChange}
            hidePlaceholder={!placeholder}
            disabled={disabled}
            groupedOptions={groupedOptions}
            {...rest}
        />
    </Grid>
);
