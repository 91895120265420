import React from 'react';

import css from '@styled-system/css';
import intlFormat from 'date-fns/intlFormat';
import ReactMarkdown from 'react-markdown';
import { useRecoilValue } from 'recoil';
import { Flex } from 'reflexbox/styled-components';
import gfm from 'remark-gfm';
import styled from 'styled-components';

import { Card } from 'components/card/new-card';
import { Text } from 'components/text/text';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicants } from 'store/applications';
import {
    notesState,
    getNotesBySection,
    getApplicationNotes,
    getNotes,
} from 'store/notes';

export const MarkdownContainer = styled.div`
    margin-top: 20px;
    font-size: 12px;

    h3 {
        font-size: 17px;
    }

    h4 {
        font-size: 16px;
    }

    h5 {
        font-size: 15px;
    }

    ul {
        padding-inline-start: 20px;
        font-size: 12px;
    }
`;

export const NotesTopSection = styled(Flex)`
    font-size: 11px;
    color: ${({ theme }) => theme.global.colors['neutral-3']};
    font-weight: 600;
`;

export const Broker = ({ note }: any) => {
    const { i18n } = useI18n();

    return (
        <Flex flexDirection="row" justifyContent={'space-between'}>
            <Text fontSize={0} fontWeight={400}>
                Broker:{' '}
                {`${note.sender.name?.firstName} ${note.sender.name?.lastName}`}
            </Text>
            <Text fontSize={0} fontWeight={400}>
                {intlFormat(
                    new Date(note.updated),
                    {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        hour: 'numeric',
                        minute: '2-digit',
                    },
                    { locale: i18n.locale }
                )}
            </Text>
        </Flex>
    );
};

export const NotesList = ({ applicationId }: { applicationId: number }) => {
    const noteState = useRecoilValue(notesState);
    const notes = useRecoilValue(getNotesBySection(applicationId));
    const applicants = useRecoilValue(getApplicants(applicationId));

    if (!notes) return null;

    return (
        <>
            {notes.map((note) => (
                <Card
                    key={`notes-${note.id}`}
                    css={css({
                        borderRadius: 0,
                        flexDirection: 'column',
                        width: '100%',
                        boxShadow: 'md',
                        padding: 2,
                        marginBottom: 3,
                        fontWeight: 700,
                    })}
                >
                    <Broker note={note} />
                    {noteState.section !== 'property' && (
                        <NotesTopSection>
                            Applicant:{' '}
                            {`${applicants[note.applicantId]?.firstName} ${
                                applicants[note.applicantId]?.lastName
                            }`}
                        </NotesTopSection>
                    )}
                    <MarkdownContainer>
                        <ReactMarkdown remarkPlugins={[[gfm]]}>
                            {note.message?.text}
                        </ReactMarkdown>
                    </MarkdownContainer>
                </Card>
            ))}
        </>
    );
};

export const SubmissionNotesList = ({
    applicationId,
}: {
    applicationId: number;
}) => {
    const notes = useRecoilValue(
        getApplicationNotes({ NodeType: 'submission', applicationId })
    );

    return (
        <>
            {!!notes?.length &&
                notes.map((note) => (
                    <Card
                        key={`notes-${note.id}`}
                        css={css({
                            borderRadius: 0,
                            flexDirection: 'column',
                            width: '100%',
                            boxShadow: 'md',
                            padding: 2,
                            marginBottom: 3,
                            fontWeight: 700,
                        })}
                    >
                        <Broker note={note} />
                        <MarkdownContainer>
                            <ReactMarkdown remarkPlugins={[[gfm]]}>
                                {note.message?.text}
                            </ReactMarkdown>
                        </MarkdownContainer>
                    </Card>
                ))}

            {!notes?.length && (
                <Text
                    css={css({ marginTop: 3, fontSize: 12, color: 'dark-1' })}
                    tx="noSubmissionNotes"
                />
            )}
        </>
    );
};

export const GeneralNotesList = ({
    applicationId,
}: {
    applicationId: number;
}) => {
    // load deps first
    // load notes / BE request before getting them by type
    useRecoilValue(getNotes({ applicationId }));

    const notes = useRecoilValue(
        getApplicationNotes({ NodeType: 'general', applicationId })
    );

    return (
        <>
            {!!notes?.length &&
                notes.map((note) => (
                    <Card
                        css={css({
                            borderRadius: 0,
                            flexDirection: 'column',
                            width: '100%',
                            boxShadow: 'md',
                            padding: 2,
                            marginBottom: 3,
                            fontWeight: 700,
                        })}
                        key={`notes-${note.id}`}
                    >
                        <Broker note={note} />
                        <MarkdownContainer>
                            <ReactMarkdown remarkPlugins={[[gfm]]}>
                                {note.message?.text}
                            </ReactMarkdown>
                        </MarkdownContainer>
                    </Card>
                ))}

            {!notes?.length && (
                <Text
                    css={css({ marginTop: 3, fontSize: 12, color: 'dark-1' })}
                    tx="noGeneralNotes"
                />
            )}
        </>
    );
};
