import { useEffect, useRef } from 'react';

import { Trans } from '@lingui/react';
import { Typography, Grid, Flex } from '@nestoca/ui';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
    DividerSpanTwoCol,
    GdsIncome,
} from 'components/scenarios/components/gds-tds-fields';
import { ScenarioLabel } from 'components/scenarios/components/scenario-label';
import styles from 'components/scenarios/scenarios.module.scss';
import {
    getQualificationFrequency,
    getQualificationState,
} from 'store/qualification';
import { SectionKeys } from 'types/application';
import { formatMoney } from 'utils';

type ScenarioLTIProps = {
    handleOnClick: (section: SectionKeys) => void;
    applicationId: number;
};

export const ScenarioLTI = ({
    applicationId,
    handleOnClick,
}: ScenarioLTIProps) => {
    const initialFrequencyRef = useRef(null);
    const qualification = useRecoilValue(getQualificationState(applicationId));
    const [frequency, setFrequency] = useRecoilState(
        getQualificationFrequency(applicationId)
    );

    // LTI needs to be calculated annually
    // Set the frequency to ANNUALLY on mount and reset it to the initial value on unmount
    useEffect(() => {
        if (!initialFrequencyRef.current) {
            initialFrequencyRef.current = frequency;
        }

        setFrequency('ANNUALLY');

        return () => setFrequency(initialFrequencyRef.current);
    }, [frequency, setFrequency]);

    return (
        <Grid gridArea="content" className={styles['scenario-container']}>
            <GdsIncome
                applicationId={applicationId}
                handleOnClick={handleOnClick}
            />

            <Flex justify="center" className={styles['scenario-container']}>
                <Typography textColor="var(--color-primary-b-500)" weight={6}>
                    <Trans id="lti" />
                </Typography>
            </Flex>

            <Grid>
                <ScenarioLabel
                    label="totalFinancingAmount"
                    value={formatMoney(
                        qualification?.totalFinancingAmount || 0,
                        true
                    )}
                    onClick={() => handleOnClick(SectionKeys.mortgageDetails)}
                />
                <DividerSpanTwoCol />
                <ScenarioLabel
                    label="lti"
                    value={qualification?.lti}
                    clickable={false}
                />
            </Grid>
        </Grid>
    );
};
