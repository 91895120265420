import React from 'react';

import { Box } from '@nestoca/ui';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import styles from './next-step-card.module.scss';
import { Step } from './next-step-info';

type Props = {
    step: Step;
};

export const NextStepIndicator = ({ step }: Props) => {
    return step.completed ? (
        <IoIosCheckmarkCircle color="var(--color-green-600)" size={28} />
    ) : (
        <Box className={styles.round_indicator}>{step.number}</Box>
    );
};
