import React from 'react';

import { Card, Divider, Flex } from '@nestoca/ui';
import { Image } from 'grommet';
import { useRecoilValue } from 'recoil';

import { Text } from 'components/text/text';
import { getLender } from 'constants/lenderConstants';
import { getApplicationMortgage } from 'store/applications';

import styles from './rate-card.module.scss';
import { RateInfo } from './rate-info';

type Props = {
    applicationId: number;
};

export const RateCard = ({ applicationId }: Props) => {
    const { product, rateExpirationDate } = useRecoilValue(
        getApplicationMortgage(applicationId)
    );

    const lender = getLender(product?.lenderId);

    return (
        <Card className={styles.rate_card}>
            <Flex className={styles.rate_header} direction="column">
                <Flex align="center" gap={2}>
                    <Image
                        alt={lender?.namePath || 'logo lender'}
                        src={lender.image}
                        height="32px"
                    />
                    <Text
                        tx="rate"
                        fontSize="var(--font-size-1)"
                        fontWeight="var(--font-weight-7)"
                    />
                </Flex>
            </Flex>
            <Divider />
            <RateInfo
                product={product}
                rateExpirationDate={rateExpirationDate}
            />
        </Card>
    );
};
