import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import { INDUSTRY_SECTOR } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { IncomeEmployment } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    employment: IncomeEmployment & { totalIncome?: number };
    partialSchema: boolean;
};

export const IndustryDetails = ({
    isCreating,
    isEditing,
    employment,
    partialSchema,
}: Props) => {
    const { i18n } = useI18n();

    const { watch } = useFormContext() || {};

    const {
        employedMonths,
        employedYears,
        industry,
        jobTitle,
        monthsInIndustry,
        yearsInIndustry,
    } = employment;

    let employedMonthsWatch = employedMonths;

    let employedYearsWatch = employedYears;

    let monthsInIndustryWatch = monthsInIndustry;

    let yearsInIndustryWatch = yearsInIndustry;

    if (watch) {
        employedMonthsWatch = watch('employedMonths');

        employedYearsWatch = watch('employedYears');

        monthsInIndustryWatch = watch('monthsInIndustry');

        yearsInIndustryWatch = watch('yearsInIndustry');
    }

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('industryDetails')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit-larger']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    required={!partialSchema}
                    isEditing={isEditing}
                    name="jobTitle"
                    label="jobTitle"
                    value={jobTitle}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={!partialSchema}
                    isEditing={isEditing}
                    name="industry"
                    label="industry"
                    fieldType="select"
                    options={INDUSTRY_SECTOR(i18n)}
                    value={industry}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !partialSchema &&
                        !employedMonthsWatch &&
                        employedMonthsWatch !== 0
                    }
                    isEditing={isEditing}
                    name="employedYears"
                    label="employedYears"
                    fieldType="number"
                    integer
                    min={0}
                    value={employedYears}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !partialSchema &&
                        !employedYearsWatch &&
                        employedYearsWatch !== 0
                    }
                    isEditing={isEditing}
                    name="employedMonths"
                    label="employedMonths"
                    fieldType="number"
                    integer
                    min={0}
                    value={employedMonths}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !partialSchema &&
                        !monthsInIndustryWatch &&
                        monthsInIndustryWatch !== 0
                    }
                    isEditing={isEditing}
                    name="yearsInIndustry"
                    label="yearsInIndustry"
                    fieldType="number"
                    integer
                    min={0}
                    value={yearsInIndustry}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !partialSchema &&
                        !yearsInIndustryWatch &&
                        yearsInIndustryWatch !== 0
                    }
                    isEditing={isEditing}
                    name="monthsInIndustry"
                    label="monthsInIndustry"
                    fieldType="number"
                    integer
                    min={0}
                    value={monthsInIndustry}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
            </Grid>
        </Flex>
    );
};
