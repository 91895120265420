import React, { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';
import { PROPERTY_PURPOSE_OPTIONS } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    currentApplicationIdState,
    getSubjectProperty,
} from 'store/applications';
import { formatAddress } from 'utils';

import { CellData } from './applicants/components/cell-data';


import type { Address } from 'types/address';

export const PropertyView = () => {
    const currentApplicationId = useRecoilValue(currentApplicationIdState);
    const property = useRecoilValue(getSubjectProperty(currentApplicationId));
    const { i18n } = useI18n();

    return property ? (
        <Grid
            gridTemplateColumns={`
                repeat(2, 1fr)
            `}
            gridTemplateAreas={`
                "dividerAddress dividerAddress"
                "formattedAddress formattedAddress"
                "dividerPurpose dividerPurpose"
                "purpose acceptanceDate"
                "dividerPrice dividerPrice"
                "purchasePrice estimatedPropertyValue"
                "dividerBottom dividerBottom"
            `}
            gridAutoFlow="row"
            gridGap={10}
        >
            <Grid gridArea="dividerAddress">
                <Divider css={{ margin: '10px 0 0 0' }} />
            </Grid>
            <CellData
                gridArea={`formattedAddress`}
                fieldType="readonly"
                label={i18n._(`propertyAddress`)}
                // send react link instead of value
                // @ts-ignore
                value={<AddressLink address={property?.address} />}
                variant="primary"
            />
            <Grid gridArea="dividerPurpose">
                <Divider css={{ margin: 0 }} />
            </Grid>
            <CellData
                gridArea={`purchasePrice`}
                fieldType="money"
                tx={`purchasePrice`}
                value={property.purchasePrice}
            />
            <CellData
                gridArea={`acceptanceDate`}
                fieldType="date"
                tx={`acceptanceDate`}
                value={property.acceptanceDate}
                variant="primary"
            />
            <Grid gridArea="dividerPrice">
                <Divider css={{ margin: 0 }} />
            </Grid>
            <CellData
                gridArea={`purpose`}
                fieldType="select"
                tx={`purpose`}
                value={property.purpose}
                options={PROPERTY_PURPOSE_OPTIONS(i18n)}
            />
            <CellData
                gridArea={`estimatedPropertyValue`}
                fieldType="money"
                tx={`dashboard.estimatedPropertyValue`}
                value={property.estimatedPropertyValue}
                variant="primary"
            />
            <Grid gridArea="dividerBottom">
                <Divider css={{ margin: 0 }} />
            </Grid>
        </Grid>
    ) : null;
};

export const Property = (props: any) => (
    <React.Suspense fallback={null}>
        <PropertyView {...props} />
    </React.Suspense>
);

type AddressLinkProp = {
    address: Address;
};

const AddressLink = ({ address }: AddressLinkProp) => {
    const googleUrl = useMemo(
        () =>
            `https://www.google.com/maps/place/${formatAddress(address).replace(
                /\s/g,
                '+'
            )}`,
        [address]
    );

    if (!address) {
        return null;
    }

    return (
        <a href={googleUrl} target="_blank" rel="noreferrer">
            {formatAddress(address)}
        </a>
    );
};
