import React from 'react';

import { Grid } from '@nestoca/ui';
import { useRouter } from 'next/router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Box } from 'reflexbox';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import {
    EMPLOYMENT_TYPE_LABEL,
    INCOME_TYPE_LABEL,
} from 'constants/appConstants';
import { MARITAL_STATUS } from 'constants/labels';
import { useI18n } from 'providers/i18n/use-i18n';
import { getDashboardApplicantSelector } from 'store/applications';
import { sectionSelectedState } from 'store/qualification';
import { formatMoney } from 'utils';

export const ApplicantCard = ({ applicant }) => {
    const { i18n } = useI18n();
    const { firstName, lastName, applicantId } = applicant;
    const router = useRouter();

    const { applicationId, totalIncome, isMainApplicant } = useRecoilValue(
        getDashboardApplicantSelector({ applicantId })
    );

    const setSectionSelected = useSetRecoilState(
        sectionSelectedState({ applicationId })
    );

    return (
        <Grid
            css={{
                gridTemplateColumns: 'auto auto',
            }}
        >
            <Box css={{ gridColumn: 'span 2' }}>
                <CellData
                    fieldType="text"
                    tx={
                        isMainApplicant
                            ? i18n._('mainApplicant')
                            : i18n._('coApplicant')
                    }
                    value={`${firstName} ${lastName}`}
                />
            </Box>
            <CellData
                fieldType="text"
                tx={'Income Type'}
                value={
                    INCOME_TYPE_LABEL(i18n)[
                        applicant?.income?.employments[0]?.incomeType
                    ]
                }
            />
            <CellData
                fieldType="text"
                tx={'employmentType'}
                value={
                    EMPLOYMENT_TYPE_LABEL(i18n)[
                        applicant?.income?.employments[0]?.employmentType
                    ]
                }
            />
            <CellData
                fieldType="text"
                tx={'maritalStatus'}
                value={MARITAL_STATUS(i18n)[applicant.maritalStatus]}
            />

            <Box>
                <CellData
                    fieldType="text"
                    tx={'dashboardHeader.totalIncome'}
                    value={formatMoney(totalIncome)}
                    onClick={() => {
                        setSectionSelected('employments');
                        router.push(`/applications/${applicationId}`);
                    }}
                    variant="link"
                    css={{
                        color: 'var(--color-blue-500)',
                        cursor: 'pointer',
                    }}
                />
            </Box>
        </Grid>
    );
};
