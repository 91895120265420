import * as yup from 'yup';

import { moneyRequired } from './number';
import { stringRequired } from './string';

import type { I18n } from '@lingui/core';
import type { OtherIncomeType } from 'types/applicant';

export const getCreateOtherIncomeSchema = (i18n: I18n) =>
    yup
        .object()
        .shape({
            applicantId: stringRequired(i18n),
            type: stringRequired(i18n),
        })
        .concat(getOtherIncomeSchema(i18n));

export const getOtherIncomeSchema = (i18n: I18n) =>
    yup.object().shape({
        income: yup.object().shape({
            // BE doesn't allow an amount of $0 but can be negative
            amount: moneyRequired(i18n).test({
                name: "Amount can't be 0$",
                message: i18n._('error.valueGreaterOrLowerThan', {
                    value: 0,
                }),
                test: (value: number) => value !== 0,
            }),
            frequency: stringRequired(i18n).emptyAsUndefined().nullable(),
        }),
        description: yup.string().when('type', {
            is: (type: OtherIncomeType) =>
                ['INVESTMENT_INCOME', 'INTEREST_INCOME', 'OTHER'].includes(
                    type
                ),
            then: (schema) =>
                schema.required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: (schema) => schema.optional().nullable(),
        }),
    });
