import { useCallback } from 'react';

import type { Editor } from '@tiptap/react';

export const useMarkup = (editor: Editor) => {
    const toggleBold = useCallback(
        () => editor.chain().focus().toggleBold().run(),
        [editor]
    );

    const toggleItalic = useCallback(
        () => editor.chain().focus().toggleItalic().run(),
        [editor]
    );

    const toggleUnorderedList = useCallback(
        () => editor.chain().focus().toggleBulletList().run(),
        [editor]
    );

    const toggleOrderedList = useCallback(
        () => editor.chain().focus().toggleOrderedList().run(),
        [editor]
    );

    const toggleH1 = useCallback(
        () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
        [editor]
    );

    const toggleH2 = useCallback(
        () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
        [editor]
    );

    const toggleH3 = useCallback(
        () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
        [editor]
    );

    // const setLink = useCallback(() => {
    //     const previousUrl = editor.getAttributes('link').href;
    //     const url = window.prompt('URL', previousUrl);

    //     if (url === null) return;
    //     if (url === '') {
    //         editor.chain().focus().extendMarkRange('link').unsetLink().run();
    //         return;
    //     }
    //     editor
    //         .chain()
    //         .focus()
    //         .extendMarkRange('link')
    //         .setLink({ href: url })
    //         .run();
    // }, [editor]);

    const setLink = useCallback(() => {
        editor.chain().focus().insertContent('([TEXT](INSERT_LINK))').run();
    }, [editor]);

    return {
        toggleBold,
        toggleItalic,
        toggleUnorderedList,
        toggleOrderedList,
        toggleH1,
        toggleH2,
        toggleH3,
        setLink,
    };
};
