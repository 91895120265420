import React from 'react';

import { Controller } from 'react-hook-form';
import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';

import {
    SelectV2 as Select,
    NumberInputComponent,
    LTVInput,
    PostalCodeInput,
    MoneyInputComponent,
    TextInputComponent,
    TextInputComponentProps,
} from 'components/forms';

import type { SpaceProps, WidthProps, MaxWidthProps } from 'styled-system';

const FieldWrapper = styled(Flex)`
    flex: 1;
    padding: 10px;
    flex-direction: column;
    background-color: ${({ theme }) => theme.global.colors['light-2']};
    box-shadow: 0px 4px 8px -4px rgba(26, 26, 26, 0.2);
    border-radius: 10px;
    min-width: 100px;
    justify-content: space-between;

    :last-child {
        margin-right: 0;
    }
`;

const Label = styled(Flex)`
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 6px;
    color: ${({ theme }) => theme.global.colors['portGore']};
    line-height: initial;
`;

type StyleProps = SpaceProps & WidthProps & MaxWidthProps;

const Field = ({
    label,
    children,
    ...styledProps
}: {
    label?: string;
    children: React.ReactNode;
} & StyleProps) => (
    <FieldWrapper {...styledProps}>
        {label && <Label>{label}</Label>}
        {children}
    </FieldWrapper>
);

type TextControlProps = {
    id: string;
    name: string;
    label: string;
    defaultValue?: string;
} & TextInputComponentProps;

export const TextControl = ({
    id,
    name,
    label,
    defaultValue,
    ...rest
}: TextControlProps) => (
    <Field label={label}>
        <Controller
            name={name}
            defaultValue={defaultValue || ''}
            render={({ field, fieldState }) => (
                <TextInputComponent
                    hidePlaceholder
                    id={`${id}-${name}`}
                    data-testid={`${id}-${name}`}
                    error={fieldState.error}
                    {...rest}
                    {...field}
                />
            )}
        />
    </Field>
);

export const SelectControl = ({
    id,
    name,
    label,
    options,
    onChange,
    ...rest
}: any) => {
    return (
        <Field label={label}>
            <Controller
                name={name}
                render={({ field, fieldState }) => (
                    <Select
                        id={`${id}-${name}`}
                        data-testid={`${id}-${name}`}
                        onChangeSelect={onChange}
                        options={options}
                        error={fieldState.error}
                        {...rest}
                        {...field}
                        hidePlaceholder
                    />
                )}
            />
        </Field>
    );
};

export const NumberControl = ({
    id,
    name,
    label,
    defaultValue,
    ...rest
}: any) => (
    <Field label={label}>
        <Controller
            name={name}
            defaultValue={defaultValue || ''}
            render={({ field, fieldState }) => (
                <NumberInputComponent
                    hidePlaceholder
                    id={`${id}-${name}`}
                    data-testid={`${id}-${name}`}
                    error={fieldState.error}
                    {...rest}
                    {...field}
                />
            )}
        />
    </Field>
);

export const MoneyControl = ({
    id,
    name,
    label,
    defaultValue,
    ...rest
}: any) => (
    <Field label={label}>
        <Controller
            name={name}
            defaultValue={defaultValue || ''}
            render={({ field, fieldState }) => (
                <MoneyInputComponent
                    hidePlaceholder
                    id={`${id}-${name}`}
                    data-testid={`${id}-${name}`}
                    error={fieldState.error}
                    {...rest}
                    {...field}
                />
            )}
        />
    </Field>
);

export const PostalCodeControl = ({
    id,
    name,
    label,
    defaultValue,
    ...rest
}: any) => {
    return (
        <Field label={label}>
            <Controller
                name={name}
                defaultValue={defaultValue || ''}
                render={({ field, fieldState }) => (
                    <PostalCodeInput
                        id={`${id}-${name}`}
                        data-testid={`${id}-${name}`}
                        error={fieldState.error}
                        {...rest}
                        {...field}
                        hidePlaceholder
                    />
                )}
            />
        </Field>
    );
};

export const FakeInput = ({ value, label }: any) => (
    <Field label={label}>
        <LTVInput hidePlaceholder value={value} />
    </Field>
);
