import React from 'react';

import { Spinner } from 'components/spinner';

const OverlaySpinner = () => (
    <div
        css={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            opacity: 0.8,
        }}
    >
        <Spinner
            css={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        />
    </div>
);

export default OverlaySpinner;
