import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import { FREQUENCY } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { EmploymentIncomeType, IncomeEmployment } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    employment: IncomeEmployment & { totalIncome?: number };
    incomeTypeWatch: EmploymentIncomeType;
};

export const IncomeDetailsPreviousYear = ({
    isCreating,
    isEditing,
    employment,
    incomeTypeWatch,
}: Props) => {
    const { i18n } = useI18n();

    const { watch } = useFormContext() || {};

    const { base, bonus, commission, overtime } =
        employment?.salaryPreviousYear || {};

    let bonusAmountWatch = bonus?.amount;

    let bonusFrequencyWatch = bonus?.frequency;

    let commissionAmountWatch = commission?.amount;

    let commissionFrequencyWatch = commission?.frequency;

    let overtimeAmountWatch = overtime?.amount;

    let overtimeFrequencyWatch = overtime?.frequency;

    if (watch) {
        bonusAmountWatch = watch('salaryPreviousYear.bonus.amount');

        bonusFrequencyWatch = watch('salaryPreviousYear.bonus.frequency');

        commissionAmountWatch = watch('salaryPreviousYear.commission.amount');

        commissionFrequencyWatch = watch(
            'salaryPreviousYear.commission.frequency'
        );

        overtimeAmountWatch = watch('salaryPreviousYear.overtime.amount');

        overtimeFrequencyWatch = watch('salaryPreviousYear.overtime.frequency');
    }

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('incomePreviousYearDetails')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit-larger']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salaryPreviousYear.base.amount"
                    label="income"
                    fieldType="money"
                    value={base?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salaryPreviousYear.base.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={base?.frequency}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={!!bonusFrequencyWatch && !bonusAmountWatch}
                    isEditing={isEditing}
                    name="salaryPreviousYear.bonus.amount"
                    label="bonus"
                    fieldType="money"
                    value={bonus?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={!!bonusAmountWatch && !bonusFrequencyWatch}
                    isClearable
                    isEditing={isEditing}
                    name="salaryPreviousYear.bonus.frequency"
                    label="bonusFrequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={bonus?.frequency}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={!!overtimeFrequencyWatch && !overtimeAmountWatch}
                    isEditing={isEditing}
                    name="salaryPreviousYear.overtime.amount"
                    label="overtime"
                    fieldType="money"
                    value={overtime?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={!!overtimeAmountWatch && !overtimeFrequencyWatch}
                    isClearable
                    isEditing={isEditing}
                    name="salaryPreviousYear.overtime.frequency"
                    label="overtimeFrequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={overtime?.frequency}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {incomeTypeWatch === 'HOURLY_COMMISSIONS' && (
                    <>
                        <EditableCell
                            required={
                                !!commissionFrequencyWatch &&
                                !commissionAmountWatch
                            }
                            isEditing={isEditing}
                            name="salaryPreviousYear.commission.amount"
                            label="commission"
                            fieldType="money"
                            value={commission?.amount}
                            isFieldInvalidCompareFn={compareForId(employment)}
                        />
                        <EditableCell
                            required={
                                !!commissionAmountWatch &&
                                !commissionFrequencyWatch
                            }
                            isClearable
                            isEditing={isEditing}
                            name="salaryPreviousYear.commission.frequency"
                            label="commissionFrequency"
                            fieldType="select"
                            options={FREQUENCY(i18n)}
                            value={commission?.frequency}
                            isFieldInvalidCompareFn={compareForId(employment)}
                        />
                    </>
                )}
            </Grid>
        </Flex>
    );
};
