import React from 'react';

import { PropertyModal } from 'components/dashboard/broker-dashboard-new/property-card/property-modal';
import { Modal } from 'components/modal';
import { ScenarioModal } from 'components/scenarios/scenario-modal';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { useEditingContext } from 'utils/use-editing-context';

import type { PropertyModalProps } from 'components/dashboard/broker-dashboard-new/property-card/property-modal';
import type { ScenarioModalProps } from 'components/scenarios/scenario-modal';

type Props = {
    children: React.ReactNode;
};

export const DashboardModalWrapper = ({ children }: Props) => {
    const { i18n } = useI18n();
    const { clearEditingKey } = useEditingContext();

    const { isVisible: isPropertyModalVisible, close: closePropertyModal } =
        useModal<PropertyModalProps>('dashBoardPropertyModal');

    const {
        isVisible: isScenarioModalVisible,
        close: closeScenarioModal,
        props: scenarioModalProps,
    } = useModal<ScenarioModalProps>('scenariosDetails');

    return (
        <>
            {children}
            <Modal
                sx={{ width: ['100%'], maxWidth: 940 }}
                visible={isPropertyModalVisible}
                onClose={() => closePropertyModal()}
                closeOnEscape
            >
                <PropertyModal />
            </Modal>
            <Modal
                sx={{ width: ['100%'], maxWidth: 800 }}
                title={i18n._('ratioCalculator')}
                visible={isScenarioModalVisible}
                onClose={() => {
                    closeScenarioModal();
                    clearEditingKey();
                }}
                closeOnEscape
            >
                <ScenarioModal {...scenarioModalProps} />
            </Modal>
        </>
    );
};
