import React from 'react';

import { i18n } from '@lingui/core';
import { Banner, BannerProps, Grid, Typography } from '@nestoca/ui';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { MPI_INSURER_RESPONSE_OPTIONS } from 'constants/appConstants';
import { getApplication, getApplicationMortgage } from 'store/applications';
import { ApplicantInfo, MPIInsurerResponse } from 'types/applicant';
import { ApplicationState, MPIInsurer } from 'types/application';
import { compareForApplicant } from 'utils/validations/comparators';

const getMPIInsurerResponseStatus = ({
    mpiInsurer,
    applicationState,
}: {
    mpiInsurer: MPIInsurer;
    applicationState: ApplicationState;
}) => {
    switch (mpiInsurer) {
        // For Canada Life we only show the success message
        case MPIInsurer.CANADA_LIFE:
            return 'success';
        case MPIInsurer.POLICY_ME:
            const applicationIsFunded = applicationState === 'FUNDED';
            return applicationIsFunded ? 'success' : 'pending';
        default:
            return '';
    }
};

const mapInsurerResponseStatusToVariant = (
    state: string
): BannerProps['variant'] => {
    switch (state) {
        case 'success':
            return 'success';
        case 'pending':
            return 'warning';
        default:
            return 'info';
    }
};

type Props = {
    isEditing: boolean;
    applicationId: number;
    applicant: ApplicantInfo;
};

export const MPIInsurerResponseField = ({
    applicationId,
    applicant,
    isEditing,
}: Props) => {
    const methods = useFormContext();

    const application = useRecoilValue(getApplication(applicationId));
    const { mpiInsurer } = useRecoilValue(
        getApplicationMortgage(applicationId)
    );

    const { applicationState } = application || {};

    let mpiInsurerResponseWatch = applicant.mpiInsurerResponse;

    if (methods) {
        const { watch } = methods;

        mpiInsurerResponseWatch = watch(
            'mpiInsurerResponse',
            applicant.mpiInsurerResponse
        );
    }

    const responseStatus = getMPIInsurerResponseStatus({
        mpiInsurer,
        applicationState,
    });

    return (
        <>
            <EditableCell
                isEditing={isEditing}
                name="mpiInsurerResponse"
                fieldType="select"
                options={MPI_INSURER_RESPONSE_OPTIONS(i18n)}
                label="mpiInsurerResponse"
                value={applicant.mpiInsurerResponse}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <Grid
                css={{
                    gridColumn: '1 / -1',
                }}
            >
                <Banner
                    visible={
                        responseStatus &&
                        mpiInsurerResponseWatch === MPIInsurerResponse.OPTED_IN
                    }
                    variant={mapInsurerResponseStatusToVariant(responseStatus)}
                >
                    <Typography>
                        {i18n._(`mpiInsurerResponseStatus.${responseStatus}`)}
                    </Typography>
                </Banner>
            </Grid>
        </>
    );
};
