import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

function getDisplayName(Component: React.ComponentType<any>) {
    return Component.displayName || Component.name || 'Component';
}

/**
 * @deprecated Use useAuth0 `withAuthenticationRequired` instead
 *
 * HOC that wraps a component and only renders it if the user is authenticated
 *
 * @param WrappedComponent - React component to wrap
 * @returns
 */
const authenticated = (WrappedComponent: React.ComponentType) => {
    const EnhancedComponent = (props: any) => {
        const { isAuthenticated } = useAuth0();

        if (!isAuthenticated) {
            return null;
        }

        return <WrappedComponent {...props} />;
    };

    EnhancedComponent.displayName = `authenticated(${getDisplayName(
        WrappedComponent
    )})`;

    return EnhancedComponent;
};

export default authenticated;
