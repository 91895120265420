import React from 'react';

import css from '@styled-system/css';
import styled from 'styled-components';

import { Card } from 'components/card/card';
import { Text } from 'components/text/text';

type Props = {
    message: string | JSX.Element;
};

const StyledCard = styled(Card)`
    border-radius: 10px;
    background-color: ${({ theme }) => theme.global.colors['status-critical']};
`;

export const Notification = ({ message }: Props) => (
    <StyledCard preset="secondary" width="inherit" alignItems="center">
        <Text css={css({ color: 'white' })}>{message}</Text>
    </StyledCard>
);
