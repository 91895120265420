import React, { useMemo, useEffect } from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicant } from 'store/applications';
import { getApplicantsSelectOptions } from 'store/applications';

import styles from './section.module.scss';

import type { OtherProperty } from 'types/property';

export const Applicants = ({
    ownedProperty,
    applicationId,
    isCreating,
    isEditing,
    isNewOwnedProperty = false,
    applicantId: defaultApplicantId,
}: {
    applicationId: number;
    ownedProperty: OtherProperty;
    isCreating?: boolean;
    isEditing: boolean;
    isNewOwnedProperty?: boolean;
    applicantId: number;
}) => {
    const { i18n } = useI18n();
    const methods = useFormContext();
    const applicant = useRecoilValue(
        getApplicant({ applicationId, applicantId: defaultApplicantId })
    );

    // watch liability applicantId
    let applicantId = ownedProperty?.applicantId;
    let coOwnerApplicantIds = ownedProperty?.coOwnerApplicantIds;
    if (methods) {
        const { watch } = methods;

        applicantId = watch('applicantId', ownedProperty?.applicantId);
        coOwnerApplicantIds = watch(
            'coOwnerApplicantIds',
            ownedProperty?.coOwnerApplicantIds || []
        );
    }

    useEffect(() => {
        if (
            methods &&
            applicantId &&
            coOwnerApplicantIds.includes(applicantId)
        ) {
            // remove applicant id from coowners list
            methods.setValue(
                'coOwnerApplicantIds',
                coOwnerApplicantIds.filter((id) => id !== applicantId)
            );
        }
    }, [methods, applicantId, coOwnerApplicantIds]);

    const applicants = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    const selectedApplicantId = isNewOwnedProperty
        ? applicantId
        : applicant?.applicantId;

    // get the co applicant list excluding current liability applicant
    const coOwnerApplicantOptions = useMemo(
        () =>
            (applicants || []).filter(
                (option) => option.value !== selectedApplicantId
            ),
        [selectedApplicantId]
    );

    return (
        <Flex className={styles.section} direction="column" gap={4}>
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('applicants')}
            </Typography>
            <Grid
                className={cn(styles['grid'], styles['grid--with-divider'], {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                {/* They can select the applicant only when
                they add a new Property */}
                <EditableCell
                    isEditing={isEditing && isNewOwnedProperty}
                    fieldType="select"
                    name="applicantId"
                    label="applicant"
                    options={applicants}
                    value={applicantId}
                    required
                />
                <EditableCell
                    isEditing={isEditing}
                    name="coOwnerApplicantIds"
                    fieldType="select"
                    label="coOwnerApplicantIds"
                    value={ownedProperty.coOwnerApplicantIds}
                    options={coOwnerApplicantOptions}
                    isClearable
                    isMulti
                />
            </Grid>
        </Flex>
    );
};
