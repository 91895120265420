import React from 'react';

import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { Grid } from 'components/grid/grid';
import { getApplicantsSelectOptions } from 'store/applications';

type ApplicantSelectorProps = {
    applicationId: number;
    isEditing: boolean;
    applicantId?: number;
};

const ApplicantSelector = ({
    applicationId,
    isEditing,
    applicantId,
}: ApplicantSelectorProps) => {
    const applicantOptions = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    return (
        <Grid gridArea="applicant" mb={2}>
            <EditableCell
                isEditing={isEditing}
                fieldType="select"
                name="applicantId"
                label="applicant"
                options={applicantOptions}
                value={applicantId}
            />
        </Grid>
    );
};

export default ApplicantSelector;
