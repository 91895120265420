import { Fragment } from 'react';

import { Card, Flex, Typography, Box } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import styles from 'components/dashboard/dashboard-progress-cards.module.scss';
import { ProgressCircle } from 'components/progress-circle/progress-circle';
import { ScrollArea } from 'components/scroll-area';
import { ONE_HUNDRED } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicantsByIds, selectedApplication } from 'store/applications';
import {
    applicationDocumentsCounts,
    getSelectedDocumentType,
} from 'store/documents';
import { getSelectedApplicantsIds } from 'store/qualification';
import { useTheme } from 'utils/use-theme';

import { CounterFlagsContainer } from './counter-flag.container';
import { DocumentsSections } from './document-sections';

type Props = {
    underwriterDashboard?: boolean;
};

export const DocumentsCardContainer = ({ underwriterDashboard }: Props) => {
    const { i18n } = useI18n();

    const { colors } = useTheme();
    const application = useRecoilValue(selectedApplication);
    const applicantsIds = useRecoilValue(
        getSelectedApplicantsIds({ applicationId: application.id })
    );
    const docStep = useRecoilValue(
        getSelectedDocumentType({ applicationId: application.id })
    );
    const applicants = useRecoilValue(
        getApplicantsByIds({ applicationId: application.id, applicantsIds })
    );
    const documentsCount = useRecoilValue(
        applicationDocumentsCounts({
            applicationId: application.id,
            step: docStep,
        })
    );
    const { receivedCount, totalCount } = documentsCount;
    const recievedDocumentsProgress =
        (receivedCount / totalCount) * ONE_HUNDRED;

    return (
        <Card
            className={
                underwriterDashboard
                    ? `${styles.card} ${styles.cardUnderwriter}`
                    : styles.card
            }
        >
            <Flex className={styles.header}>
                <Flex align="center" gap={2}>
                    <ProgressCircle
                        borderColor={colors['neutral-3']}
                        value={recievedDocumentsProgress}
                        size={50}
                        text={`${receivedCount} / ${totalCount}`}
                    />
                    <Typography weight={7} size={1}>
                        {i18n._('dashboardHeader.documents')}
                    </Typography>
                </Flex>
            </Flex>
            <ScrollArea className={styles.scrollArea}>
                <Box
                    css={{
                        padding:
                            'var(--spacing-4) var(--spacing-4) var(--spacing-7)',
                    }}
                >
                    <CounterFlagsContainer documentsCount={documentsCount} />
                    {applicants.map((applicant) => {
                        return (
                            <Fragment key={applicant.applicantId}>
                                <Typography
                                    as="h4"
                                    weight={7}
                                    size={0}
                                    css={{ marginBlock: 'var(--spacing-4)' }}
                                >
                                    {`${applicant.firstName} ${applicant.lastName}`}
                                </Typography>
                                <DocumentsSections
                                    applicationId={application.id}
                                    applicantId={applicant.applicantId}
                                />
                            </Fragment>
                        );
                    })}
                </Box>
            </ScrollArea>
        </Card>
    );
};
