import React, { useState } from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';
import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';
import { GDS } from 'components/scenarios/components/scenario-gds';
import { ScenarioLabel } from 'components/scenarios/components/scenario-label';
import { LTV } from 'components/scenarios/components/scenario-ltv';
import { TDS } from 'components/scenarios/components/scenario-tds';
import styles from 'components/scenarios/scenarios.module.scss';
import { Text } from 'components/text/text';
import { useModal } from 'providers/modals/use-modal';
import { sectionSelectedState, getQualification } from 'store/qualification';
import { formatMoney } from 'utils';

import { ScenarioLTI } from './components/scenario-lti';

import type { SectionKeys } from 'types/application';

export type ScenarioModalSection = 'GDS' | 'TDS' | 'LTV' | 'LTI' | 'netWorth';

export type ScenarioModalProps = {
    applicationId: number;
    section: ScenarioModalSection;
};

export const ScenarioModal = ({
    applicationId,
    section,
}: ScenarioModalProps) => {
    const { hideNetWorth } = useFlags();

    const router = useRouter();
    const [sectionState, setSectionState] = useState<string>(section);
    const { close: closeModal } =
        useModal<ScenarioModalProps>('scenariosDetails');

    const [, setSectionSelected] = useRecoilState(
        sectionSelectedState({ applicationId })
    );

    const qualification = useRecoilValue(getQualification(applicationId));

    const handleOnClick = (section: SectionKeys) => {
        setSectionSelected(section);

        router.push(`/applications/${applicationId}`);

        closeModal();
    };

    return (
        <Grid
            className={styles['scenario-modal']}
            gridGap={10}
            gridTemplateColumns="1fr"
            gridAutoRows="auto"
            gridTemplateAreas={`
                "header"
                "content"
            `}
        >
            <Grid
                gridArea="header"
                justifyContent="space-between"
                css={css({ display: 'flex' })}
                mt={1}
            >
                <Button
                    data-testid="lti"
                    onClick={() => setSectionState('LTI')}
                    size="large"
                    variant={sectionState === 'LTI' ? 'primary' : 'ghost'}
                    css={css({ width: 140 })}
                >
                    <Trans
                        id="ltiValue"
                        values={{ value: qualification?.lti }}
                    />
                </Button>
                <Button
                    data-testid={'gds'}
                    type="button"
                    onClick={() => setSectionState('GDS')}
                    size="large"
                    variant={sectionState === 'GDS' ? 'primary' : 'ghost'}
                    css={css({
                        width: 140,
                        justifyContent: 'center',
                    })}
                >
                    <Trans
                        id="gdsValue"
                        values={{ value: qualification.gds }}
                    />
                </Button>
                <Button
                    data-testid={'tds'}
                    type="button"
                    onClick={() => setSectionState('TDS')}
                    size="large"
                    variant={sectionState === 'TDS' ? 'primary' : 'ghost'}
                    css={css({
                        width: 140,
                        justifyContent: 'center',
                    })}
                >
                    <Trans
                        id="tdsValue"
                        values={{ value: qualification.tds }}
                    />
                </Button>
                <Button
                    data-testid={'ltv'}
                    type="button"
                    onClick={() => setSectionState('LTV')}
                    size="large"
                    variant={sectionState === 'LTV' ? 'primary' : 'ghost'}
                    css={css({
                        width: 140,
                        justifyContent: 'center',
                    })}
                >
                    <Trans
                        id="ltvValue"
                        values={{ value: qualification.ltv }}
                    />
                </Button>
                {!hideNetWorth && (
                    <Button
                        data-testid={'net-worth'}
                        type="button"
                        onClick={() => setSectionState('netWorth')}
                        size="large"
                        variant={
                            sectionState === 'netWorth' ? 'primary' : 'ghost'
                        }
                        css={css({
                            width: 140,
                            justifyContent: 'center',
                        })}
                    >
                        <Trans
                            id="netWorthLabel"
                            values={{ value: qualification.netWorthAmount }}
                        />
                    </Button>
                )}
            </Grid>

            <Grid
                gridArea="content"
                css={css({
                    marginTop: 3,
                })}
                gridTemplateAreas={`
                    "contentScenario"
                `}
            >
                <Grid
                    gridArea="contentScenario"
                    gridTemplateAreas={`
                        "divider"
                        "content"
                    `}
                >
                    <Grid gridArea="divider">
                        <Divider variant="dottedGray" />
                    </Grid>
                    {sectionState === 'LTI' && (
                        <ScenarioLTI
                            handleOnClick={handleOnClick}
                            applicationId={applicationId}
                        />
                    )}
                    {sectionState === 'LTV' && (
                        <LTV
                            qualification={qualification}
                            handleOnClick={handleOnClick}
                        />
                    )}
                    {sectionState === 'GDS' && (
                        <GDS
                            handleOnClick={handleOnClick}
                            applicationId={applicationId}
                        />
                    )}

                    {sectionState === 'TDS' && (
                        <TDS
                            handleOnClick={handleOnClick}
                            applicationId={applicationId}
                        />
                    )}
                    {sectionState === 'netWorth' && (
                        <Grid
                            gridArea="content"
                            css={css({ width: '60%', margin: '0 auto' })}
                        >
                            <Flex justifyContent="center" my={3}>
                                <Text
                                    tx="netWorthScenario"
                                    css={css({
                                        fontSize: 2,
                                        color: 'neutral-3',
                                        fontWeight: '600',
                                    })}
                                />
                            </Flex>
                            <Grid gridTemplateColumns="1fr auto">
                                <ScenarioLabel
                                    label="netWorthLabel"
                                    value={formatMoney(
                                        qualification.netWorthAmount,
                                        true
                                    )}
                                    clickable={false}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
