import React from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { useRecoilValue } from 'recoil';
import { Flex } from 'reflexbox/styled-components';

import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';
import { getApplicationMortgage } from 'store/applications';
import { getQualificationState } from 'store/qualification';
import { getAmountPerFrequency, getYearAmountFrequency } from 'utils/fns';
import { formatMoney, formatPercentage } from 'utils/normalizer';

import type { Frequency } from 'types/index';

export type MortgageModalProps = {
    applicationId?: number;
};

const CellInfo = ({
    label,
    value,
    gridArea,
}: {
    label: string;
    value: number | string;
    gridArea: string;
}) => (
    <Grid
        gridGap={10}
        gridArea={gridArea}
        gridTemplateColumns="1fr 1fr"
        gridTemplateAreas={`"label value"`}
        css={css({
            borderBottom: '1px dashed',
            borderColor: 'gray-5',
            paddingBottom: 2,
            maxWidth: 'fit-content',
        })}
    >
        <Grid gridArea="label">
            <Text tx={label} />
        </Grid>
        <Grid gridArea="value">{value}</Grid>
    </Grid>
);

const FooterCell = ({
    label,
    value,
    hideBorder = false,
    frequency,
}: {
    label: string;
    value: number;
    hideBorder?: boolean;
    frequency: Frequency;
}) => (
    <Grid
        gridTemplateColumns="1fr"
        css={css({
            padding: 10,
            fontSize: 0,
            borderRight: !hideBorder ? '1px solid white' : 'none',
        })}
        gridTemplateAreas={`"label" "value" "valuePerYear"`}
    >
        <Grid gridArea="label">
            <Text
                tx={label}
                css={css({ fontSize: 0, maxWidth: 'fit-content' })}
            />
        </Grid>

        <Grid gridArea="value">
            <Text css={css({ marginTop: 2, fontSize: 2, fontWeight: 600 })}>
                {formatMoney(value)}
            </Text>
        </Grid>
        <Grid gridArea="valuePerYear">
            <Text css={css({ marginTop: 2, fontSize: 0, fontWeight: 500 })}>
                <Trans
                    id="perYear"
                    values={{
                        value: formatMoney(
                            getYearAmountFrequency(value, frequency)
                        ),
                    }}
                />
            </Text>
        </Grid>
    </Grid>
);

export const MortgageModal = ({ applicationId }: MortgageModalProps) => {
    const mortgageDetails = useRecoilValue(
        getApplicationMortgage(applicationId)
    );

    const qualification = useRecoilValue(getQualificationState(applicationId));

    return (
        <>
            <Grid
                gridGap={10}
                gridTemplateColumns="1fr"
                gridAutoRows="auto"
                gridTemplateAreas={`
                "mortgageAmount"
                "interestRate"
                "amortization"
            `}
                css={css({ width: '40%', margin: '0 auto' })}
            >
                <CellInfo
                    label="mortgageAmount"
                    value={formatMoney(qualification.mortgageAmount)}
                    gridArea="mortgageAmount"
                />
                <CellInfo
                    label="InterestRate"
                    value={formatPercentage(mortgageDetails.product?.bestRate)}
                    gridArea="interestRate"
                />
                <CellInfo
                    label="amortization"
                    value={mortgageDetails.amortization}
                    gridArea="amortization"
                />
            </Grid>
            <Flex
                css={css({
                    margin: '50px -20px -20px -20px',
                    borderRadius: '10px',
                    backgroundColor: 'dark-1',
                    color: 'white',
                    padding: 20,
                })}
                justifyContent="center"
            >
                <Grid
                    gridGap={10}
                    gridTemplateColumns={'repeat(6, 1fr)'}
                    gridAutoRows="auto"
                    gridTemplateAreas={`
            `}
                >
                    <FooterCell
                        label="monthly"
                        value={getAmountPerFrequency(
                            getYearAmountFrequency(
                                mortgageDetails.monthlyPayment.amount,
                                'MONTHLY'
                            ),
                            'MONTHLY'
                        )}
                        frequency="MONTHLY"
                    />

                    <FooterCell
                        label="semimonthly"
                        value={getAmountPerFrequency(
                            getYearAmountFrequency(
                                mortgageDetails.monthlyPayment.amount,
                                'MONTHLY'
                            ),
                            'SEMIMONTHLY'
                        )}
                        frequency="SEMIMONTHLY"
                    />

                    <FooterCell
                        label="biweekly"
                        value={getAmountPerFrequency(
                            getYearAmountFrequency(
                                mortgageDetails.monthlyPayment.amount,
                                'MONTHLY'
                            ),
                            'BIWEEKLY'
                        )}
                        frequency="BIWEEKLY"
                    />

                    <FooterCell
                        label="biweeklyAcc"
                        value={getAmountPerFrequency(
                            getYearAmountFrequency(
                                mortgageDetails.monthlyPayment.amount,
                                'MONTHLY'
                            ),
                            'ACCELERATED_BIWEEKLY'
                        )}
                        frequency="BIWEEKLY"
                    />

                    <FooterCell
                        label="weekly"
                        value={getAmountPerFrequency(
                            getYearAmountFrequency(
                                mortgageDetails.monthlyPayment.amount,
                                'MONTHLY'
                            ),
                            'WEEKLY'
                        )}
                        frequency="WEEKLY"
                    />

                    <FooterCell
                        hideBorder
                        label="weeklyAcc"
                        value={getAmountPerFrequency(
                            getYearAmountFrequency(
                                mortgageDetails.monthlyPayment.amount,
                                'MONTHLY'
                            ),
                            'ACCELERATED_WEEKLY'
                        )}
                        frequency="WEEKLY"
                    />
                </Grid>
            </Flex>
        </>
    );
};
