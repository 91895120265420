import React from 'react';

import { Flex, Grid, VStack } from '@nestoca/ui';
import cn from 'classnames';

import { ScrollArea } from 'components/scroll-area';
import { Text } from 'components/text/text';

import { NextStepAction } from './next-step-action';
import styles from './next-step-card.module.scss';
import { NextStepIndicator } from './next-step-indicator';

export type Step = {
    number: number;
    title: string;
    explanation: string;
    completed: boolean;
    nextStep: boolean;
};

type Props = {
    steps: Step[];
};

export const NextStepInfo = ({ steps }: Props) => {
    return (
        <VStack
            className={styles.next_step_info}
            direction="column"
            align="stretch"
            gap={0}
        >
            <ScrollArea>
                {steps.map((step) => (
                    <Grid
                        key={step.number}
                        className={cn([`${styles.step}`], {
                            [`${styles.next_step}`]: step.nextStep,
                            [`${styles.step_completed}`]: step.completed,
                            [`${styles.step_todo}`]:
                                !step.completed && !step.nextStep,
                        })}
                        gap={3}
                    >
                        <NextStepIndicator step={step} />
                        <Flex align="center" justify="between" wrap="wrap">
                            <Flex
                                className={styles.text_container}
                                direction="column"
                                gap={1}
                            >
                                <Text
                                    fontSize="var(--font-size-0)"
                                    fontWeight={
                                        step.nextStep
                                            ? 'var(--font-weight-7)'
                                            : 'var(--font-weight-5)'
                                    }
                                >
                                    {step.title}
                                </Text>
                                <Text
                                    className={styles.explanation}
                                    fontSize="12px"
                                    fontWeight="var(--font-weight-5)"
                                >
                                    {step.explanation}
                                </Text>
                            </Flex>
                            <NextStepAction step={step} />
                        </Flex>
                    </Grid>
                ))}
            </ScrollArea>
        </VStack>
    );
};
