import {
    atomFamily,
    selectorFamily,
    useRecoilCallback,
    useSetRecoilState,
} from 'recoil';

import { PlaidMonitoringHit } from 'components/plaid-monitoring-card/types';
import { client as apiClient } from 'libs/api';

export const getPlaidMonitoringScreeningsData = selectorFamily({
    key: 'getPlaidMonitoringScreeningsData',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        async () => {
            try {
                const { data: screenings } =
                    await apiClient.getPlaidMonitoringScreenings(
                        applicationId,
                        applicantId
                    );

                return screenings;
            } catch (error) {
                throw error;
            }
        },
});

export const getPlaidMonitoringHitsByScreeningIdData = selectorFamily({
    key: 'getPlaidMonitoringHitsByScreeningId',
    get:
        ({
            applicationId,
            applicantId,
            screeningId,
        }: {
            applicationId: number;
            applicantId: number;
            screeningId: string;
        }) =>
        async () => {
            try {
                const { data: hits } =
                    await apiClient.getPlaidMonitoringHitsByScreeningId(
                        applicationId,
                        applicantId,
                        screeningId
                    );

                return hits;
            } catch (error) {
                throw error;
            }
        },
});

export const getPlaidMonitoringScreenings = atomFamily({
    key: 'getPlaidMonitoringScreenings',
    default: getPlaidMonitoringScreeningsData,
});

export const getPlaidMonitoringHitsByScreeningId = atomFamily({
    key: 'getPlaidMonitoringHitsByScreeningId',
    default: getPlaidMonitoringHitsByScreeningIdData,
});

export const useRefreshPlaidMonitoringScreenings = (
    applicationId: number,
    applicantId: number
) => {
    const setPlaidMonitoringScreenings = useSetRecoilState(
        getPlaidMonitoringScreenings({
            applicationId,
            applicantId,
        })
    );

    return async () => {
        const { data: screenings } =
            await apiClient.getPlaidMonitoringScreenings(
                applicationId,
                applicantId
            );

        setPlaidMonitoringScreenings(screenings);
        return screenings;
    };
};

export const useSetPlaidMonitoringHitsByScreeningId = () =>
    useRecoilCallback(
        ({ set }) =>
            async (param: {
                applicationId: number;
                applicantId: number;
                screeningId: string;
                newValue: PlaidMonitoringHit[];
            }) => {
                const { newValue, ...rest } = param;

                set(getPlaidMonitoringHitsByScreeningId(rest), newValue);
            }
    );
