import yup from './yup-extended';

import type { I18n } from '@lingui/core';

export const getPullCreditSchema = (i18n: I18n) =>
    yup
        .object({
            socialInsuranceNumber: yup
                .string()
                .optional()
                .trim()
                .canadianSin(i18n._({ id: 'error.isCanadianSIN' })),
        })
        .required();
