import React from 'react';

import { Trans } from '@lingui/react';

import { Grid } from 'components/grid/grid';
import { ProgressCircle } from 'components/progress-circle/progress-circle';

type Props = {
    id: string;
    label: string;
    value: number;
    text: string | number;
    gridArea: string;
    gridTemplate?: string;
    showBorder?: boolean;
    borderColor?: string;
    justifyContent?: string;
    reverseRow?: boolean;
    onClick?: () => void;
    fontWeight?: number;
    fontSize?: string;
};

export const ProgressLabel = ({
    id,
    label,
    value,
    gridArea,
    text,
    showBorder,
    borderColor,
    justifyContent = 'right',
    fontWeight = 600,
    fontSize = '18px',
    reverseRow = false,
    onClick,
}: Props) => (
    <Grid
        id={id}
        data-testid={id}
        gridArea={gridArea}
        gridAutoFlow="row"
        gridGap={10}
        css={{
            borderRight: showBorder ? '1px solid #E3E3E3' : 'none',
            cursor: onClick ? 'pointer' : 'not-allowed',
            '@media screen and (max-width: 1500px)': {
                gridTemplateColumns: '1fr',
                gridTemplateAreas: `
                    "label"
                    "value"
                `,
            },
            '@media screen and (min-width: 1501px)': {
                gridTemplateColumns: 'repeat(2, auto)',
                gridTemplateAreas: `
                    "label value"
                `,
            },
        }}
        onClick={onClick && onClick}
    >
        <Grid
            gridArea={`label`}
            css={{
                alignItems: 'center',
                justifyContent,
                fontWeight,
                fontSize,
                flexDirection: reverseRow ? 'row-reverse' : 'unset',
                '@media screen and (max-width: 1500px)': {
                    justifyContent: 'center',
                },
            }}
        >
            <Trans id={label} />
        </Grid>
        <Grid
            gridArea={`value`}
            style={{
                alignItems: 'center',
                minWidth: 'fit-content',
                margin: '0 auto',
            }}
        >
            <ProgressCircle
                size={65}
                fontSize="var(--font-size-1)"
                text={text}
                value={value}
                borderColor={borderColor}
                id={`progress-${label}`}
            />
        </Grid>
    </Grid>
);
