import { useTenantSettings } from '@nestoca/multi-tenant';
import { yup } from '@nestoca/validation';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useI18n } from 'providers/i18n/use-i18n';
import { usePartialValidation } from 'utils/use-partial-validation';
import { getApplicantInfoSchema } from 'validations/applicant-info';
import {
    getApplicantSINSchema,
    getApplicantInfoPartialSchema,
} from 'validations/applicant-info-partial';
import { getRegisterAddressSchema } from 'validations/register-address';

type UseApplicantSchemaProps = {
    isCreating?: boolean;
};

export const useApplicantSchema = ({
    isCreating = false,
}: UseApplicantSchemaProps) => {
    const { i18n } = useI18n();
    const { partialSavingIdentification } = useFlags();
    const {
        requireCoApplicantAddress: { value: requireCoApplicantAddress },
        applicantSettings: {
            settings: { enableSINNumber },
        },
    } = useTenantSettings();

    const partialValidation = usePartialValidation();

    let schema;

    schema =
        partialSavingIdentification && partialValidation
            ? getApplicantInfoPartialSchema(i18n)
            : getApplicantInfoSchema(i18n, isCreating);

    if (requireCoApplicantAddress) {
        schema = yup.object().shape({
            ...schema.fields,
            ...getRegisterAddressSchema(i18n).fields,
        });
    }

    if (enableSINNumber) {
        schema = yup.object().shape({
            ...schema.fields,
            ...getApplicantSINSchema(i18n).fields,
        });
    }

    return schema;
};
