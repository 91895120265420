import React, { forwardRef } from 'react';

import { Trans } from '@lingui/react';
import { Accordion, Flex, Typography } from '@nestoca/ui';
import { useFormContext } from 'react-hook-form';

import { AccountDetails } from 'components/account-merge/connect-accounts-modal/connect-accounts-body/account-details';
import { CoApplicantSearchResult } from 'types/co-applicant-search';

import styles from './accounts.module.scss';

export type AccountsProps = {
    accounts: CoApplicantSearchResult[];
};

export const Accounts = forwardRef(
    ({ accounts }: AccountsProps, ref: React.Ref<HTMLDivElement>) => {
        const { register } = useFormContext();

        return (
            <Flex className={styles.accounts} direction="column" gap={2}>
                {accounts.map((account, index) => {
                    const isLastAccount = index === accounts.length - 1;

                    const accountUserFullName =
                        `${account.firstName} ${account.lastName}`.trim();

                    return (
                        <Flex
                            key={account.id}
                            className={styles.accordions}
                            align="baseline"
                            gap={3}
                        >
                            <input
                                className={styles.radio}
                                type="radio"
                                value={`${account.id}`}
                                {...register('accountIdSelected')}
                            />
                            <Accordion
                                className={styles.accordion}
                                ref={isLastAccount ? ref : undefined}
                                label={
                                    <Typography size={0}>
                                        <Trans
                                            id="connect-account-details.title"
                                            values={{
                                                accountId: account.id,
                                                fullName: accountUserFullName,
                                            }}
                                            components={{ 0: <b /> }}
                                        />
                                    </Typography>
                                }
                            >
                                <AccountDetails
                                    className={styles['account-details']}
                                    account={account}
                                />
                            </Accordion>
                        </Flex>
                    );
                })}
            </Flex>
        );
    }
);

Accounts.displayName = 'Accounts';
