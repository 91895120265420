import React from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { FormAdd } from 'grommet-icons';
import { Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';

import type { SpaceProps } from 'styled-system';

export const CreateNote = ({
    onClick,
    ...rest
}: { onClick: () => void } & SpaceProps) => (
    <Flex justifyContent="center" mt={20} {...rest}>
        <Button
            type="button"
            variant="ghost"
            css={css({
                width: '100%',
                justifyContent: 'center',
                color: 'neutral-3',
            })}
            onClick={onClick}
        >
            <Trans id="createNote" />
            <FormAdd color="neutral-3" />
        </Button>
    </Flex>
);
