import React, { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { I18n } from '@lingui/core';
import css from '@styled-system/css';
import { FormProvider, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { ValidationError } from 'yup';

import { CheckboxComponent, Label } from 'components/forms';
import { Grid } from 'components/grid/grid';
import OverlaySpinner from 'components/loader/overlay-spinner';
import { Text } from 'components/text/text';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { useRefreshApplicationById } from 'store/applications';
import { sectionSelectedState } from 'store/qualification';
import { SectionKeys } from 'types/application';
import { getCurrentAddress } from 'utils/address';
import { getPullCreditSchema } from 'validations/pull-credit';
import yup from 'validations/yup-extended';

import { PullCreditActions } from './pull-credit-actions';
import { PullCreditForm } from './pull-credit-form';
import { PullCreditMinimumRequirement } from './pull-credit-minimum-requirement';

export type PullCreditModalProps = {
    applicationId: number;
    applicant: any;
    lightQualification?: boolean;
};

export type PullCreditFormData = {
    socialInsuranceNumber: string;
    language: string;
};

// validation schema: Minimum required fields to pull credit
const applicantRequirments = (i18n: I18n) =>
    yup
        .object({
            firstName: yup
                .string()
                .nullable()
                .required(i18n._('error.fieldRequired')),
            lastName: yup
                .string()
                .nullable()
                .required(i18n._('error.fieldRequired')),
            phone: yup
                .string()
                .nullable()
                .required(i18n._('error.fieldRequired')),
            dateOfBirth: yup
                .string()
                .nullable()
                .required(i18n._('error.fieldRequired')),
            // addresses is an array of objects
            // and test if we have at least one currentaddress  `isCurrentAddress`
            addresses: yup
                .array()
                .compact(function rejector(v) {
                    // see yup callback is `rejector` so we need to return true to reject
                    // that's why we use `!` to negate the result
                    return !v.isCurrentAddress;
                })
                // eslint-disable-next-line no-magic-numbers
                .min(1, i18n._('error.fieldRequired')),
        })
        .required();

export const PullCredit = ({
    applicationId,
    applicant,
    lightQualification = false,
}: PullCreditModalProps) => {
    const { i18n } = useI18n();
    const { close: closeModal } = useModal<PullCreditModalProps>('pullCredit');
    const setSectionSelected = useSetRecoilState(
        sectionSelectedState({ applicationId })
    );
    const hardPulledCredit =
        !!applicant?.creditReport?.hit &&
        applicant?.creditReport?.pullType === 'HARD';

    const [consentChecked, setConsentChecked] = React.useState(
        !!applicant?.creditPullConsentDate || hardPulledCredit
    );

    const refreshApplication = useRefreshApplicationById(applicationId);
    const methods = useForm<PullCreditFormData>({
        resolver: yupResolver(getPullCreditSchema(i18n)),
    });

    const { isSubmitting } = methods.formState;

    // Validate we have the minimum required fields to pull credit
    // if not, we disable the pull button
    const { pullRequirementErrors, hasRequirementErrors } = useMemo(() => {
        try {
            applicantRequirments(i18n).validateSync(applicant, {
                abortEarly: false,
            });

            return { pullRequirementErrors: {}, hasRequirementErrors: false };
        } catch (e) {
            // get the YUP error object and convert it to a map
            if (e instanceof ValidationError) {
                const pullRequirementErrors: Record<string, string> =
                    e.inner.reduce((acc, curr) => {
                        acc[curr.path] = curr.message;
                        return acc;
                    }, {});

                return { pullRequirementErrors, hasRequirementErrors: true };
            }
        }
    }, [applicant, i18n]);

    const handleClickHereLink = () => {
        setSectionSelected(SectionKeys.applicants);
        closeModal();
    };

    const clickCreditConsent = async () => {
        setConsentChecked(!consentChecked);
        const date = new Date().toISOString().split('T')[0];
        const payload = !applicant?.creditPullConsentDate ? date : null;
        await apiClient.applicantCreditConsent(
            applicationId,
            applicant.applicantId,
            payload
        );
        await refreshApplication();
    };

    const currentAddress = getCurrentAddress(applicant.addresses);
    return (
        <FormProvider {...methods}>
            {!!isSubmitting && <OverlaySpinner />}
            <Grid
                gridGap={10}
                gridTemplateColumns="1fr"
                gridAutoRows="auto"
                gridTemplateAreas={`
                "header"
                "content"
                "error"
                "copyText"
                "disclaimer"
                "footerActions"
            `}
                as="form"
                id="pull-credit"
            >
                <Grid
                    gridArea="content"
                    css={css({ justifyContent: 'center' })}
                >
                    <Text
                        tx="pullCreditModal.label"
                        values={{
                            userName: `${applicant.firstName} ${applicant.lastName}`,
                        }}
                        css={css({ fontSize: 3, fontWeight: 700 })}
                    />
                </Grid>
                <PullCreditForm
                    applicant={applicant}
                    currentAddress={currentAddress}
                />
                <PullCreditMinimumRequirement
                    applicationId={applicationId}
                    hasRequirementErrors={hasRequirementErrors}
                    errors={pullRequirementErrors}
                    onClickHereLink={handleClickHereLink}
                />
                <Grid
                    gridArea="disclaimer"
                    css={css({ justifyContent: 'center' })}
                >
                    <Label name="credit-consent-checkbox">
                        <CheckboxComponent
                            checked={consentChecked}
                            disabled={hardPulledCredit}
                            onChange={() => clickCreditConsent()}
                            css={{
                                cursor: hardPulledCredit ? 'auto' : 'pointer',
                            }}
                        />
                        <Text
                            tx="pullCreditDisclaimer"
                            values={{
                                userName: `${applicant.firstName} ${applicant.lastName}`,
                            }}
                            css={css({ fontSize: 1, fontWeight: 500 })}
                        />
                    </Label>
                </Grid>

                <PullCreditActions
                    applicationId={applicationId}
                    applicant={applicant}
                    hasRequirementErrors={hasRequirementErrors}
                    pullCreditConsent={consentChecked}
                    lightQualification={lightQualification}
                />
            </Grid>
        </FormProvider>
    );
};
