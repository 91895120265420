import React from 'react';

import { ButtonIcon, Card, Chip, Divider, Flex } from '@nestoca/ui';
import cn from 'classnames';
import { FlagFill } from 'grommet-icons';
import { GrClose } from 'react-icons/gr';
import { useRecoilValue } from 'recoil';

import { Text } from 'components/text/text';
import { getFlags } from 'store/flags';

import { Flags } from './flags';
import styles from './flags-sidebar.module.scss';

type Props = {
    applicationId: number;
    isFlagsPanelVisible: boolean;
    hideFlagsPanel: () => void;
};

export const FlagsSidebar = ({
    applicationId,
    isFlagsPanelVisible,
    hideFlagsPanel,
}: Props) => {
    const { flags } = useRecoilValue(getFlags({ applicationId }));

    const handleOnClose = () => {
        hideFlagsPanel();
    };

    return (
        isFlagsPanelVisible && (
            <Card className={cn([styles.flags_sidebar])}>
                <ButtonIcon
                    aria-label="close_btn"
                    variant="ghost"
                    size="medium"
                    icon={<GrClose title={null} />}
                    onClick={handleOnClose}
                />
                <Flex
                    className={styles.flags_top_container}
                    align="center"
                    gap={4}
                >
                    <FlagFill color="var(--color-status-error)" />
                    <Text
                        as="h1"
                        fontSize="var(--font-size-3)"
                        fontWeight="var(--font-weight-7)"
                        color="var(--color-brand)"
                        tx="redFlags"
                    />
                    <Chip label={String(flags.length)} variant="error" />
                </Flex>
                <Divider className={styles.flags_divider} />
                <Flags applicationId={applicationId} flags={flags} />
            </Card>
        )
    );
};
