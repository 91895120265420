import React from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { Box, Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';

import { Text } from 'components/text/text';
import { formatMoney } from 'utils';

import type { Product } from 'types/rates';

export const Section = styled(Flex)`
    border: 1px solid ${({ theme }) => theme.global.colors['gray-4']};
`;
const Cell = styled(Flex)`
    width: 50%;
    flex-direction: column;
`;
const BPSCell = styled(Cell)`
    border-left: 1px solid ${({ theme }) => theme.global.colors['gray-4']};
    border-top: 1px solid ${({ theme }) => theme.global.colors['gray-4']};
    border-bottom: 1px solid ${({ theme }) => theme.global.colors['gray-4']};
`;
export const RateCell = styled(Cell)`
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: var(--color-brand);
`;

type CellContentProps = {
    children: React.ReactNode;
};
export const CellHeader = ({ children, ...rest }: CellContentProps) => (
    <Text css={css({ fontSize: 0 })} {...rest}>
        {children}
    </Text>
);
export const CellValue = ({ children, ...rest }: CellContentProps) => (
    <Text {...rest}>{children}</Text>
);

type Props = Partial<Product>;

export const LenderTable = ({
    term,
    type,
    ltvMin,
    ltvMax,
    rate,
    rateHold,
    creditScoreMin,
    mortgageAmountMin,
}: Props) => {
    return (
        <Flex flexDirection="column">
            <Section flexDirection="row" padding="8px">
                <Cell>
                    <CellHeader css={css({ color: 'gray-2' })}>
                        <Trans id="term" />
                    </CellHeader>
                    <CellValue css={{ fontWeight: 'bold' }}>
                        <Trans id={term} /> <Trans id={type} />
                    </CellValue>
                </Cell>
                <Cell>
                    <CellHeader css={css({ color: 'gray-2' })}>
                        <Trans id="rateTile.ltv" />
                    </CellHeader>
                    <CellValue>
                        {ltvMin}% - {ltvMax}%
                    </CellValue>
                </Cell>
            </Section>
            <Section flexDirection="row" padding="8px" mt="20px">
                <Cell>
                    <CellHeader
                        css={css({ color: 'gray-2', fontWeight: 'bold' })}
                    >
                        <Trans id="rateHold.title" />
                    </CellHeader>
                    <CellValue>
                        <Trans id={rateHold} />
                    </CellValue>
                </Cell>
                <Cell>
                    <CellHeader css={css({ color: 'gray-2' })}>
                        <Trans id="Min. beacon" />
                    </CellHeader>
                    <CellValue>{creditScoreMin}</CellValue>
                </Cell>
            </Section>
            <Flex flexDirection="row" mt="20px">
                <BPSCell padding="8px">
                    <CellHeader css={css({ color: 'gray-2' })}>
                        <Trans id="mortgageAmount.minimum" />
                    </CellHeader>
                    <CellValue>{formatMoney(mortgageAmountMin)}</CellValue>
                </BPSCell>
                <RateCell padding="8px">
                    <CellHeader
                        css={css({ color: 'white', fontWeight: 'bold' })}
                    >
                        <Trans id="rateTile.rate" />
                    </CellHeader>
                    <Box>
                        <CellValue
                            css={css({ color: 'white', fontWeight: 'bold' })}
                        >
                            {rate.toFixed(2)}%
                        </CellValue>
                    </Box>
                </RateCell>
            </Flex>
        </Flex>
    );
};
