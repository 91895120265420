import React from 'react';

import { Banner, Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { OtherProperty } from 'types/property';
import { formatMoney, maxTwoDecimal } from 'utils';
import { compareForId } from 'utils/validations/comparators';

import styles from './property-detail-section.module.scss';

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    isBridgeLoanRequired: boolean;
    bridgeLoanNumber: string;
    showMortgageDetails: boolean;
    property: OtherProperty;
};

export const BridgeLoanInformation = ({
    isCreating,
    isEditing,
    isBridgeLoanRequired,
    bridgeLoanNumber,
    showMortgageDetails,
    property,
}: Props) => {
    const { i18n } = useI18n();

    const { bridgeLoanAmount, bridgeLoanRate, mortgage } = property;

    return (
        <Flex
            className={styles['property-detail-section']}
            direction="column"
            gap={4}
        >
            <Typography size={0} weight={6} textColor="var(--color-black)">
                {i18n._('bridgeLoanInfo')}
            </Typography>
            <Grid
                className={cn(styles['grid'], {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <Typography size={0} textColor="var(--color-black)">
                    {i18n._('doYouRequireBridgeLoan')}
                </Typography>
                <EditableCell
                    required
                    name="bridgeLoanRequired"
                    label="bridgeLoan"
                    fieldType="boolean"
                    value={isBridgeLoanRequired}
                    isEditing={isEditing}
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />
            </Grid>
            {isBridgeLoanRequired && (
                <Flex direction="column" gap={4}>
                    <Grid className={styles['grid']} gap={4}>
                        <EditableCell
                            isEditing={isEditing}
                            name="bridgeLoanAmount"
                            fieldType="money"
                            label="bridgeLoanAmount"
                            value={bridgeLoanAmount}
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                            required
                        />
                        <EditableCell
                            isEditing={isEditing}
                            name="bridgeLoanRate"
                            fieldType="number"
                            label="bridgeLoanRate"
                            max={100}
                            value={maxTwoDecimal(bridgeLoanRate)}
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />
                        <EditableCell
                            name="bridgeLoanNumber"
                            fieldType="number"
                            label="bridgeLoanNumber"
                            value={bridgeLoanNumber}
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />
                        {!showMortgageDetails && (
                            <EditableCell
                                required
                                name="mortgage.balance"
                                label="mortgageBalance"
                                fieldType="money"
                                value={formatMoney(mortgage?.balance)}
                                isEditing={isEditing}
                                isFieldInvalidCompareFn={compareForId(property)}
                                problemType={ProblemType.properties}
                            />
                        )}
                    </Grid>
                    <Banner>
                        <Typography
                            textColor="var(--color-brand)"
                            style={{ fontSize: '12px' }}
                        >
                            {i18n._('bridgeLoanRate.info')}
                        </Typography>
                    </Banner>
                </Flex>
            )}
        </Flex>
    );
};
