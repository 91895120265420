import { useCallback, useRef, useState } from 'react';

import { Flex, Typography } from '@nestoca/ui';
import { toast } from 'react-toastify';

import { CoApplicantSearchFormValues } from 'components/account-merge/types';
import { ScrollArea } from 'components/scroll-area';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { CoApplicantSearchResult } from 'types/co-applicant-search';
import { useInfiniteScroll } from 'utils/use-infinite-scroll';

import { AccountDetails } from './account-details';
import { AccountSkeleton } from './account-skeleton';
import { Accounts } from './accounts';
import styles from './connect-accounts-body.module.scss';

export type ConnectAccountsBodyProps = {
    accounts: CoApplicantSearchResult[];
    currentPage: number;
    totalPages: number;
    populatedSearchFormValues: CoApplicantSearchFormValues;
    onUpdateAccounts: (newAccounts: CoApplicantSearchResult[]) => void;
};

export const ConnectAccountsBody = ({
    accounts,
    currentPage,
    totalPages,
    populatedSearchFormValues,
    onUpdateAccounts,
}: ConnectAccountsBodyProps) => {
    const [fetchingNextPage, setFetchingNextPage] = useState(false);

    const { i18n } = useI18n();

    const currentPageRef = useRef(currentPage);

    const hasMorePages = currentPageRef.current < totalPages;

    const fetchNextPage = useCallback(async () => {
        if (fetchingNextPage) return;

        try {
            setFetchingNextPage(true);

            currentPageRef.current += 1;

            const { data } = await apiClient.coApplicantSearch(
                populatedSearchFormValues,
                { page: currentPageRef.current, limit: 25 }
            );

            onUpdateAccounts(data.results);
        } catch (error) {
            toast.error(
                <Typography size={0}>
                    {i18n._(
                        `co-applicant.search.error.${error.response.status}`
                    )}
                </Typography>,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        } finally {
            setFetchingNextPage(false);
        }
    }, [i18n, populatedSearchFormValues, fetchingNextPage, onUpdateAccounts]);

    const observableElementRef = useInfiniteScroll({
        hasMorePages,
        fetchNextPage,
    });

    return (
        <ScrollArea className={styles['connect-accounts-body']}>
            {/* Accounts can't be empty since we open the modal only if there are results */}
            {accounts.length === 1 && (
                <AccountDetails
                    className={styles['account-details']}
                    account={accounts[0]}
                />
            )}
            {accounts.length > 1 && (
                <>
                    <Flex direction="column" gap={2}>
                        <Accounts
                            accounts={accounts}
                            ref={
                                observableElementRef as React.MutableRefObject<HTMLDivElement>
                            }
                        />
                        {hasMorePages &&
                            Array.from({ length: 3 }).map((_, index) => (
                                <AccountSkeleton key={index} />
                            ))}
                    </Flex>
                </>
            )}
        </ScrollArea>
    );
};
