import { useEffect } from 'react';

import { useInView } from './use-in-view';

export type InfiniteScrollProps = {
    hasMorePages: boolean;
    fetchNextPage: () => void;
};

/**
 * This hook is used to implement infinite scroll
 * @param hasMorePages Boolean to check if there are more pages to fetch
 * @param fetchNextPage Callback function to fetch the next page
 * @returns An observable element ref
 */
export const useInfiniteScroll = ({
    hasMorePages,
    fetchNextPage,
}: InfiniteScrollProps) => {
    const { inView, observableElementRef } = useInView({ threshold: 1 });

    useEffect(() => {
        if (inView && hasMorePages) {
            fetchNextPage();
        }
    }, [inView, hasMorePages, fetchNextPage]);

    return observableElementRef;
};
