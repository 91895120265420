import React from 'react';

import { i18n } from '@lingui/core';
import { CheckmarkCircleFullIcon, ErrorIcon } from '@nestoca/icons';
import { Chip, Flex } from '@nestoca/ui';
import { Box } from 'reflexbox/styled-components';

import styles from './counter-flag.container.module.scss';

export const CounterFlagsContainer = ({ documentsCount }) => {
    const { emptyCount, brokerApprovedCount, brokerUnapprovedCount } =
        documentsCount;
    return (
        <Flex className={styles.counterFlags}>
            <Box marginRight={'var(--spacing-2)'}>
                <Chip
                    label={`${emptyCount} ${i18n._('DOCUMENT_LENDER_MISSING')}`}
                />
            </Box>
            <Box marginRight={'var(--spacing-2)'}>
                <Chip
                    label={`${brokerApprovedCount} ${i18n._(
                        'DOCUMENT_BROKER_APPROVED'
                    )}`}
                    variant="success"
                    trailingIcon={<CheckmarkCircleFullIcon />}
                />
            </Box>
            <Box marginRight={'var(--spacing-2)'}>
                <Chip
                    label={`${brokerUnapprovedCount} ${i18n._(
                        'DOCUMENT_BROKER_UNAPPROVED'
                    )}`}
                    variant="error"
                    trailingIcon={<ErrorIcon className={styles.errorIcon} />}
                />
            </Box>
        </Flex>
    );
};
