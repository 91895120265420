import React, { useState } from 'react';

import { EditableCell } from 'components/editable';
import { Popper } from 'components/popper/popper';
import { Text } from 'components/text/text';

import type { ProblemType } from 'constants/problem';
import type { PopperProps as ReactPopperProps } from 'react-popper';

type DateWithPopperProps = {
    isEditing?: boolean;
    name: string;
    gridArea?: string;
    label: string;
    required?: boolean;
    value: string;
    isFieldInvalidCompareFn?: any;
    variant?: any;
    placement?: ReactPopperProps<any>['placement'];
    problemType?: ProblemType;
    blockWeekends?: boolean;
    disabled?: boolean;
};

export const DateWithPopper = ({
    isEditing,
    name,
    gridArea,
    label,
    required,
    value,
    placement = 'left',
    isFieldInvalidCompareFn,
    problemType,
    variant = 'primary',
    blockWeekends = false,
    disabled,
}: DateWithPopperProps) => {
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);

    return (
        <>
            <EditableCell
                isEditing={isEditing}
                name={name}
                disabled={disabled}
                fieldType="date"
                gridArea={gridArea}
                label={label}
                value={value}
                required={required}
                ref={setReferenceElement}
                onMouseOver={() => {
                    setIsPopperOpen(true);
                }}
                onMouseLeave={() => {
                    setIsPopperOpen(false);
                }}
                isFieldInvalidCompareFn={isFieldInvalidCompareFn}
                variant={variant}
                problemType={problemType}
                blockWeekends={blockWeekends}
            />
            <Popper
                isOpen={isPopperOpen}
                referenceElement={referenceElement}
                placement={placement}
            >
                <Text tx="dateFormat" />
            </Popper>
        </>
    );
};
