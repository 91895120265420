import React from 'react';

import { ApplicationLabel } from 'components/scenarios/components/application-label';
import { InputContainer } from 'components/scenarios/components/input-container';
import { COMPOUND_PERIOD_OPTIONS } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';

import type { MortgagePaymentsCalculator } from 'types/calculator';

type Props = {
    onChangeValues: () => void;
    defaultValues: MortgagePaymentsCalculator;
};

const FrequencyCompareFields = ({ onChangeValues, defaultValues }: Props) => {
    const { i18n } = useI18n();

    return (
        <>
            <ApplicationLabel
                gridArea="mortgageAmount"
                tx="mortgageAmount"
                sx={{ border: 'none' }}
            />
            <InputContainer
                gridArea="mortgageAmountValue"
                value={defaultValues.mortgageAmount}
                name="mortgageAmount"
                input="money"
                onChange={onChangeValues}
                sx={{ border: 'none' }}
            />

            <ApplicationLabel
                gridArea="rate"
                tx="rate"
                sx={{ border: 'none' }}
            />
            <InputContainer
                gridArea="rateValue"
                value={defaultValues.rate}
                name="rate"
                input="percentage"
                onChange={onChangeValues}
                sx={{ border: 'none' }}
            />

            <ApplicationLabel
                gridArea="compoundPeriod"
                tx="compoundPeriod"
                sx={{ border: 'none' }}
            />
            <InputContainer
                gridArea="compoundPeriodValue"
                value={defaultValues.compoundPeriod}
                name="compoundPeriod"
                input="select"
                options={COMPOUND_PERIOD_OPTIONS(i18n)}
                onChangeSelect={onChangeValues}
                sx={{ border: 'none' }}
            />

            <ApplicationLabel
                gridArea="amortization"
                tx="amortization"
                sx={{ border: 'none' }}
            />
            <InputContainer
                gridArea="amortizationValue"
                value={defaultValues.amortization}
                name="amortization"
                input="number"
                onChange={onChangeValues}
                sx={{ border: 'none' }}
            />
        </>
    );
};

export default FrequencyCompareFields;
