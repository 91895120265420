import { Box as GrommetBox } from 'grommet';
import styled from 'styled-components';
import {
    layout,
    position,
    space,
    compose,
    color,
    flexbox,
} from 'styled-system';

import type {
    LayoutProps,
    PositionProps,
    SpaceProps,
    ColorProps,
    FlexboxProps,
} from 'styled-system';

export type BoxProps = LayoutProps &
    PositionProps &
    SpaceProps &
    ColorProps &
    FlexboxProps;

export const Box = styled(GrommetBox)<BoxProps>(
    compose(space, layout, color, position, flexbox)
);
