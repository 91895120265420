import { useMemo, useState } from 'react';

import { Box, Button, Divider, Flex, Heading, Input } from '@nestoca/ui';
import css from '@styled-system/css';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { Popper } from 'components/popper/popper';
import { Text } from 'components/text/text';
import { PRESET_REJECTION_MESSAGES } from 'constants/appConstants';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { getEntityDetailforDocuments } from 'store/applications';
import {
    getCompositeKeyFromDocument,
    useRefreshApplicantDocuments,
} from 'store/documents';
import { ApplicantDocument } from 'types/documents';
import { useGetDocumentName } from 'utils/use-document-name';

import styles from './rejection-modal.module.scss';
export type RejectionModalProps = {
    document: ApplicantDocument;
};

export const RejectionModal = ({ document }: RejectionModalProps) => {
    const [rejectionReason, setRejectionReason] = useState<string>('');
    const [customReason, setCustomReason] = useState<boolean>(false);
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);

    const { close: closeModal } = useModal('rejectionModal');

    const toggleCustomReason = () => {
        setRejectionReason('');
        setCustomReason(!customReason);
    };

    const { i18n } = useI18n();

    const refreshApplicantDocuments = useRefreshApplicantDocuments({
        applicationId: document.applicationId,
        applicantId: document.applicantId,
    });

    const handleSubmit = async () => {
        await apiClient.createDocumentMessage(document, {
            text: rejectionReason,
        });
        await refreshApplicantDocuments();
    };

    const documentDetailEntity = useRecoilValue(
        getEntityDetailforDocuments({
            applicationId: document.applicationId,
            applicantId: document.applicantId,
            category: document.category,
            subcategory: document.subCategory,
            entityId: document.entityId,
        })
    );

    const docName = useGetDocumentName({
        document,
        documentEntity: documentDetailEntity.documentEntity,
    });

    const documentUrl = useMemo(
        () =>
            getCompositeKeyFromDocument(document).split('/').slice(1).join('/'),
        [document]
    );

    return (
        <Flex direction="column">
            <Flex direction="column">
                <Heading weight={6} size={5}>
                    {i18n._('unapproveDocument')}
                </Heading>
                <Box>
                    <Link
                        href={`/applications/${document.applicationId}/documents/details/${documentUrl}`}
                    >
                        <CellData
                            fieldType="text"
                            value={docName}
                            css={css({
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                textDecorationColor: 'var(--color-blue-500)',

                                span: {
                                    color: 'var(--color-blue-500)',
                                },
                            })}
                            onClick={closeModal}
                        />
                    </Link>
                </Box>
            </Flex>
            <Divider className={styles.divider} />
            <Flex direction="column">
                <Text tx="rejectDocument.explanation" />
                <Flex direction="column" align="center" justify="center">
                    {PRESET_REJECTION_MESSAGES.map((msg, i) => (
                        <Button
                            className={styles.reason}
                            key={`preset-message-${i}`}
                            variant="ghost"
                            onClick={() => setRejectionReason(i18n._(msg))}
                        >
                            <Text tx={msg} />
                        </Button>
                    ))}
                    {customReason ? (
                        <Flex className={styles.reason}>
                            <Input
                                placeholder={i18n._(
                                    'rejectDocument.customReason'
                                )}
                                size="medium"
                                onChange={(e) =>
                                    setRejectionReason(e.target.value)
                                }
                            />
                        </Flex>
                    ) : (
                        <Button
                            variant="alternative"
                            onClick={toggleCustomReason}
                        >
                            <Text tx="rejectDocument.customReason" />
                        </Button>
                    )}
                </Flex>
            </Flex>
            <Divider className={styles.divider} />
            <Flex justify="end">
                <Button
                    onClick={closeModal}
                    className={styles.action}
                    variant="alternative"
                >
                    {i18n._('cancel')}
                </Button>
                <Flex
                    ref={setReferenceElement}
                    onMouseOver={() => {
                        !rejectionReason && setIsPopperOpen(true);
                    }}
                    onMouseLeave={() => {
                        !rejectionReason && setIsPopperOpen(false);
                    }}
                >
                    <Button
                        disabled={!rejectionReason}
                        className={styles.action}
                        onClick={handleSubmit}
                    >
                        {i18n._('unapproveDocument')}
                    </Button>
                    <Popper
                        isOpen={isPopperOpen}
                        referenceElement={referenceElement}
                        placement="top"
                    >
                        <Text tx="rejectDocument.selectAReason" />
                    </Popper>
                </Flex>
            </Flex>
        </Flex>
    );
};
