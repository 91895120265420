import React from 'react';

import { EditableCell } from 'components/editable';
import { ProblemType } from 'constants/problem';
import { AssetVehicle } from 'types/asset';
import { usePartialValidation } from 'utils/use-partial-validation';
import { compareForId } from 'utils/validations/comparators';

type Props = {
    isEditing: boolean;
    asset: AssetVehicle;
};

export const VehicleAsset = ({ isEditing, asset }: Props) => {
    const partialValidation = usePartialValidation();

    const { description, make, model, year } = asset;

    return (
        <>
            <EditableCell
                required={!partialValidation}
                isEditing={isEditing}
                name="make"
                label="assets.vehicleMake"
                value={make}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
            <EditableCell
                required={!partialValidation}
                isEditing={isEditing}
                name="model"
                label="assets.vehicleModel"
                value={model}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
            <EditableCell
                required={!partialValidation}
                isEditing={isEditing}
                name="year"
                label="year"
                value={year}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
            <EditableCell
                required={!partialValidation}
                isEditing={isEditing}
                name="description"
                label="description"
                value={description}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
        </>
    );
};
