import React from 'react';

import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import IconNameNumber from 'components/shared-components/sections/icon-name-number/icon-name-number';
import {
    getDocumentsGrouped,
    getSelectedDocumentStates,
    getSelectedDocumentType,
} from 'store/documents';
import { countDocumentsBySection } from 'utils/document';

export const DocumentsSections = ({ applicationId, applicantId }) => {
    const router = useRouter();

    const docStates = useRecoilValue(
        getSelectedDocumentStates({ applicationId })
    );
    const docStep = useRecoilValue(getSelectedDocumentType({ applicationId }));
    const documents = useRecoilValue(
        getDocumentsGrouped({
            applicationId,
            applicantId,
            docStates,
            step: docStep,
        })
    );

    return (
        <>
            {Object.entries(documents).map(([category, section]) => {
                const document = countDocumentsBySection(section);
                return (
                    <IconNameNumber
                        title={`dashboard.documents.${category}`}
                        key={category}
                        total={document.totalDocs}
                        approved={document.approvedDocs}
                        rejected={document.rejectedDocs}
                        onClick={() =>
                            router.push(
                                `/applications/${applicationId}/documents#${category}-${applicantId}`
                            )
                        }
                    />
                );
            })}
        </>
    );
};
