import React from 'react';

import { Flex, Spinner, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useRecoilState } from 'recoil';

import { EditableCell } from 'components/editable';
import { CheckboxComponent } from 'components/forms';
import { Label } from 'components/forms';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { includedInLoadingState } from 'store/ui';
import { Liability } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    liability: Liability;
    onToggleIncluded?: (checked: boolean) => void;
};

export const Tds = ({
    isCreating,
    isEditing,
    liability,
    onToggleIncluded,
}: Props) => {
    const { i18n } = useI18n();

    const hasEditingRights = useEditingRights();

    const { id, included } = liability;

    const [loadingState, setLoadingState] = useRecoilState(
        includedInLoadingState
    );

    const disableCheckbox =
        !loadingState.includes(id) && loadingState.length > 0;

    const handleToggleIncluded = (e: React.ChangeEvent<HTMLInputElement>) => {
        onToggleIncluded?.(e.currentTarget.checked);

        setLoadingState([...loadingState, id]);
    };

    return (
        <Flex
            className={cn(styles.section, styles['section--tds'], {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('tds')}
            </Typography>
            {!isCreating && !isEditing && hasEditingRights && (
                <Label name="included">
                    <Flex align="center" gap={3}>
                        {loadingState.includes(id) ? (
                            <Spinner />
                        ) : (
                            <CheckboxComponent
                                // Will make the checkbox more responsive but I think we should add a loading indicator
                                // defaultChecked={included}
                                disabled={disableCheckbox}
                                checked={included}
                                onChange={handleToggleIncluded}
                            />
                        )}
                        <Typography size={0}>
                            {i18n._('includeInTDS')}
                        </Typography>
                    </Flex>
                </Label>
            )}
            {isEditing && (
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="included"
                    label="includeInTDS"
                    fieldType="boolean"
                    value={included}
                    isFieldInvalidCompareFn={compareForId(liability)}
                />
            )}
        </Flex>
    );
};
