import React from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';

import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';

export const SectionTitle = ({
    label,
    gridArea,
}: {
    label: string;
    gridArea: string;
    gridTemplateColumns?: string;
    gridTemplateAreas?: string;
}) => (
    <Grid
        gridArea={gridArea}
        gridAutoRows="auto"
        gridTemplateColumns={'1fr'}
        gridGap={0}
    >
        <h4
            css={css({
                color: 'portGore',
            })}
        >
            <Trans id={label} />
        </h4>
        <Divider css={{ padding: 0, margin: '10px 0 0 0', height: 1 }} />
    </Grid>
);
