import React from 'react';

import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';

type WrapperProps = { size: number; borderColor: string };

const ProgressCircleWrapper = styled.div<WrapperProps>`
    position: relative;
    border-radius: 50%;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    &:hover::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 50%;
        background: ${({ borderColor }) => borderColor};
        opacity: 0.3;
        pointer-events: none;
    }
`;

interface ProgressCircleTextProps {
    fontSize: string | number;
}

const ProgressCircleText = styled(Flex)<ProgressCircleTextProps>`
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    justify-content: center;
    font-weight: 700;
    font-size: ${({ fontSize }) => fontSize};
`;

type ProgressCircleProps = {
    value: number;
    text?: string | number;
    size?: number;
    fontSize?: string | number;
    primaryStrokeWidth?: number;
    secondaryStrokeWidth?: number;
    id?: string;
    borderColor?: string;
};
export const ProgressCircle = ({
    value,
    text,
    size = 160,
    fontSize = 'var(--font-size-0)',
    primaryStrokeWidth = 4,
    secondaryStrokeWidth = 2,
    id,
    borderColor,
}: ProgressCircleProps) => {
    const primaryColor =
        borderColor || 'var(--color-status-success-foreground-saturated)';
    const secondaryColor = 'var(--color-primary-b-200)';
    const textColor = 'var(--color-black)';

    // SVG centers the stroke width on the radius, subtract out so circle fits in square, WE will use the same radius for both circles
    const radius = (size - primaryStrokeWidth) / 2;

    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * Math.min(value, 100)) / 100;

    return (
        <ProgressCircleWrapper
            size={size}
            data-testid={`progress-circle-${id}`}
            borderColor={borderColor || primaryColor}
        >
            <svg
                width={size}
                height={size}
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: 'rotate(-90deg)' }}
            >
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    style={{
                        fill: 'none',
                        stroke: secondaryColor,
                        strokeWidth: secondaryStrokeWidth,
                    }}
                ></circle>
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    style={{
                        fill: 'none',
                        stroke: primaryColor,
                        strokeWidth: primaryStrokeWidth,
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset,
                        strokeLinecap: 'round',
                        animation: 'progress 1s ease-out forwards',
                        opacity: value >= 100 ? '0.75' : 1,
                    }}
                ></circle>
            </svg>
            {text && (
                <ProgressCircleText
                    data-testid="progress-circle-text"
                    color={textColor}
                    fontSize={fontSize}
                >
                    {text}
                </ProgressCircleText>
            )}
        </ProgressCircleWrapper>
    );
};
