import { useTenantSetting } from '@nestoca/multi-tenant';
import { Typography } from '@nestoca/ui';
import css from '@styled-system/css';
import { Flex } from 'reflexbox/styled-components';

import { Grid } from 'components/grid/grid';
import { DividerSpanTwoCol } from 'components/scenarios/components/gds-tds-fields';
import { ScenarioLabel } from 'components/scenarios/components/scenario-label';
import { useI18n } from 'providers/i18n/use-i18n';
import { SectionKeys } from 'types/application';
import { formatMoney } from 'utils';

import type { Qualification } from 'types/qualification';

export const LTV = ({
    qualification,
    handleOnClick,
}: {
    qualification: Qualification;
    handleOnClick: (section: SectionKeys) => void;
}) => {
    const { i18n } = useI18n();
    const { value: enableHeloc } = useTenantSetting('enableHeloc');

    return (
        <Grid gridArea="content" css={css({ width: '60%', margin: '0 auto' })}>
            <Flex justifyContent="center" my={3}>
                <Typography
                    size={1}
                    textColor="var(--color-primary-b-500)"
                    weight={6}
                >
                    {i18n._('ltvScenario')}
                </Typography>
            </Flex>

            <Grid gridTemplateColumns="1fr auto">
                <ScenarioLabel
                    label={'propertyMarketValue'}
                    value={formatMoney(
                        qualification?.ltvEx?.propertyValue || 0,
                        true
                    )}
                    onClick={() => handleOnClick(SectionKeys.subjectProperty)}
                />

                <DividerSpanTwoCol />
                <ScenarioLabel
                    label="mortgageAmount"
                    value={formatMoney(
                        qualification?.ltvEx?.mortgageAmount || 0,
                        true
                    )}
                    onClick={() => handleOnClick(SectionKeys.subjectProperty)}
                />

                {enableHeloc && (
                    <>
                        <DividerSpanTwoCol />
                        <ScenarioLabel
                            label="helocAmount"
                            value={formatMoney(
                                qualification?.ltvEx?.helocAmount || 0,
                                true
                            )}
                            onClick={() =>
                                handleOnClick(SectionKeys.helocDetails)
                            }
                        />
                    </>
                )}

                <DividerSpanTwoCol />
                <ScenarioLabel
                    label="totalFinancingAmount"
                    value={formatMoney(
                        qualification?.totalFinancingAmount || 0,
                        true
                    )}
                    onClick={() => handleOnClick(SectionKeys.mortgageDetails)}
                />
                <DividerSpanTwoCol />
            </Grid>
        </Grid>
    );
};
