import { Card, Divider, Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { BiUser } from 'react-icons/bi';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { ScrollArea } from 'components/scroll-area';
import { useAuth } from 'providers/auth';
import { useI18n } from 'providers/i18n/use-i18n';
import { getMainApplicant } from 'store/applications';
import {
    getReferralByApplicationId,
    getReferralMainApplicant,
} from 'store/referrals';

import dashBoardStyles from '../broker-dashboard.module.scss';

import styles from './main-applicant.module.scss';

type MainApplicantProps = {
    applicationId: number;
    className?: string;
};

export const MainApplicant = ({
    applicationId,
    className,
}: MainApplicantProps) => {
    const { i18n } = useI18n();
    // Language is leaving in the client account as preffered language
    const { client } = useAuth();
    const referral = useRecoilValue(
        getReferralByApplicationId({ applicationId })
    );

    // Referral main applicant
    const referralMainApplicant = useRecoilValue(
        getReferralMainApplicant({ applicationId })
    );

    // our main applicant
    const mainApplicant = useRecoilValue(getMainApplicant(applicationId));

    return (
        <Card
            className={cn(
                styles['main-applicant__card'],
                dashBoardStyles.card,
                className
            )}
            title={'asset.property'}
        >
            <Flex
                className={dashBoardStyles.header}
                align="center"
                justify="between"
                gap={4}
                as="header"
            >
                <Flex align="center" gap={2}>
                    <BiUser size={30} />
                    <Typography weight={7} size={0}>
                        {i18n._('referral.mainApplicantInfo')}
                    </Typography>
                </Flex>
            </Flex>
            <ScrollArea>
                <Grid className={styles.grid}>
                    <CellData
                        tx={'First Name'}
                        value={mainApplicant?.firstName || ''}
                    />
                    <CellData
                        tx={'Last Name'}
                        value={mainApplicant?.lastName || ''}
                    />
                    <CellData
                        tx={'email'}
                        value={mainApplicant?.email || ''}
                        withCopy
                    />
                    <CellData tx={'Phone'} value={mainApplicant?.phone || ''} />
                </Grid>
                <Divider />
                <Grid className={styles.grid}>
                    <CellData
                        tx={'Language'}
                        value={i18n._(`language.${client.preferredLanguage}`)}
                    />
                    <CellData
                        tx={'referral.engagementModel'}
                        value={
                            referral?.externalData?.engagement_model
                                ? i18n._(
                                      `referral.engagementModel.${referral?.externalData?.engagement_model}`
                                  )
                                : ''
                        }
                    />
                </Grid>
                <Divider />
                <Typography weight={7} size={0}>
                    {i18n._('referral.clientInfo')}
                </Typography>
                <Grid className={styles.grid}>
                    <CellData
                        tx={'referral.bpID'}
                        value={
                            mainApplicant.identityId ||
                            referralMainApplicant?.externalData?.prospect_id ||
                            ''
                        }
                    />
                    <CellData
                        tx={'referral.clientAssetValue'}
                        fieldType="money"
                        value={
                            referralMainApplicant?.externalData
                                ?.client_assets || ''
                        }
                    />
                    <CellData
                        tx={'referral.clientId'}
                        value={
                            referralMainApplicant?.externalData?.client_id || ''
                        }
                    />
                    <CellData
                        tx={'referral.clientType'}
                        value={
                            referralMainApplicant?.externalData?.client_type ||
                            ''
                        }
                    />
                    <CellData
                        tx={'referral.employeeCompany'}
                        value={
                            referralMainApplicant?.externalData
                                ?.employee_company || ''
                        }
                    />
                    <CellData
                        tx={'referral.employeeId'}
                        value={
                            referralMainApplicant?.externalData?.employee_id ||
                            ''
                        }
                    />
                    <CellData
                        tx={'referral.familyGroupAssetValue'}
                        fieldType="money"
                        value={
                            referralMainApplicant?.externalData
                                ?.family_group_assets || ''
                        }
                    />
                    <CellData
                        tx={'referral.offBookAssetsValue'}
                        fieldType="money"
                        value={
                            referralMainApplicant?.externalData
                                ?.offbook_assets || ''
                        }
                    />
                    <CellData
                        tx="referral.tenantId"
                        fieldType="text"
                        value={referral?.tenantReferralId || ''}
                    />
                </Grid>
                <Divider />
                <Grid className={styles.grid}>
                    <CellData
                        tx={'referral.pricingGroup'}
                        // TODO what is the neame of the prop
                        value={
                            referralMainApplicant?.externalData
                                ?.pricing_group || ''
                        }
                    />
                    <CellData
                        tx={'referral.pricingGroupAssetValue'}
                        fieldType="money"
                        value={
                            referralMainApplicant?.externalData
                                ?.pricing_group_assets || ''
                        }
                    />
                </Grid>
                <Divider />
                <Typography weight={7} size={0}>
                    {i18n._('referral.productsDiscussed')}
                </Typography>
                <Grid className={styles.grid}>
                    <CellData
                        tx="products"
                        fieldType="text"
                        value={referral?.productsDiscussed?.join(', ') || ''}
                        css={{ gridColumn: 'span 2' }}
                    />
                    <CellData
                        tx="additionalNotes"
                        fieldType="text"
                        value={referral?.externalData?.additional_notes || ''}
                        css={{ gridColumn: 'span 2' }}
                    />
                </Grid>
            </ScrollArea>
        </Card>
    );
};
