import React from 'react';

import { Card } from 'components/card/card';

import { CardActions } from './card-actions';

import type { Props as CardProps } from 'components/card/card';

type Props = {
    actions: React.ReactNode | React.ReactNode[];
} & CardProps;

export const CardWithAction = ({ preset = 'primary', ...props }: Props) => (
    <Card preset={preset} {...props}>
        {props.children}
        {props.actions && (
            <CardActions id={props.id}>{props.actions}</CardActions>
        )}
    </Card>
);
