import { useState } from 'react';

import { useTenantSettings } from '@nestoca/multi-tenant';
import { Flex } from '@nestoca/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { DateWithPopper } from 'components/popper/date-popper';
import { Popper } from 'components/popper/popper';
import { Text } from 'components/text/text';
import {
    APPLICANT_PERMISSIONS,
    CREDITSCORE_QUALITY_OPTIONS,
    MARITAL_STATUS_OPTIONS,
    RELATION_TO_MAIN_APPLICANT_OPTIONS,
    SALUTATION_OPTIONS,
    useResidencyStatusOptions,
} from 'constants/appConstants';
import { BANKING_INSTITUTION_OPTIONS } from 'constants/lenderConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicationType } from 'store/applications';
import { MAIN_APPLICATION_TYPE } from 'types/application';
import { booleanNormalizer, formatAsSIN } from 'utils';
import { useProblems } from 'utils/use-problems';
import { useRequiredApplicantFields } from 'utils/use-required-applicant-fields';
import { compareForApplicant } from 'utils/validations/comparators';

import { MPIInsurerResponseField } from './mpi-insurer-response-field';


import type { ApplicantInfo } from 'types/applicant';
import type { ApplicationProblemsGroupedWithApplicants } from 'types/problems';

type Props = {
    isEditing: boolean;
    applicationId: number;
    applicant: ApplicantInfo;
    isMainApplicant: boolean;
    problems?: ApplicationProblemsGroupedWithApplicants;
    isCreating?: boolean;
};

export const ApplicantInfoFields = ({
    applicationId,
    applicant,
    isEditing,
    isMainApplicant = false,
    problems,
    isCreating = false,
}: Props) => {
    const { i18n } = useI18n();
    const methods = useFormContext();
    const {
        showValidationProblems,
        partialSavingIdentification,
        mpiInsurerFields,
        showEditablePermissions,
    } = useFlags();

    const applicationType = useRecoilValue(getApplicationType(applicationId));

    const RESIDENCY_STATUS_OPTIONS = useResidencyStatusOptions(i18n);

    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);

    const requiredFields = useRequiredApplicantFields(
        isMainApplicant,
        isCreating
    );

    useProblems(showValidationProblems ? problems : [], applicant.applicantId);

    const {
        enablePrivacyMode: { value: enablePrivacyMode },
        applicantSettings: { settings: applicantSettings },
    } = useTenantSettings();

    let primaryBankingInstitution = applicant.primaryBankingInstitution;
    let covid19Impacted = applicant.covid19Impacted;

    if (methods) {
        const { watch } = methods;

        primaryBankingInstitution = watch(
            'primaryBankingInstitution',
            applicant.primaryBankingInstitution
        );

        covid19Impacted = watch('covid19Impacted', applicant.covid19Impacted);
    }

    return (
        <>
            <EditableCell
                isEditing={isEditing}
                name="applicantId"
                fieldType="readonly"
                label={'applicantId'}
                value={applicant.applicantId}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <DateWithPopper
                isEditing={isEditing}
                name="dateOfBirth"
                label={'Date Of Birth'}
                value={applicant.dateOfBirth}
                required={
                    requiredFields.includes('dob') ||
                    !partialSavingIdentification
                }
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="salutation"
                fieldType="select"
                options={SALUTATION_OPTIONS(i18n)}
                label={'salutation'}
                value={applicant.salutation}
                required={!partialSavingIdentification}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="firstName"
                label={'First Name'}
                value={applicant.firstName}
                required={requiredFields.includes('firstName')}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="lastName"
                label={'Last Name'}
                value={applicant.lastName}
                required={requiredFields.includes('lastName')}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="phone"
                fieldType="phone"
                label={'Phone'}
                value={applicant.phone}
                required={requiredFields.includes('phone')}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="maritalStatus"
                fieldType="select"
                options={MARITAL_STATUS_OPTIONS(i18n)}
                label={'Marital Status'}
                value={applicant.maritalStatus}
                required={!partialSavingIdentification}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />
            <EditableCell
                isEditing={isEditing}
                name="divorcedOrSeparated"
                fieldType="boolean"
                label="divorcedOrSeparated"
                value={applicant.divorcedOrSeparated}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="email"
                fieldType="email"
                label={'email'}
                value={applicant.email}
                required={requiredFields.includes('email')}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            {!isMainApplicant && (
                <EditableCell
                    isEditing={isEditing}
                    name="relationToMainApplicant"
                    fieldType="select"
                    options={RELATION_TO_MAIN_APPLICANT_OPTIONS(i18n)}
                    label={'relationToMainApplicant'}
                    value={applicant.relationToMainApplicant}
                    required={!partialSavingIdentification}
                    isFieldInvalidCompareFn={compareForApplicant(applicant)}
                />
            )}
            <EditableCell
                isEditing={isEditing}
                name="creditScoreQuality"
                fieldType="select"
                options={CREDITSCORE_QUALITY_OPTIONS(i18n)}
                label={'creditScoreQuality'}
                value={applicant.creditScoreQuality}
                required={requiredFields.includes('creditScoreQuality')}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="numberOfDependents"
                fieldType="number"
                label={`Number Of Dependents`}
                value={applicant.numberOfDependents}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            {/* Banking */}
            <EditableCell
                isEditing={isEditing}
                name="primaryBankingInstitution"
                fieldType="select"
                options={BANKING_INSTITUTION_OPTIONS(i18n)}
                label={'primaryBank'}
                value={applicant.primaryBankingInstitution}
                required={!partialSavingIdentification}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />
            {primaryBankingInstitution === 'OTHER' && (
                <EditableCell
                    isEditing={isEditing}
                    name="primaryBankingInstitutionOther"
                    fieldType="text"
                    label={'institutionOther'}
                    value={applicant.primaryBankingInstitutionOther}
                    required={!partialSavingIdentification}
                    isFieldInvalidCompareFn={compareForApplicant(applicant)}
                />
            )}

            {/* Covid */}
            <EditableCell
                isEditing={isEditing}
                name="covid19Impacted"
                fieldType="boolean"
                label={'covid19Impacted'}
                value={applicant.covid19Impacted}
                required={!partialSavingIdentification}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />
            {booleanNormalizer(covid19Impacted) && (
                <EditableCell
                    isEditing={isEditing}
                    name="covid19ImpactDescription"
                    fieldType="text"
                    label={'description'}
                    value={applicant.covid19ImpactDescription}
                    required={!partialSavingIdentification}
                    isFieldInvalidCompareFn={compareForApplicant(applicant)}
                />
            )}

            <EditableCell
                isEditing={isEditing}
                name="hasConsumerProposalOrBankruptcyLast5yrs"
                fieldType="yesNo"
                label={'hasConsumerProposalOrBankruptcyLast5yrs'}
                value={applicant.hasConsumerProposalOrBankruptcyLast5yrs}
                required={!partialSavingIdentification}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />
            <EditableCell
                isEditing={isEditing}
                name="firstTimeHomeBuyer"
                fieldType="boolean"
                label="firstTimeHomeBuyer"
                value={applicant.firstTimeHomeBuyer}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="newToCanada"
                fieldType="boolean"
                label="newToCanada"
                value={applicant.newToCanada}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                required={requiredFields.includes('residencyStatus')}
                name="residencyStatus"
                fieldType="select"
                label="residencyStatus"
                options={RESIDENCY_STATUS_OPTIONS}
                value={applicant.residencyStatus}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                required={requiredFields.includes('hasOwnerOccupancy')}
                name="hasOwnerOccupancy"
                fieldType="boolean"
                label="hasOwnerOccupancy"
                value={applicant.hasOwnerOccupancy}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            <EditableCell
                isEditing={isEditing}
                name="isNestoEmployee"
                fieldType="boolean"
                label="isNestoEmployee"
                value={applicant.isNestoEmployee}
                isFieldInvalidCompareFn={compareForApplicant(applicant)}
            />

            {/*Restricted to RENEWAL main type only */}
            {applicantSettings?.enableVerbalConsent &&
                applicationType === MAIN_APPLICATION_TYPE.RENEWAL && (
                    <Flex align="center">
                        <EditableCell
                            isEditing={isEditing}
                            name="verbalConsent"
                            fieldType="checkbox"
                            label="verbalConsent"
                            disabled={applicant.verbalConsent} // Don't allow editing after it's been set
                            value={applicant.verbalConsent}
                            isFieldInvalidCompareFn={compareForApplicant(
                                applicant
                            )}
                        />
                    </Flex>
                )}

            {showEditablePermissions && (
                <EditableCell
                    isEditing={isEditing}
                    required
                    name="permissions"
                    fieldType="select"
                    label="applicant.permissions"
                    options={APPLICANT_PERMISSIONS({ i18n, isMainApplicant })}
                    value={applicant.permissions}
                    isFieldInvalidCompareFn={compareForApplicant(applicant)}
                />
            )}

            {/* Guarantor only required for co-applicant */}
            {!isMainApplicant && (
                <EditableCell
                    isEditing={isEditing}
                    required={requiredFields.includes('guarantor')}
                    name="guarantor"
                    fieldType="boolean"
                    label={'isGuarantor'}
                    value={applicant.guarantor}
                    isFieldInvalidCompareFn={compareForApplicant(applicant)}
                />
            )}

            {mpiInsurerFields && applicantSettings?.enableMPIInsurer && (
                <MPIInsurerResponseField
                    applicationId={applicationId}
                    applicant={applicant}
                    isEditing={isEditing}
                />
            )}
            {enablePrivacyMode && (
                <Flex
                    ref={setReferenceElement}
                    onMouseOver={() => setIsPopperOpen(true)}
                    onMouseLeave={() => setIsPopperOpen(false)}
                >
                    <EditableCell
                        isEditing={isEditing}
                        name="privacyMode"
                        fieldType="checkbox"
                        label="enablePrivacyMode"
                        value={!!applicant.privacyMode}
                        isFieldInvalidCompareFn={compareForApplicant(applicant)}
                        style={{
                            margin: 'auto 0',
                        }}
                    />
                    <Popper
                        isOpen={isPopperOpen}
                        referenceElement={referenceElement}
                        placement="top"
                    >
                        <Text tx="privacyModeTooltip" />
                    </Popper>
                </Flex>
            )}
            {isCreating && applicantSettings?.enableSINNumber && (
                <EditableCell
                    isEditing
                    name="sin"
                    fieldType="text"
                    label="socialInsuranceNumber"
                    value={formatAsSIN(applicant.sin)}
                    isFieldInvalidCompareFn={compareForApplicant(applicant)}
                />
            )}
        </>
    );
};
