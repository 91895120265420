import { I18n } from '@lingui/core';
import * as yup from 'yup';

import { stringRequired } from './string';

export const getCoApplicantSearchSchema = (i18n: I18n) =>
    yup.object().shape({
        firstName: stringRequired(i18n),
        lastName: stringRequired(i18n),
        phone: stringRequired(i18n)
            .phone(i18n._('error.invalidPhone'))
            // 12 is the length of a phone number with the country code (e.g. +1234567890)
            .length(12, i18n._('error.invalidPhone')),
        email: yup.string().email(i18n._('error.invalidEmail')),
    });
