import React from 'react';

import { Typography } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import styles from 'components/asset/asset.module.scss';
import { getPropertyById } from 'store/applications';
import { AssetProperty } from 'types/asset';
import { formatAddress } from 'utils';

type Props = {
    applicationId: number;
    asset: AssetProperty;
};

export const PropertyAsset = ({ applicationId, asset }: Props) => {
    const address = useRecoilValue(
        getPropertyById({
            applicationId: applicationId,
            propertyId: asset?.existingPropertyId,
        })
    );

    return (
        <Typography className={styles['property-address-field']} size={0}>
            {formatAddress(address?.address)}
        </Typography>
    );
};
