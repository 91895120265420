import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import {
    HandleCheckboxToggle,
    IncludeCheckbox,
} from 'components/scenarios/components/checkbox';
import { FREQUENCY } from 'constants/appConstants';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { CoOwnershipFees, OtherProperty } from 'types/property';
import { formatMoney, maxTwoDecimal } from 'utils';
import { compareForId } from 'utils/validations/comparators';

import styles from './property-detail-section.module.scss';

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    condoFees: Partial<CoOwnershipFees>;
    property: OtherProperty;
    onCheckboxToggle?: HandleCheckboxToggle;
};

export const CondoFees = ({
    isCreating,
    isEditing,
    condoFees,
    property,
    onCheckboxToggle,
}: Props) => {
    const hasEditingRights = useEditingRights();

    const { i18n } = useI18n();

    const { condoFeesIncluded } = property;

    let propertyType = property.type;

    const methods = useFormContext();

    if (methods) {
        const { watch } = methods;

        propertyType = watch('type');
    }

    const isCondo = propertyType === 'CONDO';

    return (
        <Flex
            className={styles['property-detail-section']}
            direction="column"
            gap={4}
        >
            <Typography size={0} weight={6} textColor="var(--color-black)">
                {i18n._('condoFeesLabel')}
            </Typography>
            <Grid
                className={cn(styles['grid'], {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    required={isCondo}
                    name="condoFees.amount"
                    label="amount"
                    fieldType="money"
                    value={
                        condoFees?.amount ? formatMoney(condoFees.amount) : ''
                    }
                    isEditing={isEditing}
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />

                <EditableCell
                    required={isCondo}
                    name="condoFees.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={condoFees?.frequency}
                    isEditing={isEditing}
                    isClearable
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />
                <EditableCell
                    required={isCondo}
                    name="condoFees.ratio"
                    label="Offset"
                    fieldType="percentage"
                    value={
                        condoFees?.ratio ? maxTwoDecimal(condoFees.ratio) : 50
                    }
                    isEditing={isEditing}
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />
                {!isEditing && hasEditingRights && (
                    <IncludeCheckbox
                        fieldName="condoFeesIncluded"
                        includeTitle="includeInTDS"
                        checked={condoFeesIncluded || false}
                        handleToggle={onCheckboxToggle}
                    />
                )}
            </Grid>
        </Flex>
    );
};
