import React, { useState } from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { FormPreviousLink } from 'grommet-icons';
import { useRecoilValue } from 'recoil';
import { Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';
import { Grid } from 'components/grid/grid';
import { Spinner } from 'components/spinner';
import { Text } from 'components/text/text';
import { useToasts } from 'components/toast';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    getApplicationLocked,
    useRefreshApplicationById,
} from 'store/applications';
import { useAnalytics } from 'utils/use-analytics';

export type LockApplicationProps = {
    applicationId: number;
};

export const LockApplicationModal = ({
    applicationId,
}: LockApplicationProps) => {
    const isApplicationLocked = useRecoilValue(
        getApplicationLocked(applicationId)
    );
    const { addToast } = useToasts();
    const { i18n } = useI18n();
    const { track } = useAnalytics();
    const { close: closeModal } = useModal<LockApplicationProps>(
        'lockApplicationModal'
    );
    const refreshApplication = useRefreshApplicationById(applicationId);

    const [isSubmitting, setIsSubmitting] = useState<boolean>();
    const updateLockApplication = async () => {
        try {
            await apiClient.lockApplication(applicationId, {
                locked: !isApplicationLocked,
            });
            await refreshApplication();

            setIsSubmitting(false);
            closeModal();
        } catch (error) {
            setIsSubmitting(false);
            addToast(`Error: ${i18n._({ id: 'Cannot save data to server' })}`, {
                appearance: 'error',
            });
        }
    };

    const handleLockApplication = async () => {
        setIsSubmitting(true);

        track('click lock application');

        await updateLockApplication();
    };

    const handleCloseModal = () => {
        track('click close lock application modal');

        closeModal();
    };

    if (!applicationId) {
        return null;
    }

    return (
        <Grid
            gridGap={10}
            gridTemplateColumns="1fr"
            gridAutoRows="auto"
            gridTemplateAreas={`
                "content"
                "footerActions"
            `}
        >
            <Grid gridArea="content" css={css({ justifyContent: 'center' })}>
                <Text
                    tx={
                        isApplicationLocked
                            ? 'lockApplicationText.unLocked'
                            : 'lockApplicationText.locked'
                    }
                />
            </Grid>

            <Grid
                gridArea="footerActions"
                gridTemplateColumns={'repeat(2, 1fr)'}
                css={css({ marginTop: 4 })}
            >
                <Flex>
                    <Button
                        iconLeft={<FormPreviousLink size="small" />}
                        data-testid={'back'}
                        type="button"
                        onClick={handleCloseModal}
                        size="medium"
                        variant="plain"
                    >
                        <Trans id="back" />
                    </Button>
                </Flex>
                <Grid css={css({ justifyContent: 'right', marginRight: 4 })}>
                    <Button
                        data-testid={'lockApplicationButton'}
                        type="button"
                        onClick={() => handleLockApplication()}
                        size="large"
                        variant={'ghost'}
                        css={css({
                            width: 190,
                            justifyContent: 'center',
                        })}
                        iconRight={isSubmitting ? <Spinner size={16} /> : null}
                    >
                        <Trans
                            id={
                                isApplicationLocked
                                    ? 'lockApplication.unLock'
                                    : 'lockApplication.lock'
                            }
                        />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
