import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@nestoca/ui';
import * as R from 'ramda';
import { toast } from 'react-toastify';

import { EditGrid } from 'components/editable/editable-grid';
import { ModalFormActions } from 'components/modals/modal-form-actions';
import { RegisterAddressFields } from 'components/register-address/register-address-fields';
import { SuccessfulToastContent } from 'components/successful-toast-content';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    useRefreshApplicationById,
    useRefreshApplicationMortgage,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import { useRefreshQualification } from 'store/qualification';
import { RegisteredAddress } from 'types/address';
import { booleanNormalizer } from 'utils';
import { useEditingContextWithCleanup } from 'utils/use-editing-context';
import { getRegisterAddressSchema } from 'validations/register-address';

import styles from './register-address-modal.module.scss';

export type RegisterAddressModalProps = {
    editableKey: string;
    applicationId: number;
    applicantId: number;
};

const registerAddress: RegisteredAddress = {
    isCurrentAddress: undefined,
    occupiedYears: 0,
    occupiedMonths: 0,
    situation: 'OWNER',
    address: {
        countryCode: 'CA',
        city: '',
        postalCode: '',
        stateCode: '',
        unit: '',
        streetNumber: '',
        street: '',
    },
    rent: {
        amount: 0,
        frequency: 'MONTHLY',
    },
};

const normalizeData = R.evolve({
    occupiedYears: Number,
    occupiedMonths: Number,
    isCurrentAddress: booleanNormalizer,
});

export const RegisterAddressModal = ({
    editableKey,
    applicationId,
    applicantId,
}: RegisterAddressModalProps) => {
    const { i18n } = useI18n();

    const refreshApplicationMortgage =
        useRefreshApplicationMortgage(applicationId);

    const refreshApplication = useRefreshApplicationById(applicationId);

    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });

    const { refresh: refreshQualification } = useRefreshQualification();

    const { close: closeModal } = useModal<RegisterAddressModalProps>(
        'createRegisterAddress'
    );

    const { editingKey } = useEditingContextWithCleanup(editableKey);

    const isEditing = useMemo(() => editingKey === editableKey, [editingKey]);

    const schema = getRegisterAddressSchema(i18n);

    const onSubmit = async (values: RegisteredAddress) => {
        try {
            const normalizedData = normalizeData({
                ...registerAddress,
                ...values,
                address: {
                    ...values.address,
                    stateCode: ['CA', 'US'].includes(values.address.countryCode)
                        ? values.address.stateCode
                        : '',
                },
            });

            await apiClient.createRegisteredAddress(
                applicationId,
                applicantId,
                normalizedData
            );

            await Promise.all([
                refreshApplication(),
                refreshApplicationDocumentsCounts(),
                refreshQualification(applicationId),
                refreshApplicationMortgage(),
            ]);

            closeModal();

            toast.success(
                <SuccessfulToastContent
                    text={i18n._('applicantAddress.successfullySaved')}
                />,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        } catch (error) {
            toast.error(
                <Typography size={0} height={0}>
                    {i18n._('failedToSave')}
                </Typography>,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    };

    return (
        <EditGrid
            hideToolbar
            id="createRegisteredAddress"
            className={styles['register-address-modal']}
            resolver={yupResolver(schema)}
            defaultValues={registerAddress}
            normalizeData={normalizeData}
            onSubmit={onSubmit}
            gridTemplateColumns="repeat(4, 1fr)"
            gridGap={16}
        >
            <RegisterAddressFields
                isEditing
                applicationId={applicationId}
                applicantId={applicantId}
                index={0}
                registeredAddress={registerAddress}
                formattedAddress=""
                copyMainApplicantAddressOptionAvailable
            />
            {isEditing && <ModalFormActions closeModal={closeModal} />}
        </EditGrid>
    );
};
