import React from 'react';

import { Flex, Typography } from '@nestoca/ui';

import { AddressEditable } from 'components/editable/address-editable';
import {
    EmployerDetails,
    EmploymentDetails,
    IncomeDetails,
    IndustryDetails,
} from 'components/employment/section';
import { useI18n } from 'providers/i18n/use-i18n';

import { EmploymentTypeSectionProps } from './employment-type';

export const Salaried = ({
    isEditing,
    isCreating,
    employment,
    incomeTypeWatch,
    partialSchema,
    onToggleIncluded,
}: EmploymentTypeSectionProps) => {
    const { i18n } = useI18n();

    return (
        <>
            <EmploymentDetails
                isCreating={isCreating}
                isEditing={isEditing}
                employment={employment}
                incomeTypeWatch={incomeTypeWatch}
            />
            <IncomeDetails
                isCreating={isCreating}
                isEditing={isEditing}
                employment={employment}
                incomeTypeWatch={incomeTypeWatch}
                onToggleIncluded={onToggleIncluded}
            />
            <IndustryDetails
                isCreating={isCreating}
                isEditing={isEditing}
                employment={employment}
                partialSchema={partialSchema}
            />
            <EmployerDetails
                isCreating={isCreating}
                isEditing={isEditing}
                employment={employment}
            />
            <Flex direction="column" gap={4}>
                <Typography weight={7} textColor="var(--color-black)">
                    {i18n._('employerAddress')}
                </Typography>
                <AddressEditable
                    required={!partialSchema}
                    isEditing={isEditing}
                    address={employment?.employer?.address}
                    namePrefix="employer.address."
                    menuPlacement="bottom"
                    gridGap="var(--spacing-4)"
                    gridTemplateColumns={
                        isCreating
                            ? 'repeat(auto-fit, minmax(var(--size-13), 1fr))'
                            : 'repeat(auto-fill, minmax(var(--size-13), 1fr))'
                    }
                />
            </Flex>
        </>
    );
};
