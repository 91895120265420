import React from 'react';

import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import { INSTITUTION_OPTIONS } from 'constants/lenderConstants';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { AssetFinancial } from 'types/asset';
import { usePartialValidation } from 'utils/use-partial-validation';
import { compareForId } from 'utils/validations/comparators';

type Props = {
    isEditing: boolean;
    asset: AssetFinancial;
};

export const FinancialAsset = ({ isEditing, asset }: Props) => {
    const { i18n } = useI18n();

    const { watch } = useFormContext() || {};

    const partialValidation = usePartialValidation();

    const { institution, institutionOther } = asset;

    let institutionWatch = institution;

    if (watch) {
        institutionWatch = watch('institution');
    }

    return (
        <>
            <EditableCell
                required={!partialValidation}
                isEditing={isEditing}
                name="institution"
                label="institution"
                fieldType="select"
                options={INSTITUTION_OPTIONS(i18n)}
                value={institution}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
            {institutionWatch === 'OTHER' && (
                <EditableCell
                    required={!partialValidation}
                    isEditing={isEditing}
                    name="institutionOther"
                    label="institutionOther"
                    value={institutionOther}
                    isFieldInvalidCompareFn={compareForId(asset)}
                    problemType={ProblemType.assets}
                />
            )}
        </>
    );
};
