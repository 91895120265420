import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import { IncludeCheckbox } from 'components/scenarios/components/checkbox';
import { FREQUENCY } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { compareForId } from 'utils/validations/comparators';

import { IncludeCheckboxWithTooltip } from '../../popper/include-checkbox-with-tooltip';
import { OnToggleIncluded } from '../employment-type';

import styles from './section.module.scss';

import type { AmountFrequency } from 'types';
import type {
    EmploymentIncomeType,
    IncomeEmployment,
    IncomeIncludedFieldName,
} from 'types/applicant';

export const showFrequency = (salary: AmountFrequency) => {
    return salary?.amount ? salary.frequency : undefined;
};

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    employment: IncomeEmployment & { totalIncome?: number };
    incomeTypeWatch: EmploymentIncomeType;
    onToggleIncluded: OnToggleIncluded;
};

export const IncomeDetails = ({
    isCreating,
    isEditing,
    employment,
    incomeTypeWatch,
    onToggleIncluded,
}: Props) => {
    const { i18n } = useI18n();

    const { watch } = useFormContext() || {};

    const hasEditingRights = useEditingRights();

    const { incomeOverride, incomeOverrideIncluded, isCurrent, salary } =
        employment;

    let incomeOverrideAmountWatch = incomeOverride?.amount;

    let incomeOverrideFrequencyWatch = incomeOverride?.frequency;

    let salaryBonusAmountWatch = salary?.bonus?.amount;

    let salaryBonusFrequencyWatch = salary?.bonus?.frequency;

    let salaryCommissionAmountWatch = salary?.commission?.amount;

    let salaryCommissionFrequencyWatch = salary?.commission?.frequency;

    let salaryOvertimeAmountWatch = salary?.overtime?.amount;

    let salaryOvertimeFrequencyWatch = salary?.overtime?.frequency;

    if (watch) {
        incomeOverrideAmountWatch = watch('incomeOverride.amount');

        incomeOverrideFrequencyWatch = watch('incomeOverride.frequency');

        salaryBonusAmountWatch = watch('salary.bonus.amount');

        salaryBonusFrequencyWatch = watch('salary.bonus.frequency');

        salaryCommissionAmountWatch = watch('salary.commission.amount');

        salaryCommissionFrequencyWatch = watch('salary.commission.frequency');

        salaryOvertimeAmountWatch = watch('salary.overtime.amount');

        salaryOvertimeFrequencyWatch = watch('salary.overtime.frequency');
    }

    const handleToggleIncluded = (
        checked: boolean,
        fieldName: IncomeIncludedFieldName
    ) => {
        onToggleIncluded?.(checked, fieldName);
    };

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('incomeDetails')}
            </Typography>
            <Grid
                className={
                    isEditing ? styles['editing-incomes'] : styles.incomes
                }
                gap={4}
            >
                <EditableCell
                    required={
                        !!incomeOverrideFrequencyWatch &&
                        !incomeOverrideAmountWatch
                    }
                    isEditing={isEditing}
                    name="incomeOverride.amount"
                    label="incomeOverride"
                    fieldType="money"
                    value={incomeOverride?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !!incomeOverrideAmountWatch &&
                        !incomeOverrideFrequencyWatch
                    }
                    isEditing={isEditing}
                    isClearable
                    name="incomeOverride.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={showFrequency(incomeOverride)}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {!isCreating && !isEditing && hasEditingRights && (
                    <IncludeCheckbox
                        handleToggle={handleToggleIncluded}
                        checked={incomeOverrideIncluded}
                        fieldName="incomeOverrideIncluded"
                        disabled={isNaN(incomeOverride?.amount)}
                        includeTitle="includeInIncome"
                    />
                )}
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salary.base.amount"
                    label="income"
                    fieldType="money"
                    value={salary?.base?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salary.base.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={showFrequency(salary?.base)}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {!isCreating && !isEditing && hasEditingRights && (
                    <IncludeCheckboxWithTooltip
                        shouldShowTooltip={!isCurrent && !!salary?.base?.amount}
                        handleToggleIncluded={handleToggleIncluded}
                        checked={salary?.baseRatiosIncluded && isCurrent}
                        disabled={
                            !salary?.base?.amount ||
                            incomeOverrideIncluded ||
                            !isCurrent
                        }
                        fieldName="baseRatiosIncluded"
                    />
                )}
                <EditableCell
                    required={
                        !!salaryBonusFrequencyWatch && !salaryBonusAmountWatch
                    }
                    isEditing={isEditing}
                    name="salary.bonus.amount"
                    label="bonus"
                    fieldType="money"
                    value={salary?.bonus?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !!salaryBonusAmountWatch && !salaryBonusFrequencyWatch
                    }
                    isEditing={isEditing}
                    isClearable
                    name="salary.bonus.frequency"
                    label="bonusFrequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={showFrequency(salary?.bonus)}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {!isCreating && !isEditing && hasEditingRights && (
                    <IncludeCheckboxWithTooltip
                        shouldShowTooltip={
                            !isCurrent && !!salary?.bonus?.amount
                        }
                        handleToggleIncluded={handleToggleIncluded}
                        checked={salary?.bonusRatiosIncluded && isCurrent}
                        disabled={
                            !salary?.bonus?.amount ||
                            incomeOverrideIncluded ||
                            !isCurrent
                        }
                        fieldName="bonusRatiosIncluded"
                    />
                )}
                <EditableCell
                    required={
                        !!salaryOvertimeFrequencyWatch &&
                        !salaryOvertimeAmountWatch
                    }
                    isEditing={isEditing}
                    name="salary.overtime.amount"
                    label="overtime"
                    fieldType="money"
                    value={salary?.overtime?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !!salaryOvertimeAmountWatch &&
                        !salaryOvertimeFrequencyWatch
                    }
                    isEditing={isEditing}
                    isClearable
                    name="salary.overtime.frequency"
                    label="overtimeFrequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={showFrequency(salary?.overtime)}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {!isCreating && !isEditing && hasEditingRights && (
                    <IncludeCheckboxWithTooltip
                        shouldShowTooltip={
                            !isCurrent && !!salary?.overtime?.amount
                        }
                        handleToggleIncluded={handleToggleIncluded}
                        checked={salary?.overtimeRatiosIncluded && isCurrent}
                        disabled={
                            !salary?.overtime?.amount ||
                            incomeOverrideIncluded ||
                            !isCurrent
                        }
                        fieldName="overtimeRatiosIncluded"
                    />
                )}
                {['HOURLY_COMMISSIONS', 'SALARIED'].includes(
                    incomeTypeWatch
                ) && (
                    <>
                        <EditableCell
                            required={
                                !!salaryCommissionFrequencyWatch &&
                                !salaryCommissionAmountWatch
                            }
                            isEditing={isEditing}
                            name="salary.commission.amount"
                            label="commission"
                            fieldType="money"
                            value={salary?.commission?.amount}
                            isFieldInvalidCompareFn={compareForId(employment)}
                        />
                        <EditableCell
                            required={
                                !!salaryCommissionAmountWatch &&
                                !salaryCommissionFrequencyWatch
                            }
                            isEditing={isEditing}
                            isClearable
                            name="salary.commission.frequency"
                            label="commissionFrequency"
                            fieldType="select"
                            options={FREQUENCY(i18n)}
                            value={showFrequency(salary?.commission)}
                            isFieldInvalidCompareFn={compareForId(employment)}
                        />
                        {!isCreating && !isEditing && hasEditingRights && (
                            <IncludeCheckboxWithTooltip
                                shouldShowTooltip={
                                    !isCurrent && !!salary?.commission?.amount
                                }
                                handleToggleIncluded={handleToggleIncluded}
                                checked={
                                    salary?.commissionRatiosIncluded &&
                                    isCurrent
                                }
                                disabled={
                                    !salary?.commission?.amount ||
                                    incomeOverrideIncluded ||
                                    !isCurrent
                                }
                                fieldName="commissionRatiosIncluded"
                            />
                        )}
                    </>
                )}
            </Grid>
        </Flex>
    );
};
