import { useTenantSetting } from '@nestoca/multi-tenant';

import { MapsStreetView, MapsStreetViewLiteral } from 'types/index';

type GoogleMapsViewProps = {
    view: MapsStreetViewLiteral;
    subjectPropertyAddress: string | undefined;
    employmentAddress: string | undefined;
};

export const GoogleMapsView = ({
    view,
    subjectPropertyAddress,
    employmentAddress,
}: GoogleMapsViewProps) => {
    const {
        settings: { apiKey },
    } = useTenantSetting('googleApiMiddleOffice');

    const googleApiKey =
        location.hostname === 'localhost'
            ? 'AIzaSyAfK_W77TgIq8melU14zZR_Na34cDsuJY4' // Local dev key (https://console.cloud.google.com/apis/credentials/key/c9a4aed9-494d-4f17-938c-df8f4b7b4e3d?project=nesto-dev-80311906)
            : apiKey;

    return (
        <>
            {view === MapsStreetView.Directions ? (
                <iframe
                    height="100%"
                    referrerPolicy="no-referrer-when-downgrade"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/directions?key=${googleApiKey}&origin=${subjectPropertyAddress}&destination=${employmentAddress}`}
                    style={{ border: 'none' }}
                />
            ) : (
                <iframe
                    height="100%"
                    referrerPolicy="no-referrer-when-downgrade"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${subjectPropertyAddress}&maptype=${view}`}
                    style={{ border: 'none' }}
                />
            )}
        </>
    );
};
