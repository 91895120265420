import { useEffect, useMemo, useState } from 'react';

import { i18n } from '@lingui/core';
import { Tabs, Tab, TabList, TabListProps } from '@nestoca/ui';
import cn from 'classnames';

import { MapsStreetView, MapsStreetViewLiteral } from 'types/index';

import styles from './google-maps-view-selector.module.scss';

type GoogleMapsViewSelectorProps = {
    view: MapsStreetViewLiteral;
    updateSelectedView: (view: MapsStreetViewLiteral) => void;
} & TabListProps;

export const GoogleMapsViewSelector = ({
    className,
    view,
    updateSelectedView,
    ...rest
}: GoogleMapsViewSelectorProps) => {
    const [tabIndexActive, setTabIndexActive] = useState(0);

    const viewTabs = useMemo(
        () => [
            { label: 'roadmap', view: MapsStreetView.Roadmap },
            { label: 'satellite', view: MapsStreetView.Satellite },
            { label: 'directions', view: MapsStreetView.Directions },
        ],
        []
    );

    const handleTabChange = (tabIndex: number) => {
        updateSelectedView(viewTabs[tabIndex].view);
    };

    useEffect(() => {
        const index = viewTabs.findIndex((viewTab) => viewTab.view === view);

        setTabIndexActive(index);
    }, [view, viewTabs]);

    return (
        <Tabs index={tabIndexActive}>
            <TabList
                className={cn(styles['google-maps-view-tab-list'], className)}
                {...rest}
            >
                {viewTabs.map((viewTab, index) => (
                    <Tab
                        key={index}
                        className={cn(styles.tab, {
                            [styles['tab--active']]: tabIndexActive === index,
                        })}
                        onMouseDown={() => handleTabChange(index)}
                    >
                        {i18n._(viewTab.label)}
                    </Tab>
                ))}
            </TabList>
        </Tabs>
    );
};
