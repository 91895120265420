import React, { memo } from 'react';

import { Chip, Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { BsCloudDownload, BsExclamationDiamond } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { constSelector, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { getPlaidMonitoringErrorMessage } from 'components/plaid-monitoring-card/utils';
import { ScrollArea } from 'components/scroll-area';
import { useI18n } from 'providers/i18n/use-i18n';
import { selectedApplication } from 'store/applications';
import { getPlaidMonitoringHitsByScreeningId } from 'store/plaid-monitoring';

import styles from './plaid-monitoring-hits-modal.module.scss';
import { PlaidMonitoringTableHits } from './plaid-monitoring-table-hits';

export type PlaidMonitoringHitsModalProps = {
    applicantId: number;
    screeningId: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const PlaidMonitoringHitsModal = memo(
    ({
        className,
        applicantId,
        screeningId,
        ...rest
    }: PlaidMonitoringHitsModalProps) => {
        const { i18n } = useI18n();

        const application = useRecoilValue(selectedApplication);

        const hits = useRecoilValueLoadable(
            application
                ? getPlaidMonitoringHitsByScreeningId({
                      applicationId: application.id,
                      applicantId,
                      screeningId,
                  })
                : constSelector([])
        );

        const hitsValue = hits.state === 'hasValue' ? hits.contents : [];

        const processingPullHits = hits.state === 'loading';

        if (hits.state === 'hasError') {
            toast.error(
                <Typography size={0} height={0}>
                    {getPlaidMonitoringErrorMessage(i18n, hits.contents)}
                </Typography>,
                {
                    toastId: 'plaid-monitoring-hits-error',
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }

        return (
            <Flex
                className={cn(styles['plaid-monitoring-hits-modal'], className)}
                direction="column"
                {...rest}
            >
                <Flex
                    className={styles['plaid-monitoring-hits-modal__header']}
                    align="center"
                    gap={5}
                    as="header"
                >
                    <Flex align="center" gap={2}>
                        <BsCloudDownload />
                        <Typography weight={7} textColor="var(--color-black)">
                            {i18n._('plaidMonitoring.hitsModal.title')}
                        </Typography>
                    </Flex>
                    {hitsValue.length > 0 && (
                        <Chip
                            variant="error"
                            variantStyle="inverted"
                            label="Hits"
                            leadingIcon={
                                <BsExclamationDiamond
                                    key="exclamation-diamond-icon"
                                    size={12}
                                />
                            }
                        />
                    )}
                </Flex>
                <ScrollArea
                    className={
                        styles['plaid-monitoring-hits-modal__scroll-area']
                    }
                >
                    <PlaidMonitoringTableHits
                        hits={hitsValue}
                        processingPullHits={processingPullHits}
                    />
                </ScrollArea>
            </Flex>
        );
    }
);

PlaidMonitoringHitsModal.displayName = 'PlaidMonitoringHitsModal';
