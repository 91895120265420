import { useState } from 'react';

import css from '@styled-system/css';
import { Flex } from 'reflexbox/styled-components';

import { Popper } from 'components/popper/popper';
import { IncludeCheckbox } from 'components/scenarios/components/checkbox';
import { Text } from 'components/text/text';
import { IncomeIncludedFieldName } from 'types/applicant';
import { PropertyIncludedFieldName } from 'types/property';

type Props = {
    shouldShowTooltip: boolean;
    handleToggleIncluded: (
        checked: boolean,
        fieldName: IncomeIncludedFieldName
    ) => unknown;
    checked: boolean;
    disabled: boolean;
    fieldName: IncomeIncludedFieldName | PropertyIncludedFieldName;
}


export const IncludeCheckboxWithTooltip = ({
    shouldShowTooltip,
    handleToggleIncluded,
    checked,
    disabled,
    fieldName,
}: Props) => {

    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    
    return (
        <Flex
            css={css({
                position: 'relative',
                cursor: 'pointer',
            })}
            ref={setReferenceElement}
            onMouseOver={() => {
                setIsPopperOpen(true);
            }}
            onMouseLeave={() => {
                setIsPopperOpen(false);
            }}
        >
            <IncludeCheckbox
                handleToggle={handleToggleIncluded}
                checked={checked}
                fieldName={fieldName}
                disabled={disabled}
                includeTitle="includeInIncome"
            />
            {shouldShowTooltip && 
                <Popper
                    isOpen={isPopperOpen}
                    referenceElement={referenceElement}
                    placement={'top'}
                >
                    <Text tx='pastIncome.checkbox.tooltip' />
                </Popper>
            }
        </Flex>
    )
}