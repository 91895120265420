import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTenantFlags } from '@nestoca/multi-tenant';
import { Typography } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { EditGrid } from 'components/editable/editable-grid';
import { ModalFormActions } from 'components/modals/modal-form-actions';
import { RegisterAddressFields } from 'components/register-address/register-address-fields';
import { useToasts } from 'components/toast';
import { client as apiClient } from 'libs/api';
import { useAccount } from 'providers/auth0';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    getApplicationEmails,
    useRefreshApplicationById,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import {
    getQualificationState,
    useRefreshQualification,
} from 'store/qualification';
import { useRefreshSubmissionNotes } from 'store/submission-notes';
import { RegisteredAddress } from 'types/address';
import { type ApplicantInfo } from 'types/applicant';
import { getOnlyNums } from 'utils';
import { useEditingContextWithCleanup } from 'utils/use-editing-context';
import { useRequiredApplicantFields } from 'utils/use-required-applicant-fields';

import { ApplicantInfoFields } from './applicant-info-fields';
import {
    normalizeApplicantInfo,
    normalizeApplicantInfoWithAddress,
} from './applicant-utils';
import { useApplicantSchema } from './use-applicant-schema';

export type ApplicantModalProps = {
    editableKey: string;
    applicationId: number;
    applicantId: number | undefined;
    prefillInfo?: PrefillInfo;
};

export type PrefillInfo = Pick<
    ApplicantInfo,
    'firstName' | 'lastName' | 'phone' | 'email'
>;

const registerAddress: RegisteredAddress = {
    isCurrentAddress: undefined,
    occupiedYears: 0,
    occupiedMonths: 0,
    situation: 'OWNER',
    address: {
        countryCode: 'CA',
        city: '',
        postalCode: '',
        stateCode: '',
        unit: '',
        streetNumber: '',
        street: '',
    },
    rent: {
        amount: 0,
        frequency: 'MONTHLY',
    },
};

type ExtendedApplicantInfo = ApplicantInfo & { addresses: RegisteredAddress[] };

export const ApplicantModal = ({
    editableKey,
    applicationId,
    applicantId,
    prefillInfo,
}: ApplicantModalProps) => {
    const { i18n } = useI18n();
    const { close: closeModal } =
        useModal<ApplicantModalProps>('createApplicant');
    const { account } = useAccount();
    const { addToast } = useToasts();

    // @ts-expect-error error is expected because the ApplicantInfo type is based on an already created applicant @see https://nestoca.atlassian.net/browse/OG-5183
    const applicant: ApplicantInfo = {
        applicantId,
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        dateOfBirth: '',
        salutation: undefined,
        maritalStatus: undefined,
        relationToMainApplicant: undefined,
        firstTimeHomeBuyer: false, // TBD
        primaryBankingInstitution: undefined,
        primaryBankingInstitutionOther: '',
        creditScoreQuality: undefined,
        propertiesSpecified: false,
        otherIncomesSpecified: false,
        covid19Impacted: false, // TBD
        covid19ImpactDescription: '',
        hasConsumerProposalOrBankruptcyLast5yrs: '', // TBD
        permissions: 'CO_APPLICANT_DEFAULT',
        sin: undefined,
        ...(prefillInfo || {}),
    };

    const isMainApplicant = false;
    const isCreating = true;

    const requiredFields = useRequiredApplicantFields(
        isMainApplicant,
        isCreating
    );

    useRecoilValue(getQualificationState(applicationId));
    const refreshApplication = useRefreshApplicationById(applicationId);
    const { refresh: refreshQualification } = useRefreshQualification();
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);

    const applicationEmails = useRecoilValue(
        getApplicationEmails(applicationId)
    );

    const otherEmails = Object.values(applicationEmails).filter(
        (email) => email !== applicant.email
    );

    const { requireCoApplicantAddress } = useTenantFlags();

    const { editingKey } = useEditingContextWithCleanup(editableKey);

    const isEditing = useMemo(() => editingKey === editableKey, [editingKey]);

    const schema = useApplicantSchema({ isCreating: true });

    const createApplicant = async (
        values: typeof requireCoApplicantAddress extends true
            ? ExtendedApplicantInfo
            : ApplicantInfo
    ) => {
        // we don't want to send the SIN number here since it won't be saved be the backend
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { sin, ...normalizedData } = requireCoApplicantAddress
            ? normalizeApplicantInfoWithAddress({ ...applicant, ...values })
            : normalizeApplicantInfo({ ...applicant, ...values });
        const { data } = await apiClient.createCoapplicant(applicationId, {
            ...normalizedData,
        });

        return data.applicantId;
    };

    const updateApplicantSIN = async (applicantId: number, sin: string) => {
        await apiClient.updateApplicantSIN(applicationId, applicantId, {
            sin: getOnlyNums(sin),
        });
    };

    const resetApplicant = async () => {
        await refreshApplication();
        await refreshApplicationDocumentsCounts();
        await refreshSubmissionNotes();
        await refreshQualification(applicationId);
        closeModal();
    };

    const onSubmit = async (
        values: typeof requireCoApplicantAddress extends true
            ? ExtendedApplicantInfo
            : ApplicantInfo
    ) => {
        let newApplicantId: number;
        try {
            newApplicantId = await createApplicant(values);
        } catch (error) {
            addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                appearance: 'error',
            });
            return;
        }

        if (values.sin) {
            try {
                await updateApplicantSIN(newApplicantId, values.sin);
            } catch (error) {
                await resetApplicant();

                addToast(`Error: ${i18n._({ id: 'failedToSaveSIN' })}`, {
                    appearance: 'error',
                });

                return;
            }
        }

        await resetApplicant();

        addToast(i18n._('successfullySaved'), {
            appearance: 'success',
        });
    };

    return (
        <EditGrid
            id="createApplicantInfo"
            onSubmit={onSubmit}
            resolver={yupResolver(schema)}
            defaultValues={applicant}
            normalizeData={
                requireCoApplicantAddress
                    ? normalizeApplicantInfoWithAddress
                    : normalizeApplicantInfo
            }
            gridGap={10}
            gridTemplateColumns={'repeat(auto-fill, minmax(200px, 1fr))'}
            gridAutoRows="auto"
            justifyContent="flex-start"
            hideToolbar
            context={{
                isCreating,
                requiredFields,
                otherEmails,
                accountEmail: account?.email,
            }}
        >
            <ApplicantInfoFields
                isEditing
                applicationId={applicationId}
                applicant={applicant}
                isMainApplicant={isMainApplicant}
                isCreating
            />
            <br />

            {requireCoApplicantAddress && (
                <>
                    <Typography size={1} weight={6}>
                        {i18n._('address')}
                    </Typography>
                    {/* Keep empty div to keep the grid layout */}
                    <div></div>
                    <RegisterAddressFields
                        index={0}
                        formattedAddress=""
                        registeredAddress={registerAddress}
                        isEditing
                        applicationId={applicationId}
                        applicantId={applicantId}
                        copyMainApplicantAddressOptionAvailable
                    />
                </>
            )}
            {isEditing && <ModalFormActions closeModal={closeModal} />}
        </EditGrid>
    );
};
