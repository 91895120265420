import { Grid } from '@nestoca/ui';
import cn from 'classnames';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { useI18n } from 'providers/i18n/use-i18n';
import { CoApplicantSearchResult } from 'types/co-applicant-search';

import styles from './account-details.module.scss';

export type AccountDetailsProps = {
    className?: string;
    account: CoApplicantSearchResult;
};

export const AccountDetails = ({ className, account }: AccountDetailsProps) => {
    const { i18n } = useI18n();

    const { id, firstName, lastName, dateOfBirth, phone, email, region } =
        account;

    return (
        <Grid className={cn(styles['account-details'], className)} gap={4}>
            <CellData tx="accountId" value={id} />
            <CellData tx="First name" value={firstName} />
            <CellData tx="Last name" value={lastName} />
            <CellData tx="dateOfBirth" fieldType="date" value={dateOfBirth} />
            <CellData tx="Phone" fieldType="phone" value={phone} />
            <CellData tx="email" fieldType="email" value={email} />
            <CellData tx="Province" value={i18n._(`provinces.${region}`)} />
        </Grid>
    );
};
