import React, { useState } from 'react';

import { Flex } from '@nestoca/ui';

import { Address } from 'types/address';
import { MapsStreetView, MapsStreetViewLiteral } from 'types/index';
import { concatAddress } from 'utils';

import { GoogleMapsView } from './google-maps-view';
import { GoogleMapsViewSelector } from './google-maps-view-selector';

export type GoogleMapsProps = {
    subjectPropertyAddress: Address;
    employmentAddress: Address | undefined;
} & React.HTMLAttributes<HTMLDivElement>;

export const GoogleMaps = ({
    subjectPropertyAddress,
    employmentAddress,
    ...rest
}: GoogleMapsProps) => {
    const [view, setView] = useState<MapsStreetViewLiteral>(
        MapsStreetView.Roadmap
    );

    const handleViewSelection = (selectedView: MapsStreetViewLiteral) => {
        setView(selectedView);
    };

    return (
        <Flex direction="column" gap={2} {...rest}>
            <GoogleMapsViewSelector
                view={view}
                updateSelectedView={handleViewSelection}
            />
            <GoogleMapsView
                view={view}
                subjectPropertyAddress={concatAddress(subjectPropertyAddress)}
                employmentAddress={
                    employmentAddress
                        ? concatAddress(employmentAddress)
                        : undefined
                }
            />
        </Flex>
    );
};
