import { useState } from 'react';

import { Box, Flex } from '@nestoca/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { GoogleMapsView } from 'components/google-maps-ui/google-maps-view';
import { GoogleMapsViewSelector } from 'components/google-maps-ui/google-maps-view-selector';
import { getMainApplicant } from 'store/applications';
import { MapsStreetView, MapsStreetViewLiteral } from 'types/index';
import { SubjectProperty } from 'types/property';
import {
    concatAddress,
    formatAddress,
    getEmploymentAddressOptions,
} from 'utils';

type Props = {
    applicationId: number;
    property: SubjectProperty;
};

export const PropertyMap = ({ applicationId, property }: Props) => {
    const [selectedView, setSelectedView] = useState<MapsStreetViewLiteral>(
        MapsStreetView.Roadmap
    );
    const { income } = useRecoilValue(getMainApplicant(applicationId));

    const formattedAddress = formatAddress(property.address);
    const mapAdress = concatAddress(property.address);

    let employmentAddress = concatAddress(
        income?.employments[0]?.employer?.address
    );

    const employmentAddressOptions = getEmploymentAddressOptions(
        income?.employments
    );

    const defaultValues = {
        subjectPropertyAddress: formattedAddress,
        employmentAddress,
    };

    const methods = useForm({ defaultValues });

    employmentAddress = methods.watch('employmentAddress');

    const updateSelectedView = (view: MapsStreetViewLiteral) => {
        setSelectedView(view);
    };

    return (
        <Flex gap={5} wrap="wrap">
            <Box css={{ flex: '1 1 auto' }}>
                <FormProvider {...methods}>
                    <EditableCell
                        name="subjectProperty"
                        label="subjectProperty"
                        value={formattedAddress}
                    />
                    <EditableCell
                        isEditing
                        name="employmentAddress"
                        fieldType="select"
                        label="employmentAddress"
                        value={employmentAddress}
                        options={employmentAddressOptions}
                    />
                </FormProvider>
            </Box>
            <Flex
                direction="column"
                css={{ flex: '1 0 auto', height: '300px' }}
            >
                <GoogleMapsViewSelector
                    view={selectedView}
                    updateSelectedView={updateSelectedView}
                />
                <GoogleMapsView
                    view={selectedView}
                    subjectPropertyAddress={mapAdress}
                    employmentAddress={employmentAddress}
                />
            </Flex>
        </Flex>
    );
};
