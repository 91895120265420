import { Table } from '@nestoca/ui';
import { ColumnDef } from '@tanstack/react-table';

import {
    PlaidMonitoringHit,
    PlaidMonitoringWatchlistCode,
} from 'components/plaid-monitoring-card/types';
import { useI18n } from 'providers/i18n/use-i18n';

import { getPlaidMonitoringWatchlistName } from '../utils';

import styles from './plaid-monitoring-hits-modal.module.scss';

export type PlaidMonitoringTableHitsProps = {
    hits: PlaidMonitoringHit[];
    processingPullHits: boolean;
};

export const PlaidMonitoringTableHits = ({
    hits,
    processingPullHits,
}: PlaidMonitoringTableHitsProps) => {
    const { i18n } = useI18n();

    const columns: ColumnDef<PlaidMonitoringHit>[] = [
        {
            id: 'fullName',
            accessorKey: 'fullName',
            header: i18n._('plaidMonitoring.table.header.fullName'),
            enableColumnFilter: false,
            enableSorting: false,
            meta: {
                className: styles['column-nowrap'],
            },
        },
        {
            id: 'dateOfBirth',
            accessorKey: 'dateOfBirth',
            header: i18n._('plaidMonitoring.table.header.dateOfBirth'),
            enableColumnFilter: false,
            enableSorting: false,
            meta: {
                className: styles['column-nowrap'],
            },
        },
        {
            id: 'watchlistCode',
            accessorKey: 'watchlistCode',
            header: i18n._('plaidMonitoring.table.header.watchlistCode'),
            enableColumnFilter: false,
            enableSorting: false,
            meta: {
                className: styles['column-nowrap'],
            },
        },
        {
            id: 'watchlistName',
            accessorKey: 'watchlistCode',
            header: i18n._('plaidMonitoring.table.header.watchlistName'),
            cell: ({ getValue }) =>
                getPlaidMonitoringWatchlistName(i18n)[
                    getValue<PlaidMonitoringWatchlistCode>()
                ],
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            id: 'countries',
            accessorKey: 'countries',
            header: i18n._('plaidMonitoring.table.header.countries'),
            cell: ({ getValue }) => getValue<string[]>().join(', '),
            enableColumnFilter: false,
            enableSorting: false,
        },
    ];

    return (
        <Table<PlaidMonitoringHit>
            className={styles['table-hits']}
            data={hits}
            columns={columns}
            loading={processingPullHits}
            loadingRowCount={5}
        >
            <Table.Header />
            <Table.Body noResultsFoundLabel={i18n._('table.noResultsFound')} />
            <Table.Footer
                firstButtonLabel={i18n._('table.footer.firstButtonLabel')}
                lastButtonLabel={i18n._('table.footer.lastButtonLabel')}
                pageLabel={i18n._('table.footer.pageLabel')}
                perPageLabel={i18n._('table.footer.perPageLabel')}
            />
        </Table>
    );
};
