import { I18n } from '@lingui/core';

import { ONE_HUNDRED, ZERO } from 'constants/appConstants';
import { Problems, PROBLEMS_SECTION_TITLES } from 'constants/problem';

export const getMissingTotal = (sections: { totalProblems: number }[]) =>
    sections && !!sections.length
        ? sections
              .map((section) => section.totalProblems)
              .reduce((acc, curr) => acc + curr)
        : ZERO;

export const getCompletedSections = (sections: any[], i18n: I18n) => {
    const missingSections = sections.map((section) => section.section);
    const allSections = Object.keys(PROBLEMS_SECTION_TITLES(i18n));

    return allSections
        .filter((section) => !missingSections.includes(section))
        .map((section) => {
            return { section: section, totalProblems: ZERO };
        }) as Problems[];
};

export const getProgress = (sections: Problems[], i18n: I18n) => {
    const allSections = Object.keys(PROBLEMS_SECTION_TITLES(i18n));
    const completedSections = getCompletedSections(sections, i18n);
    return Math.floor(
        (sections.length + completedSections.length * ONE_HUNDRED) /
            allSections.length
    );
};
