import React from 'react';

import { useRecoilValue } from 'recoil';

import { LabelWithValue } from 'components/dashboard-header/components/label-with-value';
import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';
import { MARITAL_STATUS } from 'constants/labels';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    getSumCurrentIncome,
    getSumApplicantOtherIncome,
} from 'store/applications';
import { formatMoney } from 'utils';

export const ApplicantInfo = ({
    applicant,
    currentApplicationId,
}: {
    applicant: any;
    currentApplicationId: number;
}) => {
    const otherIncomesSum = useRecoilValue(
        getSumApplicantOtherIncome({
            applicationId: currentApplicationId,
            applicantId: applicant.applicantId,
        })
    );
    const currentIncome = useRecoilValue(
        getSumCurrentIncome({
            applicationId: currentApplicationId,
            applicantId: applicant.applicantId,
        })
    );
    const { i18n } = useI18n();

    const { open: openDashboardEmploymentModal } = useModal(
        'dashboardEmploymentDetails'
    );

    return (
        <Grid
            gridAutoRows="auto"
            gridTemplateColumns={'repeat(2, 1fr)'}
            gridTemplateAreas={`
            "applicantDetails income"
            "maritalStatus incomeType"
            "divider divider"
            `}
        >
            <LabelWithValue
                gridArea="applicantDetails"
                label={'applicant'}
                value={`${applicant.firstName} ${applicant.lastName}`}
                showBorderRight
            />

            <LabelWithValue
                gridArea="income"
                label={'dashboardHeader.totalIncome'}
                value={formatMoney(currentIncome)}
                onClick={() => {
                    openDashboardEmploymentModal();
                }}
                preset="secondary"
            />

            <LabelWithValue
                gridArea="maritalStatus"
                label={'Marital Status'}
                value={MARITAL_STATUS(i18n)[applicant.maritalStatus]}
                showBorderRight
            />

            <LabelWithValue
                gridArea="incomeType"
                label={'dashboardHeader.otherIncome'}
                value={formatMoney(otherIncomesSum)}
                onClick={() => {
                    openDashboardEmploymentModal();
                }}
                preset="secondary"
            />
            <Grid gridArea={`divider`}>
                <Divider css={{ margin: 0 }} />
            </Grid>
        </Grid>
    );
};
