import { Flex, Skeleton } from '@nestoca/ui';

import styles from './account-skeleton.module.scss';

export const AccountSkeleton = () => {
    return (
        <Flex
            className={styles['account-skeleton']}
            align="center"
            justify="between"
            gap={3}
        >
            <Flex
                className={styles['left-container']}
                align="center"
                gap={3}
                grow={1}
            >
                <Skeleton
                    width={20}
                    height={20}
                    style={{ borderRadius: '50%' }}
                />
                <Skeleton width="100%" height={20} />
            </Flex>
            <Skeleton width={20} height={20} />
        </Flex>
    );
};
