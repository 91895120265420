import React from 'react';

import { Button, Flex } from '@nestoca/ui';
import { FlagFill } from 'grommet-icons';
import { useRecoilValue } from 'recoil';

import { GdsTds } from 'components/scenarios/components/scenario-gds-tds';
import { Text } from 'components/text/text';
import { getApplicantsList } from 'store/applications';
import { getFlags } from 'store/flags';

import styles from './underwriter-dashboard.module.scss';

type Props = {
    applicationId: number;
    isFlagsPanelVisible: boolean;
    toggleFlagsPanel: () => void;
};

export const Header = ({
    applicationId,
    isFlagsPanelVisible,
    toggleFlagsPanel,
}: Props) => {
    const { flags } = useRecoilValue(getFlags({ applicationId }));
    const applicants = useRecoilValue(getApplicantsList(applicationId));

    const handleFlagsBtnClick = () => {
        toggleFlagsPanel();
    };

    const joinApplicantsFullName = () => {
        return applicants
            .map((applicant) => `${applicant.firstName} ${applicant.lastName}`)
            .join(', ');
    };

    return (
        <Flex
            className={styles.header}
            align="center"
            justify="between"
            wrap="wrap"
            gap={4}
        >
            <Flex direction="column" gap={0}>
                <Text
                    fontSize="var(--font-size-00)"
                    fontWeight="var(--font-weight-5)"
                    color="var(--color-gray-500)"
                    tx="uwDashboard.title"
                />
                <Text
                    as="h1"
                    fontSize="var(--font-size-3)"
                    fontWeight="var(--font-weight-7)"
                    color="var(--color-black)"
                >
                    {joinApplicantsFullName()}
                </Text>
            </Flex>
            <Flex
                className={styles.right_container}
                align="center"
                gap={7}
                shrink={0}
            >
                <Button
                    className={styles.flag_btn}
                    aria-label="flags-btn"
                    variant="secondary"
                    size="medium"
                    isActive={isFlagsPanelVisible}
                    onClick={handleFlagsBtnClick}
                    leftIcon={<FlagFill color="var(--color-status-error)" />}
                >
                    {flags.length}
                </Button>
                <GdsTds gridArea="gdsTds" applicationId={applicationId} />
            </Flex>
        </Flex>
    );
};
