export const PLAID_MONITORING_WATCHLIST_CODE = {
    AU_CON: 'AU_CON',
    CA_CON: 'CA_CON',
    EU_CON: 'EU_CON',
    IZ_CIA: 'IZ_CIA',
    IZ_IPL: 'IZ_IPL',
    IZ_PEP: 'IZ_PEP',
    IZ_UNC: 'IZ_UNC',
    IZ_WBK: 'IZ_WBK',
    UK_HMC: 'UK_HMC',
    US_DPL: 'US_DPL',
    US_DTC: 'US_DTC',
    US_FBI: 'US_FBI',
    US_FSE: 'US_FSE',
    US_ISN: 'US_ISN',
    US_PLC: 'US_PLC',
    US_SDN: 'US_SDN',
    US_SSI: 'US_SSI',
    SG_SOF: 'SG_SOF',
    TR_TWL: 'TR_TWL',
    TR_DFD: 'TR_DFD',
    TR_FOR: 'TR_FOR',
    TR_WMD: 'TR_WMD',
    TR_CMB: 'TR_CMB',
} as const;

export type PlaidMonitoringWatchlistCode =
    (typeof PLAID_MONITORING_WATCHLIST_CODE)[keyof typeof PLAID_MONITORING_WATCHLIST_CODE];

export type PlaidMonitoringScreeningStatus =
    | 'CLEARED'
    | 'PENDING_REVIEW'
    | 'REJECTED';

export type PlaidMonitoringScreening = {
    id: string;
    pullDate: string;
    status: PlaidMonitoringScreeningStatus;
};

export type PlaidMonitoringHit = {
    fullName: string;
    dateOfBirth: string;
    watchlistCode: PlaidMonitoringWatchlistCode;
    countries: string[];
};

export type PlaidMonitoringScreeningInfo = {
    id: string;
    hits: PlaidMonitoringHit[];
};

export type TabChange = (tabIndex: number) => void;

export type PlaidMonitoringErrorStatus = 400 | 429 | 500 | 503;

export type PlaidMonitoringErrorData = {
    error: string;
    description?: string;
};
