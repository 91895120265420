import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import {
    BORROWER_OCCUPATION,
    INCOME_TYPE,
    OCCUPATIONS_LABELS,
} from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { IncomeEmployment } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    employment: IncomeEmployment & { totalIncome?: number };
};

export const SelfEmployedIndustryDetails = ({
    isCreating,
    isEditing,
    employment,
}: Props) => {
    const { i18n } = useI18n();

    const { incomeType, isCurrent } = employment;

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('selfEmployedDetails')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                {!isCreating && (
                    <>
                        <EditableCell
                            required
                            isEditing={isEditing}
                            name="incomeType"
                            label="Income Type"
                            fieldType="readonly"
                            options={INCOME_TYPE(i18n)}
                            value={incomeType}
                            isFieldInvalidCompareFn={compareForId(employment)}
                        />
                        <EditableCell
                            required
                            isEditing={isEditing}
                            name="occupation"
                            label="occupation"
                            fieldType="select"
                            value={
                                OCCUPATIONS_LABELS(i18n)[employment.occupation]
                            }
                            options={BORROWER_OCCUPATION(i18n)}
                            isFieldInvalidCompareFn={compareForId(employment)}
                        />
                    </>
                )}
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="isCurrent"
                    label="currentEmployer"
                    fieldType="boolean"
                    value={!!isCurrent}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
            </Grid>
        </Flex>
    );
};
