import React, { useMemo } from 'react';

import { Trans } from '@lingui/react';
import { useTenantSettings } from '@nestoca/multi-tenant';
import css from '@styled-system/css';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { Box, Flex } from 'reflexbox/styled-components';

import { Card } from 'components/card/new-card';
import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { Emoji } from 'components/emoji';
import { Grid } from 'components/grid/grid';
import { CreditScoreRow } from 'components/scenarios/components/credit-score';
import { GdsTds } from 'components/scenarios/components/scenario-gds-tds';
import { SectionTitle } from 'components/scenarios/components/section-title';
import { INVESTOR_MAP } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getFinancingAmount,
    getMortgagesBalance,
    getSumAssets,
    selectedApplication,
    getApplicantsList,
    getApplicationMortgage,
    useTransactionTypeOptions,
} from 'store/applications';
import { getApplicationMainType } from 'utils/application-type';

import type { SelectOption } from 'types';

export const UnderwriterClientSnapshot = () => {
    const { i18n } = useI18n();
    const {
        tenantInvestorOptions: { value: investorIds },
    } = useTenantSettings();
    const application = useRecoilValue(selectedApplication);
    const sumAssets = useRecoilValue(getSumAssets(application?.id));
    const mortgageBalance = useRecoilValue(getMortgagesBalance(application.id));
    const financingAmount = useRecoilValue(getFinancingAmount(application.id));
    const applicants = useRecoilValue(getApplicantsList(application.id));
    const mortgageDetails = useRecoilValue(
        getApplicationMortgage(application.id)
    );
    const transactionTypeOptions = useTransactionTypeOptions();

    const { isNewApplication, isRefinanceApplication } = getApplicationMainType(
        application.type
    );

    const investors = Array.isArray(investorIds) ? investorIds : [investorIds];

    const investorOptions = investors.map((investorId) => {
        const investor = INVESTOR_MAP[investorId];

        return { value: investor.id, label: i18n._(investor.labelKey) };
    });

    const investor = useMemo(
        () =>
            investorOptions.find(
                (option: SelectOption) =>
                    option.value === mortgageDetails?.investor
            )?.label,
        [mortgageDetails]
    );

    const mortgageAmount = useMemo(() => {
        // NEW
        // mortgageAmount = propertyValue - downPayment
        if (isNewApplication) {
            return {
                label: 'mortgageAmount',
                amount:
                    application.property.purchasePrice -
                    sumAssets.amountUsedForDownPayment,
            };
        }

        // RENEW
        // Add mortgageBalance (taken from mortgage info)
        // REFI
        // Add mortgageBalance (taken from mortgage info)
        return {
            label: 'mortgageBalance',
            amount: mortgageBalance,
        };
    }, [application]);

    return (
        <Box
            css={css({
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: -50,
            })}
        >
            <Card
                variant="summary"
                css={css({
                    flexDirection: 'column',
                    width: '100%',
                    boxShadow: ['md', '2xl'],
                    marginTop: 3,
                    marginBottom: 3,
                    height: '100%',
                })}
            >
                <Grid
                    gridTemplateColumns="1.5fr 1fr"
                    gridAutoFlow="row"
                    gridGap={10}
                    gridTemplateAreas={`
                    "clientDetails creditScore"
                `}
                >
                    <Grid
                        gridArea="clientDetails"
                        gridTemplateColumns="1fr"
                        gridTemplateAreas={`
                            "title"
                            "clientInfo"
                            "gdsTds"
                        `}
                    >
                        <Grid gridArea="title">
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <h2>
                                    <Emoji
                                        symbol="📸"
                                        label="property"
                                        css={{ marginRight: 10 }}
                                    />
                                    <Trans id="clientSnapshot" />
                                </h2>
                                {mortgageDetails?.daysToClose && (
                                    <Link
                                        href={`/applications/${application?.id}`}
                                        style={{
                                            borderRadius: '10px',
                                            backgroundColor: '#F9423A',
                                            color: '#fff',
                                            border: 'none',
                                            width: '160px',
                                            height: '40px',
                                            fontSize: 14,
                                            fontWeight: 600,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            marginBottom: 10,
                                            textDecoration: 'none',
                                        }}
                                    >
                                        {i18n._({
                                            id: 'closingInDays',
                                            values: {
                                                days: mortgageDetails?.daysToClose,
                                            },
                                        })}
                                    </Link>
                                )}
                            </Flex>
                        </Grid>

                        <Grid
                            gridArea="clientInfo"
                            gridTemplateColumns="1fr"
                            gridAutoFlow="row"
                            css={{ marginBottom: '20px' }}
                        >
                            <Grid
                                gridTemplateColumns="repeat(3, 1fr)"
                                gridAutoFlow="row"
                                gridGap={10}
                                css={css({
                                    borderTop: '1px solid',
                                    borderColor: 'gray-4',
                                    paddingY: 2,
                                })}
                            >
                                <CellData
                                    fieldType="select"
                                    tx={'transactionType'}
                                    value={application.type}
                                    options={transactionTypeOptions}
                                />
                                {isNewApplication ? (
                                    <CellData
                                        fieldType="money"
                                        tx={'purchasePrice'}
                                        value={
                                            application.property.purchasePrice
                                        }
                                    />
                                ) : (
                                    <CellData
                                        fieldType="money"
                                        tx={'estimatedPropertyValueShort'}
                                        value={
                                            application.property
                                                .estimatedPropertyValue
                                        }
                                    />
                                )}

                                <CellData
                                    fieldType="money"
                                    tx={'totalAssets'}
                                    value={sumAssets.value}
                                    variant="primary"
                                />
                            </Grid>
                            <Grid
                                gridTemplateColumns="repeat(3, 1fr)"
                                gridAutoFlow="row"
                                gridGap={10}
                                css={css({
                                    borderTop: '1px solid',
                                    borderBottom: '1px solid',
                                    borderColor: 'gray-4',
                                    paddingY: 2,
                                })}
                            >
                                <CellData
                                    fieldType="money"
                                    tx={'totalDownpayment'}
                                    value={sumAssets.amountUsedForDownPayment}
                                />
                                <CellData
                                    fieldType="money"
                                    tx={'closingCost'}
                                    value={
                                        application.property.purchasePrice *
                                        0.015
                                    }
                                />

                                <CellData
                                    fieldType="money"
                                    tx={mortgageAmount.label}
                                    value={mortgageAmount.amount}
                                    variant="primary"
                                />
                            </Grid>
                            {isRefinanceApplication && (
                                <Grid
                                    gridTemplateColumns="repeat(3, 1fr)"
                                    gridAutoFlow="row"
                                    gridGap={10}
                                    css={css({
                                        borderBottom: '1px solid',
                                        borderColor: 'gray-4',
                                        paddingY: 2,
                                    })}
                                >
                                    <CellData
                                        fieldType="money"
                                        tx={'additionalAmount'}
                                        value={
                                            application.property
                                                .additionalFundAmount
                                        }
                                    />
                                    <CellData
                                        fieldType="money"
                                        tx={'financingAmount'}
                                        value={financingAmount}
                                        variant="primary"
                                    />
                                </Grid>
                            )}

                            <Grid
                                gridTemplateColumns="repeat(3, 1fr)"
                                gridAutoFlow="row"
                                gridGap={10}
                                css={css({
                                    borderBottom: '1px solid',
                                    borderColor: 'gray-4',
                                    paddingY: 2,
                                })}
                            >
                                <CellData
                                    fieldType="date"
                                    tx={'rateExpirationDate'}
                                    value={mortgageDetails?.rateExpirationDate}
                                />

                                <CellData
                                    fieldType="text"
                                    tx={'investor'}
                                    value={investor}
                                />
                            </Grid>
                        </Grid>
                        <GdsTds
                            gridArea="gdsTds"
                            applicationId={application.id}
                        />
                    </Grid>

                    <Grid
                        gridArea="creditScore"
                        gridAutoFlow="column"
                        css={css({
                            backgroundColor: 'gray-4',
                            borderRadius: '0px 0px 10px 0px',
                            margin: '-14px -16px -16px 0',
                            padding: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        })}
                        gridTemplateAreas={`
                            "sectionTitle"
                            "applicantsInfo"
                            ${(applicants || [])
                                .map(
                                    (applicant) =>
                                        `" applicant-${applicant.applicantId}"`
                                )
                                .join('')}
                            "copyText"
                       `}
                    >
                        <SectionTitle
                            label="creditScoresLabel"
                            gridArea="sectionTitle"
                            gridTemplateColumns="1fr"
                            gridTemplateAreas="title"
                        />
                        <Grid
                            css={{
                                gridArea: 'applicantsInfo',
                                gridTemplateColumns: 'repeat(2, auto)',
                                gridAutoFlow: 'row',
                                marginTop: 10,
                                width: '100%',
                            }}
                        >
                            {(applicants || []).map((applicant) => (
                                <CreditScoreRow
                                    key={`creditRow-${applicant.applicantId}`}
                                    applicationId={application.id}
                                    applicant={applicant}
                                    dashboardView
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};
