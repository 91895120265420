import subYears from 'date-fns/subYears';
import * as yup from 'yup';

import type { I18n } from '@lingui/core';

export const getApplicantInfoEmailSchema = (i18n: I18n) =>
    yup.string().when(
        ['$otherEmails', '$isMainApplicant', '$accountEmail'],
        // @ts-ignore
        (
            otherEmails: string[],
            isMainApplicant: boolean,
            accountEmail: string,
            schema
        ) => {
            return schema
                .test({
                    name: 'account email used',
                    message: i18n._({ id: 'error.invalidAccountEmail' }),
                    test: (email: string) => email !== accountEmail,
                })
                .test({
                    name: 'duplicate email',
                    message: i18n._({
                        id: 'error.duplicateEmail',
                    }),
                    test: (email: string) =>
                        !(otherEmails.includes(email) && !isMainApplicant),
                })
                .requiredInArray('email', i18n);
        }
    );

export const getApplicantInfoPartialSchema = (i18n: I18n) =>
    yup.object().shape({
        applicantId: yup
            .number()
            .isValueNaN()
            .notRequired()
            .nullable()
            .default(0),
        salutation: yup.string().optional().emptyAsUndefined(),
        maritalStatus: yup.string().optional().emptyAsUndefined(),
        dateOfBirth: getDateOfBirthSchema(i18n),
        firstName: yup.string().requiredInArray('firstName', i18n),
        lastName: yup.string().requiredInArray('lastName', i18n),
        phone: yup
            .string()
            .requiredInArray('phone', i18n)
            .phone(i18n._({ id: 'error.invalidPhone' })),
        email: getApplicantInfoEmailSchema(i18n),
        creditScoreQuality: yup
            .string()
            .requiredInArray('creditScoreQuality', i18n)
            .emptyAsUndefined(),
        primaryBankingInstitution: yup.string().optional().emptyAsUndefined(),
        primaryBankingInstitutionOther: yup
            .string()
            .optional()
            .emptyAsUndefined(),
        covid19Impacted: yup
            .boolean()
            .emptyAsUndefined()
            .optional()
            .default(false),
        firstTimeHomeBuyer: yup.boolean().emptyAsUndefined().optional(),
        covid19ImpactDescription: yup.string().optional().emptyAsUndefined(),
        hasConsumerProposalOrBankruptcyLast5yrs: yup
            .string()
            .optional()
            .emptyAsUndefined(),
        relationToMainApplicant: yup
            .string()
            .optional()
            .emptyAsUndefined()
            .default(''),
        numberOfDependents: yup
            .number()
            .isValueNaN()
            .optional()
            .nullable()
            .default(0),
        newToCanada: yup.boolean().emptyAsUndefined().optional().default(false),
        residencyStatus: yup
            .string()
            .requiredInArray('residencyStatus', i18n)
            .nullAsEmpty(),
        isNestoEmployee: yup
            .boolean()
            .emptyAsUndefined()
            .optional()
            .default(false),
        isDigital: yup.boolean().emptyAsUndefined().optional(),
        divorcedOrSeparated: yup
            .boolean()
            .emptyAsUndefined()
            .optional()
            .default(null)
            .nullable(),
        hasOwnerOccupancy: yup
            .boolean()
            .requiredInArray('hasOwnerOccupancy', i18n)
            .emptyAsUndefined()
            .default(null)
            .nullable(),
        guarantor: yup // Only Required for co-applicants
            .boolean()
            .emptyAsUndefined()
            .requiredInArray('guarantor', i18n),
        mpiInsurerResponse: yup.string().nullable().optional(),
        verbalConsent: yup.boolean().optional().emptyAsUndefined(),
    });

export const getDateOfBirthSchema = (i18n: I18n) =>
    yup
        .date()
        .nullable()
        .transform((v) => (!isNaN(v) && v instanceof Date ? v : null))
        .requiredInArray('dob', i18n)
        .max(
            subYears(Date.now(), 18),
            i18n._({ id: 'error.dateMin18Required' })
        );

export const getApplicantSINSchema = (i18n: I18n) =>
    yup.object().shape({
        sin: yup
            .string()
            .optional()
            .trim()
            .canadianSin(i18n._({ id: 'error.isCanadianSIN' })),
    });
