import css from '@styled-system/css';
import { useRecoilValue } from 'recoil';

import { Grid } from 'components/grid/grid';
import { ProgressLabel } from 'components/scenarios/components/progress-label';
import { useModal } from 'providers/modals/use-modal';
import {
    getHasCalculationExecutionPlan,
    getQualificationState,
} from 'store/qualification';

import {
    useGdsBorderColor,
    useTdsBorderColor,
    useLTVBorderColor,
    useHelocLTVBorderColor,
} from '../utils';

import type {
    ScenarioModalProps,
    ScenarioModalSection,
} from 'components/scenarios/scenario-modal';

type Props = {
    applicationId: number;
    gridArea?: string;
};

export const GdsTds = ({ applicationId, gridArea = 'gdsTdsInfo' }: Props) => {
    const { open: openScenarioModal } =
        useModal<ScenarioModalProps>('scenariosDetails');

    const qualification = useRecoilValue(getQualificationState(applicationId));
    const hasCalculationExecutionPlan = useRecoilValue(
        getHasCalculationExecutionPlan(applicationId)
    );

    const gdsBorderColor = useGdsBorderColor(applicationId);
    const tdsBorderColor = useTdsBorderColor(applicationId);
    const ltvBorderColor = useLTVBorderColor(applicationId);
    const helocLTVBorderColor = useHelocLTVBorderColor(applicationId);

    const onClick = (section: ScenarioModalSection) => () =>
        openScenarioModal({
            applicationId,
            section: section,
        });

    return (
        <Grid
            gridArea={gridArea}
            gridTemplateColumns={
                qualification?.ltvEx?.heloclLtv
                    ? 'repeat(4, 1fr)'
                    : 'repeat(3, 1fr)'
            }
            gridAutoFlow="row"
            gridColumnGap={24}
            gridTemplateAreas={`
                "gds tds ltv ${qualification?.ltvEx?.heloclLtv && 'heloc-ltv'}"
                "action action action ${
                    qualification?.ltvEx?.heloclLtv && 'action'
                }"
            `}
            css={css({
                marginTop: '10px',
                padding: '15px',
            })}
        >
            <ProgressLabel
                id="ratio-gds"
                gridArea="gds"
                fontSize="14px"
                fontWeight={400}
                label="gdsLabel"
                text={`${qualification?.gds}%`}
                value={qualification?.gds}
                borderColor={gdsBorderColor}
                onClick={
                    hasCalculationExecutionPlan ? onClick('GDS') : undefined
                }
            />
            <ProgressLabel
                id="ratio-tds"
                gridArea="tds"
                fontSize="14px"
                fontWeight={400}
                label="tdsLabel"
                text={`${qualification?.tds}%`}
                value={qualification?.tds}
                borderColor={tdsBorderColor}
                onClick={
                    hasCalculationExecutionPlan ? onClick('TDS') : undefined
                }
            />
            <ProgressLabel
                id="ratio-ltv"
                gridArea="ltv"
                fontSize="14px"
                fontWeight={400}
                label="ltv"
                text={`${qualification?.ltv}%`}
                value={qualification?.ltv}
                borderColor={ltvBorderColor}
                onClick={
                    hasCalculationExecutionPlan ? onClick('LTV') : undefined
                }
            />
            {!!qualification.helocAmount && (
                <ProgressLabel
                    id="heloc-ltv"
                    gridArea="heloc-ltv"
                    fontSize="14px"
                    fontWeight={400}
                    label="heloc.ltv"
                    text={`${qualification?.ltvEx?.heloclLtv}%`}
                    value={qualification?.ltvEx?.heloclLtv}
                    borderColor={helocLTVBorderColor}
                />
            )}
        </Grid>
    );
};
