import React from 'react';

import css from '@styled-system/css';
import { Close, LinkPrevious } from 'grommet-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import { Card } from 'components/card/new-card';
import { Flags } from 'components/dashboard/broker-dashboard/flags';
import { GeneralNotes } from 'components/dashboard/broker-dashboard/general-notes';
import { Emoji } from 'components/emoji';
import { ScrollArea } from 'components/scroll-area';
import { getSubjectProperty } from 'store/applications';
import { isContextualSidebarOpenState } from 'store/ui';

const SidebarDashboardView = ({ applicationId }: { applicationId: number }) => {
    const subjectProperty = useRecoilValue(getSubjectProperty(applicationId));
    const [isContextualSidebarOpen, setIsContextualSidebarOpen] =
        useRecoilState(isContextualSidebarOpenState);

    return isContextualSidebarOpen ? (
        <Card
            css={css({
                flexDirection: 'column',
                boxShadow: ['md', '2xl'],
                width: '400px',
                position: 'fixed',
                zIndex: 'var(--z-sidebar)',
                right: 0,
                top: 0,
                bottom: 0,
                margin: 0,
                borderRadius: 0,
            })}
        >
            <Close
                onClick={() => setIsContextualSidebarOpen(false)}
                css={{ cursor: 'pointer' }}
            />

            <ScrollArea>
                {(subjectProperty?.address?.stateCode ||
                    subjectProperty?.address?.postalCode) && (
                    <Flags applicationId={applicationId} />
                )}

                <GeneralNotes applicationId={applicationId} />
            </ScrollArea>
        </Card>
    ) : (
        <Card
            css={css({
                flexDirection: 'column',
                boxShadow: ['md', '2xl'],
                width: '60px',
                position: 'fixed',
                zIndex: 'var(--z-sidebar)',
                right: 0,
                top: 0,
                bottom: 0,
                margin: 0,
                borderRadius: 0,
            })}
        >
            <LinkPrevious
                onClick={() => setIsContextualSidebarOpen(true)}
                css={{ cursor: 'pointer' }}
            />
            <Box mt={3}>
                <Emoji symbol="🚩" label="property" css={{ marginLeft: 2 }} />
            </Box>
            <Box mt={2}>
                <Emoji
                    symbol="📝"
                    label="generalNotes"
                    css={css({ marginTop: 5 })}
                />
            </Box>
        </Card>
    );
};

export const SidebarDashboard = ({
    applicationId,
}: {
    applicationId: number;
}) => {
    return (
        <React.Suspense fallback={null}>
            <SidebarDashboardView applicationId={applicationId} />
        </React.Suspense>
    );
};
