import * as inflection from 'inflection';

import { ASSET_TYPE, INSTITUTION } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { categoriesKeyStateMap } from 'store/applications';

import type { I18n } from '@lingui/core';
import type { AssetFinancial, AssetTypes } from 'types/asset';
import type { ApplicantDocument } from 'types/documents';

export const useGetDocumentName = ({
    document,
    documentEntity,
}: {
    document: ApplicantDocument;
    documentEntity: any;
}): string => {
    const { i18n } = useI18n();

    const documentEntityType =
        categoriesKeyStateMap?.[document.category]?.[document.subCategory]
            ?.documentEntityType ||
        categoriesKeyStateMap?.[document.category]?.['undefined']
            ?.documentEntityType;

    const transId = `documents.documentType.${inflection.camelize(
        (document.documentType || '').toLowerCase(),
        true
    )}`;

    if (documentEntityType === 'asset') {
        const assetType =
            documentEntity && getAssetText(i18n, documentEntity.type);
        const institutionName =
            documentEntity &&
            getInstitutionLabel(i18n, documentEntity as AssetFinancial);

        const assetDescription =
            (assetType || institutionName) &&
            `(${assetType || ''} ${
                institutionName && ` - ${institutionName}`
            })`;

        return `${i18n._(transId)} ${document.year || ''} ${assetDescription}`;
    }

    return `${i18n._(transId)} ${document.year ? document.year : ''}`;
};

const getInstitutionLabel = (i18n: I18n, documentEntity: AssetFinancial) => {
    const found = INSTITUTION(i18n)?.[documentEntity.institution];

    if (documentEntity.institution !== 'OTHER' && found) {
        return found;
    } else if (found) {
        return documentEntity.institutionOther;
    }

    return '';
};

/**
 * Get the asset type translated name
 * @param {I18n} i18n
 * @param {AssetType} code Asset type
 * @returns {string} Asset type or empty string
 */
const getAssetText = (i18n: I18n, code: AssetTypes) => {
    const found = ASSET_TYPE(i18n)[code];

    return found || '';
};
