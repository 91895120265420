import { addYears, isBefore } from 'date-fns';
import * as yup from 'yup';

import {
    getSubjectPropertyAmortizationMonthsSchema,
    getSubjectPropertyAmortizationYearsSchema,
} from './amortization';
import { getAmountFrequencyRequiredSchema } from './amount-frequency';
import { numberRequired } from './number';
import { stringRequired } from './string';

import type { I18n } from '@lingui/core';

export const getMaxAmortization = ({
    insuranceQuestionOriginallyInsured,
    currentAmortizationMonths,
}: {
    insuranceQuestionOriginallyInsured: string;
    currentAmortizationMonths: number;
}) => {
    let maxAmortization = 30;
    let maxAmortizationMessage = 'error.maxAmortizationMessageUninsured';
    if (insuranceQuestionOriginallyInsured === 'YES') {
        maxAmortization = 25;
        maxAmortizationMessage = 'error.maxAmortizationMessageInsured';
    }

    if (currentAmortizationMonths > 0) {
        maxAmortization -= 1;
    }

    return { maxAmortization, maxAmortizationMessage };
};

export const mortgagePaymentsFrequencyCompareSchema = (i18n: I18n) =>
    yup.object().shape({
        mortgageAmount: numberRequired(i18n).emptyAsUndefined(),
        rate: numberRequired(i18n).emptyAsUndefined(),
        compoundPeriod: stringRequired(i18n).emptyAsUndefined(),
        amortization: numberRequired(i18n).emptyAsUndefined(),
    });

export const mortgageRateCompareSchema = (i18n: I18n) =>
    yup.object({
        mortgageAmountA: yup
            .number()
            .required(i18n._(`mortgageChart.errors.mortgageAmount`)),
        mortgageAmountB: yup
            .number()
            .required(i18n._(`mortgageChart.errors.mortgageAmount`)),
        interestRateTypeA: yup.string().required(),
        interestRateTypeB: yup.string().required(),
        rateA: yup.number().required(i18n._(`mortgageChart.errors.rate`)),
        rateB: yup.number().required(i18n._(`mortgageChart.errors.rate`)),
        termA: yup
            .string()
            .required(i18n._(`mortgageChart.errors.terms.required`)),
        termB: yup
            .string()
            .oneOf([yup.ref('termA'), null])
            .required(i18n._(`mortgageChart.errors.terms.match`)),
        amortizationYearsA: numberRequired(i18n)
            .min(1, i18n._({ id: 'error.fieldRequired' }))
            .max(30, i18n._({ id: 'errors.maxAmortizationYears' }))
            .emptyAsUndefined()
            .default(0),
        amortizationMonthsA: numberRequired(i18n)
            .max(11, i18n._({ id: 'errors.maxAmortizationMonths' }))
            .emptyAsUndefined()
            .default(0)
            .when('amortizationYears', {
                is: (amortizationYears) => amortizationYears === 30,
                then: (amortizationMonths) =>
                    amortizationMonths.max(
                        0,
                        i18n._({ id: 'errors.maxAmortizationPeriod' })
                    ),
            }),
        amortizationYearsB: numberRequired(i18n)
            .min(1, i18n._({ id: 'error.fieldRequired' }))
            .max(30, i18n._({ id: 'errors.maxAmortizationYears' }))
            .emptyAsUndefined()
            .default(0),
        amortizationMonthsB: numberRequired(i18n)
            .max(11, i18n._({ id: 'errors.maxAmortizationMonths' }))
            .emptyAsUndefined()
            .default(0)
            .when('amortizationYears', {
                is: (amortizationYears) => amortizationYears === 30,
                then: (amortizationMonths) =>
                    amortizationMonths.max(
                        0,
                        i18n._({ id: 'errors.maxAmortizationPeriod' })
                    ),
            }),
    });

export const mortgagesSchemaRenewal = (i18n: I18n) =>
    yup.array().of(
        yup.object().shape({
            payment: getAmountFrequencyRequiredSchema(i18n),
            balance: numberRequired(i18n).isValueNaN().nullable(),
            lender: stringRequired(i18n).nullable(),
            interestRate: numberRequired(i18n)
                .min(0.1, i18n._({ id: 'error.fieldRequired' }))
                .emptyAsUndefined(),
            interestRateType: stringRequired(i18n),
            mortgageBalanceDate: yup.string().optional().emptyAsUndefined(),
            remainingTermDays: yup.number().optional().nullable(),
            termType: stringRequired(i18n).nullable(),
            mortgageType: stringRequired(i18n),
            insuranceQuestionOriginallyInsured:
                stringRequired(i18n).emptyAsUndefined(),
            insuranceQuestionRefinanceOrRenewal:
                stringRequired(i18n).emptyAsUndefined(),
            insuranceQuestionAddFundsOrIncreasedMortgageAmount: yup
                .string()
                .when('insuranceQuestionRefinanceOrRenewal', {
                    is: (insuranceQuestionRefinanceOrRenewal) =>
                        insuranceQuestionRefinanceOrRenewal === 'YES',
                    then: stringRequired(i18n),
                    otherwise: yup.string().nullable().emptyAsUndefined(),
                }),
            insuranceQuestionIncreasedAmortization: yup
                .string()
                .when('insuranceQuestionRefinanceOrRenewal', {
                    is: (insuranceQuestionRefinanceOrRenewal) =>
                        insuranceQuestionRefinanceOrRenewal === 'YES',
                    then: stringRequired(i18n),
                    otherwise: yup.string().nullable().emptyAsUndefined(),
                }),
            currentAmortizationYears: getSubjectPropertyAmortizationYearsSchema(
                i18n
            ).required(i18n._('error.fieldRequired')),
            currentAmortizationMonths:
                getSubjectPropertyAmortizationMonthsSchema(i18n).required(
                    i18n._('error.fieldRequired')
                ),
            maturityDate: yup
                .date()
                .fixDateFormat()
                .fixBackendIssue0001()
                .required(i18n._({ id: 'error.fieldRequired' }))
                .nullable()
                .emptyAsUndefined()
                .max(
                    addYears(new Date(), 10),
                    i18n._({ id: 'error.maxMaturityDate' })
                )
                .when(
                    [
                        'lastClosingDate',
                        'insuranceQuestionIncreasedAmortization',
                        'insuranceQuestionAddFundsOrIncreasedMortgageAmount',
                    ],
                    // @ts-ignore
                    (
                        lastClosingDate,
                        insuranceQuestionIncreasedAmortization,
                        insuranceQuestionAddFundsOrIncreasedMortgageAmount,
                        schema
                    ) => {
                        if (
                            lastClosingDate &&
                            (insuranceQuestionIncreasedAmortization === 'YES' ||
                                insuranceQuestionAddFundsOrIncreasedMortgageAmount ===
                                    'YES') &&
                            !isNaN(Date.parse(lastClosingDate))
                        ) {
                            return schema.min(
                                isBefore(lastClosingDate, new Date())
                                    ? new Date()
                                    : lastClosingDate,
                                i18n._({
                                    id: 'error.dateShouldBeFuture',
                                })
                            );
                        }
                    }
                ),
            lastClosingDate: yup
                .date()
                .when(
                    [
                        'insuranceQuestionRefinanceOrRenewal',
                        'insuranceQuestionIncreasedAmortization',
                        'insuranceQuestionAddFundsOrIncreasedMortgageAmount',
                    ],
                    {
                        is: (
                            insuranceQuestionRefinanceOrRenewal,
                            insuranceQuestionIncreasedAmortization,
                            insuranceQuestionAddFundsOrIncreasedMortgageAmount
                        ) =>
                            insuranceQuestionRefinanceOrRenewal === 'YES' &&
                            (insuranceQuestionIncreasedAmortization === 'YES' ||
                                insuranceQuestionAddFundsOrIncreasedMortgageAmount ===
                                    'YES'),
                        then: yup
                            .date()
                            .emptyAsUndefined()
                            .fixDateFormat()
                            .max(
                                new Date(),
                                i18n._({
                                    id: 'error.dateShouldBePast',
                                })
                            )
                            .required(
                                i18n._({
                                    id: 'error.fieldRequired',
                                })
                            ),
                        otherwise: yup
                            .date()
                            .optional()
                            .nullable()
                            .fixBackendIssue0001()
                            .nullable()
                            .fixDateFormat()
                            .emptyAsUndefined()
                            .transform((v) =>
                                // @ts-ignore
                                v instanceof Date && !isNaN(v) ? v : null
                            ),
                    }
                ),
        })
    );

export const mortgagesSchemaRefinance = (i18n: I18n) =>
    yup.array().of(
        yup.object().shape({
            payment: getAmountFrequencyRequiredSchema(i18n),
            lender: stringRequired(i18n).nullable(),
            balance: numberRequired(i18n).isValueNaN().nullable(),
            mortgagePayoutAmount: yup
                .number()
                .emptyAsUndefined()
                .typeError(i18n._('error.typeError')),
            interestRate: numberRequired(i18n)
                .min(0.1, i18n._({ id: 'error.fieldRequired' }))
                .emptyAsUndefined(),
            interestRateType: stringRequired(i18n),
            mortgageBalanceDate: yup.string().optional().emptyAsUndefined(),
            remainingTermDays: yup.number().optional().nullable(),
            termType: stringRequired(i18n).nullable(),
            mortgageType: stringRequired(i18n),
            maturityDate: yup
                .date()
                .fixBackendIssue0001()
                .nullable()
                .fixDateFormat()
                .emptyAsUndefined()
                .required(i18n._({ id: 'error.fieldRequired' }))
                .max(
                    addYears(new Date(), 10),
                    i18n._({ id: 'error.maxMaturityDate' })
                )
                .when('lastClosingDate', (lastClosingDate, schema) => {
                    if (
                        lastClosingDate &&
                        !isNaN(Date.parse(lastClosingDate))
                    ) {
                        return schema.min(
                            isBefore(lastClosingDate, new Date())
                                ? new Date()
                                : lastClosingDate,
                            i18n._({
                                id: 'error.dateShouldBeFuture',
                            })
                        );
                    }

                    return schema;
                }),
        })
    );
