import * as R from 'ramda';
import { atom, atomFamily, selectorFamily, useSetRecoilState } from 'recoil';

import { client as apiClient } from 'libs/api';

import type {
    NotesStateTypes,
    ApplicantNotesSection,
    NotesSectionTypes,
} from 'types/notes';

export const notesState = atom<NotesStateTypes>({
    key: 'notesState',
    default: {
        noteType: 'internal',
        section: 'information',
        applicantId: 0,
        nodeType: 'underwriter',
    },
});

export const isNotesVisible = atom({
    key: 'isNotesVisible',
    default: false,
});

export const useRefreshNotes = (applicationId) => {
    const setNotes = useSetRecoilState(getNotes({ applicationId }));

    return async () => {
        const { data } = await apiClient.getNotes(applicationId);
        setNotes(data);

        return data;
    };
};

export const getNotesValues = selectorFamily({
    key: 'getNotesValues',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async () => {
            try {
                const { data } = await apiClient.getNotes(applicationId);

                return data;
            } catch (error) {
                console.error('Notes error', error);

                // will return an empty object here to avoid the selector to trigger errors
                // few error will be avoid here on the note side bar
                return {
                    general: [],
                    submission: [],
                    underwriter: {
                        applicants: {},
                        property: [],
                    },
                    advisor: {
                        applicants: {},
                        property: [],
                    },
                };
            }
        },
});

export const getNotes = atomFamily({
    key: 'getNotes',
    default: getNotesValues,
});

export const getNotesBySection = selectorFamily({
    key: 'getNotesBySection',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            try {
                const noteState = get(notesState);

                const data = get(getNotes({ applicationId }));

                const notesValues = [];

                if (noteState.section === 'property') {
                    (
                        Object.values(
                            (data[noteState.nodeType] as ApplicantNotesSection)
                                .property
                        ) || []
                    ).forEach((note) => {
                        notesValues.push(note);
                    });
                } else {
                    (
                        Object.keys(
                            (data[noteState.nodeType] as ApplicantNotesSection)
                                .applicants || {}
                        ) || []
                    ).forEach((applicantId) => {
                        const notesArray = (
                            data[noteState.nodeType] as ApplicantNotesSection
                        ).applicants[applicantId];

                        Object.keys(notesArray).forEach((node) => {
                            if (node === noteState.section) {
                                notesArray[noteState.section].forEach((note) =>
                                    notesValues.push({
                                        ...note,
                                        applicantId,
                                    })
                                );
                            }
                        });
                    });
                }

                return notesValues.sort(
                    (a, b) =>
                        new Date(b.created).getTime() -
                        new Date(a.created).getTime()
                );
            } catch (error) {
                console.error('Notes error', error);
            }
        },
});

export const getAdvisorNotes = selectorFamily({
    key: 'getAdvisorNotes',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            try {
                const data = get(getNotes({ applicationId }));
                let notesSections = {
                    general: [],
                    assets: [],
                    employments: [],
                    incomeOthers: [],
                    information: [],
                    properties: [],
                    property: [],
                };
                (Object.values(data.advisor.applicants) || []).forEach(
                    (notes, index) => {
                        const applicantId = Number(
                            Object.keys(data.advisor.applicants)[index]
                        );
                        Object.keys(notesSections).forEach((section) => {
                            if (section === 'property' || section === 'general')
                                return;
                            const applicantNotes = notes[section].map(
                                (note) => {
                                    return { ...note, applicantId };
                                }
                            );
                            notesSections[section].push(...applicantNotes);
                        });
                    }
                );
                if (data.advisor.property) {
                    notesSections = {
                        ...notesSections,
                        property: data.advisor.property,
                    };
                }
                if (data.general) {
                    notesSections = {
                        ...notesSections,
                        general: data.general,
                    };
                }
                return notesSections;
            } catch (error) {
                console.error('Notes error', error);
            }
        },
});

// get General or Submission notes
export const getApplicationNotes = selectorFamily({
    key: 'getApplicationNotes',
    get:
        ({
            NodeType,
            applicationId,
        }: {
            NodeType: 'general' | 'submission';
            applicationId: number;
        }) =>
        async ({ get }) => {
            try {
                const data = get(getNotes({ applicationId }));

                const notesValues = [];

                (Object.values(data[NodeType]) || []).forEach((note) => {
                    notesValues.push(note);
                });

                return notesValues.sort(
                    (a, b) =>
                        new Date(b.created).getTime() -
                        new Date(a.created).getTime()
                );
            } catch (error) {
                console.error('Notes error', error);
            }
        },
});

export const getNotesSections = selectorFamily({
    key: 'getNotesSections',
    get:
        (applicationId: number) =>
        async ({ get }) => {
            const notesSections = {
                assets: 0,
                employments: 0,
                incomeOthers: 0,
                information: 0,
                properties: 0,
                property: 0,
            };

            try {
                const noteState = get(notesState);

                const data = get(getNotes({ applicationId }));

                const dataArray = data[
                    noteState.nodeType
                ] as ApplicantNotesSection;

                (Object.values(dataArray.applicants || {}) || []).forEach(
                    (nodeApplicant: any) => {
                        Object.keys(notesSections).forEach((section) => {
                            if (section !== 'property') {
                                notesSections[section] +=
                                    nodeApplicant[section].length;
                            }
                        });
                    }
                );

                (Object.keys(dataArray) || []).forEach((nodeApplicant) => {
                    if (nodeApplicant === 'property') {
                        notesSections[nodeApplicant] +=
                            dataArray[nodeApplicant].length;
                    }
                });

                return notesSections;
            } catch (error) {
                console.error('Notes error', error);

                return notesSections;
            }
        },
});

const objArrayLengthCounter = (item: any) => {
    if (Array.isArray(item)) {
        return item.length;
    }

    if (typeof item === 'object') {
        return Object.values(item).reduce(
            (acc, item) => acc + objArrayLengthCounter(item),
            0
        );
    }

    return 0;
};

export const getApplicationNotesTotalCount = selectorFamily({
    key: 'GetApplicationNotesTotalCount',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const notes = get(getNotes({ applicationId }));

            const total = objArrayLengthCounter(R.omit(['general'], notes));

            return total;
        },
});

export const getNotesTotalCount = selectorFamily({
    key: 'GetNotesTotalCount',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const notes = get(getNotes({ applicationId }));

            const total = objArrayLengthCounter(notes);

            return total;
        },
});

export const getNotesCount = selectorFamily({
    key: 'getNotesCount',
    get:
        ({
            applicationId,
            applicantId,
            section,
        }: {
            applicationId: number;
            applicantId: number;
            section: NotesSectionTypes;
        }) =>
        async ({ get }) => {
            const data = get(getNotes({ applicationId }));

            const sections = {
                information: 0,
                employments: 0,
                incomeOthers: 0,
                assets: 0,
                properties: 0,
                property: 0,
            };

            const results = {
                general: 0,
                advisor: { ...sections },
                underwriter: { ...sections },
            };

            (Object.keys(data) || []).forEach((nodeType) => {
                if (['advisor', 'underwriter'].includes(nodeType)) {
                    if (data?.[nodeType]?.property.length) {
                        results[nodeType].property =
                            data?.[nodeType].property.length;
                    }

                    if (
                        applicantId &&
                        data?.[nodeType]?.applicants?.[applicantId]
                    ) {
                        const applicantNote =
                            data?.[nodeType]?.applicants?.[applicantId];
                        results[nodeType][section] = applicantNote
                            ? applicantNote[section]?.length
                            : 0;
                    } else {
                        const applicantsNotes = data[nodeType].applicants;

                        (Object.values(applicantsNotes) || []).forEach(
                            (notes) => {
                                (Object.keys(notes) || []).forEach(
                                    (noteSection) => {
                                        if (Array.isArray(notes[noteSection])) {
                                            results[nodeType][noteSection] +=
                                                notes[noteSection].length;
                                        }
                                    }
                                );
                            }
                        );
                    }
                }
            });

            return results;
        },
});
