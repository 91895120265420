import React, { Suspense } from 'react';

import { useTenantFlags } from '@nestoca/multi-tenant';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { BrokerDashboard as BrokerDashboardNew } from 'components/dashboard/broker-dashboard-new/broker-dashboard-new';
import {
    BrokerDashboard as BrokerDashboardOld,
    NoApplicationSelected,
} from 'components/dashboard/broker-dashboard-old';
import { ZERO } from 'constants/appConstants';
import { applicationListState, selectedApplication } from 'store/applications';
import { getSelectedDashboard } from 'store/dashboard';

import CreateApplicationForm from './create-application';
import { UnderWriterDashboard } from './underwriter-dashboard/underwriter-dashboard';

export const BrokerDashboardView = () => {
    const { enhancedAdvisorDashboard } = useFlags();
    const router = useRouter();
    const { enableApplicationCreationInternal } = useTenantFlags();

    const applications = useRecoilValue(applicationListState);
    const application = useRecoilValue(selectedApplication);
    const activeDashboard = useRecoilValue(getSelectedDashboard);

    if (applications?.length === ZERO) {
        if (!enableApplicationCreationInternal) {
            router.push('/forbidden');
        } else return <CreateApplicationForm />;
    }

    if (!application) {
        return <NoApplicationSelected />;
    }

    if (activeDashboard === 'UNDERWRITER') {
        return <UnderWriterDashboard />;
    }

    return enhancedAdvisorDashboard ? (
        <BrokerDashboardNew
            applicationId={application.id}
            segment={application.segment}
        />
    ) : (
        <BrokerDashboardOld />
    );
};

// BrokerDashboardContainer suspense wait for promise dependencies to be resolve before rendering
export const BrokerDashboard = () => (
    <Suspense fallback={null}>
        <BrokerDashboardView />
    </Suspense>
);
