import React from 'react';

import { Flex } from '@nestoca/ui';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { getRateHoldExpiration } from 'components/rates/rates-utils';
import { RATE_HOLD } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { Product } from 'types/product';

import styles from './rate-card.module.scss';

type Props = {
    product: Product;
    rateExpirationDate: string;
};

export const RateInfo = ({ product, rateExpirationDate }: Props) => {
    const { i18n } = useI18n();

    return (
        <Flex className={styles.rate_info} direction="column">
            <CellData fieldType="percentage" tx="rate" value={product?.rate} />
            <CellData tx="productLabel" value={product?.name} />
            <CellData
                fieldType="select"
                tx="rateHold.title"
                options={RATE_HOLD(i18n)}
                value={product?.rateHold}
            />
            <CellData
                tx="rateHold.expiration"
                value={getRateHoldExpiration(rateExpirationDate)}
            />
        </Flex>
    );
};
