import React from 'react';

import { Trans } from '@lingui/react';
import { Flex } from 'reflexbox';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { formatMoney } from 'utils';
import { paymentFrequencyProductTitle } from 'utils/adapter/product-selection/payment-frequency';

import type { AmountFrequency } from 'types';

const Payment = ({ amount, frequency }: AmountFrequency) => {
    return (
        <Flex justifyContent="space-between">
            <Trans id={paymentFrequencyProductTitle[frequency]} />
            <CellData fieldType="text" value={formatMoney(amount)} />
        </Flex>
    );
};

export default Payment;
