import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTenantSetting } from '@nestoca/multi-tenant';
import * as R from 'ramda';
import { useRecoilValue } from 'recoil';

import { EditableGrid } from 'components/editable';
import { ModalFormActions } from 'components/modals/modal-form-actions';
import { FormValues } from 'components/owned-properties/owned-properties';
import { useToasts } from 'components/toast';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    useRefreshApplicationById,
    getApplication,
    useRefreshApplicationMortgage,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import { useRefreshQualification } from 'store/qualification';
import { useRefreshSubmissionNotes } from 'store/submission-notes';
import {
    booleanNormalizer,
    compose,
    maxTwoDecimal,
    moneyNormalizer,
    numberNormalizer,
} from 'utils';
import { useEditingContextWithCleanup } from 'utils/use-editing-context';
import { usePartialValidation } from 'utils/use-partial-validation';
import { getOtherPropertyModelSchema } from 'validations/other-property';
import { getOtherPropertyPartialModelSchema } from 'validations/other-property-partial';

import { onChangePortIndicator } from './owned-properties';
import { Sections } from './sections';

import type { OtherProperty } from 'types/property';

export type OwnedPropertiesProps = {
    editableKey: string;
    applicationId: number;
    applicantId?: number;
};

const ownedProperty = {} as unknown as OtherProperty;

export const normalizeOwnedProperty = R.evolve({
    purchasePrice: moneyNormalizer,
    estimatedPropertyValue: moneyNormalizer,
    taxes: {
        amount: (value) => moneyNormalizer(value) || 0,
        year: (value) => numberNormalizer(value) || 0,
    },
    rentalIncome: { amount: moneyNormalizer },
    mortgage: {
        balance: (value) => moneyNormalizer(value) || 0,
        payment: {
            amount: (value) => moneyNormalizer(value) || 0,
            frequency: (value) => value || '',
        },
        interestRate: (value: string | number) =>
            numberNormalizer(maxTwoDecimal(value)) || 0,

        interestRateType: (value) => value || '',
        lender: (value) => value || '',
        maturityDate: (value) => value || null,
    },
    condoFees: {
        amount: (value) => moneyNormalizer(value) || 0,
        ratio: numberNormalizer,
        heatingIncluded: booleanNormalizer,
    },
    heatingCost: {
        amount: moneyNormalizer,
    },
    coOwnerApplicantIds: (value) => value || [],
    hasMortgage: booleanNormalizer,
    bridgeLoanAmount: (value: number) => moneyNormalizer(value) || 0,
    bridgeLoanRate: (value: number) =>
        numberNormalizer(maxTwoDecimal(value)) || null,
});

function cleanOwnedPropertyData(formValues: FormValues) {
    const isSold = R.propEq('SOLD', 'afterTransactionPurpose');

    /* Fields bridgeLoanRequired and bridgeLoanNumber are only part
    of the formValues if it's a bridge loan. If not they are unregister. */
    const bridgeLoanRequired = booleanNormalizer(formValues.bridgeLoanRequired);

    const bridgeLoanOmittedKeys = [
        'estimatedPropertyValue',
        'type',
        'taxes',
        'rentalIncome',
        'condoFees',
        'bridgeLoanRequired',
        'bridgeLoanNumber',
    ];

    if (bridgeLoanRequired) {
        return R.omit(bridgeLoanOmittedKeys, formValues);
    }

    const soldOmittedKeys = [
        'estimatedPropertyValue',
        'type',
        'taxes',
        'rentalIncome',
        'condoFees',
        'bridgeLoanRequired',
        'bridgeLoanNumber',
        'bridgeLoanAmount',
        'bridgeLoanRate',
    ];

    const notSoldOmittedKeys = [
        'currentSaleStatus',
        'purchasePrice',
        'sellingDate',
        'bridgeLoanAmount',
        'bridgeLoanRate',
    ];

    const sold = R.omit(soldOmittedKeys);

    const isRental = R.propSatisfies(
        (afterTransactionPurpose) =>
            afterTransactionPurpose === 'OWNER_OCCUPIED_AND_RENTAL' ||
            afterTransactionPurpose === 'RENTAL',
        'afterTransactionPurpose'
    );

    // const isCondo = R.propSatisfies((type) => type === 'CONDO', 'type');

    const notSold = R.pipe(
        // If not sold alway remove this three property
        R.omit(notSoldOmittedKeys),
        // if NOT rental remove rentalIncome from payload
        // eslint-disable-next-line
        // @ts-ignore
        R.ifElse(isRental, (data) => data, R.omit(['rentalIncome']))
        // if NOT condo remove heatingIncluded from payload
        // R.ifElse(isCondo, (data) => data, R.omit(['condoFees.heatingIncluded']))
    );

    return R.ifElse(isSold, sold, notSold)(formValues);
}

export const OwnedPropertiesModal = ({
    editableKey,
    applicationId,
    applicantId,
}: OwnedPropertiesProps) => {
    const { i18n } = useI18n();
    const { addToast } = useToasts();
    const { close: closeModal } = useModal<OwnedPropertiesProps>(
        'createOwnedProperty'
    );
    const refreshApplication = useRefreshApplicationById(applicationId);
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const { refresh: refreshQualification } = useRefreshQualification();
    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);
    const refreshApplicationMortgage =
        useRefreshApplicationMortgage(applicationId);
    const application = useRecoilValue(getApplication(applicationId));

    const partialValidation = usePartialValidation();

    const { editingKey } = useEditingContextWithCleanup(editableKey);

    const isEditing = useMemo(() => editingKey === editableKey, [editingKey]);

    const {
        settings: { enableBridgeLoanInformation },
    } = useTenantSetting('enableBridgeLoan');

    const defaultValues = {
        bridgeLoanRequired: null,
        bridgeLoanAmount: null,
        bridgeLoanRate: null,
        bridgeLoanNumber: application?.bridgeLoanNumber,
    };

    const schema = partialValidation
        ? getOtherPropertyPartialModelSchema(i18n)
        : getOtherPropertyModelSchema(i18n);

    const onSubmit = async (values: FormValues) => {
        try {
            const { applicantId, bridgeLoanRequired, hasMortgage, mortgage } =
                values;

            const newOwnedProperty = R.omit(
                ['applicantId'],
                values
            ) as unknown as FormValues;

            const data: FormValues = compose(
                normalizeOwnedProperty,
                cleanOwnedPropertyData
            )({
                ...ownedProperty,
                ...newOwnedProperty,
                mortgage: booleanNormalizer(hasMortgage)
                    ? mortgage
                    : booleanNormalizer(bridgeLoanRequired)
                      ? { balance: mortgage.balance }
                      : {},
            });

            const property = await apiClient.createOtherProperty(
                applicationId,
                applicantId,
                {
                    ...data,
                }
            );

            application.type === 'PORT' &&
                (await onChangePortIndicator(
                    data.mortgage.portIndicator,
                    application,
                    property.data
                ));

            await refreshApplication();
            await refreshApplicationDocumentsCounts();
            await refreshQualification(applicationId);
            await refreshSubmissionNotes();
            await refreshApplicationMortgage();

            closeModal();
            addToast(i18n._('successfullySaved'), {
                appearance: 'success',
            });
        } catch (error) {
            addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                appearance: 'error',
            });
        }
    };

    return (
        <EditableGrid
            onSubmit={onSubmit}
            resolver={yupResolver(schema)}
            defaultValues={defaultValues}
            id={`ownedProperty-${ownedProperty.id}`}
            context={{
                enableBridgeLoanInformation,
                type: application?.type,
                portPropertyId: application.portProperty?.id,
                propertyId: ownedProperty?.id,
            }}
            editableKey={editableKey}
            gridGap="var(--spacing-4)"
            gridAutoRows="auto"
            gridTemplateColumns="1fr"
            hideToolbar
        >
            <Sections
                isCreating
                isEditing={isEditing}
                isNewOwnedProperty
                applicationId={applicationId}
                applicantId={applicantId}
                property={ownedProperty}
            />
            {isEditing && <ModalFormActions closeModal={closeModal} />}
        </EditableGrid>
    );
};
