import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import { useI18n } from 'providers/i18n/use-i18n';
import { IncomeEmployment } from 'types/applicant';
import { usePartialValidation } from 'utils/use-partial-validation';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    employment: IncomeEmployment & { totalIncome?: number };
};

export const EmployerDetails = ({
    isCreating,
    isEditing,
    employment,
}: Props) => {
    const { i18n } = useI18n();

    const partialValidation = usePartialValidation();

    const { name, phone } = employment?.employer || {};

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('employerDetails')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    required={!partialValidation}
                    isEditing={isEditing}
                    name="employer.name"
                    label="employer"
                    value={name}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={!partialValidation}
                    isEditing={isEditing}
                    name="employer.phone"
                    fieldType="phone"
                    label="Phone"
                    value={phone}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
            </Grid>
        </Flex>
    );
};
