import React, { Suspense, memo, useEffect, useState } from 'react';

import { Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { SiGooglestreetview } from 'react-icons/si';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { EditableCell } from 'components/editable';
import { GoogleMaps } from 'components/google-maps-ui';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getApplicantsSelectOptions,
    getIncomeByApplicant,
    getSubjectProperty,
} from 'store/applications';
import { formatAddress } from 'utils';

import { ApplicantEmployments } from './applicant-employments';
import styles from './property-location-modal.module.scss';
import {
    getApplicantEmploymentAddress,
    updateApplicantEmployments,
} from './utils';

export type PropertyLocationModalProps = {
    applicationId: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const PropertyLocationModal = memo(
    ({ className, applicationId, ...rest }: PropertyLocationModalProps) => {
        const [employmentId, setEmploymentId] = useState<number | null>(null);

        const { i18n } = useI18n();

        const applicantOptions = useRecoilValue(
            getApplicantsSelectOptions(applicationId)
        );

        const defaultValues = {
            // The first applicant is selected by default
            applicantId: applicantOptions[0].value,
        };

        const methods = useForm({ defaultValues });

        const applicantIdWatch = methods.watch('applicantId');

        const subjectProperty = useRecoilValue(
            getSubjectProperty(applicationId)
        );

        // Update applicantId when applicantIdWatch changes
        const applicantEmployments = useRecoilValue(
            getIncomeByApplicant({
                applicationId,
                applicantId: applicantIdWatch,
            })
        );

        const applicantEmploymentsWithValidAddresses =
            updateApplicantEmployments(applicantEmployments);

        const employmentAddress = getApplicantEmploymentAddress(
            employmentId,
            applicantEmployments
        );

        const onEmploymentChange = (employmentId: number) => {
            setEmploymentId(employmentId);
        };

        useEffect(() => {
            // Select first employment by default if there is any
            if (applicantEmploymentsWithValidAddresses?.length) {
                setEmploymentId(applicantEmploymentsWithValidAddresses[0].id);
            }
        }, [applicantEmploymentsWithValidAddresses, applicantIdWatch]);

        return (
            <Flex
                className={cn(styles['property-location-modal'], className)}
                direction="column"
                {...rest}
            >
                <Flex
                    className={styles.header}
                    align="center"
                    gap={4}
                    as="header"
                >
                    <SiGooglestreetview size={20} />
                    <Typography weight={7} textColor="var(--color-black)">
                        {i18n._('location')}
                    </Typography>
                </Flex>
                <Suspense fallback={null}>
                    <Flex className={styles.body} gap={5}>
                        <Flex
                            className={styles['applicant-info']}
                            direction="column"
                            gap={4}
                        >
                            <FormProvider {...methods}>
                                <Flex
                                    className={styles.form}
                                    direction="column"
                                    gap={4}
                                    as="form"
                                >
                                    <CellData
                                        tx="subjectProperty"
                                        value={formatAddress(
                                            subjectProperty.address
                                        )}
                                    />
                                    <EditableCell
                                        isEditing
                                        fieldType="select"
                                        name="applicantId"
                                        label="applicant"
                                        options={applicantOptions}
                                    />
                                </Flex>
                            </FormProvider>
                            <ApplicantEmployments
                                employmentId={employmentId}
                                applicantEmployments={
                                    applicantEmploymentsWithValidAddresses
                                }
                                onEmploymentChange={onEmploymentChange}
                            />
                        </Flex>
                        <GoogleMaps
                            className={styles['google-maps']}
                            subjectPropertyAddress={subjectProperty.address}
                            employmentAddress={employmentAddress}
                        />
                    </Flex>
                </Suspense>
            </Flex>
        );
    }
);

PropertyLocationModal.displayName = 'PropertyLocationModal';
