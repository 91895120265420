import { PlaidMonitoringErrorStatus } from './types';

export const PLAID_MONITORING_ERROR_MESSAGE: Record<
    PlaidMonitoringErrorStatus | 'default',
    string
> = {
    400: 'plaidMonitoring.error.badFormat',
    429: 'plaidMonitoring.error.rateLimitExceeded',
    500: 'plaidMonitoring.error.internal',
    503: 'plaidMonitoring.error.plannedMaintenance',
    default: 'plaidMonitoring.error.unexpected',
};
