import intlFormat from 'date-fns/intlFormat';
import { evolve } from 'ramda';

import { booleanNormalizer, moneyNormalizer, numberNormalizer } from 'utils';
import { toDateNoTime } from 'utils/closing-documents';

import { dateNormalizer } from './normalizer';

import type { ProductTrackerCode, Purpose } from 'types/property';

export const normalizeSubjectProperty: any = evolve({
    isFound: booleanNormalizer,
    purchasePrice: moneyNormalizer,
    estimatedPropertyValue: moneyNormalizer,
    originalMortgageAmount: moneyNormalizer,
    additionalFundAmount: moneyNormalizer,
    coOwnershipFees: {
        amount: numberNormalizer,
        heatingIncluded: booleanNormalizer,
        ratio: numberNormalizer,
    },
    rentalIncome: {
        amount: moneyNormalizer,
    },
    rentalIncomeRatio: numberNormalizer,
    heatingCost: {
        amount: numberNormalizer,
    },
    mlsListingNumber: numberNormalizer,
    schoolAndMunicipalTaxes: {
        amount: moneyNormalizer,
        year: moneyNormalizer,
        paidOnYourBehalf: booleanNormalizer,
        taxHoldBackAmount: numberNormalizer,
    },
    livingSpace: {
        amount: numberNormalizer,
    },
    lotSize: {
        amount: numberNormalizer,
    },
    numberOfUnits: numberNormalizer,
    yearBuilt: numberNormalizer,
    garage: {
        present: booleanNormalizer,
    },
    purchaseDate: toDateNoTime,
    // @ts-ignore
    mortgages: [
        {
            balance: numberNormalizer,
            currentAmortizationMonths: numberNormalizer,
            currentAmortizationYears: numberNormalizer,
            originallyInsured: booleanNormalizer,
            payment: { amount: numberNormalizer },
            lastClosingDate: toDateNoTime,
            maturityDate: toDateNoTime,
            interestRate: numberNormalizer,
            mortgagePayoutAmount: numberNormalizer,
            originalTermYears: numberNormalizer || null,
            originalTermMonths: numberNormalizer || null,
        },
    ],
    acceptanceDate: dateNormalizer,
    appraisalDate: dateNormalizer,
    previousSaleClosingDate: dateNormalizer,
    environmentalHazard: booleanNormalizer,
    previousSalePrice: moneyNormalizer,
    improvedPropertyValue: moneyNormalizer,
    improvementAmount: moneyNormalizer,
    holdbackAmount: moneyNormalizer,
    improvementsMadeToProperty: booleanNormalizer,
    previousMortgagePurchasePrice: moneyNormalizer,
    previousMortgageBalance: moneyNormalizer,
    firstMortgageOSBalance: moneyNormalizer || null,
    originalGDS: numberNormalizer || null,
    originalTDS: numberNormalizer || null,
    producTrackerCode: (value: ProductTrackerCode | null) => value || '',
});

export const getIsPropertyPurposeRental = (purpose?: Purpose) =>
    ['OWNER_OCCUPIED_AND_RENTAL', 'RENTAL'].includes(purpose);

export function formatDate(dateToFormat: string | undefined, locale: string) {
    if (!dateToFormat) return '-';
    return intlFormat(
        new Date(`${dateToFormat}T01:00:00-05:00`),
        {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        },
        { locale }
    );
}
