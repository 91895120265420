import React, { useCallback, useMemo, useState } from 'react';

import { Trans } from '@lingui/react';
import { Typography } from '@nestoca/ui';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Flex } from 'reflexbox';

import { Button } from 'components/button-v2';
import { Divider } from 'components/divider/divider';
import { Checkbox } from 'components/forms';
import { Grid } from 'components/grid/grid';
import { Spinner } from 'components/spinner';
import { SuccessfulToastContent } from 'components/successful-toast-content';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    getApplicantsByIds,
    useRefreshApplicationById,
} from 'store/applications';
import { useRefreshQualification } from 'store/qualification';
import { useRefreshSubmissionNotes } from 'store/submission-notes';
import { formatMoney } from 'utils';

export type DeleteLiabilitiesModalProps = {
    applicationId: number;
    applicantsIds: number[];
};

type SelectedLiabilities = {
    applicantId: number;
    liabilityIds?: number[];
}[];

export const DeleteLiabilitiesModal = ({
    applicationId,
    applicantsIds,
}: DeleteLiabilitiesModalProps) => {
    const { i18n } = useI18n();
    const refreshApplication = useRefreshApplicationById(applicationId);
    const { refresh: refreshQualification } = useRefreshQualification();
    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);
    const { close: closeModal } =
        useModal<DeleteLiabilitiesModalProps>('deleteLiabilities');
    const [isSaving, setIsSaving] = useState(false);

    const initialState = applicantsIds.map((id) => ({
        applicantId: id,
        liabilityIds: [],
    }));
    const [selectedLiabilities, setSelectedLiabilities] =
        useState<SelectedLiabilities>(initialState);

    const applicants = useRecoilValue(
        getApplicantsByIds({
            applicationId,
            applicantsIds,
        })
    );

    const onSubmit = async () => {
        setIsSaving(true);
        selectedLiabilities.forEach(({ applicantId, liabilityIds }) => {
            if (!liabilityIds.length) return;

            liabilityIds.forEach(async (liabilityId) => {
                try {
                    await apiClient.deleteLiability(
                        applicationId,
                        applicantId,
                        liabilityId
                    );
                    await Promise.all([
                        refreshApplication(),
                        refreshQualification(applicationId),
                        refreshSubmissionNotes(),
                    ]);
                    closeModal();
                    setIsSaving(false);

                    toast.success(
                        <SuccessfulToastContent
                            text={i18n._('liabilities.deleted')}
                        />,
                        {
                            autoClose: 5000,
                            closeButton: false,
                            closeOnClick: true,
                        }
                    );
                } catch (error) {
                    toast.error(
                        <Typography size={0} height={1}>
                            {i18n._('failedToSave')}
                        </Typography>,
                        {
                            theme: 'colored',
                            closeButton: false,
                            closeOnClick: true,
                        }
                    );

                    closeModal();
                    setIsSaving(false);
                }
            });
        });
    };

    const toggleLiability = useCallback(
        (applicantId, liabilityId) => {
            const { liabilityIds } = selectedLiabilities.find(
                (applicant) => applicantId === applicant.applicantId
            );
            const newState = [...selectedLiabilities];

            if (liabilityIds.includes(liabilityId)) {
                const index = newState.findIndex(({ liabilityIds }) =>
                    liabilityIds.find((id) => id === liabilityId)
                );
                newState
                    .find((applicant) => applicant.applicantId === applicantId)
                    .liabilityIds.splice(index, 1);
            } else {
                newState
                    .find((applicant) => applicant.applicantId === applicantId)
                    .liabilityIds.push(liabilityId);
            }

            setSelectedLiabilities([...newState]);
        },
        [selectedLiabilities]
    );

    const isButtonDisabled = useMemo(
        () =>
            !selectedLiabilities.some(
                ({ liabilityIds }) => liabilityIds?.length > 0
            ),
        [selectedLiabilities]
    );

    return (
        <Flex flexDirection="column">
            <Grid
                css={{
                    width: '100%',
                    gridGap: 10,
                    alignItems: 'center',
                    gridTemplateColumns: '.25fr 1fr 3fr 1fr',
                }}
            >
                <Grid></Grid>
                <Grid css={{ fontWeight: 'bold' }}>
                    <Trans id="applicants" />
                </Grid>
                <Grid css={{ fontWeight: 'bold' }}>
                    <Trans id="description" />
                </Grid>
                <Grid css={{ fontWeight: 'bold' }}>
                    <Trans id="amount" />
                </Grid>
                {applicants.map(
                    ({ firstName, lastName, applicantId, liabilities }) =>
                        liabilities.map(({ id, description, payment }) => (
                            <React.Fragment key={id}>
                                <Grid>
                                    <Checkbox
                                        checked={
                                            !!selectedLiabilities.find(
                                                ({ liabilityIds }) =>
                                                    liabilityIds.includes(id)
                                            )
                                        }
                                        onClick={() =>
                                            toggleLiability(applicantId, id)
                                        }
                                    />
                                </Grid>
                                <Grid>
                                    {firstName} {lastName}
                                </Grid>
                                <Grid>{description}</Grid>
                                <Grid>{formatMoney(payment.amount)}</Grid>
                                <Divider css={{ gridColumn: '1/5' }} />
                            </React.Fragment>
                        ))
                )}
            </Grid>
            <Trans id="deleteLiabilitiesWarning" />
            <Flex
                css={{
                    alignItems: 'center',
                    justifyContent: 'end',
                    width: '100%',
                }}
            >
                <Button
                    mr={2}
                    type="button"
                    variant="secondary"
                    onClick={closeModal}
                >
                    <Trans id="cancel" />
                </Button>
                <Button
                    type="submit"
                    disabled={isButtonDisabled}
                    onClick={onSubmit}
                    iconRight={isSaving ? <Spinner size={16} /> : null}
                >
                    <Trans id="delete" />
                </Button>
            </Flex>
        </Flex>
    );
};
