import { useState } from 'react';

import { Trans } from '@lingui/react';
import { useTenantSettings } from '@nestoca/multi-tenant';
import { Banner, Box, Button, Flex, Tooltip, Typography } from '@nestoca/ui';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { ConnectAccountsModalProps } from 'components/account-merge/connect-accounts-modal';
import { CoApplicantSearchFormValues } from 'components/account-merge/types';
import {
    getAccountSelected,
    isEmailAlreadyRegistered,
} from 'components/account-merge/utils';
import { ApplicantModalProps } from 'components/applicant/applicant-modal';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { currentApplicationIdState } from 'store/applications';
import { CoApplicantSearchResult } from 'types/co-applicant-search';
import { formatPhone } from 'utils';

import styles from './connect-accounts-footer.module.scss';

export type ConnectAccountsFooterProps = {
    accounts: CoApplicantSearchResult[];
    populatedSearchFormValues: CoApplicantSearchFormValues;
};

export const ConnectAccountsFooter = ({
    accounts,
    populatedSearchFormValues,
}: ConnectAccountsFooterProps) => {
    const [hideWarningBanner, setHideWarningBanner] = useState(false);

    const { i18n } = useI18n();

    const {
        applicantSettings: { settings: applicantSettings },
    } = useTenantSettings();

    const { register } = useFormContext();

    const applicationId = useRecoilValue(currentApplicationIdState);

    const { close: closeConnectAccountsModal } =
        useModal<ConnectAccountsModalProps>('connect-accounts-modal');

    const { open: openCreateCoApplicantModal } =
        useModal<ApplicantModalProps>('createApplicant');

    const acceptTerms = useWatch({ name: 'acceptTerms' });

    const accountIdSelected = useWatch({ name: 'accountIdSelected' });

    const accountSelected = getAccountSelected(accountIdSelected, accounts);

    const accountUserFullName =
        `${accountSelected?.firstName} ${accountSelected?.lastName}`.trim();

    const { email } = populatedSearchFormValues;

    const emailAlreadyRegistered =
        applicantSettings.checkEmailIsRegistered &&
        isEmailAlreadyRegistered(email, accounts);

    const createNewAccountTooltipId = 'create-new-account-tooltip';

    const onCancel = () => {
        closeConnectAccountsModal();
    };

    const onCreateNewAccount = () => {
        closeConnectAccountsModal();

        /* Open the existing co-applicant modal and pre-fill
           the form with the info used for the search */
        openCreateCoApplicantModal({
            editableKey: 'createCoApplicant',
            applicationId,
            applicantId: undefined,
            prefillInfo: populatedSearchFormValues,
        });
    };

    const onContinue = () => {
        closeConnectAccountsModal();

        /* Open the existing co-applicant modal and pre-fill
           the form with the account selected from search */
        openCreateCoApplicantModal({
            editableKey: 'createCoApplicant',
            applicationId,
            applicantId: accountSelected.id,
            prefillInfo: {
                firstName: accountSelected.firstName,
                lastName: accountSelected.lastName,
                phone: formatPhone(accountSelected.phone),
                email: accountSelected.email,
            },
        });
    };

    const onHideWarningBanner = () => {
        setHideWarningBanner(true);
    };

    return (
        <Flex
            className={styles['connect-accounts-footer']}
            direction="column"
            as="footer"
        >
            <Banner
                className={styles.banner}
                visible={!!accountSelected && !hideWarningBanner}
                variant="warning"
                hasCloseButton
                onClose={onHideWarningBanner}
            >
                <Typography size={0} height={2}>
                    <Trans
                        id="connect-accounts-modal.warning"
                        values={{ fullName: accountUserFullName }}
                        components={{ 0: <b /> }}
                    />
                </Typography>
            </Banner>
            <Flex
                className={styles['checkbox-wrapper']}
                align="center"
                gap={3}
                as="label"
            >
                <input
                    className={styles.checkbox}
                    type="checkbox"
                    // Checkbox is disabled if no account is selected
                    disabled={!accountSelected}
                    {...register('acceptTerms')}
                />
                <Typography size={0}>
                    {i18n._('connect-accounts-modal.acknowledge')}
                </Typography>
            </Flex>
            <Flex align="center" justify="end" gap={4}>
                <Button variant="ghost" onClick={onCancel}>
                    {i18n._('cancel')}
                </Button>
                {emailAlreadyRegistered && (
                    <Tooltip id={createNewAccountTooltipId}>
                        {i18n._('create-new-account.disabled.message')}
                    </Tooltip>
                )}
                {/* Only way to connect the tooltip to the disabled button */}
                <Box data-tooltip-id={createNewAccountTooltipId}>
                    <Button
                        variant="secondary"
                        disabled={emailAlreadyRegistered}
                        onClick={onCreateNewAccount}
                    >
                        {i18n._('create-new-account')}
                    </Button>
                </Box>
                <Button disabled={!acceptTerms} onClick={onContinue}>
                    {i18n._('continue')}
                </Button>
            </Flex>
        </Flex>
    );
};
