import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import { OPERATING_AS } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { IncomeEmployment } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isEditing: boolean;
    isCreating: boolean;
    employment: IncomeEmployment & { totalIncome?: number };
    partialSchema: boolean;
};

export const SelfEmployedDetails = ({
    isCreating,
    isEditing,
    employment,
    partialSchema,
}: Props) => {
    const { i18n } = useI18n();

    const { employer, selfEmployed } = employment;

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('employerDetails')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="selfEmployed.operatingAs"
                    label="operatingAs"
                    fieldType="select"
                    options={OPERATING_AS(i18n)}
                    value={selfEmployed?.operatingAs}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={!partialSchema}
                    isEditing={isEditing}
                    name="employer.name"
                    label="employer"
                    value={employer?.name}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={!partialSchema}
                    isEditing={isEditing}
                    name="employer.phone"
                    fieldType="phone"
                    label="Phone"
                    value={employer?.phone}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
            </Grid>
        </Flex>
    );
};
