import { Fragment } from 'react';

import { Card, Divider, Flex } from '@nestoca/ui';
import { BiUser } from 'react-icons/bi';
import { useRecoilValue } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import { Text } from 'components/text/text';
import { getApplicantsList } from 'store/applications';

import styles from './applicant-card.module.scss';
import { ApplicantInfo } from './applicant-info';

type Props = {
    applicationId: number;
};

export const ApplicantCard = ({ applicationId }: Props) => {
    const applicants = useRecoilValue(getApplicantsList(applicationId));

    return (
        <Card className={styles.applicant_card}>
            <Flex className={styles.applicant_header} align="center" gap={2}>
                <BiUser title={null} size={24} />
                <Text
                    tx={applicants?.length > 1 ? 'applicants' : 'applicant'}
                    fontSize="var(--font-size-1)"
                    fontWeight="var(--font-weight-7)"
                />
            </Flex>

            {applicants.map((applicant) => (
                <Fragment key={applicant.applicantId}>
                    <Divider />
                    <Box className={styles.applicant_element}>
                        <ApplicantInfo applicant={applicant} />
                    </Box>
                </Fragment>
            ))}
        </Card>
    );
};
