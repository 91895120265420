import { Card, Flex, Typography } from '@nestoca/ui';
import { BsPeople } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import { useI18n } from 'providers/i18n/use-i18n';
import {
    currentApplicationIdState,
    getApplicantsList,
} from 'store/applications';

import dashBoardStyles from '../broker-dashboard.module.scss';

import { ApplicantCard } from './applicant-card';

export const ApplicantCardContainer = () => {
    const { i18n } = useI18n();

    const applicationId = useRecoilValue(currentApplicationIdState);

    const applicants = useRecoilValue(getApplicantsList(applicationId));

    return (
        <Card className={dashBoardStyles.card}>
            <Flex className={dashBoardStyles.header} align="center" gap={2}>
                <BsPeople size={30} />
                <Typography weight={7} size={1}>
                    {i18n._('applicants')}
                </Typography>
            </Flex>
            <Box css={{ padding: 'var(--spacing-4)' }}>
                {applicants.map((applicant) => (
                    <ApplicantCard
                        applicant={applicant}
                        key={applicant.applicantId}
                    />
                ))}
            </Box>
        </Card>
    );
};
