export const MARITAL_STATUS = (i18n) => ({
    SINGLE: i18n._(`Single`),
    MARRIED: i18n._(`Married`),
    DIVORCED: i18n._(`Divorced`),
    COMMON_LAW: i18n._(`Common Law`),
    WIDOWED: i18n._(`Widowed`),
    SEPARATED: i18n._(`Separated`),
    OTHER: i18n._(`Other`),
});

export const CREDITSCORE_QUALITY = (i18n) => ({
    UNKNOWN: i18n._(`creditScoreQuality.unknown`),
    EXCELLENT: i18n._(`creditScoreQuality.excellent`),
    GOOD: i18n._(`creditScoreQuality.good`),
    FAIR: i18n._(`creditScoreQuality.fair`),
    POOR: i18n._(`creditScoreQuality.poor`),
});
