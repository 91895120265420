import { evolve } from 'ramda';
import * as yup from 'yup';

import { MAX_MONTHS, MAX_YEARS } from 'constants/appConstants';
import { booleanNormalizer, numberNormalizer } from 'utils';

import { getAddressSchema } from './address';
import { getAmountFrequencySchema } from './amount-frequency';
import { booleanRequired } from './boolean';
import {
    getEmploymentEmploymentTypeSchema,
    getEmploymentHasGuaranteedHoursSchema,
    getEmploymentPensionTypeSchema,
    getEmploymentSalaryPreviousYearSchema,
    getEmploymentSalarySchema,
    getEmploymentSelfEmployedSchema,
    getEmploymentTenureSchema,
} from './employment-partial';
import { numberRequired } from './number';
import { stringRequired } from './string';

import type { I18n } from '@lingui/core';
import type {
    EmploymentIncomeType,
    IncomeEmployment,
    IncomeEmploymentPayload,
} from 'types/applicant';

export const normalizeEmployment = (
    incomeEmployment: IncomeEmployment
): IncomeEmploymentPayload => {
    return evolve(
        {
            hasGuaranteedHours: booleanNormalizer,
            isCurrent: booleanNormalizer,
            employedMonths: numberNormalizer,
            employedYears: numberNormalizer,
            yearsInIndustry: numberNormalizer,
            monthsInIndustry: numberNormalizer,
            salary: {
                base: { amount: numberNormalizer },
                commission: { amount: numberNormalizer },
                bonus: { amount: numberNormalizer },
                overtime: { amount: numberNormalizer },
            },
            salaryPreviousYear: {
                base: { amount: numberNormalizer },
                commission: { amount: numberNormalizer },
                bonus: { amount: numberNormalizer },
                overtime: { amount: numberNormalizer },
            },
            selfEmployed: {
                grossRevenue: { amount: numberNormalizer },
                grossRevenuePreviousYear: { amount: numberNormalizer },
            },
            incomeOverride: {
                amount: numberNormalizer,
            },
        },
        incomeEmployment
    );
};

export const getCreateEmploymentSchema = (i18n: I18n) =>
    yup
        .object()
        .shape({
            applicantId: numberRequired(i18n).emptyAsUndefined(),
        })
        .concat(getEmploymentSchema(i18n));

export const getEmploymentSchema = (i18n: I18n) =>
    yup.object().shape(
        {
            incomeType: stringRequired(i18n),
            employedYears: yup
                .number()
                .min(
                    0,
                    i18n._({
                        id: 'error.valueEqualOrGreaterThan',
                        values: { value: 0 },
                    })
                )
                .max(MAX_YEARS, i18n._({ id: 'error.maxYears' }))
                .isValueNaN()
                .nullable()
                .when('employedMonths', {
                    is: (employedMonths: number) =>
                        employedMonths || employedMonths === 0,
                    then: (schema) => schema.optional(),
                    otherwise: (schema) =>
                        schema.required(i18n._({ id: 'error.fieldRequired' })),
                }),
            employedMonths: yup
                .number()
                .min(
                    0,
                    i18n._({
                        id: 'error.valueEqualOrGreaterThan',
                        values: { value: 0 },
                    })
                )
                .max(MAX_MONTHS, i18n._({ id: 'error.maxMonths' }))
                .isValueNaN()
                .nullable()
                .when('employedYears', {
                    is: (employedYears: number) =>
                        employedYears || employedYears === 0,
                    then: (schema) => schema.optional(),
                    otherwise: (schema) =>
                        schema.required(i18n._({ id: 'error.fieldRequired' })),
                }),
            yearsInIndustry: yup
                .number()
                .min(
                    0,
                    i18n._({
                        id: 'error.valueEqualOrGreaterThan',
                        values: { value: 0 },
                    })
                )
                .isValueNaN()
                .nullable()
                .when(['incomeType', 'monthsInIndustry'], {
                    is: (
                        incomeType: EmploymentIncomeType,
                        monthsInIndustry: number
                    ) =>
                        // When incomeType is 'NONE' or 'PENSION' `yearsInIndustry` is not required
                        [
                            'SALARIED',
                            'HOURLY',
                            'HOURLY_COMMISSIONS',
                            'COMMISSIONS',
                            'SELF_EMPLOYED',
                        ].includes(incomeType) &&
                        !monthsInIndustry &&
                        monthsInIndustry !== 0,
                    then: (schema) =>
                        schema.required(i18n._({ id: 'error.fieldRequired' })),
                    otherwise: (schema) => schema.optional(),
                }),
            monthsInIndustry: yup
                .number()
                .min(
                    0,
                    i18n._({
                        id: 'error.valueEqualOrGreaterThan',
                        values: { value: 0 },
                    })
                )
                .isValueNaN()
                .nullable()
                .when(['incomeType', 'yearsInIndustry'], {
                    is: (
                        incomeType: EmploymentIncomeType,
                        yearsInIndustry: number
                    ) =>
                        // When incomeType is 'NONE' or 'PENSION' `monthsInIndustry` is not required
                        [
                            'SALARIED',
                            'HOURLY',
                            'HOURLY_COMMISSIONS',
                            'COMMISSIONS',
                            'SELF_EMPLOYED',
                        ].includes(incomeType) &&
                        !yearsInIndustry &&
                        yearsInIndustry !== 0,
                    then: (schema) =>
                        schema
                            .required(i18n._({ id: 'error.fieldRequired' }))
                            .max(MAX_MONTHS, i18n._({ id: 'error.maxMonths' })),
                    otherwise: (schema) => schema.optional(),
                }),
            jobTitle: yup.string().when('incomeType', {
                is: (incomeType: EmploymentIncomeType) =>
                    [
                        'SALARIED',
                        'HOURLY',
                        'HOURLY_COMMISSIONS',
                        'COMMISSIONS',
                        'SELF_EMPLOYED',
                    ].includes(incomeType),
                then: (schema) =>
                    schema.required(i18n._({ id: 'error.fieldRequired' })),
                otherwise: (schema) => schema.optional().nullable(),
            }),
            pensionType: getEmploymentPensionTypeSchema(i18n),
            industry: yup.string().when('incomeType', {
                is: (incomeType: EmploymentIncomeType) =>
                    [
                        'SALARIED',
                        'HOURLY',
                        'HOURLY_COMMISSIONS',
                        'COMMISSIONS',
                        'SELF_EMPLOYED',
                    ].includes(incomeType),
                then: (schema) =>
                    schema.required(i18n._({ id: 'error.fieldRequired' })),
                otherwise: (schema) => schema.optional().nullable(),
            }),
            hasGuaranteedHours: getEmploymentHasGuaranteedHoursSchema(i18n),
            tenure: getEmploymentTenureSchema(i18n),
            employmentType: getEmploymentEmploymentTypeSchema(i18n),
            isCurrent: booleanRequired(i18n),
            salary: getEmploymentSalarySchema(i18n),
            salaryPreviousYear: getEmploymentSalaryPreviousYearSchema(i18n),
            selfEmployed: getEmploymentSelfEmployedSchema(i18n),
            incomeOverride: getAmountFrequencySchema(i18n),
            incomeOverrideIncluded: yup.boolean().nullable().default(false),
            employer: yup.object().when(
                ['incomeType', 'pensionType'],
                // @ts-ignore
                (incomeType: string, pensionType: string, schema: any) => {
                    if (
                        [
                            'SALARIED',
                            'HOURLY',
                            'HOURLY_COMMISSIONS',
                            'COMMISSIONS',
                            'SELF_EMPLOYED',
                        ].includes(incomeType)
                    ) {
                        return yup.object().shape({
                            address: getAddressSchema(i18n),
                            name: stringRequired(i18n),
                            phone: stringRequired(i18n).phone(
                                i18n._({ id: 'error.invalidPhone' })
                            ),
                        });
                    }

                    if (
                        incomeType === 'PENSION' &&
                        (pensionType === 'EMPLOYER' || pensionType === 'OTHER')
                    ) {
                        return yup.object().shape({
                            name: stringRequired(i18n),
                        });
                    }

                    return schema;
                }
            ),
        },
        [
            ['employedMonths', 'employedYears'],
            ['monthsInIndustry', 'yearsInIndustry'],
        ]
    );
