import { memo, useCallback, useState } from 'react';

import { Flex, Heading, Typography } from '@nestoca/ui';
import { FormProvider, useForm } from 'react-hook-form';

import {
    AccountConnectsFormValues,
    CoApplicantSearchFormValues,
} from 'components/account-merge/types';
import { getDefaultAccountIdSelected } from 'components/account-merge/utils';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    CoApplicantSearchResponse,
    CoApplicantSearchResult,
} from 'types/co-applicant-search';

import { ConnectAccountsBody } from './connect-accounts-body';
import { ConnectAccountsFooter } from './connect-accounts-footer';
import styles from './connect-accounts-modal.module.scss';

export type ConnectAccountsModalProps = {
    populatedSearchFormValues: CoApplicantSearchFormValues;
    coApplicantSearchResponse: CoApplicantSearchResponse;
};

export const ConnectAccountsModal = memo(
    ({
        populatedSearchFormValues,
        coApplicantSearchResponse,
    }: ConnectAccountsModalProps) => {
        const [accounts, setAccounts] = useState<CoApplicantSearchResult[]>(
            coApplicantSearchResponse.results
        );

        const defaultValues: AccountConnectsFormValues = {
            acceptTerms: false,
            accountIdSelected: getDefaultAccountIdSelected(accounts),
        };

        const { i18n } = useI18n();

        const methods = useForm({ defaultValues });

        const { currentPage, totalPages } = coApplicantSearchResponse;

        const onUpdateAccounts = useCallback(
            (newAccounts: CoApplicantSearchResult[]) => {
                setAccounts((currentState) => [
                    ...currentState,
                    ...newAccounts,
                ]);
            },
            []
        );

        return (
            <FormProvider {...methods}>
                <Flex
                    className={styles['connect-accounts-modal']}
                    direction="column"
                    noValidate
                    as="form"
                >
                    <Flex
                        className={styles.header}
                        direction="column"
                        gap={4}
                        as="header"
                    >
                        <Heading className={styles.title} size={5} weight={7}>
                            {i18n._('connect-accounts-modal.title')}
                        </Heading>
                        <Typography size={0}>
                            {i18n._('connect-accounts-modal.subtitle')}
                        </Typography>
                    </Flex>
                    <ConnectAccountsBody
                        accounts={accounts}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        populatedSearchFormValues={populatedSearchFormValues}
                        onUpdateAccounts={onUpdateAccounts}
                    />
                    <ConnectAccountsFooter
                        accounts={accounts}
                        populatedSearchFormValues={populatedSearchFormValues}
                    />
                </Flex>
            </FormProvider>
        );
    }
);

ConnectAccountsModal.displayName = 'ConnectAccountsModal';
