import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { EditableCell } from 'components/editable';
import { OTHER_INCOME_OPTIONS } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicantsSelectOptions } from 'store/applications';
import { IncomeOther } from 'types/applicant';

import styles from './other-income.module.scss';

type Props = {
    isEditing: boolean;
    applicationId: number;
    applicantId: number;
    otherIncome: IncomeOther;
};

export const OtherIncomeFieldsCreation = ({
    isEditing,
    applicationId,
    applicantId,
    otherIncome,
}: Props) => {
    const { i18n } = useI18n();

    const applicantOptions = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    const { watch } = useFormContext() || {};

    let otherIncomeType = otherIncome.type;

    if (watch) {
        otherIncomeType = watch('type', otherIncome.type);
    }

    const editingDescription = [
        'INVESTMENT_INCOME',
        'INTEREST_INCOME',
        'OTHER',
    ].includes(otherIncomeType);

    return (
        <>
            <EditableCell
                className={styles['applicant-field']}
                required
                isEditing={isEditing}
                name="applicantId"
                label="applicant"
                fieldType="select"
                options={applicantOptions}
                value={applicantId}
            />
            <EditableCell
                className={styles['type-field']}
                required
                isEditing={isEditing}
                name="type"
                label="type"
                fieldType="select"
                options={OTHER_INCOME_OPTIONS(i18n)}
                value={otherIncome.type}
            />
            {editingDescription ? (
                <EditableCell
                    className={styles['description-field']}
                    required
                    isEditing={isEditing}
                    name="description"
                    label="description"
                    value={otherIncome?.description}
                />
            ) : (
                <CellData
                    className={styles['description-field']}
                    tx="description"
                    value="-"
                />
            )}
        </>
    );
};
