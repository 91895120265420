import React from 'react';

import { Box, Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { BsArrowRight } from 'react-icons/bs';

import { ScrollArea } from 'components/scroll-area';
import { EMPLOYMENT_TYPE_LABEL } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { IncomeEmployment } from 'types/applicant';
import { formatAddress } from 'utils';

import styles from './applicant-employments.module.scss';

export type ApplicantEmploymentsProps = {
    employmentId: number | null;
    applicantEmployments: IncomeEmployment[];
    onEmploymentChange: (employmentId: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const ApplicantEmployments = ({
    className,
    employmentId,
    applicantEmployments,
    onEmploymentChange,
    ...rest
}: ApplicantEmploymentsProps) => {
    const { i18n } = useI18n();

    return applicantEmployments.length ? (
        <ScrollArea
            className={cn(styles['applicant-employments'], className)}
            {...rest}
        >
            <ul className={styles.list}>
                {applicantEmployments.map((employment) => (
                    <li
                        key={employment.id}
                        className={styles['applicant-employment']}
                    >
                        <Flex
                            className={cn(styles.content, {
                                [styles['content--active']]:
                                    employmentId === employment.id,
                            })}
                            role="button"
                            target={0}
                            align="center"
                            justify="between"
                            gap={3}
                            onClick={() => onEmploymentChange(employment.id)}
                        >
                            <Flex direction="column">
                                <Typography size={0} weight={7}>
                                    {formatAddress(employment.employer.address)}
                                </Typography>
                                {employment.employmentType && (
                                    <Typography
                                        className={styles['employment-type']}
                                    >
                                        {
                                            EMPLOYMENT_TYPE_LABEL(i18n)[
                                                employment.employmentType
                                            ]
                                        }
                                    </Typography>
                                )}
                            </Flex>
                            <BsArrowRight
                                className={styles['arrow-icon']}
                                size={12}
                            />
                        </Flex>
                    </li>
                ))}
            </ul>
        </ScrollArea>
    ) : (
        <Box>
            <Typography size={0} weight={7}>
                {i18n._('employment.addresses.notFound')}
            </Typography>
        </Box>
    );
};
