import React from 'react';

import { Center } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { ScrollArea } from 'components/scroll-area';
import { Text } from 'components/text/text';
import { HIDDEN_FLAGS } from 'constants/appConstants';
import { getSubjectProperty } from 'store/applications';
import { FlagType } from 'types/application';

import { Flag } from './flag';
import styles from './flags-sidebar.module.scss';

type Props = {
    applicationId: number;
    flags: FlagType[];
};

export const Flags = ({ applicationId, flags }: Props) => {
    const {
        address: { stateCode, postalCode },
    } = useRecoilValue(getSubjectProperty(applicationId));

    const flagsVisible = flags.filter(
        (flag) => !HIDDEN_FLAGS.includes(flag.code)
    );

    return (stateCode || postalCode) && flagsVisible.length ? (
        <ScrollArea>
            <ul className={styles.flags_content}>
                {flagsVisible.map((flag) => (
                    <Flag
                        key={`${flag.applicantId}-${flag.code}`}
                        applicationId={applicationId}
                        flag={flag}
                    />
                ))}
            </ul>
        </ScrollArea>
    ) : (
        <Center className={styles.flags_content}>
            <Text
                fontSize="var(--font-size-2)"
                fontWeight="var(--font-weight-7)"
                color="var(--color-brand)"
                tx="noFlags"
            />
        </Center>
    );
};
