import React from 'react';

import css from '@styled-system/css';

import { Text } from 'components/text/text';

type LabelProps = {
    label: string;
    value: string | number;
    frequency?: string;
    onClick?: () => void;
    isTotal?: boolean;
    clickable?: boolean;
};

export const ScenarioLabel = ({
    label,
    value,
    frequency,
    isTotal = false,
    onClick,
    clickable = true,
}: LabelProps) => (
    <>
        <Text
            onClick={onClick}
            tx={label}
            css={css({
                fontSize: 1,
                fontWeight: '600',
                cursor: clickable ? 'pointer' : 'default',
                textDecoration: clickable ? 'underline' : '',
            })}
        />
        <Text
            data-testid={`${label}-value`}
            css={css({
                fontSize: 1,
                fontWeight: isTotal ? 600 : 400,
            })}
        >
            {value} {frequency && '/'} {frequency}
        </Text>
    </>
);
