import React from 'react';

import { i18n, I18n } from '@lingui/core';
import { Card, Divider, Flex } from '@nestoca/ui';

import { Text } from 'components/text/text';

import styles from './next-step-card.module.scss';
import { NextStepInfo } from './next-step-info';

export const NextStepCard = () => {
    const steps = (i18n: I18n) => [
        {
            number: 1,
            title: i18n._('initialReview'),
            explanation: 'Conduct the initial review of the application.',
            completed: false,
            nextStep: true,
        },
        {
            number: 2,
            title: i18n._('mortgageCommitment'),
            explanation: 'Sub-text explaining what the step is all about',
            completed: false,
            nextStep: false,
        },
        {
            number: 3,
            title: i18n._('funding'),
            explanation: 'Sub-text explaining what the step is all about',
            completed: false,
            nextStep: false,
        },
        {
            number: 4,
            title: i18n._('reviewConditions'),
            explanation: 'Sub-text explaining what the step is all about',
            completed: false,
            nextStep: false,
        },
        {
            number: 5,
            title: i18n._('qaCheck'),
            explanation: 'Sub-text explaining what the step is all about',
            completed: false,
            nextStep: false,
        },
    ];

    return (
        <Card className={styles.next_step_card}>
            <Flex className={styles.next_step_header} direction="column">
                <Text
                    tx="nextStep"
                    fontSize="var(--font-size-1)"
                    fontWeight="var(--font-weight-7)"
                />
            </Flex>
            <Divider />
            <NextStepInfo steps={steps(i18n)} />
        </Card>
    );
};
