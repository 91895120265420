import React, { Fragment } from 'react';

import { Trans } from '@lingui/react';
import { useTenantSetting } from '@nestoca/multi-tenant';
import css from '@styled-system/css';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button } from 'components/button-v2';
import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';
import { ScenarioLabel } from 'components/scenarios/components/scenario-label';
import { ScenarioLabelWithPopper } from 'components/scenarios/components/scenario-label-popper';
import { Text } from 'components/text/text';
import { FREQUENCY_LABEL } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getCalApplicantsLiabilities,
    getCalculationRentalIncome,
    getCalOtherDebtObligations,
    getCalOtherPropertiesLiabilities,
    getCalSubjectPropertyLiability,
    getQualificationFrequency,
    getQualification,
    getApplicantsIncome,
} from 'store/qualification';
import { LiabilityCreditTypeEnum } from 'types/applicant';
import { SectionKeys } from 'types/application';
import { formatMoney } from 'utils';
import { isEmpty } from 'utils';

export const LIABILITY_CREDIT_TYPE_LABEL = (i18n: any) => ({
    [LiabilityCreditTypeEnum.INSTALMENT]: i18n._(
        `liability.creditType.instalment`
    ),
    [LiabilityCreditTypeEnum.MORTGAGE]: i18n._(`liability.creditType.mortgage`),
    [LiabilityCreditTypeEnum.OPEN]: i18n._(`liability.creditType.open`),
    [LiabilityCreditTypeEnum.REVOLVING]: i18n._(
        `liability.creditType.revolving`
    ),
});

type GDSProps = {
    handleOnClick: (section: SectionKeys) => void;
    applicationId: number;
    isTds?: boolean;
};

export const GdsIncome = ({ handleOnClick, applicationId }: GDSProps) => {
    const { i18n } = useI18n();
    const { calculationExecutionPlan } = useRecoilValue(
        getQualification(applicationId)
    );

    const qualificationApplicantsIncome = useRecoilValue(
        getApplicantsIncome({ applicationId })
    );

    const qualificationApplicantsRentalIncome = useRecoilValue(
        getCalculationRentalIncome({ applicationId })
    );

    const subjectPropertyRentalIncome =
        calculationExecutionPlan?.grossAnnualIncome
            ?.subjectPropertyRentalIncome;

    return (
        <Grid
            gridTemplateColumns="1fr auto"
            css={css({ margin: '0 auto', marginBottom: 5, columnGap: 3 })}
        >
            <Text
                tx="scenariosIncome"
                css={css({
                    fontSize: 2,
                    color: 'neutral-3',
                    fontWeight: '600',
                    margin: '0 auto',
                    marginY: 3,
                    gridColumn: '1 / span 2',
                })}
            />

            {/* Applicants Income breakdown (employments and other sums) */}
            {(qualificationApplicantsIncome || []).map((applicantIncome) => (
                <Fragment
                    key={`applicantsIncome-${applicantIncome.applicantId}`}
                >
                    {applicantIncome.employmentTotal > 0 && (
                        <ScenarioLabel
                            label={i18n._(
                                'applicantIncomeEmployment',
                                applicantIncome
                            )}
                            value={formatMoney(applicantIncome.employmentTotal)}
                            frequency={
                                FREQUENCY_LABEL(i18n)[
                                    applicantIncome?.frequency
                                ]
                            }
                            onClick={() =>
                                handleOnClick(SectionKeys.employments)
                            }
                        />
                    )}
                    {applicantIncome.employmentTotal > 0 && (
                        <DividerSpanTwoCol />
                    )}
                    {applicantIncome.otherTotal !== 0 && (
                        <ScenarioLabel
                            label={i18n._(
                                'applicantIncomeOther',
                                applicantIncome
                            )}
                            value={formatMoney(applicantIncome.otherTotal)}
                            frequency={
                                FREQUENCY_LABEL(i18n)[
                                    applicantIncome?.frequency
                                ]
                            }
                            onClick={() =>
                                handleOnClick(SectionKeys.otherIncome)
                            }
                        />
                    )}
                    {applicantIncome.otherTotal !== 0 && <DividerSpanTwoCol />}
                </Fragment>
            ))}

            {/* Owned properties rental */}
            {(qualificationApplicantsRentalIncome || []).map(
                (ownedProperty) => (
                    <Fragment key={`ownedProperties-${ownedProperty.id}`}>
                        <ScenarioLabel
                            label={`rentalIncome.ownedProperties`}
                            value={formatMoney(ownedProperty.ratioAmount)}
                            frequency={
                                FREQUENCY_LABEL(i18n)[ownedProperty?.frequency]
                            }
                            onClick={() =>
                                handleOnClick(SectionKeys.ownedProperties)
                            }
                        />

                        <DividerSpanTwoCol />
                    </Fragment>
                )
            )}

            {/* Subject property rental */}
            {subjectPropertyRentalIncome?.amount > 0 && (
                <>
                    <ScenarioLabel
                        label={`rentalIncome.subjectProperty`}
                        value={formatMoney(
                            subjectPropertyRentalIncome.ratioAmount,
                            true
                        )}
                        frequency={
                            FREQUENCY_LABEL(i18n)[
                                subjectPropertyRentalIncome?.frequency
                            ]
                        }
                        onClick={() =>
                            handleOnClick(SectionKeys.subjectProperty)
                        }
                    />

                    <DividerSpanTwoCol />
                </>
            )}

            <ScenarioLabel
                label={`totalValue`}
                value={formatMoney(
                    calculationExecutionPlan?.grossAnnualIncome?.total?.amount,
                    true
                )}
                frequency={
                    FREQUENCY_LABEL(i18n)[
                        calculationExecutionPlan?.grossAnnualIncome?.total
                            ?.frequency
                    ]
                }
                onClick={() => handleOnClick(SectionKeys.ownedProperties)}
                isTotal
            />
        </Grid>
    );
};

const GdsExpenses = ({ handleOnClick, applicationId, isTds }: GDSProps) => {
    const { i18n } = useI18n();
    const { value: enableHeloc } = useTenantSetting('enableHeloc');

    const otherDebtObligations = useRecoilValue(
        getCalOtherDebtObligations({ applicationId })
    );

    const subjectPropertyLiability = useRecoilValue(
        getCalSubjectPropertyLiability({ applicationId })
    );

    const otherPropertiesLiabilities = useRecoilValue(
        getCalOtherPropertiesLiabilities({ applicationId })
    );

    const applicantsLiabilities = useRecoilValue(
        getCalApplicantsLiabilities({ applicationId })
    );

    const qualification = useRecoilValue(getQualification(applicationId));

    return (
        <Grid
            data-testid="expenses-grid"
            alignItems="start"
            alignContent="start"
            css={css({
                width: '70%',
                margin: '0 auto',
                gridTemplateColumns: '1fr auto',
            })}
        >
            <Text
                tx="scenariosExpenses"
                css={css({
                    fontSize: 2,
                    color: 'status-critical',
                    fontWeight: '600',
                    margin: '0 auto',
                    marginY: 3,
                    gridColumn: '1 / span 2',
                })}
            />
            <ScenarioLabel
                label="propertyTaxesSubjectProperty"
                value={formatMoney(
                    subjectPropertyLiability?.taxes?.amount,
                    true
                )}
                frequency={
                    FREQUENCY_LABEL(i18n)[
                        subjectPropertyLiability?.taxes?.frequency
                    ]
                }
                onClick={() => handleOnClick(SectionKeys.subjectProperty)}
            />
            <DividerSpanTwoCol />
            <ScenarioLabel
                label="heatingFeesSubjectProperty"
                value={formatMoney(
                    subjectPropertyLiability?.heatingCost?.amount,
                    true
                )}
                frequency={
                    FREQUENCY_LABEL(i18n)[
                        subjectPropertyLiability?.heatingCost?.frequency
                    ]
                }
                onClick={() => handleOnClick(SectionKeys.subjectProperty)}
            />
            <DividerSpanTwoCol />

            <ScenarioLabelWithPopper
                label="coOwnershipFeesSubjectProperty"
                ratioValue={formatMoney(
                    subjectPropertyLiability?.condoFees?.ratioAmount,
                    true
                )}
                frequency={
                    FREQUENCY_LABEL(i18n)[
                        subjectPropertyLiability?.condoFees?.frequency
                    ]
                }
                onClick={() => handleOnClick(SectionKeys.subjectProperty)}
                ratio={subjectPropertyLiability?.condoFees?.ratio}
                originalValue={subjectPropertyLiability?.condoFees?.amount}
            />

            <DividerSpanTwoCol />

            <ScenarioLabel
                label="mortgagePaymentSubjectProperty"
                value={formatMoney(
                    subjectPropertyLiability?.mortgagePayment?.amount,
                    true
                )}
                frequency={
                    FREQUENCY_LABEL(i18n)[
                        subjectPropertyLiability?.mortgagePayment?.frequency
                    ]
                }
                onClick={() => handleOnClick(SectionKeys.subjectProperty)}
            />

            {/* Show applicants liabilities */}
            {isTds && !isEmpty(applicantsLiabilities) && <DividerSpanTwoCol />}
            {isTds &&
                (applicantsLiabilities || [])?.map((liability, index) => (
                    <Fragment key={`liabilitySection-${liability.id}`}>
                        <ScenarioLabel
                            label={
                                LIABILITY_CREDIT_TYPE_LABEL(i18n)[
                                    liability.type
                                ]
                            }
                            value={formatMoney(liability?.total?.amount, true)}
                            frequency={
                                FREQUENCY_LABEL(i18n)[
                                    liability?.total?.frequency
                                ]
                            }
                            onClick={() =>
                                handleOnClick(SectionKeys.liabilities)
                            }
                        />
                        {index < applicantsLiabilities.length - 1 && (
                            <DividerSpanTwoCol />
                        )}
                    </Fragment>
                ))}

            {/* Show other properties liabilities */}
            {isTds && !isEmpty(otherPropertiesLiabilities) && (
                <DividerSpanTwoCol />
            )}
            {isTds &&
                !isEmpty(otherPropertiesLiabilities) &&
                otherPropertiesLiabilities?.map((otherProperty, index) => (
                    <Fragment
                        key={`otherPropertiesSection-${otherProperty.id}`}
                    >
                        <ScenarioLabelWithPopper
                            label="tds.condoFees"
                            ratioValue={formatMoney(
                                otherProperty?.condoFees?.ratioAmount,
                                true
                            )}
                            frequency={
                                FREQUENCY_LABEL(i18n)[
                                    otherProperty?.condoFees?.frequency ||
                                        'ANNUALLY'
                                ]
                            }
                            onClick={() =>
                                handleOnClick(SectionKeys.ownedProperties)
                            }
                            ratio={otherProperty?.condoFees?.ratio}
                            originalValue={otherProperty?.condoFees?.amount}
                        />
                        <DividerSpanTwoCol />
                        <ScenarioLabel
                            label="tds.heating"
                            value={formatMoney(
                                otherProperty?.heatingCost?.amount,
                                true
                            )}
                            frequency={
                                FREQUENCY_LABEL(i18n)[
                                    otherProperty?.heatingCost?.frequency ||
                                        'ANNUALLY'
                                ]
                            }
                            onClick={() =>
                                handleOnClick(SectionKeys.ownedProperties)
                            }
                        />
                        <DividerSpanTwoCol />
                        <ScenarioLabel
                            label="tds.mortgage"
                            value={formatMoney(
                                otherProperty?.mortgagePayment?.amount,
                                true
                            )}
                            frequency={
                                FREQUENCY_LABEL(i18n)[
                                    otherProperty?.mortgagePayment?.frequency ||
                                        'ANNUALLY'
                                ]
                            }
                            onClick={() =>
                                handleOnClick(SectionKeys.ownedProperties)
                            }
                        />
                        <DividerSpanTwoCol />
                        <ScenarioLabel
                            label="tds.taxes"
                            value={formatMoney(
                                otherProperty?.taxes?.amount,
                                true
                            )}
                            frequency={
                                FREQUENCY_LABEL(i18n)[
                                    otherProperty?.taxes?.frequency ||
                                        'ANNUALLY'
                                ]
                            }
                            onClick={() =>
                                handleOnClick(SectionKeys.ownedProperties)
                            }
                        />
                        {index < otherPropertiesLiabilities.length - 1 && (
                            <DividerSpanTwoCol />
                        )}
                    </Fragment>
                ))}

            {enableHeloc && (
                <>
                    <DividerSpanTwoCol />
                    <ScenarioLabel
                        label="helocPayment"
                        value={formatMoney(
                            qualification?.helocQualifyingPayment?.amount || 0,
                            true
                        )}
                        frequency={
                            FREQUENCY_LABEL(i18n)[
                                qualification?.helocQualifyingPayment
                                    ?.frequency || 'ANNUALLY'
                            ]
                        }
                        onClick={() => handleOnClick(SectionKeys.helocDetails)}
                    />
                </>
            )}

            <Divider
                css={css({
                    gridColumn: '1 / span 2',
                })}
                variant="dottedGray"
            />

            <ScenarioLabel
                label="totalValue"
                value={formatMoney(
                    isTds
                        ? subjectPropertyLiability?.total?.amount +
                              otherDebtObligations?.total?.amount
                        : subjectPropertyLiability?.total?.amount,
                    true
                )}
                frequency={
                    FREQUENCY_LABEL(i18n)[
                        subjectPropertyLiability?.total?.frequency
                    ]
                }
                onClick={() => handleOnClick(SectionKeys.subjectProperty)}
                isTotal
            />
        </Grid>
    );
};

export const GdsTdsFields = ({
    handleOnClick,
    applicationId,
    isTds = false,
}: GDSProps) => (
    <Grid
        gridGap={10}
        gridArea="content"
        gridTemplateColumns="1fr"
        css={css({ position: 'relative' })}
    >
        <ChangeQualificationFrequency applicationId={applicationId} />

        <GdsIncome
            handleOnClick={handleOnClick}
            applicationId={applicationId}
        />
        <GdsExpenses
            handleOnClick={handleOnClick}
            applicationId={applicationId}
            isTds={isTds}
        />
    </Grid>
);

export const DividerSpanTwoCol = (props) => (
    <Divider
        css={css({
            gridColumn: '1 / span 2',
        })}
        variant="dottedGray"
        {...props}
    />
);

export const ChangeQualificationFrequency = ({ applicationId }) => {
    const [frequency, setFrequency] = useRecoilState(
        getQualificationFrequency(applicationId)
    );

    return (
        <div
            css={css({
                position: 'absolute',
                right: 0,
                top: 0,
                display: 'flex',
                gap: 2,
            })}
        >
            <Button
                id="qualification-freq-annual-btn"
                data-testid="qualification-freq-annual-btn"
                size="small"
                variant={frequency === 'ANNUALLY' ? 'primary' : 'plain'}
                onClick={() => setFrequency('ANNUALLY')}
            >
                <Trans id="frequency.yr" />
            </Button>

            <Button
                id="qualification-freq-monthly-btn"
                data-testid="qualification-freq-monthly-btn"
                size="small"
                variant={frequency === 'MONTHLY' ? 'primary' : 'plain'}
                onClick={() => setFrequency('MONTHLY')}
            >
                <Trans id="frequency.mo" />
            </Button>
        </div>
    );
};
