import React, { useState } from 'react';

import { Flex } from '@nestoca/ui';

import { DashboardModalWrapper } from 'components/dashboard/dashboard-modal-wrapper';
import { DocumentsCardContainer } from 'components/dashboard/documents-card/documents-card.container';
import { FlagsSidebar } from 'components/dashboard/flags-sidebar';
import { useEditing } from 'utils/use-editing';
import { EditingProvider } from 'utils/use-editing-context';

import { ApplicantCard } from './applicant/applicant-card';
import { Header } from './header';
import { NextStepCard } from './next-step/next-step-card';
import { PropertyCard } from './property/property-card';
import { RateCard } from './rate/rate-card';
import styles from './underwriter-dashboard.module.scss';

type Props = {
    applicationId: number;
};

const UnderwriterDashboard = ({ applicationId }: Props) => {
    const editing = useEditing();
    const [isFlagsPanelVisible, setIsFlagsPanelVisible] = useState(false);

    const hideFlagsPanel = () => {
        setIsFlagsPanelVisible(false);
    };

    const toggleFlagsPanel = () => {
        setIsFlagsPanelVisible((currentValue) => !currentValue);
    };

    return (
        <EditingProvider {...editing}>
            <DashboardModalWrapper>
                <Header
                    applicationId={applicationId}
                    isFlagsPanelVisible={isFlagsPanelVisible}
                    toggleFlagsPanel={toggleFlagsPanel}
                />
                <Flex
                    className={styles.main_section}
                    direction="column"
                    gap={5}
                >
                    <Flex className={styles.cards_layout} wrap="wrap" gap={5}>
                        <NextStepCard />
                        <DocumentsCardContainer underwriterDashboard />
                        <ApplicantCard applicationId={applicationId} />
                        <PropertyCard
                            applicationId={applicationId}
                            showViewMore
                        />
                        <RateCard applicationId={applicationId} />
                    </Flex>
                </Flex>
                <FlagsSidebar
                    applicationId={applicationId}
                    isFlagsPanelVisible={isFlagsPanelVisible}
                    hideFlagsPanel={hideFlagsPanel}
                />
            </DashboardModalWrapper>
        </EditingProvider>
    );
};

export default UnderwriterDashboard;
