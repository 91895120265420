import React from 'react';

import css from '@styled-system/css';

import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';

export type DownPaymentModalProps = {
    text: string;
};

export const DownPaymentModal = ({
    text = 'downPaymentChangingOccupancyMessage.text',
}: DownPaymentModalProps) => {
    return (
        <Grid gridGap={10} gridTemplateColumns="1fr" gridAutoRows="auto">
            <Text tx={text} css={css({ textAlign: 'center', fontSize: 3 })} />
        </Grid>
    );
};
