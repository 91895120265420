import { Trans } from '@lingui/react';
import { useTenantSetting } from '@nestoca/multi-tenant';
import css from '@styled-system/css';
import { FormPreviousLink } from 'grommet-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';
import { Grid } from 'components/grid/grid';
import { useToasts } from 'components/toast';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    useRefreshApplicationById,
    useRefreshApplicantCreditDecisions,
    useRefreshApplicantCreditReportHistory,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import {
    sectionSelectedState,
    useRefreshQualification,
} from 'store/qualification';
import { Applicant, PullType } from 'types/applicant';
import { SectionKeys } from 'types/application';
import { getOnlyNums } from 'utils';
import { useAnalytics } from 'utils/use-analytics';

import type {
    PullCreditFormData,
    PullCreditModalProps,
} from './pull-credit-modal';

type PullCreditActionsProps = {
    applicationId: number;
    applicant: Applicant;
    hasRequirementErrors: boolean;
    pullCreditConsent: boolean;
    lightQualification: boolean;
};

const pullButtonStyle = css({
    minWidth: 190,
    justifyContent: 'center',
});

const gridButtonStyle = css({
    justifyContent: 'right',
    marginRight: 0,
});

export const PullCreditActions = ({
    applicationId,
    applicant,
    hasRequirementErrors = false,
    pullCreditConsent,
    lightQualification,
}: PullCreditActionsProps) => {
    const { track } = useAnalytics();
    const { i18n } = useI18n();
    const { addToast } = useToasts();
    const { push } = useRouter();
    const { close: closeModal } = useModal<PullCreditModalProps>('pullCredit');
    const setSectionSelected = useSetRecoilState(
        sectionSelectedState({ applicationId })
    );
    const refreshApplication = useRefreshApplicationById(applicationId);
    const { refresh: refreshQualification } = useRefreshQualification();
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const refreshCreditDecisions = useRefreshApplicantCreditDecisions({
        applicationId,
        locale: i18n.locale,
    });
    const refreshCreditHistory = useRefreshApplicantCreditReportHistory(
        applicationId,
        applicant.applicantId
    );
    const { hardCreditPull, enableSoftPull: enableSoftPullLD } = useFlags();
    const { value: enableSoftPullTenant } = useTenantSetting('enableSoftPull');

    const showSoftPullBtn = enableSoftPullLD && enableSoftPullTenant;

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = useFormContext<PullCreditFormData>();

    const onSubmit =
        (pullCreditType: PullType) =>
        async ({ language, socialInsuranceNumber }: PullCreditFormData) => {
            try {
                await apiClient.pullCreditReports(
                    applicationId,
                    applicant.applicantId,
                    {
                        pullType:
                            pullCreditType === 'SOFT_SCORE_ONLY'
                                ? 'SOFT'
                                : pullCreditType,
                        // send numbers only without whitespaces for backend
                        socialInsuranceNumber: getOnlyNums(
                            socialInsuranceNumber
                        ),
                        language,
                        disableImport: pullCreditType === 'SOFT_SCORE_ONLY', //we disable import just for SOFT_SCORE_ONLY
                    }
                );

                // if soft pull only, we are sending a DD track event
                if (['SOFT_SCORE_ONLY', 'SOFT'].includes(pullCreditType)) {
                    track('soft_pull', {
                        applicationId,
                        applicantId: applicant.applicantId,
                        firstName: applicant.firstName,
                    });
                }

                await Promise.all([
                    refreshApplication(),
                    refreshQualification(applicationId),
                    refreshApplicationDocumentsCounts(),
                    refreshCreditDecisions(),
                    refreshCreditHistory(),
                ]);

                if (!lightQualification) {
                    setSectionSelected(SectionKeys.liabilities);

                    push(`/applications/${applicationId}`);
                }
                closeModal();
            } catch (error) {
                addToast(`Error: ${i18n._({ id: 'pullCreditModal.error' })}`, {
                    appearance: 'error',
                });
            }
        };

    const handlePullSoftScoreOnly = handleSubmit(onSubmit('SOFT_SCORE_ONLY'));
    const handlePullSoft = handleSubmit(onSubmit('SOFT'));
    const handlePullHard = handleSubmit(onSubmit('HARD'));

    return (
        <Grid
            gridArea="footerActions"
            gridTemplateColumns={'repeat(auto-fill, minmax(200px, 1fr))'}
            css={css({ marginTop: 4 })}
        >
            <Grid>
                <Flex>
                    <Button
                        iconLeft={<FormPreviousLink size="small" />}
                        data-testid={'back'}
                        type="button"
                        onClick={() => closeModal()}
                        size="medium"
                        variant="plain"
                    >
                        <Trans id="back" />
                    </Button>
                </Flex>
            </Grid>
            {/* enableSoftPull flag will be tenant and LaunchDarkly tied */}
            {showSoftPullBtn && (
                <>
                    <Grid css={gridButtonStyle}>
                        <Button
                            data-testid={'pullCreditReports-soft-score'}
                            type="submit"
                            form="pull-credit"
                            onClick={handlePullSoftScoreOnly}
                            size="large"
                            variant={'ghost'}
                            css={pullButtonStyle}
                            disabled={hasRequirementErrors || isSubmitting}
                        >
                            <Trans id="softPullScoreOnly" />
                        </Button>
                    </Grid>
                    <Grid css={gridButtonStyle}>
                        <Button
                            data-testid={'pullCreditReports-soft'}
                            type="submit"
                            form="pull-credit"
                            onClick={handlePullSoft}
                            size="large"
                            variant={'ghost'}
                            css={pullButtonStyle}
                            disabled={hasRequirementErrors || isSubmitting}
                        >
                            <Trans id="softPull" />
                        </Button>
                    </Grid>
                </>
            )}
            {hardCreditPull && (
                <Grid css={gridButtonStyle}>
                    <Button
                        data-testid={'pullCreditReports-hard'}
                        type="submit"
                        form="pull-credit"
                        onClick={handlePullHard}
                        size="large"
                        variant={'primary'}
                        css={pullButtonStyle}
                        disabled={
                            hasRequirementErrors ||
                            isSubmitting ||
                            !pullCreditConsent
                        }
                    >
                        <Trans id="hardPull" />
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};
