import { selectorFamily } from 'recoil';

import { client as apiClient } from 'libs/api';
import { Referral } from 'types/referral';

export const getReferralByApplicationId = selectorFamily({
    key: 'GetReferralsQuery',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async () => {
            try {
                const { data } = await apiClient.getReferrals(applicationId);

                return data;
            } catch (error) {
                // At the moment we need to rely on the 404 error to determine if the referral exists
                // send an empty tenant object if the referral does not exist
                return {
                    id: undefined,
                    mainApplicantId: undefined,
                    applicants: [],
                    closingAuthority: '',
                    contacts: [],
                    externalData: {},
                    productsDiscussed: [],
                    rateDiscountType: '',
                    tenantReferralId: '',
                    time: undefined,
                    updated: undefined,
                } as unknown as Referral;
            }
        },
});

export const getReferralMainApplicant = selectorFamily({
    key: 'GetReferralMainApplicant',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const referral = get(getReferralByApplicationId({ applicationId }));

            return referral.applicants.find(
                (applicant) =>
                    applicant.applicantId === referral.mainApplicantId
            );
        },
});

export const getReferralReferrer = selectorFamily({
    key: 'GetReferralReferrer',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const referral = get(getReferralByApplicationId({ applicationId }));

            return referral.contacts.find(
                (contact) => contact.contactType === 'referrer'
            );
        },
});

export const getReferralOtherContacts = selectorFamily({
    key: 'GetReferralOtherContacts',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const referral = get(getReferralByApplicationId({ applicationId }));

            return referral.contacts.filter(
                (contact) => contact.contactType === 'other'
            );
        },
});

export const getReferralConsultantContacts = selectorFamily({
    key: 'GetReferralConsultantContacts',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const referral = get(getReferralByApplicationId({ applicationId }));

            return referral.contacts.filter(
                (contact) => contact.contactType === 'consultant'
            );
        },
});
