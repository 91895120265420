import React from 'react';

import { GdsTdsFields } from './gds-tds-fields';

import type { SectionKeys } from 'types/application';

type GDSProps = {
    handleOnClick: (section: SectionKeys) => void;
    applicationId: number;
};

export const TDS = ({ handleOnClick, applicationId }: GDSProps) => (
    <GdsTdsFields
        handleOnClick={handleOnClick}
        applicationId={applicationId}
        isTds
    />
);
