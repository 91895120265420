import { useMemo } from 'react';

import { useAuth } from 'providers/auth';

import { BrokerDashboard } from './broker-dashboard';
import { PartnerDashboard } from './partner-dashboard';

export const UserDashboard = () => {
    const { token, getRole } = useAuth();

    const role = useMemo(() => getRole(), [getRole, token]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        {
            broker: <BrokerDashboard />,
            realtor: <PartnerDashboard />,
            financialadvisor: <PartnerDashboard />,
        }[role] || null
    );
};
