import { useTenantFlags } from '@nestoca/multi-tenant';

/**
 * Gets the required field via feature flags for the given applicant type and operation.
 *
 * The following feature flags are used:
 * - Applicant create: https://tenant-admin.staging.nesto.ca/feature-flags/required-create-applicant-fields
 * - Applicant edit: https://tenant-admin.staging.nesto.ca/feature-flags/required-edit-applicant-fields
 * - Co-applicant create: https://tenant-admin.staging.nesto.ca/feature-flags/required-create-co-applicant-fields
 * - Co-applicant edit: https://tenant-admin.staging.nesto.ca/feature-flags/required-edit-co-applicant-fields
 *
 * @param isMainApplicant Is this the main applicant or a co-applicant?
 * @param isCreating Are we creating a new applicant or editing an existing one?
 * @returns An array of field names that are required for the given applicant type and operation.
 */
export const useRequiredApplicantFields = (
    isMainApplicant: boolean,
    isCreating: boolean
): string[] => {
    const {
        requiredCreateApplicantFields,
        requiredEditApplicantFields,
        requiredCreateCoApplicantFields,
        requiredEditCoApplicantFields,
    } = useTenantFlags();

    if (isMainApplicant) {
        return isCreating
            ? requiredCreateApplicantFields
            : requiredEditApplicantFields;
    } else {
        return isCreating
            ? requiredCreateCoApplicantFields
            : requiredEditCoApplicantFields;
    }
};
