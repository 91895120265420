import React, { useMemo } from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { Flex } from 'reflexbox';

import { Card } from 'components/card/new-card';
import { Divider } from 'components/divider/divider';
import OverlaySpinner from 'components/loader/overlay-spinner';

import Payment from './payment';

import type { AmountFrequency } from 'types';

type Props = {
    payments?: AmountFrequency[];
    isLoading: boolean;
};

const FrequencyCompareDetails = ({ payments = [], isLoading }: Props) => {
    const content = useMemo(() => {
        return (
            <>
                {isLoading && <OverlaySpinner />}
                {payments.map(({ amount, frequency }, index) => (
                    <Flex
                        key={`${amount}-${frequency}`}
                        flexDirection="column"
                        width="100%"
                    >
                        <Payment amount={amount} frequency={frequency} />

                        {index < payments.length - 1 && (
                            <Divider variant="dottedGray" />
                        )}
                    </Flex>
                ))}
            </>
        );
    }, [isLoading, payments]);

    return (
        <Card
            css={css({
                flexDirection: 'column',
                width: '100%',
                boxShadow: ['md', '2xl'],
                padding: 40,
                height: '100%',
            })}
        >
            <Flex flexDirection="column" alignItems="center">
                {!payments.length && !isLoading && (
                    <h1 css={{ marginBottom: 20 }}>
                        <Trans id="frequencyCompare.noPayments" />
                    </h1>
                )}

                {content}
            </Flex>
        </Card>
    );
};

export default FrequencyCompareDetails;
