import { I18n } from '@lingui/core';
import { ChipProps } from '@nestoca/ui';
import { AxiosError } from 'axios';

import { PLAID_MONITORING_ERROR_MESSAGE } from './constants';
import {
    PLAID_MONITORING_WATCHLIST_CODE,
    PlaidMonitoringWatchlistCode,
    PlaidMonitoringErrorData,
    PlaidMonitoringScreeningStatus,
} from './types';

/**
 * This function returns a map of watchlist codes to their names
 *
 * @param i18n I18n instance
 * @returns Record<PlaidMonitoringWatchlistCode, string>
 */
export const getPlaidMonitoringWatchlistName = (
    i18n: I18n
): Record<PlaidMonitoringWatchlistCode, string> => {
    const watchlistNames = {} as Record<PlaidMonitoringWatchlistCode, string>;

    for (const code in PLAID_MONITORING_WATCHLIST_CODE) {
        watchlistNames[
            PLAID_MONITORING_WATCHLIST_CODE[
                code as keyof typeof PLAID_MONITORING_WATCHLIST_CODE
            ]
        ] = i18n._(`plaidMonitoring.watchlistCode.${code}`);
    }

    return watchlistNames;
};

/**
 * This function returns the error message for the given plaid monitoring error
 *
 * @param i18n I18n instance
 * @param error AxiosError
 * @returns string
 */
export const getPlaidMonitoringErrorMessage = (
    i18n: I18n,
    error: AxiosError<PlaidMonitoringErrorData>
) => {
    const errorData = error.response.data;

    const errorStatus = error.response.status;

    const errorMessageKey =
        PLAID_MONITORING_ERROR_MESSAGE[errorStatus] ||
        PLAID_MONITORING_ERROR_MESSAGE.default;

    return i18n._({
        id: errorMessageKey,
        values: errorData.description
            ? { description: errorData.description }
            : undefined,
    });
};

/**
 * This function returns the chip variant for the given plaid monitoring screening status
 * @param status Plaid monitoring screening status
 * @returns The chip variant for the given plaid monitoring screening status
 */
export const getChipVariantForPlaidMonitoringStatus = (
    status: PlaidMonitoringScreeningStatus
): ChipProps['variant'] => {
    const plaidMonitoringStatusToChipVariantMap: Record<
        PlaidMonitoringScreeningStatus,
        ChipProps['variant']
    > = {
        CLEARED: 'success',
        PENDING_REVIEW: 'warning',
        REJECTED: 'error',
    };

    return plaidMonitoringStatusToChipVariantMap[status] || 'default';
};
