import type { Product } from 'types/product-selection';

export const paymentFrequencyProductTitle = {
    WEEKLY: 'frequency.wkly',
    WEEKLY_ACCELERATED: 'frequency.wklyAcc',
    BI_WEEKLY: 'frequency.bw',
    BI_WEEKLY_ACCELERATED: 'frequency.bwAcc',
    SEMI_MONTHLY: 'frequency.sm',
    MONTHLY: 'frequency.mo',
};

export const paymentFrequencyProduct = (
    product: Product,
    selectedProduct?: Product
) => {
    const deltaPenalty = selectedProduct
        ? Math.floor(product.interestPaymentAmountOverTerm) -
          Math.floor(selectedProduct.interestPaymentAmountOverTerm)
        : undefined;

    return {
        ...product,
        active: selectedProduct
            ? product.stepProductId === selectedProduct.stepProductId
            : false,
        notesLabel: 'recommendationNoteYears',
        recommendation: product.suggested,
        title: paymentFrequencyProductTitle[product.stepProductId],
        titleValues: { years: product.stepProductId },
        insuranceValue: 0,
        penaltyValue: product.interestPaymentAmountOverTerm || undefined,
        deltaPenaltyValue:
            selectedProduct &&
            product.stepProductId !== selectedProduct.stepProductId
                ? deltaPenalty || undefined // `0` value will return undefined and not show delta
                : undefined,
    };
};
