import React, { forwardRef } from 'react';

import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { variant } from 'styled-system';

import type { BoxProps } from 'reflexbox/styled-components';
import type { ResponsiveValue, BorderProps } from 'styled-system';
import type { Assign } from 'types/components';

interface DividerOwnProps extends BoxProps, BorderProps {
    // As prop is defined into BoxProps
    // as?: React.ElementType;
    variant?: ResponsiveValue<Variant>;
}

export type DividerProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    DividerOwnProps
>;

type Variant = 'primary' | 'secondary' | 'dotted' | 'dottedGray' | 'dashboard';

export const DividerStyled = styled(Box)`
    ${variant({
        scale: 'dividers',
        prop: 'variant',
    })}
`;

type DividerType = BoxProps & DividerProps;

export const Divider = forwardRef(
    (
        { variant = 'primary', ...props }: DividerType,
        ref: React.Ref<HTMLDivElement>
    ) => <DividerStyled variant={variant} ref={ref} {...props} />
);
