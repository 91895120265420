import React from 'react';

import { Divider, Grid } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import {
    PROPERTY_PURPOSE_OPTIONS,
    ADDRESS_SITUATION_OPTIONS,
} from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getPropertySelectors,
    getApplicantCurrentAddress,
} from 'store/applications';
import { SubjectProperty } from 'types/property';
import { formatAddress } from 'utils';
import { formatDate } from 'utils/property';

import styles from './property-card.module.scss';

type Props = {
    applicationId: number;
    property: SubjectProperty;
};

export const PropertyInfo = ({ applicationId, property }: Props) => {
    const { i18n } = useI18n();

    const { mainApplicant, ownedProperties } =
        useRecoilValue(getPropertySelectors);

    const currentAddress = useRecoilValue(
        getApplicantCurrentAddress({
            applicationId,
            applicantId: mainApplicant.applicantId,
        })
    );

    return (
        <>
            <CellData
                fieldType="select"
                tx="addressSituation"
                value={currentAddress?.situation}
                options={ADDRESS_SITUATION_OPTIONS(i18n)}
                className={styles.property_info}
            />
            <Divider />
            <Grid className={styles.property_info_grid}>
                <CellData
                    gridArea="purpose"
                    fieldType="select"
                    tx="purpose"
                    value={property.purpose}
                    options={PROPERTY_PURPOSE_OPTIONS(i18n)}
                />
                <CellData
                    gridArea="acceptanceDate"
                    tx="acceptanceDate"
                    value={formatDate(property.acceptanceDate, i18n.locale)}
                />
                <CellData
                    gridArea="purchasePrice"
                    fieldType="money"
                    tx="purchasePrice"
                    value={property.purchasePrice}
                />
                <CellData
                    gridArea="estimatedPropertyValue"
                    fieldType="money"
                    tx="estimatedPropertyValue"
                    value={property.estimatedPropertyValue}
                />
                <CellData
                    gridArea="address"
                    tx="address"
                    value={formatAddress(property.address)}
                />
            </Grid>
            <Divider />
            <CellData
                tx="ownedProperties"
                value={ownedProperties.length}
                className={styles.property_info}
            />
        </>
    );
};
