import formatISO from 'date-fns/formatISO';
import { trim, evolve, pipe } from 'ramda';

import { RegisteredAddress } from 'types/address';
import { ApplicantInfo } from 'types/applicant';
import { booleanNormalizer, numberNormalizer } from 'utils';

export const normalizeAddressInfo = (applicant) => {
    const {
        isCurrentAddress,
        occupiedYears,
        occupiedMonths,
        situation,
        rent,
        address,
        ...otherData
    } = applicant;

    const addressInfo: RegisteredAddress = {
        isCurrentAddress: booleanNormalizer(isCurrentAddress),
        occupiedYears: numberNormalizer(occupiedYears),
        occupiedMonths: numberNormalizer(occupiedMonths),
        situation,
        address,
    };

    if (situation === 'RENTING') {
        addressInfo.rent = rent;
    }

    return {
        ...otherData,
        addresses: [addressInfo],
    };
};

export const normalizeApplicantInfo = (values: ApplicantInfo) => {
    /*
        primaryBankingInstitutionOther needs to be cleared when primaryBankingInstitution !== OTHER to serialize with the backend
        https://nestoca.atlassian.net/browse/OG-6554

        Backend Applications validation:
        https://github.com/nestoca/applications/blob/5ee0219d4e4dbe02a3b84f5ea5a594dd052d36f8/api/validator_tenant.go#L159-L166
    */
    const normalizedValues = {
        ...values,
        primaryBankingInstitutionOther:
            values?.primaryBankingInstitution === 'OTHER'
                ? values?.primaryBankingInstitutionOther
                : null,
    };

    return evolve(
        {
            email: trim,
            firstName: trim,
            lastName: trim,
            phone: trim,
            numberOfDependents: numberNormalizer,
            dateOfBirth: (date: string | Date) => {
                const isDate = date instanceof Date;

                return isDate
                    ? formatISO(date as Date, { representation: 'date' })
                    : (date as string);
            },
        },
        normalizedValues
    );
};

export const normalizeApplicantInfoWithAddress = pipe(
    normalizeApplicantInfo,
    normalizeAddressInfo
);
