import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import styles from 'components/employment/section/section.module.scss';
import { IncludeCheckboxWithTooltip } from 'components/popper/include-checkbox-with-tooltip';
import { FREQUENCY, INCOME_TYPE, PENSION_TYPE } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { IncomeEmployment, IncomeIncludedFieldName } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import { EmploymentTypeSectionProps } from './employment-type';

const useConditionalFields = (employment: IncomeEmployment) => {
    const { watch } = useFormContext() || {};

    let pensionTypeWatch = employment.pensionType ?? '';

    let employedMonthsWatch = employment.employedMonths;

    let employedYearsWatch = employment.employedYears;

    if (watch) {
        pensionTypeWatch = watch('pensionType');

        employedMonthsWatch = watch('employedMonths');

        employedYearsWatch = watch('employedYears');
    }

    const showEmployerName = pensionTypeWatch === 'EMPLOYER';

    const showDescription = pensionTypeWatch === 'OTHER';

    return {
        employedMonthsWatch,
        employedYearsWatch,
        showEmployerName,
        showDescription,
    };
};

export const Pension = ({
    isCreating,
    isEditing,
    employment,
    partialSchema,
    onToggleIncluded,
}: EmploymentTypeSectionProps) => {
    const { i18n } = useI18n();

    const hasEditingRights = useEditingRights();

    const {
        employedMonths,
        employedYears,
        employer,
        incomeOverrideIncluded,
        incomeType,
        isCurrent,
        pensionType,
        salary,
        totalIncome,
    } = employment;

    const {
        employedMonthsWatch,
        employedYearsWatch,
        showEmployerName,
        showDescription,
    } = useConditionalFields(employment);

    const handleToggleIncluded = (
        checked: boolean,
        fieldName: IncomeIncludedFieldName
    ) => {
        onToggleIncluded?.(checked, fieldName);
    };

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('pensionDetails')}
            </Typography>
            <Grid
                className={
                    isEditing ? styles['editing-incomes'] : styles.incomes
                }
                gap={4}
            >
                {!isCreating && (
                    <EditableCell
                        isEditing={isEditing}
                        name="incomeType"
                        label="Income Type"
                        fieldType="readonly"
                        options={INCOME_TYPE(i18n)}
                        value={incomeType}
                        isFieldInvalidCompareFn={compareForId(employment)}
                    />
                )}
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="isCurrent"
                    label="current"
                    fieldType="boolean"
                    value={!!isCurrent}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="pensionType"
                    label="pensionType"
                    fieldType="select"
                    options={PENSION_TYPE(i18n)}
                    value={pensionType}
                    isFieldInvalidCompareFn={compareForId(employment)}
                    css={{ gridColumn: isEditing && '1 / -1' }}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salary.base.amount"
                    label="income"
                    fieldType="money"
                    value={salary?.base?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salary.base.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={salary?.base?.frequency}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {!isCreating && !isEditing && hasEditingRights && (
                    <IncludeCheckboxWithTooltip
                        shouldShowTooltip={!isCurrent && !!totalIncome}
                        handleToggleIncluded={handleToggleIncluded}
                        checked={salary?.baseRatiosIncluded && isCurrent}
                        disabled={
                            !totalIncome || incomeOverrideIncluded || !isCurrent
                        }
                        fieldName="baseRatiosIncluded"
                    />
                )}
                <EditableCell
                    required={
                        !partialSchema &&
                        !employedMonthsWatch &&
                        employedMonthsWatch !== 0
                    }
                    isEditing={isEditing}
                    name="employedYears"
                    label="employedYears"
                    fieldType="number"
                    integer
                    value={employedYears}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !partialSchema &&
                        !employedYearsWatch &&
                        employedYearsWatch !== 0
                    }
                    isEditing={isEditing}
                    name="employedMonths"
                    label="employedMonths"
                    fieldType="number"
                    integer
                    value={employedMonths}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {/**
                 * WARNING:
                 *
                 * This may seem really weird to have description fields to be using `employer.name`
                 * but it is how it is done in the website and backend for a while now.
                 * Hope this will be refactor one day for `employment.description` because it does not make sense
                 *  */}
                {(showEmployerName || showDescription) && (
                    <EditableCell
                        required
                        isEditing={isEditing}
                        name="employer.name"
                        label={showDescription ? 'description' : 'employer'}
                        value={employer?.name}
                        isFieldInvalidCompareFn={compareForId(employment)}
                    />
                )}
            </Grid>
        </Flex>
    );
};
