import { Card, Flex, Typography } from '@nestoca/ui';
import Image from 'next/image';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { ScrollArea } from 'components/scroll-area';
import { SEGMENT_LABEL } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { Segment } from 'types/application';

import styles from './canada-life-card.module.scss';

type CanadaLifeCardProps = {
    segment: Segment;
};

export const CanadaLifeCard = ({ segment }: CanadaLifeCardProps) => {
    const { i18n } = useI18n();

    return (
        <Card className={styles['canada-life-card']}>
            <Card.Header className={styles['canada-life-card__header']}>
                <Image
                    src={
                        i18n.locale === 'en'
                            ? '/canada-life-logo-en.svg'
                            : '/canada-life-logo-fr.svg'
                    }
                    alt={i18n.locale === 'en' ? 'Canada Life' : 'Canada Vie'}
                    width={95}
                    height={36}
                />
                <Typography weight={7} textColor="var(--color-black)">
                    {i18n._('product')}
                </Typography>
            </Card.Header>
            <ScrollArea className={styles['scroll-area']}>
                <Flex
                    className={styles['canada-life-card__body']}
                    direction="column"
                    gap={1}
                >
                    <CellData
                        tx="segment"
                        value={SEGMENT_LABEL(i18n)[segment]}
                    />
                </Flex>
            </ScrollArea>
        </Card>
    );
};
