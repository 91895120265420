import React from 'react';

import css from '@styled-system/css';
import { useRecoilValue } from 'recoil';
import { Box, Flex } from 'reflexbox/styled-components';

import { Card } from 'components/card/new-card';
import { Grid } from 'components/grid/grid';
import { LenderHeader } from 'components/rate-tile/lender-header';
import { LenderTable } from 'components/rate-tile/lender-table';
import { Text } from 'components/text/text';
import { getValidBestProducts } from 'store/applications';
import { formatDateTime } from 'utils';
import { isEmpty } from 'utils';

import type { Product } from 'types/product';

const ProductCard = ({ product }: { product: Product }) => {
    const {
        lenderId,
        name,
        term,
        type,
        ltvMin,
        ltvMax,
        bestRate,
        rateHold,
        created,
        updated,
        mortgageAmountMin,
    } = product;

    return (
        <Card
            css={css({
                flexDirection: 'column',
                width: '100%',
                boxShadow: ['md', '2xl'],
                height: '100%',
            })}
        >
            <Box height={90}>
                <LenderHeader lenderId={lenderId} productName={name} />
            </Box>
            <LenderTable
                term={term}
                type={type}
                ltvMin={ltvMin}
                ltvMax={ltvMax}
                rate={bestRate}
                rateHold={rateHold}
                mortgageAmountMin={mortgageAmountMin}
            />
            <Flex
                css={css({
                    paddingTop: '20px',
                    flexDirection: 'column',
                })}
            >
                <Text
                    css={css({
                        fontSize: 0,
                        color: 'gray-2',
                        marginBottom: 1,
                    })}
                    tx="Created"
                >
                    {`: ${formatDateTime(new Date(created))}`}
                </Text>
                <Text
                    css={css({ fontSize: 0, color: 'gray-2' })}
                    tx="Last updated"
                >
                    {`: ${formatDateTime(new Date(updated))}`}
                </Text>
            </Flex>
        </Card>
    );
};

const Title = ({ tx }: { tx: string }) => (
    <Text
        tx={tx}
        css={css({
            fontWeight: 600,
            fontSize: 3,
            textAlign: 'center',
            color: 'neutral-3',
        })}
    />
);

const NoProductCard = ({
    title,
    text,
    gridAreaTitle,
    gridAreaText,
}: {
    title: string;
    text: string;
    gridAreaTitle: string;
    gridAreaText: string;
}) => (
    <>
        <Grid gridArea={gridAreaTitle}>
            <Title tx={title} />
        </Grid>
        <Card
            css={css({
                flexDirection: 'column',
                width: '100%',
                boxShadow: ['md', '2xl'],
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
            })}
        >
            <Grid gridArea={gridAreaText} gridGap={10}>
                <Text
                    tx={text}
                    css={css({
                        fontWeight: 600,
                        fontSize: 1,
                        textAlign: 'center',
                        color: 'portGore',
                    })}
                />
            </Grid>
        </Card>
    </>
);

export const BestProducts = ({ applicationId }: { applicationId: number }) => {
    const bestProducts = useRecoilValue(getValidBestProducts(applicationId));
    const { standardFixed, restrictedFixed, variable } = bestProducts;

    if (isEmpty(bestProducts)) {
        return null;
    }

    return (
        <Box
            css={css({
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: -50,
            })}
        >
            <Card
                css={css({
                    flexDirection: 'column',
                    width: '100%',
                    boxShadow: ['md', '2xl'],
                    marginTop: 4,
                    marginBottom: 3,
                    height: '100%',
                })}
            >
                <Grid
                    gridTemplateColumns="repeat(3, 1fr)"
                    gridAutoFlow="row"
                    gridGap={10}
                    gridTemplateAreas={`
                        "bestStandardTitle bestRestrictedTitle bestVariableTitle"
                        "bestStandardProduct bestRestrictedProduct bestVariableProduct"
                    `}
                >
                    {standardFixed ? (
                        <>
                            <Grid gridArea="bestStandardTitle">
                                <Title tx="bestStandard" />
                            </Grid>
                            <Grid gridArea="bestStandardProduct">
                                <ProductCard product={standardFixed} />
                            </Grid>
                        </>
                    ) : (
                        <NoProductCard
                            title="bestStandard"
                            text="noRestrictedProductText"
                            gridAreaTitle="bestStandardTitle"
                            gridAreaText="bestStandardProduct"
                        />
                    )}

                    {restrictedFixed ? (
                        <>
                            <Grid gridArea="bestRestrictedTitle">
                                <Title tx="bestRestricted" />
                            </Grid>
                            <Grid gridArea="bestRestrictedProduct" gridGap={10}>
                                <ProductCard product={restrictedFixed} />
                            </Grid>
                        </>
                    ) : (
                        <NoProductCard
                            title="bestRestricted"
                            text="noBestRestrictedProductText"
                            gridAreaTitle="bestRestrictedTitle"
                            gridAreaText="bestRestrictedProduct"
                        />
                    )}

                    {variable ? (
                        <>
                            <Grid gridArea="bestVariableTitle">
                                <Title tx="bestVariable" />
                            </Grid>
                            <Grid gridArea="bestVariableProduct" gridGap={10}>
                                <ProductCard product={variable} />
                            </Grid>
                        </>
                    ) : (
                        <NoProductCard
                            title="bestVariable"
                            text="noBestVariableProductText"
                            gridAreaTitle="bestVariableTitle"
                            gridAreaText="bestVariableProduct"
                        />
                    )}
                </Grid>
            </Card>
        </Box>
    );
};
