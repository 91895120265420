import * as React from 'react';

import * as ReactIs from 'react-is';
import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { space, width } from 'styled-system';

import type { WidthProps, SpaceProps } from 'styled-system';

type Props = {
    children: any;
    id?: string;
};

type StyledProps = WidthProps & SpaceProps;
type BoxProps = {
    bothActions: boolean;
};

export type CardActionsProps = Props & StyledProps;

const Actions = styled(Flex)<BoxProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    transform: translateY(50%);
    justify-content: flex-end;
    align-items: flex-end;

    @media (max-width: ${({ theme }) => theme.global.breakpoints.small.value}) {
        ${(props: any) => props.bothActions && `transform: translateY(25%);`};
        flex-direction: column;

        > * {
            margin: ${({ theme }) => theme.global.size.xsmall} 0;
        }
    }

    margin-top: 6px;

    > * {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }

    ${space}
    ${width}
`;

const hasMoreThanOneAction = (children: any) => {
    const isFragment = ReactIs.isFragment(children);
    if (isFragment) {
        if (
            children.props?.children &&
            Array.isArray(children.props.children) &&
            children.props.children.length >= 2
        ) {
            return true;
        }

        return false;
    }

    if (children && Array.isArray(children) && children.length >= 2) {
        return true;
    }
    return false;
};

export const CardActions = ({
    id,
    children,
    ...props
}: Props & StyledProps) => (
    <Actions
        data-testid={`cardActions_${id || ''}`}
        width="100%"
        bothActions={hasMoreThanOneAction(children)}
        {...props}
    >
        {children}
    </Actions>
);
