import { Card, Divider, Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { useI18n } from 'providers/i18n/use-i18n';
import { getReferralReferrer } from 'store/referrals';

import dashBoardStyles from '../broker-dashboard.module.scss';

import styles from './referrer-contact.module.scss';

type ReferrerContactProps = {
    applicationId: number;
};

export const ReferrerContact = ({ applicationId }: ReferrerContactProps) => {
    const { i18n } = useI18n();
    const referrerContact = useRecoilValue(
        getReferralReferrer({ applicationId })
    );

    return (
        <Card
            className={cn(
                styles['referrer-contact__card'],
                dashBoardStyles.card
            )}
            title={'asset.property'}
        >
            <Flex
                className={dashBoardStyles.header}
                align="center"
                justify="between"
                gap={4}
                as="header"
            >
                <Flex align="center" gap={2}>
                    <BsArrowCounterclockwise size={30} />

                    <Typography weight={7} size={0}>
                        {i18n._('referral.referrerInfo')}
                    </Typography>
                </Flex>
            </Flex>
            <Flex className={styles.body} direction="column">
                <Grid className={styles.grid}>
                    <CellData
                        tx={'First Name'}
                        value={referrerContact?.firstName || ''}
                    />
                    <CellData
                        tx={'Last Name'}
                        value={referrerContact?.lastName || ''}
                    />
                    <CellData
                        tx={'email'}
                        value={referrerContact?.email || ''}
                        withCopy
                    />
                    <CellData
                        tx={'Phone'}
                        value={referrerContact?.phone || ''}
                    />
                </Grid>
                <Divider />
                <Grid className={styles.grid}>
                    <CellData
                        tx={'referral.department'}
                        value={referrerContact?.externalData?.department || ''}
                    />
                    <CellData
                        tx={'referral.id'}
                        value={referrerContact?.externalData?.id || ''}
                    />
                    <CellData
                        tx={'referral.title'}
                        value={referrerContact?.externalData?.title || ''}
                    />
                    <CellData
                        tx={'referral.username'}
                        value={referrerContact?.externalData?.username || ''}
                    />
                </Grid>
            </Flex>
        </Card>
    );
};
