import React from 'react';

import css from '@styled-system/css';
import { useFormContext } from 'react-hook-form';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { EditableCell } from 'components/editable';
import { Grid } from 'components/grid/grid';
import { LANGUAGE } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { formatAddress } from 'utils/address';
import { useIsTenant } from 'utils/useIsTenant';

import { PullCreditFormData } from './pull-credit-modal';

import type { RegisteredAddress } from 'types/address';
import type { Applicant } from 'types/applicant';

type PullCreditFormProps = {
    applicant: Applicant;
    currentAddress: RegisteredAddress;
};

export const PullCreditForm = ({
    applicant,
    currentAddress,
}: PullCreditFormProps) => {
    const { i18n } = useI18n();
    const {
        formState: { errors },
    } = useFormContext<PullCreditFormData>();
    const langOptions = LANGUAGE(i18n);
    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    return (
        <Grid
            gridArea="content"
            css={css({
                justifyContent: 'center',
                margin: '0 auto',
                marginTop: 5,
                px: 3,
            })}
            gridGap={10}
            gridTemplateColumns={'repeat(4, 1fr)'}
        >
            <CellData
                fieldType="text"
                tx={'applicant'}
                value={`${applicant.firstName} ${applicant.lastName}`}
                mr={3}
                pr={1}
            />
            <CellData
                fieldType="phone"
                tx={'phoneNumber'}
                value={`${applicant.phone}`}
                mr={3}
                pr={1}
            />
            <CellData
                fieldType="date"
                tx={'Date Of Birth'}
                value={applicant.dateOfBirth}
            />
            <CellData
                fieldType="text"
                tx={'currentAddress'}
                value={
                    currentAddress?.isCurrentAddress
                        ? formatAddress(currentAddress?.address)
                        : ''
                }
            />
            <CellData tx={'socialInsuranceNumber'} variant="primary">
                {/* allow editing if nesto or if no sin number, no autopopulation with nesto as its not applicable  */}
                <EditableCell
                    isEditing={!applicant.sin || !isIGTenant}
                    data-testid="SIN"
                    fieldType="text"
                    placeholder={i18n._('SIN')}
                    name="socialInsuranceNumber"
                    error={errors.socialInsuranceNumber}
                    value={isIGTenant ? applicant.sin : ''}
                    autoComplete="off"
                />
            </CellData>
            <CellData tx={'Language'} variant="primary">
                <EditableCell
                    isEditing
                    data-testid="language"
                    fieldType="select"
                    placeholder={i18n._('language')}
                    name="language"
                    options={langOptions}
                    value={i18n.locale}
                />
            </CellData>
        </Grid>
    );
};
