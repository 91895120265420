import React, { useCallback, useImperativeHandle, useMemo } from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { Link } from '@tiptap/extension-link';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Save } from 'grommet-icons';
import { useFormContext } from 'react-hook-form';
import { Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';
import { Spinner } from 'components/spinner';

import ApplicantSelector from './applicants-selector/applicant-selector';
import MarkupButtons from './markup-buttons/markup-buttons';
import styles from './Tiptap.module.scss';

import type { NodeType } from 'types/notes';
import type { NotesStateTypes } from 'types/notes';

interface TiptapProps {
    applicationId: number;
    noteState: NotesStateTypes;
}

export const Tiptap = React.forwardRef(
    ({ noteState, applicationId }: TiptapProps, ref) => {
        const editor = useEditor({
            /**
             * StartKit contains a lot of the markup tools used in the editor
             * @see https://tiptap.dev/api/extensions/starter-kit
             */
            extensions: [StarterKit, Link],
            editorProps: {
                attributes: {
                    class: styles.container,
                },
            },
            content: '',
        });

        const {
            formState: { isSubmitting },
            setValue,
        } = useFormContext();

        const handleSubmit = useCallback(() => {
            setValue('text', editor.getText());
        }, [editor, setValue]);

        /**
         * This is used to allow parent components to call methods on this component
         * Since the form state is handled by the Tiptap lib, we have to resort to this
         * to perform manual updates.
         *
         * To use this, assign a ref to the Tiptap component inside your parent
         * and call ref.current.yourMethod() as needed.
         */
        useImperativeHandle(ref, () => ({
            clearContent() {
                editor.commands.clearContent();
            },
        }));

        const { section, noteType, nodeType, applicantId } = noteState;
        const displayApplicantSelector = useMemo(() => {
            const acceptedNodeTypes: NodeType[] = ['underwriter', 'advisor'];
            return (
                applicationId &&
                    section !== 'property' &&
                    acceptedNodeTypes.includes(nodeType) &&
                    noteType !== 'submission',
                [applicationId, nodeType, noteType, section]
            );
        }, [applicationId, nodeType, noteType, section]);

        const IconRight = useMemo(
            () =>
                isSubmitting ? (
                    <Spinner size={16} />
                ) : (
                    <Save size="1em" color="currentColor" />
                ),
            [isSubmitting]
        );

        return (
            <Flex flexDirection="column" justifyContent="center">
                {displayApplicantSelector && (
                    <ApplicantSelector
                        applicationId={applicationId}
                        isEditing
                        applicantId={applicantId}
                    />
                )}
                <MarkupButtons editor={editor} />
                <EditorContent editor={editor} />
                <Button
                    type="submit"
                    variant="primary"
                    iconRight={IconRight}
                    onClick={handleSubmit}
                    css={css({
                        width: 200,
                        marginTop: 3,
                        marginBottom: 2,
                        justifyContent: 'center',
                        alignSelf: 'center',
                    })}
                >
                    <Trans id="save" />
                </Button>
            </Flex>
        );
    }
);

export default Tiptap;
