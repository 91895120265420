import * as yup from 'yup';

import type { I18n } from '@lingui/core';

export const booleanRequired = (
    i18n: I18n,
    message?: string,
    values?: Record<string, any>
) =>
    yup
        .boolean()
        .required(i18n._({ id: message || 'error.fieldRequired', values }));
