import { memo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Button,
    Heading,
    FormElement,
    Input,
    InputGroup,
    InputAddonLeft,
    PhoneInput,
    ErrorMessage,
    Flex,
    Grid,
    Typography,
} from '@nestoca/ui';
import { useForm, Controller } from 'react-hook-form';
import { BsPhone } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { ConnectAccountsModalProps } from 'components/account-merge/connect-accounts-modal';
import { CoApplicantSearchFormValues } from 'components/account-merge/types';
import { ApplicantModalProps } from 'components/applicant/applicant-modal';
import { ScrollArea } from 'components/scroll-area';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { currentApplicationIdState } from 'store/applications';
import { getCoApplicantSearchSchema } from 'validations/co-applicant-search';

import styles from './co-applicant-search-modal.module.scss';

export const CoApplicantSearchModal = memo(() => {
    const { i18n } = useI18n();

    const applicationId = useRecoilValue(currentApplicationIdState);

    const defaultValues: CoApplicantSearchFormValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues,
        resolver: yupResolver(getCoApplicantSearchSchema(i18n)),
    });

    const { close: closeCoApplicantSearchModal } = useModal(
        'co-applicant-search-modal'
    );

    const { open: openConnectAccountsModal } =
        useModal<ConnectAccountsModalProps>('connect-accounts-modal');

    const { open: openCreateCoApplicantModal } =
        useModal<ApplicantModalProps>('createApplicant');

    const onCancel = () => {
        closeCoApplicantSearchModal();
    };

    const onSubmit = async (formValues: CoApplicantSearchFormValues) => {
        const { phone, ...rest } = formValues;

        const normalizedPayload = {
            ...rest,
            // Remove the +1 from the phone number
            phone: phone.replace('+1', ''),
        };

        try {
            const { data } = await apiClient.coApplicantSearch(
                normalizedPayload,
                { limit: 25 }
            );

            closeCoApplicantSearchModal();

            /* Open the connect accounts modal if results are found
               If no result found it triggers a 404 error */
            openConnectAccountsModal({
                populatedSearchFormValues: normalizedPayload,
                coApplicantSearchResponse: data,
            });
        } catch (error) {
            const status = error?.response?.status;

            /* Open the existing co-applicant modal and pre-fill
               the form with the current values. Don't show an error message
               if no accounts found (404) */
            if (status === 404) {
                closeCoApplicantSearchModal();

                openCreateCoApplicantModal({
                    editableKey: 'createCoApplicant',
                    applicationId,
                    applicantId: undefined,
                    prefillInfo: normalizedPayload,
                });

                return;
            }

            toast.error(
                <Typography size={0}>
                    {i18n._(
                        status
                            ? `co-applicant.search.error.${status}`
                            : 'failedToSave'
                    )}
                </Typography>,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    };

    return (
        <Flex
            className={styles['co-applicant-search-modal']}
            direction="column"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            as="form"
        >
            <Box className={styles.header} as="header">
                <Heading className={styles.title} size={5} weight={7}>
                    {i18n._('co-applicant-search-modal.title')}
                </Heading>
            </Box>
            <ScrollArea className={styles['scroll-area']}>
                <Grid className={styles.grid} gap={4}>
                    <FormElement>
                        <Input
                            required
                            isInvalid={!!errors.firstName}
                            placeholder={i18n._('First name')}
                            {...register('firstName')}
                        />
                        <ErrorMessage className={styles['error-message']}>
                            {errors.firstName?.message}
                        </ErrorMessage>
                    </FormElement>
                    <FormElement>
                        <Input
                            required
                            isInvalid={!!errors.lastName}
                            placeholder={i18n._('Last name')}
                            {...register('lastName')}
                        />
                        <ErrorMessage className={styles['error-message']}>
                            {errors.lastName?.message}
                        </ErrorMessage>
                    </FormElement>
                    <FormElement>
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => (
                                <InputGroup>
                                    <InputAddonLeft>
                                        {BsPhone({})}
                                    </InputAddonLeft>
                                    <PhoneInput
                                        placeholder={i18n._('Phone')}
                                        hideCountry
                                        {...field}
                                        // Override onChange to pass the value to the field
                                        onChange={(value) => {
                                            field.onChange(value);
                                        }}
                                    />
                                </InputGroup>
                            )}
                        />
                        <ErrorMessage className={styles['error-message']}>
                            {errors.phone?.message}
                        </ErrorMessage>
                    </FormElement>
                    <FormElement>
                        <InputGroup>
                            <InputAddonLeft>@</InputAddonLeft>
                            <Input
                                type="email"
                                isInvalid={!!errors.email}
                                placeholder={i18n._('email')}
                                {...register('email')}
                            />
                        </InputGroup>
                        <ErrorMessage className={styles['error-message']}>
                            {errors.email?.message}
                        </ErrorMessage>
                    </FormElement>
                </Grid>
            </ScrollArea>
            <Flex
                className={styles.footer}
                align="center"
                justify="end"
                gap={4}
                as="footer"
            >
                <Button variant="ghost" onClick={onCancel}>
                    {i18n._('cancel')}
                </Button>
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                >
                    {i18n._('next')}
                </Button>
            </Flex>
        </Flex>
    );
});

CoApplicantSearchModal.displayName = 'CoApplicantSearchModal';
