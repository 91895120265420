import React from 'react';

import css from '@styled-system/css';

import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';

export type ErrorsModalProps = {
    text: string;
};

export const ErrorsModal = ({ text = 'errorsText' }: any) => {
    return (
        <Grid gridGap={10} gridTemplateColumns="1fr" gridAutoRows="auto">
            <Text
                tx={text}
                css={css({ color: 'status-critical', textAlign: 'center' })}
            />
        </Grid>
    );
};
