import { Chip } from '@nestoca/ui';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { BsFillCheckCircleFill as IconCheck } from 'react-icons/bs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'reflexbox/styled-components';

import { PROBLEM_TYPE_TO_SECTION_KEYS } from 'components/application-review/application-toolbar/constants';
import styles from 'components/dashboard/dashboard-progress-cards.module.scss';
import { ScrollArea } from 'components/scroll-area';
import { Text } from 'components/text/text';
import { ZERO } from 'constants/appConstants';
import { PROBLEMS_SECTION_TITLES } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    applicationProblemsSidebar,
    getProblemsSectionsWithCount,
    selectedApplication,
} from 'store/applications';
import { sectionSelectedState } from 'store/qualification';

import type { ProblemType } from 'constants/problem';

export const Section = ({
    section,
    totalProblems,
}: {
    section: string;
    totalProblems: number;
}) => {
    const { i18n } = useI18n();
    return (
        <Flex
            className={cx({
                [styles.section]: true,
                [styles.complete]: !totalProblems,
            })}
        >
            <Text
                tx={PROBLEMS_SECTION_TITLES(i18n)[section]}
                // eslint-disable-next-line no-magic-numbers
                mr={totalProblems ? 2 : undefined}
            />
            {!totalProblems ? (
                <IconCheck className={styles.checked} />
            ) : (
                <Chip label={`${totalProblems}`} />
            )}
        </Flex>
    );
};

export const Sections = ({ applicationId }: { applicationId: number }) => {
    const router = useRouter();
    const { i18n } = useI18n();
    const sections = useRecoilValue(
        getProblemsSectionsWithCount({ applicationId })
    );
    const application = useRecoilValue(selectedApplication);
    const setSectionSelected = useSetRecoilState(
        sectionSelectedState({ applicationId })
    );
    const [problemsSidebarStatus, setProblemsSidebarStatus] = useRecoilState(
        applicationProblemsSidebar
    );

    const allSections = Object.keys(PROBLEMS_SECTION_TITLES(i18n));

    if (!application.heloc) {
        allSections.splice(allSections.indexOf('helocDetails'), 1);
    }

    const handleSelectSection = (section: string) => {
        setProblemsSidebarStatus({
            ...problemsSidebarStatus,
            isSidebarOpen: true,
            section: section as ProblemType,
        });
        setSectionSelected(PROBLEM_TYPE_TO_SECTION_KEYS[section]);
        router.push(`applications/${application.id}`);
    };

    return (
        <ScrollArea className={styles.scrollArea}>
            <Flex
                flexDirection="column"
                css={{
                    gap: 'var(--spacing-2)',
                    padding: '0 var(--spacing-4) var(--spacing-7)',
                    marginTop: 'calc(var(--spacing-3) + 2px)',
                }}
            >
                {allSections.map((sectionLabel) => (
                    <div
                        key={`section-${sectionLabel}`}
                        onClick={() => handleSelectSection(sectionLabel)}
                    >
                        <Section
                            section={sectionLabel}
                            totalProblems={
                                sections.find(
                                    (sec) => sec.section === sectionLabel
                                )?.totalProblems || ZERO
                            }
                        />
                    </div>
                ))}
            </Flex>
        </ScrollArea>
    );
};
