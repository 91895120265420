import React from 'react';

import css from '@styled-system/css';

import { Frame } from 'components/card/frame';
import { Text } from 'components/text/text';

import type { Props as FrameProps } from 'components/card/frame';

export type Props = {
    title?: string;
} & FrameProps;

export const Card = ({
    preset = 'primary',
    title,
    children,
    ...rest
}: Props) => (
    <Frame preset={preset} {...rest}>
        {title && (
            <Text
                css={css({
                    color: preset === 'primary' ? 'black' : 'white',
                    fontWeight: 'bold',
                    marginBottom: 3,
                })}
            >
                {title}
            </Text>
        )}
        {children}
    </Frame>
);
