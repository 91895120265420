import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@nestoca/ui';
import cn from 'classnames';
import * as R from 'ramda';
import { toast } from 'react-toastify';

import { EditableGrid } from 'components/editable';
import { ModalFormActions } from 'components/modals/modal-form-actions';
import { OtherIncomeFields } from 'components/other-income/other-income-fields';
import { SuccessfulToastContent } from 'components/successful-toast-content';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { useRefreshApplicationById } from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import { useRefreshQualification } from 'store/qualification';
import {
    useRefreshDocumentValidation,
    useRefreshSubmissionNotes,
} from 'store/submission-notes';
import { moneyNormalizer } from 'utils';
import { useEditingContextWithCleanup } from 'utils/use-editing-context';
import { getCreateOtherIncomeSchema } from 'validations/other-income';

import styles from './other-income.module.scss';

import type { IncomeOther } from 'types/applicant';

export type OtherIncomeModalProps = {
    editableKey: string;
    applicationId: number;
    applicantId: number;
};

const otherIncome = { income: { amount: 0 } } as IncomeOther;

type NewOtherIncome = IncomeOther & { applicantId: number };

const normalizeOtherIncome = R.evolve({
    income: {
        amount: moneyNormalizer,
    },
});

export const OtherIncomeModal = ({
    editableKey,
    applicationId,
    applicantId,
}: OtherIncomeModalProps) => {
    const { i18n } = useI18n();
    const { close: closeModal } =
        useModal<OtherIncomeModalProps>('createOtherIncome');

    const refreshApplication = useRefreshApplicationById(applicationId);
    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);
    const refreshDocumentValidation =
        useRefreshDocumentValidation(applicationId);
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const { refresh: refreshQualification } = useRefreshQualification();

    const { editingKey } = useEditingContextWithCleanup(editableKey);

    const isEditing = useMemo(() => editingKey === editableKey, [editingKey]);

    const schema = getCreateOtherIncomeSchema(i18n);

    const onSubmit = async (values: NewOtherIncome) => {
        try {
            const newOtherIncome = R.omit(
                ['applicantId'],
                values
            ) as unknown as NewOtherIncome;

            const normalizedData = normalizeOtherIncome({
                ...otherIncome,
                ...newOtherIncome,
            });

            await apiClient.createApplicantOtherIncome(
                applicationId,
                values.applicantId,
                normalizedData
            );

            await refreshApplication();
            await refreshApplicationDocumentsCounts();
            await refreshQualification(applicationId);
            await refreshSubmissionNotes();
            await refreshDocumentValidation();
            closeModal();

            toast.success(
                <SuccessfulToastContent text={i18n._('otherIncome.created')} />,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        } catch (error) {
            toast.error(
                <Typography size={0} height={1}>
                    {i18n._('failedToSave')}
                </Typography>,
                {
                    theme: 'colored',
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    };

    return (
        <EditableGrid
            id="otherIncome-modal"
            className={cn(styles.grid, styles['grid--modal'])}
            onSubmit={onSubmit}
            resolver={yupResolver(schema)}
            editableKey={editableKey}
            hideToolbar
        >
            <OtherIncomeFields
                isCreating
                isEditing={isEditing}
                applicationId={applicationId}
                applicantId={applicantId}
                otherIncome={otherIncome}
            />
            {isEditing && <ModalFormActions closeModal={closeModal} />}
        </EditableGrid>
    );
};
