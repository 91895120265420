import React, { useCallback } from 'react';

import { Bold, Italic, List, Link, Task } from 'grommet-icons';
import styled from 'styled-components';

import MarkupButton from './markup-button/markup-button';
import { useMarkup } from './useMarkup';

import type { Editor } from '@tiptap/react';

const MarkupButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #ddd;
    height: 25px;
    align-self: center;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
`;

interface ButtonAttributes {
    property:
        | 'bold'
        | 'italic'
        | 'listItem'
        | 'orderedList'
        | 'heading'
        | 'link';
    attributes?: Record<string, unknown>;
}

const MarkupButtons = ({ editor }: { editor: Editor }) => {
    const {
        toggleBold,
        toggleItalic,
        toggleUnorderedList,
        toggleOrderedList,
        toggleH1,
        toggleH2,
        toggleH3,
        setLink,
    } = useMarkup(editor);

    const getClassName = useCallback(
        ({ property, attributes }: ButtonAttributes) =>
            editor?.isActive(property, attributes && attributes)
                ? 'is-active'
                : '',
        [editor]
    );

    return (
        <MarkupButtonContainer>
            <MarkupButton
                onClick={toggleBold}
                className={getClassName({ property: 'bold' })}
            >
                <Bold size="small" fontSize="11" />
            </MarkupButton>
            <MarkupButton
                onClick={toggleItalic}
                className={getClassName({ property: 'italic' })}
            >
                <Italic size="small" />
            </MarkupButton>
            <MarkupButton
                onClick={toggleUnorderedList}
                className={getClassName({ property: 'listItem' })}
            >
                <List size="small" />
            </MarkupButton>
            <MarkupButton
                onClick={toggleOrderedList}
                className={getClassName({ property: 'orderedList' })}
            >
                <Task size="small" />
            </MarkupButton>
            <MarkupButton
                onClick={toggleH1}
                className={getClassName({
                    property: 'heading',
                    attributes: { level: 1 },
                })}
            >
                <span className="h1Button">H1</span>
            </MarkupButton>
            <MarkupButton
                onClick={toggleH2}
                className={getClassName({
                    property: 'heading',
                    attributes: { level: 2 },
                })}
            >
                <span className="h2Button">H2</span>
            </MarkupButton>
            <MarkupButton
                onClick={toggleH3}
                className={getClassName({
                    property: 'heading',
                    attributes: { level: 3 },
                })}
            >
                <span className="h3Button">H3</span>
            </MarkupButton>
            <MarkupButton
                onClick={setLink}
                className={getClassName({ property: 'link' })}
            >
                <Link size="small" />
            </MarkupButton>
        </MarkupButtonContainer>
    );
};

export default MarkupButtons;
