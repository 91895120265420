import { Trans } from '@lingui/react';
import { Box } from '@nestoca/ui';

import { Badge } from 'components/badge/badge';
import { LenderId } from 'constants/lenderConstants';
import { INVESTOR_PRODUCT_ACCENT_COLOR_MAP } from 'types/application';

import styles from './investor-accent.module.scss';

import type { HelocProductType } from 'types/heloc';
import type { Product } from 'types/rates';

const DEFAULT_ACCENT_COLOR = '#DBDBDB';

type InvestorAccentProps = {
    product: HelocProductType | Product;
};

export const InvestorAccent = ({ product }: InvestorAccentProps) => {
    // Only show Investor accent color when lender is nesto
    if (product?.lenderId !== LenderId.NESTO) {
        return null;
    }

    const color = INVESTOR_PRODUCT_ACCENT_COLOR_MAP[product?.investorId];

    return (
        <>
            <Box
                className={styles['investor-accent']}
                style={{
                    borderTop: `10px solid ${color || DEFAULT_ACCENT_COLOR}`,
                }}
            />

            <Badge
                className={styles['investor-accent__badge']}
                variant="DOCUMENT_LENDER_ACCEPTED"
            >
                <Trans id="nestoValue" />
            </Badge>
        </>
    );
};
