import { Divider } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { PropertyDefaultFields } from 'components/dashboard/broker-dashboard-new/property-card/property-card.container';
import { NewMortgage } from 'components/dashboard/broker-dashboard-new/property-card/property-newMortgage-container';
import { Refinance } from 'components/dashboard/broker-dashboard-new/property-card/property-refinance-container';
import { Renewal } from 'components/dashboard/broker-dashboard-new/property-card/property-renewal-container';
import { selectedApplication } from 'store/applications';
import { getApplicationMainType } from 'utils/application-type';

export type PropertyModalProps = {
    isOpen: boolean;
    closeModal: () => void;
};

export const PropertyModal = () => {
    const { type } = useRecoilValue(selectedApplication);

    const { isNewApplication, isRenewalApplication, isRefinanceApplication } =
        getApplicationMainType(type);

    return (
        <>
            <PropertyDefaultFields showViewMore={false} />

            <Divider />
            {isRefinanceApplication && <Refinance />}
            {isNewApplication && <NewMortgage />}
            {isRenewalApplication && <Renewal />}
        </>
    );
};
