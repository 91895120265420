import styled from 'styled-components';

export const DividerVertical = styled.div`
    display: flex;
    align-items: stretch;
    align-self: stretch;
    justify-self: stretch;
    width: 1px;
    background-color: #9b9b9b;
    flex: 0 0 1px;
`;
