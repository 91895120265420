import React from 'react';

import css from '@styled-system/css';
import Link from 'next/link';
import { Flex } from 'reflexbox/styled-components';

import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';

type PullCreditMinimumRequirementProps = {
    applicationId: number;
    hasRequirementErrors: boolean;
    errors: Record<string, string>;
    onClickHereLink: () => void;
};

const errorMessageStyle = css({
    gap: 2,
    fontWeight: 700,
    color: 'status-critical',
    justifyContent: 'center',
});

export const PullCreditMinimumRequirement = ({
    applicationId,
    hasRequirementErrors = false,
    errors,
    onClickHereLink,
}: PullCreditMinimumRequirementProps) => {
    return hasRequirementErrors ? (
        <Grid
            gridArea="error"
            css={css({
                marginTop: 3,
                px: 3,
                gap: 1,
                textAlign: 'center',
                justifyContent: 'center',
            })}
        >
            {errors.firstName && (
                <Flex css={errorMessageStyle}>
                    <Text tx="First Name">:</Text>
                    <Text tx="error.fieldRequired" />
                </Flex>
            )}
            {errors.lastName && (
                <Flex css={errorMessageStyle}>
                    <Text tx="Last Name">:</Text>
                    <Text tx="error.fieldRequired" />
                </Flex>
            )}
            {errors.dateOfBirth && (
                <Flex css={errorMessageStyle}>
                    <Text tx="Date Of Birth">:</Text>
                    <Text tx="error.fieldRequired" />
                </Flex>
            )}
            {errors.addresses && (
                <Flex css={errorMessageStyle}>
                    <Text tx="pullCreditModal.currentAddressError" />
                </Flex>
            )}
            <Link
                href={`/applications/${applicationId}`}
                onClick={onClickHereLink}
            >
                <Text tx="clickHere" />
            </Link>
        </Grid>
    ) : null;
};
