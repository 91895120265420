import React from 'react';

import css from '@styled-system/css';

import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';

const LabelColor = {
    primary: '#9B9B9B',
    secondary: '#3D66B0',
};

type LabelWithValueProps = {
    label: string;
    value: string | number;
    gridArea?: string;
    showBorderRight?: boolean;
    preset?: 'primary' | 'secondary';
    onClick?: () => void;
};

export const LabelWithValue = ({
    label,
    value,
    gridArea,
    preset = 'primary',
    onClick,
}: LabelWithValueProps) => (
    <Grid
        gridColumnGap={10}
        gridArea={gridArea}
        gridAutoRows="auto"
        gridTemplateColumns={'1fr 1.5fr'}
        gridTemplateAreas={`"label value"`}
        css={{
            cursor: onClick ? 'pointer' : 'default',
        }}
        onClick={onClick}
    >
        <Grid
            gridArea="label"
            css={{
                padding: '10px 0',
            }}
        >
            <Text
                css={css({
                    fontSize: 1,
                    fontWeight: preset === 'secondary' ? 600 : 400,
                    color: LabelColor[preset],
                    paddingLeft: 10,
                    textDecoration: onClick ? 'underline' : 'none',
                })}
                tx={label}
            />
        </Grid>
        <Grid
            gridArea="value"
            css={css({ padding: '10px 0', fontSize: 2, fontWeight: 600 })}
        >
            {value}
        </Grid>
    </Grid>
);
