import { useState } from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Flex } from 'reflexbox';

import { Card } from 'components/card';
import { EditableCell } from 'components/editable';
import { getMainApplicant, getSubjectProperty } from 'store/applications';
import { MapsStreetView, MapsStreetViewLiteral } from 'types/index';
import { concatAddress, getEmploymentAddressOptions } from 'utils';

import { GoogleMapsView } from '../google-maps-ui/google-maps-view';
import { GoogleMapsViewSelector } from '../google-maps-ui/google-maps-view-selector/google-maps-view-selector';

import type { Props as FrameProps } from 'components/card/frame';

type Props = {
    applicationId: number;
};

export const GoogleMaps = ({ applicationId, ...rest }: Props & FrameProps) => {
    const { income } = useRecoilValue(getMainApplicant(applicationId));
    const { address } = useRecoilValue(getSubjectProperty(applicationId));
    const subjectPropertyAddress = concatAddress(address);

    const employmentAddressOptions = getEmploymentAddressOptions(
        income?.employments
    );

    const defaultValues = {
        subjectProperty: subjectPropertyAddress,
        employmentAddress: concatAddress(
            income?.employments[0]?.employer?.address
        ),
    };

    const methods = useForm({
        defaultValues,
    });

    let employmentAddress = defaultValues.employmentAddress;

    if (methods) {
        const { watch } = methods;

        employmentAddress = watch(
            'employmentAddress',
            defaultValues.employmentAddress || employmentAddress
        );
    }

    const [selectedView, setSelectedView] = useState<MapsStreetViewLiteral>(
        MapsStreetView.Roadmap
    );

    const updateSelectedView = (view: MapsStreetViewLiteral) => {
        setSelectedView(view);
    };

    return (
        <Card
            {...rest}
            css={css({
                position: 'relative',
                height: '550px',
            })}
        >
            <Flex justifyContent="center" margin="10px 0px">
                <h2>
                    <Trans id="targetProperty" />
                </h2>
            </Flex>
            <Flex flexDirection="column" marginBottom="10px">
                <FormProvider {...methods}>
                    <EditableCell
                        name="subjectProperty"
                        fieldType="text"
                        label="subjectProperty"
                        value={subjectPropertyAddress}
                    />
                    <EditableCell
                        isEditing
                        name="employmentAddress"
                        fieldType="select"
                        label="employmentAddress"
                        value={employmentAddress}
                        options={employmentAddressOptions}
                    />
                </FormProvider>
            </Flex>
            <GoogleMapsViewSelector
                view={selectedView}
                updateSelectedView={updateSelectedView}
            />
            <GoogleMapsView
                view={selectedView}
                subjectPropertyAddress={subjectPropertyAddress}
                employmentAddress={employmentAddress}
            />
        </Card>
    );
};
