import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { i18n } from '@lingui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { Grid } from 'components/grid/grid';
import { useToasts } from 'components/toast';
import { client as apiClient } from 'libs/api';
import {
    currentApplicationIdState,
    getApplicationMortgage,
} from 'store/applications';
import { getQualification } from 'store/qualification';
import { debounce } from 'utils';
import { mortgagePaymentsFrequencyCompareSchema } from 'validations/mortgages';

import FrequencyCompareDetails from './frequency-compare-details';
import FrequencyCompareFields from './frequency-compare-fields';

import type { AmountFrequency } from 'types';
import type { MortgagePaymentsCalculator as MortgagePaymentsCalculatorPayload } from 'types/calculator';

export const FrequencyCompareModal = () => {
    const [data, setData] = useState<AmountFrequency[]>();
    const [isLoading, setIsLoading] = useState(true);
    const { addToast } = useToasts();

    const applicationId = useRecoilValue(currentApplicationIdState);
    const { ltvEx } = useRecoilValue(getQualification(applicationId));
    const { amortizationYears, product, compoundPeriod } = useRecoilValue(
        getApplicationMortgage(applicationId)
    );

    /*
     * BE sends us ltvEx.mortgageAmount which already includes the override or the default value
     */
    const defaultValues = {
        mortgageAmount: ltvEx?.mortgageAmount || 0,
        rate: product?.bestRate || 0,
        compoundPeriod,
        amortization: amortizationYears,
    };

    const { handleSubmit, formState, ...rest } = useForm({
        resolver: yupResolver(mortgagePaymentsFrequencyCompareSchema(i18n)),
        defaultValues,
    });

    const onSubmit = async (values: MortgagePaymentsCalculatorPayload) => {
        try {
            setIsLoading(true);
            const { data } = await apiClient.getMortgagePayments(values);
            setData(data);
        } catch (error: unknown) {
            error instanceof Error &&
                addToast(error.message, {
                    appearance: 'error',
                    autoDismiss: true,
                });
        } finally {
            setIsLoading(false);
        }
    };

    const _handleSubmit = async (values: MortgagePaymentsCalculatorPayload) => {
        await onSubmit(values);
    };

    const onChangeValues = useCallback(
        debounce(async () => {
            handleSubmit(_handleSubmit)();
        }, 500),
        [handleSubmit]
    );

    useEffect(() => {
        onChangeValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid gridGap={10} gridTemplateColumns="1fr" gridAutoRows="auto">
            <FormProvider
                handleSubmit={handleSubmit}
                formState={formState}
                {...rest}
            >
                <Grid
                    gridGap={10}
                    gridTemplateColumns="repeat(4, 1fr)"
                    gridAutoRows="auto"
                    as="form"
                    onChange={onChangeValues}
                    onSubmit={handleSubmit(_handleSubmit)}
                    gridTemplateAreas={`
                        "mortgageAmount rate compoundPeriod amortization"
                        "mortgageAmountValue rateValue compoundPeriodValue amortizationValue"
                    `}
                    marginBottom={20}
                >
                    <FrequencyCompareFields
                        onChangeValues={onChangeValues}
                        defaultValues={defaultValues}
                    />
                </Grid>
            </FormProvider>
            <FrequencyCompareDetails payments={data} isLoading={isLoading} />
        </Grid>
    );
};
